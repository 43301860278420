var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var uV,wV,vZ,yna,zZ,DV,EV,Ika,FV,GV,HV,JV,KV,LV,ZV,$V,aW,cW,jW,kW,mW,nW,qW,rW,Hka;uV=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Vm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.xf(c)}),a)};
$CLJS.vV=function(a){var b=2>$CLJS.E(a);return b?b:$CLJS.R.h($CLJS.Su,function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){var g=f;if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v);$CLJS.Ff(q,$CLJS.gW(x,function(){return function(y){return $CLJS.Km.l(uV(y),$CLJS.iy,$CLJS.G([$CLJS.cb]))}}(v,x,l,n,q,g,f,b)));v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),e($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g);return $CLJS.nf($CLJS.gW(u,function(){return function(v){return $CLJS.Km.l(uV(v),$CLJS.iy,$CLJS.G([$CLJS.cb]))}}(u,g,f,b)),e($CLJS.zd(g)))}return null}},null,null)}(a)}())};
wV=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lg,a],null)],null);for(var c=$CLJS.Lg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,y,B,H,I){return function(Q){return $CLJS.oh.j(v,$CLJS.rg.g(function(Y,aa,ha,qa,Ea){return function(kb){var lb=$CLJS.J(kb,0,null);kb=$CLJS.J(kb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kf.h(Ea,lb),kb],null)}}(q,u,v,x,y,B,H,I)),Q)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.kf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.dg($CLJS.an,g)):n}else return c}};vZ=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Ie(b,$CLJS.M.h(c,2))};$CLJS.wZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Sm.h($CLJS.jC,$CLJS.Zd)),$CLJS.RP.g(a));return wV($CLJS.Km.l(a,$CLJS.mN,$CLJS.G([$CLJS.hV])),function(c){return vZ($CLJS.fy,b,c)})};
$CLJS.xZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Sm.h($CLJS.yC,$CLJS.Zd)),$CLJS.nE.g(a));return wV($CLJS.Km.l(a,$CLJS.mN,$CLJS.G([$CLJS.hV])),function(c){return vZ($CLJS.nE,b,c)})};yna=function(a){function b(d){return $CLJS.sg(c,$CLJS.G([$CLJS.mN.g(d)]))}function c(d){return $CLJS.nf($CLJS.OC.g(d),$CLJS.sg(b,$CLJS.G([$CLJS.aL.g(d)])))}return $CLJS.m($CLJS.xV.g(a))?$CLJS.ag(!0):$CLJS.si(b(a))};
zZ=function(a){if($CLJS.ne(a))for(var b=$CLJS.ag(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),y=$CLJS.D(v),B=x,H=y,I=$CLJS.Tx(n,yna(B));f=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(Q,Y,aa,ha,qa,Ea){return function Mb(lb,Fb){try{if($CLJS.qe(Fb)&&3===$CLJS.E(Fb))try{var ac=$CLJS.Td(Fb,0);if($CLJS.O(ac,$CLJS.yE))try{var Tb=$CLJS.Td(Fb,1);if($CLJS.m($CLJS.lP.g(Tb)))try{var Ub=$CLJS.Td(Fb,1);if(null!=Ub?Ub.C&256||$CLJS.t===Ub.bg||(Ub.C?0:$CLJS.Nb($CLJS.lc,
Ub)):$CLJS.Nb($CLJS.lc,Ub))try{var ge=$CLJS.M.j(Ub,$CLJS.lP,$CLJS.mV);if($CLJS.m($CLJS.$f(ha)(ge))){var aA=$CLJS.M.h(Ub,$CLJS.lP);$CLJS.Td(Fb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Ea),": no join named ",$CLJS.aj.l($CLJS.G([aA]))].join("")],null)}throw $CLJS.Z;}catch(Og){if(Og instanceof Error){var tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;
}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)return $CLJS.tZ(Mb,lb,Fb);throw tj;}throw Og;}}}(f,g,l,I,n,q,u,v,x,y,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Lg,$CLJS.Km.l(B,$CLJS.mN,$CLJS.G([Hka]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;
else return null}else return null};DV=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);EV=new $CLJS.w("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Ika=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);FV=new $CLJS.w(null,"distinct-refs?","distinct-refs?",-2065255505,null);
GV=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);HV=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.IV=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);JV=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
KV=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);LV=new $CLJS.w(null,"refs","refs",80480079,null);ZV=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);$V=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);aW=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.bW=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);cW=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);jW=new $CLJS.N("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);kW=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);mW=new $CLJS.N("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
nW=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.oW=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.xV=new $CLJS.N(null,"source-card","source-card",-1580820390);$CLJS.pW=new $CLJS.N(null,"filters","filters",974726919);qW=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);rW=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.sW=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Hka=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.lL(ZV,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.bW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aQ,$CLJS.Hb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Us,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.Lb],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xQ,new $CLJS.k(null,1,[$CLJS.Ks,
!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.YT],null)],null)],null));$CLJS.lL(jW,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.CE],null));
$CLJS.lL(DV,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),jW],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Breakouts must be distinct"],null),new $CLJS.ud(function(){return $CLJS.vV},EV,$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.iV,FV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.X(new $CLJS.S(null,1,5,$CLJS.T,[LV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.m($CLJS.vV)?$CLJS.vV.J:null]))],null)],null));
$CLJS.lL(kW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.CE],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,":fields must be distinct"],null),new $CLJS.ud(function(){return $CLJS.vV},EV,$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.iV,FV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.X(new $CLJS.S(null,
1,5,$CLJS.T,[LV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.m($CLJS.vV)?$CLJS.vV.J:null]))],null)],null));$CLJS.lL(mW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.UD],null),$CLJS.ik,$CLJS.ak],null)],null));
$CLJS.lL(cW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),mW],null));
var SZ=$CLJS.Tx(function(a){a=$CLJS.C($CLJS.wZ(a));if($CLJS.m(a)){var b=$CLJS.Lu(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null));return $CLJS.m(b)?["Invalid :expression reference: no expression named ",$CLJS.aj.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.g($CLJS.M.h(a,1))].join("")}return null},function(a){a=$CLJS.C($CLJS.xZ(a));if($CLJS.m(a)){var b=$CLJS.Lu(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null));return $CLJS.m(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.g(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.g($CLJS.M.h(a,1))].join("")}return null});$CLJS.lL(nW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"Valid references for a single query stage",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return SZ(a)}],null),$CLJS.$f(SZ)],null));
$CLJS.lL($CLJS.sW,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.IV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.ZK],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.LC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.mE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),DV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),kW],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pW,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),cW],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GR,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.pL],null)],
null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yK],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xV,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.FK],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ie(a,$CLJS.zQ)}],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,
new $CLJS.k(null,1,[$CLJS.tt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.PB($CLJS.XO,$CLJS.xV))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,nW],null)],null));$CLJS.lL(GV,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.sW],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XO,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yK],null)],null)],null)],null));
$CLJS.lL(HV,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.sW],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xV,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.FK],null)],null)],null)],null));$CLJS.lL(KV,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,GV],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,HV],null)],null));
$CLJS.lL($V,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.sW],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.Tx($CLJS.XO,$CLJS.xV))],null)],null));$CLJS.lL(aW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,$CLJS.bW,$CLJS.IV],null));
$CLJS.lL(Ika,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,aW],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,$CLJS.cu],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bW,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ZV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IV,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.sW],null)],null)],null)],null));
$CLJS.lL(rW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,aW],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,$CLJS.cu],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bW,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ZV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IV,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,KV],null)],null)],null)],null));$CLJS.lL(JV,$V);
$CLJS.lL(qW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"Valid references for all query stages",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return zZ.g?zZ.g(a):zZ.call(null,a)}],null),$CLJS.$f(zZ)],null));
$CLJS.lL($CLJS.XK,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ks,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,rW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,JV],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,qW],null)],null));
$CLJS.lL($CLJS.JL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.oW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.GK,$CLJS.NK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.XK],null)],null)],null),$CLJS.xna],null));