var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var Jsa,Ksa,Lsa,c0,f0,g0,Msa,Vma,Nsa,h0,i0,Wma;$CLJS.V_=function(a){var b=$CLJS.Zd($CLJS.Di($CLJS.Fi(["(.*?)",$CLJS.cI($CLJS.G([$CLJS.fI])),"$"].join("")),a));return $CLJS.m(b)?b:a};Jsa=function(a,b){return a.isSame(b,"day")};Ksa=function(a,b){return a.isSame(b,"month")};Lsa=function(a,b){return a.isSame(b,"year")};
c0=function(a){return $CLJS.Xf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);var d=$CLJS.Qf(b);b=$CLJS.M.h(d,$CLJS.Xk);d=$CLJS.M.h(d,$CLJS.S_);return $CLJS.m($CLJS.Di(b,a))?(b=$CLJS.XL.parseZone(a,d,!0),$CLJS.m(b.isValid())?new $CLJS.S(null,2,5,$CLJS.T,[b,c],null):null):null},$CLJS.Uma)};f0=function(a){return a instanceof Date?$CLJS.XL.utc(a):a};g0=function(a,b,c){b="string"===typeof b?$CLJS.C(c0(b)):f0(b);return("string"===typeof c?$CLJS.C(c0(c)):f0(c)).diff(b,$CLJS.ui(a))};
Msa=function(a,b){return g0.j?g0.j($CLJS.fl,a,b):g0.call(null,$CLJS.fl,a,b)};Vma=function(a,b){if("string"===typeof a)return a;a=f0(a);var c=function(){if($CLJS.QH(b,$CLJS.Yk))return $CLJS.P_;if($CLJS.QH(b,$CLJS.gl))return $CLJS.O_;if($CLJS.QH(b,$CLJS.Lk))return $CLJS.T_;if($CLJS.QH(b,$CLJS.Uk))return $CLJS.Q_;if($CLJS.QH(b,$CLJS.Ik))return $CLJS.R_;throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}();return $CLJS.U_.g(new $CLJS.S(null,2,5,$CLJS.T,[a,c],null))};
$CLJS.k7=function(a){if($CLJS.m($CLJS.XL.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.XL)($CLJS.V_(a),Nsa);throw $CLJS.zj("Unknown input to coerce-to-time; expecting a string",new $CLJS.k(null,1,[$CLJS.vl,a],null));};Nsa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];h0=function h0(a,b){if("string"===typeof a){var d=c0(a);a=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);b=h0.h?h0.h(a,b):h0.call(null,a,b);return $CLJS.U_.g(new $CLJS.S(null,2,5,$CLJS.T,[b,d],null))}a=f0(a);return a.startOf($CLJS.ui(b))};
i0=function i0(a,b,c){if("string"===typeof a){var e=c0(a);a=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);b=i0.j?i0.j(a,b,c):i0.call(null,a,b,c);return $CLJS.U_.g(new $CLJS.S(null,2,5,$CLJS.T,[b,e],null))}a=f0(a);return a.add(c,$CLJS.ui(b))};Wma=new $CLJS.N(null,"local","local",-1497766724);$CLJS.l7=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.f7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.m7=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.j7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Psa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Jsa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Qsa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Ksa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Rsa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Lsa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Ssa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Msa,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Xma=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(g0,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.j0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(h0,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Yma=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(i0,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Zma=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Vma,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.n7=function n7(a){switch(arguments.length){case 1:return n7.g(arguments[0]);case 2:return n7.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.shared.util.time.coerce_to_timestamp",$CLJS.n7);$CLJS.n7.g=function(a){return $CLJS.n7.h(a,$CLJS.P)};
$CLJS.n7.h=function(a,b){b=$CLJS.nn.l($CLJS.G([$CLJS.P,$CLJS.qG(b)]));if(!$CLJS.m($CLJS.i7(a))){var c=(c="string"===typeof a)?$CLJS.Di(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.m(c)?$CLJS.XL.parseZone(a):"string"===typeof a?$CLJS.g7.h(a,b):$CLJS.h7.h(a,b)}return $CLJS.m(Wma.g(b))?a.local():a};$CLJS.n7.v=2;module.exports={coerce_to_timestamp:$CLJS.n7,coerce_to_time:$CLJS.k7};