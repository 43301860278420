var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Rza=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Sza=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},Tza=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c},Uza=function(a,b,c,d){return $CLJS.VZ(a,b,c,d,$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.YY,A9],null),new $CLJS.k(null,1,[$CLJS.WY,!0],null)])))},Vza=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.iy);b=$CLJS.M.h(b,$CLJS.cb);a=$CLJS.m(b)?
b:a;return $CLJS.QH(a,$CLJS.ek)||$CLJS.QH(a,$CLJS.Gj)},Wza=function(a,b,c,d){var e=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(f,g){return new $CLJS.S(null,2,5,$CLJS.T,[g,f],null)}),d);return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);y=$CLJS.y1.D(a,b,y,d);y=$CLJS.M.j(e,y,-1);v.add(y);x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),
null)}v=$CLJS.C(n);v=$CLJS.y1.D(a,b,v,d);return $CLJS.nf($CLJS.M.j(e,v,-1),l($CLJS.zd(n)))}return null}},null,null)}(c)},I9=function(a,b){return new $CLJS.Yi(function(){try{return $CLJS.F9.h(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.ZF($CLJS.xw);$CLJS.m($CLJS.fG("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.gG("metabase.lib.js.metadata",d,$CLJS.X_("Error parsing %s objects: %s",$CLJS.G([a,$CLJS.UZ(c)])),c):$CLJS.gG("metabase.lib.js.metadata",d,$CLJS.X_(c,$CLJS.G(["Error parsing %s objects: %s",
a,$CLJS.UZ(c)])),null));return null}throw e;}})},iwa=function(a,b){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.be(g,x);$CLJS.J(y,0,null);var B=$CLJS.J(y,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.KK.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),
e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.F.h($CLJS.KK.g(v),b):x}()))return $CLJS.nf(v,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.JC.g(a);return null==d?null:$CLJS.r(d)}())},jwa=function(a,b){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=
$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.be(g,x);$CLJS.J(y,0,null);var B=$CLJS.J(y,1,null),H=function(){var I=B;return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.KK.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=
v;return $CLJS.m(x)?$CLJS.F.h($CLJS.KK.g(v),b):x}()))return $CLJS.nf(v,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.z8.g(a);return null==d?null:$CLJS.r(d)}())},Xza=function(a,b){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.be(g,x);$CLJS.J(y,0,null);var B=$CLJS.J(y,1,null),H=function(){var I=B;
return null==I?null:$CLJS.r(I)}();$CLJS.m(function(){var I=H;return $CLJS.m(I)?$CLJS.F.h($CLJS.KK.g(H),b):I}())&&n.add(H);x+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);$CLJS.J(q,0,null);var u=$CLJS.J(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.F.h($CLJS.KK.g(v),b):x}()))return $CLJS.nf(v,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.B8.g(a);return null==d?null:$CLJS.r(d)}())},B9=function(a,b,c,d){this.Xg=a;this.zj=b;this.metadata=c;this.li=d;this.C=393216;this.K=0},Yza=function(a,b){var c=new $CLJS.k(null,6,[$CLJS.rza,I9($CLJS.MP,b),$CLJS.U8,I9($CLJS.tk,b),$CLJS.JC,I9($CLJS.yE,b),$CLJS.jza,I9($CLJS.eN,b),$CLJS.z8,I9($CLJS.tE,b),$CLJS.B8,I9($CLJS.UD,b)],null),d=$CLJS.ZF($CLJS.ww);$CLJS.m($CLJS.fG("metabase.lib.js.metadata",d))&&$CLJS.gG("metabase.lib.js.metadata",d,$CLJS.WB.l($CLJS.G(["Created metadata provider for metadata"])),
null);return new B9(a,b,c,$CLJS.P)},Zza=function(a){return $CLJS.Ib(a)?Rza(a,function(b){return void 0!==b}):a},$za=function(a){return $CLJS.Mu($CLJS.jF(Sza(a,function(b){return Zza(b)})),function(b){return $CLJS.Xm.j($CLJS.dG(b,$CLJS.Oi),$CLJS.$k,$CLJS.Oi)})},K9=function(a,b){if($CLJS.O2(b))a=b;else{var c=$CLJS.p.g(a);a=$CLJS.VZ(c,b,b,$CLJS.bg(Yza,a),new $CLJS.k(null,2,[$CLJS.YY,$CLJS.RZ,$CLJS.WY,!0],null))}return a},aAa=function(a){a=$CLJS.kG(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",
$CLJS.Pu(a,/\?$/,"")].join(""):a;return $CLJS.WG.g?$CLJS.WG.g(a):$CLJS.WG.call(null,a)},E9=function(a){return $CLJS.oe(a)?C9.g?C9.g(a):C9.call(null,a):$CLJS.ne(a)?D9.g?D9.g(a):D9.call(null,a):a instanceof $CLJS.N?$CLJS.kG(a):a},L9=function(a,b){return $CLJS.HW($CLJS.HW((0,$CLJS.S1)($CLJS.H9($CLJS.jF(a))),$CLJS.aQ,function(c){return $CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.xQ,$CLJS.P],null),c]))}),$CLJS.SD,function(c){var d=function(){var e=$CLJS.JC.g(c);return $CLJS.m(e)?e:function l(g){return new $CLJS.yf(null,
function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);v.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,y,null],null));x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}v=$CLJS.C(n);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,v,null],null),l($CLJS.zd(n)))}return null}},null,null)}(b)}();return $CLJS.U.j(c,$CLJS.JC,$CLJS.hwa(d))})},bAa=function(a,b){function c(l,
n){l=$CLJS.Gb($CLJS.iy.g(n))?$CLJS.Km.h(l,$CLJS.iy):l;n=$CLJS.Gb($CLJS.cb.g(n))?$CLJS.Km.h(l,$CLJS.cb):l;return $CLJS.Rf(n)}var d=$CLJS.J(a,0,null),e=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var f=$CLJS.J(b,0,null),g=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return $CLJS.F.h(new $CLJS.S(null,3,5,$CLJS.T,[d,e,c(a,b)],null),new $CLJS.S(null,3,5,$CLJS.T,[f,g,c(b,a)],null))},cAa=function(a,b){var c=$CLJS.e3(a,b),d=$CLJS.a2();return $CLJS.Oe($CLJS.rg.h(function(e){return $CLJS.Xm.j($CLJS.U.j(e,$CLJS.EY,!0),$CLJS.V,
d)},$CLJS.L_.j(a,b,c)))},dAa=function(a){return $CLJS.m(function(){var b=$CLJS.C(a),c=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.UD,null,$CLJS.nE,null,$CLJS.tE,null],null),null);return c.g?c.g(b):c.call(null,b)}())?$CLJS.bn.j(a,0,2):$CLJS.Xm.D(a,2,$CLJS.Mu,function(b){return $CLJS.xf(b)?$CLJS.kG(b):b})},J9=function(a){return $CLJS.r2.g($CLJS.Xm.j($CLJS.iF(a,$CLJS.G([$CLJS.hk,!0])),0,$CLJS.Oi))},eAa=function(a){var b=$CLJS.Ib(a)?a:null;return $CLJS.m(b)?(a=$CLJS.y9.g?$CLJS.y9.g(b):$CLJS.y9.call(null,
b),$CLJS.F.h($CLJS.vK.g(a),$CLJS.UK)?(b=J9(b.field_ref),$CLJS.U.j(a,$CLJS.m0,$CLJS.$d(b))):a):a},M9=function(a,b){var c=null==a?null:$CLJS.C(a);c=null==c?null:$CLJS.F.h(c,$CLJS.nE);return $CLJS.m(c)?$CLJS.U.j(b,$CLJS.m0,$CLJS.$d(a)):b},S9=function(a,b,c){b=$CLJS.zua.l($CLJS.G([a,b]));a=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);b=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(g,l){$CLJS.J(l,
0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[l,g],null)}),b);var d=$CLJS.q2,e=$CLJS.p2;$CLJS.q2=a;$CLJS.p2=b;try{var f=function(){var g=c.field_ref;return $CLJS.m(g)?J9(g):null}();return M9(f,$CLJS.y9.g?$CLJS.y9.g(c):$CLJS.y9.call(null,c))}finally{$CLJS.p2=e,$CLJS.q2=d}},fAa=function(a){return function(b){var c=function(){var e=a.g?a.g(b):a.call(null,b);return $CLJS.y9.g?$CLJS.y9.g(e):$CLJS.y9.call(null,e)}(),d=function(){var e=$CLJS.o1.g(c);
return $CLJS.m(e)?J9(e):null}();return new $CLJS.k(null,3,[$CLJS.ob,M9(d,c),$CLJS.a5,d,$CLJS.vl,b.value],null)}},A9=function A9(a){switch(arguments.length){case 0:return A9.A();case 3:return A9.j(arguments[0],arguments[1],arguments[2]);case 4:return A9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};A9.A=function(){return{}};A9.j=function(a,b,c){a=$CLJS.Ua(a,b);return $CLJS.m(a)?a.get(c):null};
A9.D=function(a,b,c,d){return Tza(a,b,function(){return new WeakMap}).set(c,d)};A9.v=4;$CLJS.h=B9.prototype;$CLJS.h.O=function(a,b){return new B9(this.Xg,this.zj,this.metadata,b)};$CLJS.h.N=function(){return this.li};$CLJS.h.ej=$CLJS.t;$CLJS.h.nh=function(a,b){a=this.metadata;a=null==a?null:$CLJS.z8.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.oh=function(a,b){return jwa(this.metadata,b)};
$CLJS.h.qh=function(a,b){a=this.metadata;a=null==a?null:$CLJS.U8.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.mh=function(a,b){return iwa(this.metadata,b)};$CLJS.h.lh=function(a,b){a=this.metadata;a=null==a?null:$CLJS.JC.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.Be=function(a,b){return Xza(this.metadata,b)};$CLJS.h.Se=function(a,b){return $CLJS.r9($CLJS.r9(this.zj,"settings"),$CLJS.ui(b))};
$CLJS.h.jh=function(a,b){a=this.metadata;a=null==a?null:$CLJS.jza.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.ph=function(a,b){a=this.metadata;a=null==a?null:$CLJS.B8.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.kh=function(){var a=this.metadata,b=this.Xg;a=null==a?null:$CLJS.rza.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.M.h(a,b);return null==b?null:$CLJS.r(b)};
var gAa=new $CLJS.N(null,"can-save","can-save",-462869392),hAa=new $CLJS.N(null,"can-run","can-run",1635004421);var U9=function U9(a){switch(arguments.length){case 1:return U9.g(arguments[0]);case 2:return U9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.extract_template_tags",U9);U9.g=function(a){return U9.h(a,$CLJS.P)};U9.h=function(a,b){return $CLJS.qn($CLJS.Uya.l($CLJS.G([a,$za(b)])))};U9.v=2;
var V9=function V9(a){switch(arguments.length){case 2:return V9.h(arguments[0],arguments[1]);case 3:return V9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.query",V9);V9.h=function(a,b){return $CLJS.uva.l($CLJS.G([a,b]))};V9.j=function(a,b,c){return Uza($CLJS.p.g(a),b,c,function(d){return $CLJS.uva.l($CLJS.G([b,$CLJS.r2.g((0,$CLJS.S1)($CLJS.X7($CLJS.g6(d),$CLJS.$k,$CLJS.SD)))]))})};V9.v=3;
var N9=function N9(a){return $CLJS.xf(a)?[$CLJS.qf(a),"/",$CLJS.ui(a)].join(""):$CLJS.oe(a)?$CLJS.Mu(a,N9):$CLJS.ne(a)?$CLJS.rg.h(N9,a):a},C9=$CLJS.jG(function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);d=aAa(d);c=E9.g?E9.g(c):E9.call(null,c);b[d]=c;return b},{},a)}),D9=$CLJS.jG(function(a){return $CLJS.Oe($CLJS.rg.h(E9,a))}),O9=function O9(a){switch(arguments.length){case 1:return O9.g(arguments[0]);case 2:return O9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.order_by_clause",O9);O9.g=function(a){return O9.h(a,$CLJS.vE)};O9.h=function(a,b){return $CLJS.rva.l($CLJS.G([$CLJS.p9.l($CLJS.G([$CLJS.iF(a,$CLJS.G([$CLJS.hk,!0]))])),$CLJS.Oi.g(b)]))};O9.v=2;var P9=function P9(a){switch(arguments.length){case 2:return P9.h(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.Ra("metabase.lib.js.available_binning_strategies",P9);P9.h=function(a,b){return $CLJS.Oe($CLJS.o9.l($CLJS.G([a,b])))};P9.j=function(a,b,c){return $CLJS.Oe($CLJS.o9.l($CLJS.G([a,b,c])))};P9.v=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.h(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.available_temporal_buckets",Q9);
Q9.h=function(a,b){return $CLJS.Oe($CLJS.q9.l($CLJS.G([a,b])))};Q9.j=function(a,b,c){return $CLJS.Oe($CLJS.q9.l($CLJS.G([a,b,c])))};Q9.v=3;
var W9=function W9(a,b){return $CLJS.qe(a)&&$CLJS.qe(b)&&$CLJS.F.l($CLJS.C(a),$CLJS.C(b),$CLJS.G([$CLJS.yE]))?bAa(a,b):$CLJS.oe(a)&&$CLJS.oe(b)?$CLJS.F.h($CLJS.si($CLJS.gi(a)),$CLJS.si($CLJS.gi(b)))&&$CLJS.Wf(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);e=$CLJS.M.h(b,e);return W9.h?W9.h(d,e):W9.call(null,d,e)},a):$CLJS.ne(a)&&$CLJS.ne(b)?$CLJS.F.h($CLJS.E(a),$CLJS.E(b))&&$CLJS.Wf($CLJS.ue,$CLJS.rg.j(W9,a,b)):$CLJS.F.h(a,b)},R9=function R9(a){switch(arguments.length){case 2:return R9.h(arguments[0],
arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.js.query_EQ_",R9);R9.h=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=L9(a,c);b=L9(b,c);return W9(a,b)};R9.v=3;var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};
$CLJS.Ra("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.R.D($CLJS.Uua,a,b,c)};T9.v=2;T9.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};var X9=function X9(a){switch(arguments.length){case 3:return X9.j(arguments[0],arguments[1],arguments[2]);case 4:return X9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.Ra("metabase.lib.js.with_different_database",X9);X9.j=function(a,b,c){return X9.D(a,b,c,null)};X9.D=function(a,b,c,d){return $CLJS.Sya.l($CLJS.G([a,K9(b,c),$CLJS.iF(d,$CLJS.G([$CLJS.hk,!0]))]))};X9.v=4;var iAa=fAa(function(a){return a.col}),jAa=fAa(function(a){return a.column}),Y9=function Y9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Y9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.z(c.slice(3),0,null):null)};
$CLJS.Ra("metabase.lib.js.drill_thru",Y9);Y9.l=function(a,b,c,d){return $CLJS.R.R($CLJS.nya,a,b,c,d)};Y9.v=3;Y9.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("returned-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){return cAa(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.zua.l($CLJS.G([a,b]));a=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);b=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=
$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[l,g],null)}),b);var d=$CLJS.q2,e=$CLJS.p2;$CLJS.q2=a;$CLJS.p2=b;try{var f=$CLJS.i2.g(c);return $CLJS.qn(function(){var g;if(g=$CLJS.qe(f)){g=$CLJS.C(f);var l=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.vl,null,$CLJS.dM,null],null),null);g=l.g?l.g(g):l.call(null,g)}return $CLJS.m(g)?$CLJS.M.h(f,1):f}())}finally{$CLJS.p2=e,$CLJS.q2=d}},drop_stage_if_empty:function(a){return $CLJS.bza.l($CLJS.G([a]))},extract_template_tags:U9,engine:function(a){return $CLJS.ui($CLJS.Nya.l($CLJS.G([a])))},
filter_operator:function(a,b,c){return $CLJS.Aya.l($CLJS.G([a,b,c]))},binning:function(a){return $CLJS.Cua.l($CLJS.G([a]))},query:V9,columns_group_columns:function(a){return $CLJS.Oe($CLJS.Hua.l($CLJS.G([a])))},join_fields:function(a){a=$CLJS.bva.l($CLJS.G([a]));return a instanceof $CLJS.N?$CLJS.kG(a):$CLJS.Oe(a)},aggregate:function(a,b,c){return $CLJS.xua.l($CLJS.G([a,b,$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))]))},join_conditions:function(a){return $CLJS.Oe($CLJS.ava.l($CLJS.G([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Dva.l($CLJS.G([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var g=$CLJS.zua.l($CLJS.G([a,b])),l=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(v,x){$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.Qf(x);x=$CLJS.M.h(x,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[v,x],null)}),g);g=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(v,x){$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.Qf(x);x=$CLJS.M.h(x,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[x,v],null)}),g);var n=$CLJS.q2,q=$CLJS.p2;$CLJS.q2=l;$CLJS.p2=g;try{var u=function(){var v=$CLJS.m(c)?
c.field_ref:c;return $CLJS.m(v)?J9(v):null}();return $CLJS.Oe($CLJS.mya.l($CLJS.G([a,b,$CLJS.nn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.ob,$CLJS.m(c)?M9(u,$CLJS.y9.g?$CLJS.y9.g(c):$CLJS.y9.call(null,c)):null,$CLJS.a5,u,$CLJS.vl,void 0===d?null:null==d?$CLJS.S5:d],null),$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.Ga,$CLJS.Wm.h(iAa,e)],null):null,$CLJS.m($CLJS.Rf(f))?new $CLJS.k(null,1,[$CLJS.BL,$CLJS.Wm.h(jAa,f)],null):null]))])))}finally{$CLJS.p2=q,$CLJS.q2=n}},has_clauses:function(a,b){return $CLJS.cza.l($CLJS.G([a,
b]))},remove_clause:function(a,b,c){return $CLJS.wva.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.QZ(a);if($CLJS.m(b))return b;a=$CLJS.s1(a);return null==a?null:["card__",$CLJS.p.g(a)].join("")},legacy_ref:function(a,b,c){b=$CLJS.nE.g($CLJS.e3(a,b));a=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(f,g){$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);g=$CLJS.Qf(g);g=$CLJS.M.h(g,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[f,g],null)}),b);
b=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(f,g){$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);g=$CLJS.Qf(g);g=$CLJS.M.h(g,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[g,f],null)}),b);var d=$CLJS.q2,e=$CLJS.p2;$CLJS.q2=a;$CLJS.p2=b;try{return $CLJS.qn(dAa($CLJS.i2.g($CLJS.vva.l($CLJS.G([c])))))}finally{$CLJS.p2=e,$CLJS.q2=d}},replace_clause:function(a,b,c,d){return $CLJS.xva.l($CLJS.G([a,b,$CLJS.p9.l($CLJS.G([$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))])),$CLJS.p9.l($CLJS.G([$CLJS.iF(d,$CLJS.G([$CLJS.hk,!0]))]))]))},
drop_stage:function(a){return $CLJS.zva.l($CLJS.G([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.Hya.l($CLJS.G([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.yya.l($CLJS.G([a,b,c]))},segment_metadata:function(a,b){return $CLJS.a8(a,b)},pivot_types:function(a){return $CLJS.Oe($CLJS.rg.h($CLJS.ui,$CLJS.pya.l($CLJS.G([a]))))},native_extras:function(a){return $CLJS.qn($CLJS.Qya.l($CLJS.G([a])))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.Dua.l($CLJS.G([a,b]))},
append_stage:function(a){return $CLJS.yva.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.Wua.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.Oe($CLJS.zua.l($CLJS.G([a,b])))},drill_thru:Y9,raw_native_query:function(a){return $CLJS.Lya.l($CLJS.G([a]))},filterable_column_operators:function(a){return $CLJS.Oe($CLJS.Tua.l($CLJS.G([a])))},with_temporal_bucket:function(a,b){return $CLJS.Eva.l($CLJS.G([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.EZ(a)&&$CLJS.EZ(b)||$CLJS.IZ(a)&&
$CLJS.IZ(b)||$CLJS.CZ(a)&&$CLJS.CZ(b)||$CLJS.QH($CLJS.iy.g(a),$CLJS.iy.g(b))},with_join_conditions:function(a,b){return $CLJS.hva.l($CLJS.G([a,$CLJS.iF(b,$CLJS.G([$CLJS.hk,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.iF(e,$CLJS.G([$CLJS.hk,!0]));c=S9(a,b,c);d=S9(a,b,d);return $CLJS.Dya.l($CLJS.G([a,b,c,d,e]))},available_metrics:function(a,b){return $CLJS.Oe($CLJS.kva.l($CLJS.G([a,b])))},with_different_database:X9,with_fields:function(a,b,c){return $CLJS.Oua.l($CLJS.G([a,b,c]))},is_column_metadata:function(a){return $CLJS.oe(a)&&
$CLJS.F.h($CLJS.tK,$CLJS.cu.g(a))},filter_args_display_name:function(a,b,c){return $CLJS.wya.l($CLJS.G([a,b,c]))},join_clause:function(a,b){return $CLJS.Xua.l($CLJS.G([a,b]))},aggregation_clause:function(a,b){return void 0===b?$CLJS.n9.l($CLJS.G([a])):$CLJS.n9.l($CLJS.G([a,b]))},external_op:function(a){a=$CLJS.Jua.l($CLJS.G([a]));var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.sC);var c=$CLJS.M.h(b,$CLJS.Bj);b=$CLJS.M.h(b,$CLJS.Us);return{operator:a,options:$CLJS.qn(c),args:$CLJS.Oe(b)}},with_join_fields:function(a,
b){return $CLJS.fva.l($CLJS.G([a,"string"===typeof b?$CLJS.Oi.g(b):b]))},visible_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("visible-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){var c=$CLJS.e3(a,b),d=$CLJS.N_.j(a,b,c);c=$CLJS.L_.j(a,b,c);return $CLJS.Oe($CLJS.M2(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=S9(a,b,c);return $CLJS.Eya.l($CLJS.G([a,b,c,d,e]))},available_binning_strategies:P9,update_temporal_filter:function(a,b,c,d,e){c=S9(a,b,c);return $CLJS.Fya.l($CLJS.G([a,
b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.nE.g($CLJS.e3(a,b)),f=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(u,v){$CLJS.J(v,0,null);v=$CLJS.J(v,1,null);v=$CLJS.Qf(v);v=$CLJS.M.h(v,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[u,v],null)}),e);e=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(u,v){$CLJS.J(v,0,null);v=$CLJS.J(v,1,null);v=$CLJS.Qf(v);v=$CLJS.M.h(v,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[v,u],null)}),e);var g=$CLJS.q2,l=$CLJS.p2;$CLJS.q2=f;$CLJS.p2=e;try{var n=
$CLJS.Wm.h(eAa,c),q=$CLJS.rg.h(J9,d);return $CLJS.Oe(Wza(a,b,q,n))}finally{$CLJS.p2=l,$CLJS.q2=g}},expression:function(a,b,c,d){return $CLJS.Kua.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Oi.g(a):a;b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Cva.l($CLJS.G([a,b]))},order_bys:function(a,b){return $CLJS.Oe($CLJS.sva.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.a_($CLJS.Oi.h("display-info-outer",["stage-",$CLJS.p.g(b)].join("")),
c,function(d){return E9($CLJS.iva.l($CLJS.G([$CLJS.M5(a,b),b,d])))})},can_run:function(a){return $CLJS.a_(hAa,a,function(){return $CLJS.Vya.l($CLJS.G([a]))})},fieldable_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("fieldable-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){return $CLJS.Oe($CLJS.Pua.l($CLJS.G([a,b])))})},template_tags:function(a){return $CLJS.qn($CLJS.ova.l($CLJS.G([a])))},selected_aggregation_operators:function(a,b){return $CLJS.Oe($CLJS.Bua.l($CLJS.G([$CLJS.A(a),b])))},
order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("filterable-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){return $CLJS.Oe($CLJS.Sua.l($CLJS.G([a,b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.Oe($CLJS.$ua.l($CLJS.G([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Gya.l($CLJS.G([a,b,c,d]))},filters:function(a,b){return $CLJS.Oe($CLJS.Rua.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,
c,d,e){return $CLJS.Oe($CLJS.Yua.l($CLJS.G([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.h1(a,b)},with_expression_name:function(a,b){return $CLJS.sya.l($CLJS.G([a,b]))},orderable_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("orderable-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){return $CLJS.Oe($CLJS.k9.h(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Bya.l($CLJS.G([a,b,$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))]))},suggestedName:function(a){return $CLJS.jva.l($CLJS.G([a]))},
find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.qya.l($CLJS.G([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Tya.l($CLJS.G([a]))},metadataProvider:K9,filter_clause:T9,fields:function(a,b){return $CLJS.Oe($CLJS.Nua.l($CLJS.G([a,b])))},dependent_metadata:function(a){return $CLJS.Oe($CLJS.rg.h($CLJS.qn,$CLJS.tya.l($CLJS.G([a]))))},available_aggregation_operators:function(a,b){return $CLJS.Oe($CLJS.Aua.l($CLJS.G([a,b])))},breakouts:function(a,b){return $CLJS.Oe($CLJS.Gua.l($CLJS.G([a,
b])))},joinable_columns:function(a,b,c){return $CLJS.Oe($CLJS.Iya.l($CLJS.G([a,b,c])))},group_columns:function(a){return $CLJS.Oe($CLJS.Iua.l($CLJS.G([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Ava.l($CLJS.G([a,b]))},breakout_column:function(a,b,c){return $CLJS.kya.l($CLJS.G([a,b,c]))},joins:function(a,b){return $CLJS.Oe($CLJS.dva.l($CLJS.G([a,b])))},native_query:function(a,b,c){return $CLJS.Kya.l($CLJS.G([K9(a,b),c]))},available_join_strategies:function(a,
b){return $CLJS.Oe($CLJS.Vua.l($CLJS.G([a,b])))},legacy_query:function(a){return $CLJS.qn.l(N9($CLJS.i2.g(a)),$CLJS.G([$CLJS.um,$CLJS.kG]))},stage_count:function(a){return $CLJS.Xya.l($CLJS.G([a]))},expression_clause:function(a,b,c){return $CLJS.p9.l($CLJS.G([$CLJS.uya.l($CLJS.G([$CLJS.Oi.g(a),b,$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.rya.l($CLJS.G([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Rya.l($CLJS.G([a,$CLJS.iF(b,$CLJS.G([$CLJS.hk,
!0]))]))},join_strategy:function(a){return $CLJS.cva.l($CLJS.G([a]))},picker_info:function(a,b){var c=$CLJS.cu.g(b);switch(c instanceof $CLJS.N?c.S:null){case "metadata/table":return{databaseId:$CLJS.MP.g(a),tableId:$CLJS.Zf.g(b)};case "metadata/card":return{databaseId:$CLJS.MP.g(a),tableId:["card__",$CLJS.p.g($CLJS.Zf.g(b))].join(""),cardId:$CLJS.Zf.g(b),isModel:$CLJS.vY.g(b)};default:return a=$CLJS.ZF($CLJS.zw),$CLJS.m($CLJS.fG("metabase.lib.js",a))&&$CLJS.gG("metabase.lib.js",a,$CLJS.WB.l($CLJS.G(["Cannot provide picker-info for",
$CLJS.cu.g(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.vya.l($CLJS.G([a,b,c]));return $CLJS.XZ(function(d){if($CLJS.oe(d)&&$CLJS.F.h($CLJS.r7,$CLJS.cu.g(d))){var e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.sC);var f=$CLJS.M.h(e,$CLJS.Bj);e=$CLJS.M.h(e,$CLJS.Us);return{operator:$CLJS.ui(d),options:$CLJS.qn($CLJS.li(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GE,$CLJS.al],null))),args:$CLJS.Oe($CLJS.rg.h(function(g){return g instanceof $CLJS.N?$CLJS.kG(g):g},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,
b,c){b=$CLJS.zua.l($CLJS.G([a,b]));a=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(l,n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);n=$CLJS.Qf(n);n=$CLJS.M.h(n,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[l,n],null)}),b);b=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(l,n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);n=$CLJS.Qf(n);n=$CLJS.M.h(n,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[n,l],null)}),b);var d=$CLJS.q2,e=$CLJS.p2;$CLJS.q2=a;$CLJS.p2=b;try{var f=$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0])),g=$CLJS.C($CLJS.Z$(new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.SD,$CLJS.nE],null),new $CLJS.S(null,1,5,$CLJS.T,[f],null)));return $CLJS.r2.g(g)}finally{$CLJS.p2=e,$CLJS.q2=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.Oi.g(b);d=null==d?null:$CLJS.Oi.g(d);e=$CLJS.iF(e,$CLJS.G([$CLJS.hk,!0]));return $CLJS.O1((0,$CLJS.XL)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.Oe($CLJS.yua.l($CLJS.G([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Cya.l($CLJS.G([a,b,c]))},can_save:function(a){return $CLJS.a_(gAa,
a,function(){return $CLJS.Wya.l($CLJS.G([a]))})},available_segments:function(a,b){return $CLJS.Oe($CLJS.aza.l($CLJS.G([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.Oe($CLJS.Zua.l($CLJS.G([a,b,c,d])))},aggregation_column:function(a,b,c){return $CLJS.jya.l($CLJS.G([a,b,c]))},expressionable_columns:function(a,b,c){return $CLJS.a_($CLJS.Oi.h("expressionable-columns",["stage-",$CLJS.p.g(b),"-",$CLJS.p.g(c)].join("")),a,function(){return $CLJS.Oe($CLJS.Mua.l($CLJS.G([a,b,c])))})},filter:function(a,
b,c){return $CLJS.Qua.l($CLJS.G([a,b,$CLJS.iF(c,$CLJS.G([$CLJS.hk,!0]))]))},metric_metadata:function(a,b){return $CLJS.N7(a,b)},change_direction:function(a,b){return $CLJS.pva.l($CLJS.G([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.Oe($CLJS.oya.l($CLJS.G([a,$CLJS.Oi.g(b)])))},filter_drill_details:function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.SD);var c=$CLJS.M.h(b,$CLJS.ob),d=$CLJS.M.h(b,$CLJS.yL);b=$CLJS.M.h(b,$CLJS.vl);return{column:c,query:a,stageIndex:d,value:$CLJS.F.h(b,$CLJS.S5)?
null:b}},remove_field:function(a,b,c){return $CLJS.zya.l($CLJS.G([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Oya.l($CLJS.G([a,$za(b)]))},expressions:function(a,b){return $CLJS.Oe($CLJS.Lua.l($CLJS.G([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.Oe($CLJS.Jya.l($CLJS.G([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.gva.l($CLJS.G([a,b]))},order_by:function(a,b,c,d){return $CLJS.qva.l($CLJS.G([a,b,c,$CLJS.Oi.g(d)]))},field_values_search_info:function(a,b){var c=
$CLJS.Xm.j;if($CLJS.m(b)){var d=$CLJS.Zf.g(b),e=$CLJS.Zf.g;var f=$CLJS.U4(b)?b:null;$CLJS.m(f)?a=f:(f=$CLJS.Lu(b,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IK,$CLJS.EK],null)),a=$CLJS.m(f)?$CLJS.b4(a,f):null,a=$CLJS.m(a)?a:b);a=Vza(a)?a:null;e=e.call($CLJS.Zf,a);d=$CLJS.Be(d)?d:null;e=$CLJS.Be(e)?e:null;$CLJS.m(b)?(b=$CLJS.Qf(b),a=$CLJS.M.h(b,$CLJS.MY),b=$CLJS.F.h(a,$CLJS.OY)?$CLJS.Oj:$CLJS.m(a)?a:Vza(b)?$CLJS.sY:$CLJS.Ix):b=$CLJS.Ix;b=new $CLJS.k(null,3,[$CLJS.EK,d,$CLJS.vsa,e,$CLJS.MY,b],null)}else b=
null;return $CLJS.qn($CLJS.dG(c.call($CLJS.Xm,b,$CLJS.MY,$CLJS.ui),aAa))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Oi.g(a):a;b="string"===typeof b?$CLJS.Oi.g(b):b;return $CLJS.Bva.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.Eua.l($CLJS.G([a,b,$CLJS.vva.l($CLJS.G([c]))]))},database_id:function(a){return $CLJS.lya.l($CLJS.G([a]))},required_native_extras:function(a,b){return $CLJS.Oe($CLJS.rg.h($CLJS.kG,$CLJS.Pya.l($CLJS.G([K9(a,b)]))))},remove_join:function(a,
b,c){return $CLJS.Zya.l($CLJS.G([a,b,c]))},joined_thing:function(a,b){return $CLJS.R2(a,b)},legacy_column__GT_metadata:S9,with_native_query:function(a,b){return $CLJS.Mya.l($CLJS.G([a,b]))},breakoutable_columns:function(a,b){return $CLJS.a_($CLJS.Oi.h("breakoutable-columns",["stage-",$CLJS.p.g(b)].join("")),a,function(){return $CLJS.Oe($CLJS.Fua.l($CLJS.G([a,b])))})},rename_join:function(a,b,c,d){return $CLJS.$ya.l($CLJS.G([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Yya.l($CLJS.G([a,
b]))}};