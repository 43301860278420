var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Dna,ZZ;$CLJS.UZ=function(a){return a instanceof Error?a.message:null};
Dna=function(a,b,c){if($CLJS.hf(c)){var d=$CLJS.R.h($CLJS.X,$CLJS.rg.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.Ig(c)?(d=new $CLJS.Je(function(){var e=$CLJS.tc(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.uc(c);return a.g?a.g(e):a.call(null,e)}()),b.g?b.g(d):b.call(null,d)):$CLJS.we(c)?(d=$CLJS.Ci($CLJS.rg.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.pe(c)?(d=$CLJS.Sb(function(e,f){return $CLJS.kf.h(e,a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,d)):$CLJS.ke(c)?
(d=$CLJS.oh.h($CLJS.ae(c),$CLJS.rg.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)};$CLJS.YZ=function(a){function b(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return"string"===typeof d?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Oi.g(d),c],null):new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}return $CLJS.XZ(function(c){return $CLJS.oe(c)?$CLJS.oh.h($CLJS.P,$CLJS.rg.h(b,c)):c},a)};
$CLJS.jV=function(a){var b=new $CLJS.rb;for(a=$CLJS.A(a);;)if(null!=a)b=b.append($CLJS.p.g($CLJS.C(a))),a=$CLJS.D(a);else return b.toString()};$CLJS.$Z=function(a,b,c){a=$CLJS.Pu(a,/''/,"'");var d=$CLJS.lz(a,ZZ);d=$CLJS.F.h($CLJS.E(d),1)?new $CLJS.S(null,2,5,$CLJS.T,[a,""],null):d;a=$CLJS.Ei(/.*\{0\}.*/,a);return $CLJS.jz.ngettext($CLJS.jz.msgid($CLJS.qn(d),$CLJS.m(a)?c:""),$CLJS.Pu($CLJS.Pu(b,/''/,"'"),ZZ,$CLJS.p.g(c)),c)};
$CLJS.TN=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Km,a,$CLJS.gi(b)),b)};$CLJS.tZ=function(a,b,c){return $CLJS.oe(c)?$CLJS.sg(function(d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);e=$CLJS.kf.h(b,e);return a.h?a.h(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.ne(c)?$CLJS.sg($CLJS.bg(a,$CLJS.C(c)instanceof $CLJS.N?$CLJS.kf.h(b,$CLJS.C(c)):b),$CLJS.G([c])):null};
$CLJS.XZ=function XZ(a,b){return Dna($CLJS.bg(XZ,a),a,b)};ZZ=/\{0\}/;$CLJS.kV=new $CLJS.N(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.lV=new $CLJS.N(null,"binning-strategy","binning-strategy",2063329158);$CLJS.mV=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.sV=new $CLJS.N(null,"name-key-fn","name-key-fn",-1634839199);var l_=$CLJS.Sh([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.WI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.By,$CLJS.KI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.By,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.RI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zI,null],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SI,null],null),new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.By,$CLJS.DI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.By,$CLJS.OI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FI,null],null)],[$CLJS.Jk,$CLJS.CF,$CLJS.XI,$CLJS.JI,$CLJS.Gk,$CLJS.Vk,$CLJS.Tk,$CLJS.bl,$CLJS.Kk,$CLJS.JI,$CLJS.YI,$CLJS.xF]),W_;$CLJS.si($CLJS.rg.h($CLJS.C,$CLJS.gi(l_)));var M_,zka=$CLJS.na($CLJS.P),Aka=$CLJS.na($CLJS.P),Bka=$CLJS.na($CLJS.P),Fka=$CLJS.na($CLJS.P),Gka=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
M_=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.util","negate*"),$CLJS.C,Gka,zka,Aka,Bka,Fka);M_.o(null,$CLJS.ss,function(a){$CLJS.J(a,0,null);return $CLJS.J(a,1,null)});M_.o(null,$CLJS.As,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);return $CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.qs],null),$CLJS.rg.h(M_,a))});M_.o(null,$CLJS.qs,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);return $CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.As],null),$CLJS.rg.h(M_,a))});
M_.o(null,$CLJS.$r,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PE,b,a],null)});M_.o(null,$CLJS.PE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$r,b,a],null)});M_.o(null,$CLJS.Ur,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Xr,b,a],null)});
M_.o(null,$CLJS.Wr,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Vr,b,a],null)});M_.o(null,$CLJS.Vr,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Wr,b,a],null)});M_.o(null,$CLJS.Xr,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ur,b,a],null)});
M_.o(null,$CLJS.YD,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Wr,b,c],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ur,b,a],null)],null)});M_.o(null,$CLJS.xE,function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ss,a],null)});M_.o(null,$CLJS.pE,function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ss,a],null)});
M_.o(null,$CLJS.JE,function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ss,a],null)});
W_=function W_(a){return $CLJS.oe(a)?$CLJS.Rf($CLJS.oh.h($CLJS.P,function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=W_.g?W_.g(u):W_.call(null,u);null!=u&&n.add(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null));q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);
n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);g=W_.g?W_.g(g):W_.call(null,g);if(null!=g)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[n,g],null),e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}(a)}())):$CLJS.ne(a)?$CLJS.Rf($CLJS.oh.h($CLJS.ae(a),$CLJS.ug($CLJS.Hb,$CLJS.rg.h(W_,a)))):a};
$CLJS.s_=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.z(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.J(c,0,null),g=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);d=$CLJS.Rf(W_($CLJS.R.j(d,c,e)));return $CLJS.m(d)?new $CLJS.S(null,3,5,$CLJS.T,[f,g,d],null):$CLJS.F.h(f,$CLJS.yE)?new $CLJS.S(null,3,5,$CLJS.T,[f,g,null],null):new $CLJS.S(null,2,5,$CLJS.T,[f,g],null)}a.v=2;a.B=function(c){var d=
$CLJS.C(c);c=$CLJS.D(c);var e=$CLJS.C(c);c=$CLJS.zd(c);return b(d,e,c)};a.l=b;return a}();