var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var t9,s9,Fva,fza,gza,z9,hza,iza,kza,w8,lza,mza,dza,D8,nza,u9,oza,pza,qza;$CLJS.r9=function(a,b){return $CLJS.m($CLJS.m(a)?b in a:a)?$CLJS.Ua(a,b):null};t9=function(a,b){return s9(a,b,$CLJS.P)};
s9=function(a,b,c){c=$CLJS.Qf(c);c=$CLJS.M.j(c,dza,!0);if($CLJS.oe(b))return $CLJS.oh.j($CLJS.P,a,b);c=$CLJS.m(c)?function(){var d=$CLJS.r9(b,"_plainObject");d=null==d?null:$CLJS.jF(d);return null==d?null:$CLJS.Rf(d)}():null;return $CLJS.m(c)?$CLJS.oh.j($CLJS.P,a,c):$CLJS.oh.j($CLJS.P,$CLJS.Sm.j($CLJS.rg.g(function(d){return new $CLJS.S(null,2,5,$CLJS.T,[d,$CLJS.r9(b,d)],null)}),$CLJS.Vm.g(function(d){$CLJS.J(d,0,null);return"function"===typeof $CLJS.J(d,1,null)}),a),$CLJS.Ta(b))};
Fva=function(a){var b=v9.g(a),c=w9.g(a),d=m8.g(a);return $CLJS.Sm.j($CLJS.rg.g(function(e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);var g=$CLJS.T;f=$CLJS.Oi.g(eza(f));if($CLJS.m(d)){var l=d.g?d.g(f):d.call(null,f);f=$CLJS.m(l)?l:f}return new $CLJS.S(null,2,5,g,[f,e],null)}),$CLJS.je(b)?$CLJS.Ye:$CLJS.Vm.g(function(e){var f=$CLJS.J(e,0,null);$CLJS.J(e,1,null);return $CLJS.Ie(b,f)}),$CLJS.Gb(c)?$CLJS.Ye:$CLJS.rg.g(function(e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return new $CLJS.S(null,2,
5,$CLJS.T,[f,c.h?c.h(f,e):c.call(null,f,e)],null)}))};fza=function(a){return t9($CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.Oi.g(eza(c));c=$CLJS.F.h(c,$CLJS.lV)?$CLJS.EC:c;b=$CLJS.F.h(c,$CLJS.EC)?$CLJS.Oi.g(b):b;return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)}),a)};
gza=function(a){var b=$CLJS.wW(function(d){d=$CLJS.r9(d,"type");var e=new $CLJS.ni(null,new $CLJS.k(null,2,["internal",null,"external",null],null),null);return e.g?e.g(d):e.call(null,d)},a);if($CLJS.m(b)){var c=$CLJS.Oi.g($CLJS.r9(b,"type"));return $CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.Zf,$CLJS.r9(b,"id"),$CLJS.V,$CLJS.r9(b,"name")],null),function(){var d=c instanceof $CLJS.N?c.S:null;switch(d){case "external":return new $CLJS.k(null,2,[$CLJS.cu,$CLJS.TK,$CLJS.EK,$CLJS.r9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.k(null,1,[$CLJS.cu,$CLJS.OK],null);default:throw Error(["No matching clause: ",$CLJS.p.g(d)].join(""));}}()]))}return null};z9=function(a){return $CLJS.Wm.h(o8.h($CLJS.yE,$CLJS.P),a)};
hza=function(a,b){var c=o8.h($CLJS.eN,new $CLJS.k(null,1,[dza,!1],null)),d=o8.h($CLJS.eN,$CLJS.P);return $CLJS.nn.l($CLJS.G([$CLJS.U.j(function(){var e=$CLJS.r9($CLJS.r9(a,"tables"),["card__",$CLJS.p.g(b)].join(""));return c.g?c.g(e):c.call(null,e)}(),$CLJS.Zf,b),function(){var e=$CLJS.r9($CLJS.r9(a,"questions"),$CLJS.p.g(b));var f=$CLJS.r9(e,"_card");e=$CLJS.m(f)?f:e;return d.g?d.g(e):d.call(null,e)}()]))};iza=new $CLJS.N(null,"plain-object","plain-object",-943264281);
$CLJS.jza=new $CLJS.N(null,"cards","cards",169174038);kza=new $CLJS.N(null,"binning-info","binning-info",-2117036653);w8=new $CLJS.N("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);lza=new $CLJS.N(null,"db","db",993250759);$CLJS.z8=new $CLJS.N(null,"metrics","metrics",394093469);mza=new $CLJS.N(null,"fks","fks",2041987076);$CLJS.B8=new $CLJS.N(null,"segments","segments",1937535949);dza=new $CLJS.N(null,"use-plain-object?","use-plain-object?",-1174595475);
D8=new $CLJS.N("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);nza=new $CLJS.N(null,"metadata","metadata",1799301597);$CLJS.U8=new $CLJS.N(null,"tables","tables",1334623052);u9=new $CLJS.N(null,"dimension-options","dimension-options",2122379680);oza=new $CLJS.N(null,"schema-name","schema-name",1666725119);pza=new $CLJS.N(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);qza=new $CLJS.N(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.rza=new $CLJS.N(null,"databases","databases",2040134125);var eza=$CLJS.ej($CLJS.pG),v9,sza=$CLJS.na($CLJS.P),tza=$CLJS.na($CLJS.P),uza=$CLJS.na($CLJS.P),vza=$CLJS.na($CLJS.P),wza=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null)),G9,Mza,Nza,Oza,Pza,Qza;v9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","excluded-keys"),$CLJS.Oi,wza,sza,tza,uza,vza);v9.o(null,$CLJS.oa,function(){return null});
var w9,xza=$CLJS.na($CLJS.P),yza=$CLJS.na($CLJS.P),zza=$CLJS.na($CLJS.P),Aza=$CLJS.na($CLJS.P),Bza=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));w9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Oi,Bza,xza,yza,zza,Aza);w9.o(null,$CLJS.oa,function(){return null});var x9,Cza=$CLJS.na($CLJS.P),Dza=$CLJS.na($CLJS.P),Eza=$CLJS.na($CLJS.P),Fza=$CLJS.na($CLJS.P),Gza=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
x9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","lib-type"),$CLJS.Oi,Gza,Cza,Dza,Eza,Fza);var m8,Hza=$CLJS.na($CLJS.P),Iza=$CLJS.na($CLJS.P),Jza=$CLJS.na($CLJS.P),Kza=$CLJS.na($CLJS.P),Lza=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));m8=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Ye,Lza,Hza,Iza,Jza,Kza);m8.o(null,$CLJS.oa,function(){return null});
var o8=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
o8.o(null,$CLJS.oa,function(a,b){var c=Fva(a),d=x9.g(a);return function(e){try{var f=$CLJS.U.j(s9(c,e,b),$CLJS.cu,d),g=$CLJS.ZF($CLJS.ww);$CLJS.m($CLJS.fG("metabase.lib.js.metadata",g))&&$CLJS.gG("metabase.lib.js.metadata",g,$CLJS.X_("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Zf.g(f),$CLJS.Y_(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.ZF($CLJS.xw),$CLJS.m($CLJS.fG("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.gG("metabase.lib.js.metadata",g,$CLJS.X_("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.aj.l($CLJS.G([e])),$CLJS.UZ(f)])),f):$CLJS.gG("metabase.lib.js.metadata",g,$CLJS.X_(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.aj.l($CLJS.G([e])),$CLJS.UZ(f)])),null)),null;throw l;}}});$CLJS.F9=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Oi.g(f)},e,a,b,c,d)}();
Mza=$CLJS.na($CLJS.P);Nza=$CLJS.na($CLJS.P);Oza=$CLJS.na($CLJS.P);Pza=$CLJS.na($CLJS.P);Qza=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));G9=new $CLJS.vj($CLJS.Ui.h("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Oi,Qza,Mza,Nza,Oza,Pza);
$CLJS.F9.o(null,$CLJS.oa,function(a,b){var c=o8.h(a,$CLJS.P);return t9($CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)}),$CLJS.r9(b,G9.g(a)))});x9.o(null,$CLJS.MP,function(){return $CLJS.QK});v9.o(null,$CLJS.MP,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.JC,null,$CLJS.U8,null],null),null)});
w9.o(null,$CLJS.MP,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "dbms-version":return $CLJS.iF(b,$CLJS.G([$CLJS.hk,!0]));case "features":return $CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Oi),b);case "native-permissions":return $CLJS.Oi.g(b);default:return b}}});G9.o(null,$CLJS.MP,function(){return"databases"});x9.o(null,$CLJS.tk,function(){return $CLJS.BK});
v9.o(null,$CLJS.tk,function(){return new $CLJS.ni(null,new $CLJS.k(null,5,[u9,null,$CLJS.JC,null,$CLJS.B8,null,$CLJS.MP,null,$CLJS.z8,null],null),null)});w9.o(null,$CLJS.tk,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "entity-type":return $CLJS.Oi.g(b);case "field-order":return $CLJS.Oi.g(b);case "initial-sync-status":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);default:return b}}});
$CLJS.F9.o(null,$CLJS.tk,function(a,b){var c=o8.h(a,$CLJS.P);return t9($CLJS.Sm.h($CLJS.Vm.g(function(d){var e=$CLJS.J(d,0,null);$CLJS.J(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.rg.g(function(d){var e=$CLJS.J(d,0,null),f=$CLJS.J(d,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sL(e),new $CLJS.Yi(function(){return c.g?c.g(f):c.call(null,f)})],null)})),$CLJS.r9(b,"tables"))});x9.o(null,$CLJS.yE,function(){return $CLJS.tK});
v9.o(null,$CLJS.yE,function(){return new $CLJS.ni(null,new $CLJS.k(null,6,[u9,null,$CLJS.tk,null,qza,null,$CLJS.MP,null,pza,null,$CLJS.z8,null],null),null)});m8.o(null,$CLJS.yE,function(){return new $CLJS.k(null,6,[$CLJS.Fx,$CLJS.vK,$CLJS.Fk,$CLJS.AW,$CLJS.SR,$CLJS.jC,kza,$CLJS.FW,$CLJS.BL,D8,$CLJS.bt,w8],null)});
w9.o(null,$CLJS.yE,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "base-type":return $CLJS.Oi.g(b);case "coercion-strategy":return $CLJS.Oi.g(b);case "effective-type":return $CLJS.Oi.g(b);case "fingerprint":return $CLJS.oe(b)?$CLJS.YZ(b):$CLJS.iF(b,$CLJS.G([$CLJS.hk,!0]));case "has-field-values":return $CLJS.Oi.g(b);case "field-ref":return $CLJS.Oe(b);case "lib/source":switch(b){case "aggregation":return $CLJS.UK;case "breakout":return $CLJS.MK;default:return $CLJS.Oi.h("source",
b)}case "metabase.lib.field/temporal-unit":return $CLJS.Oi.g(b);case "semantic-type":return $CLJS.Oi.g(b);case "visibility-type":return $CLJS.Oi.g(b);case "id":return $CLJS.qe(b)?$CLJS.Zd(b):b;case "metabase.lib.field/binning":return fza(b);case "metabase.lib.js.metadata/field-values":return $CLJS.F.h($CLJS.r9(b,"type"),"full")?new $CLJS.k(null,2,[$CLJS.bt,$CLJS.jF($CLJS.r9(b,"values")),$CLJS.AK,$CLJS.jF($CLJS.r9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return gza(b);
default:return b}}});
o8.o(null,$CLJS.yE,function(a,b){var c=function(){var d=$CLJS.sj(o8,$CLJS.oa);return d.h?d.h(a,b):d.call(null,a,b)}();return function(d){d=c.g?c.g(d):c.call(null,d);var e=$CLJS.Qf(d);d=$CLJS.M.h(e,D8);var f=$CLJS.Qf(d),g=$CLJS.M.h(f,$CLJS.cu),l=$CLJS.M.h(e,w8);return $CLJS.Km.l(function(){switch(g instanceof $CLJS.N?g.S:null){case "metadata.column.remapping/external":return $CLJS.U.j(e,$CLJS.IK,f);case "metadata.column.remapping/internal":return $CLJS.U.j(e,$CLJS.PK,$CLJS.nn.l($CLJS.G([f,l])));default:return e}}(),
D8,$CLJS.G([w8]))}});
$CLJS.F9.o(null,$CLJS.yE,function(a,b){var c=o8.h(a,$CLJS.P),d=$CLJS.r9(b,"fields");return t9($CLJS.Fn(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null);e=function(){var l=$CLJS.sL(f);if($CLJS.m(l))return l;l=$CLJS.Di(/card__\d+:(\d+)/,f);if($CLJS.m(l)){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=null==$CLJS.r9(d,l);return n?$CLJS.sL(l):n}return null}();return $CLJS.m(e)?new $CLJS.S(null,2,5,$CLJS.T,[e,new $CLJS.Yi(function(){return c.g?c.g(g):c.call(null,g)})],null):null}),d)});
x9.o(null,$CLJS.eN,function(){return $CLJS.SK});v9.o(null,$CLJS.eN,function(){return new $CLJS.ni(null,new $CLJS.k(null,11,[u9,null,$CLJS.jm,null,mza,null,$CLJS.tk,null,lza,null,iza,null,$CLJS.B8,null,$CLJS.MP,null,nza,null,$CLJS.z8,null,oza,null],null),null)});
w9.o(null,$CLJS.eN,function(){return function(a,b){switch(a instanceof $CLJS.N?a.S:null){case "result-metadata":return $CLJS.m($CLJS.Tx($CLJS.ne,$CLJS.Db)(b))?z9(b):$CLJS.iF(b,$CLJS.G([$CLJS.hk,!0]));case "fields":return z9(b);case "visibility-type":return $CLJS.Oi.g(b);case "dataset-query":return $CLJS.iF(b,$CLJS.G([$CLJS.hk,!0]));case "dataset":return b;default:return b}}});
$CLJS.F9.o(null,$CLJS.eN,function(a,b){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(c){return new $CLJS.S(null,2,5,$CLJS.T,[c,new $CLJS.Yi(function(){return hza(b,c)})],null)}),$CLJS.oh.j($CLJS.oh.j($CLJS.oi,$CLJS.Fn($CLJS.e0),$CLJS.Ta($CLJS.r9(b,"tables"))),$CLJS.rg.g($CLJS.sL),$CLJS.Ta($CLJS.r9(b,"questions"))))});x9.o(null,$CLJS.tE,function(){return $CLJS.zK});v9.o(null,$CLJS.tE,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tk,null,$CLJS.MP,null],null),null)});
w9.o(null,$CLJS.tE,function(){return function(a,b){return b}});G9.o(null,$CLJS.tE,function(){return"metrics"});x9.o(null,$CLJS.UD,function(){return $CLJS.xK});v9.o(null,$CLJS.UD,function(){return new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tk,null,$CLJS.MP,null],null),null)});w9.o(null,$CLJS.UD,function(){return function(a,b){return b}});G9.o(null,$CLJS.UD,function(){return"segments"});$CLJS.y9=o8.h($CLJS.yE,$CLJS.P);