var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var AI,CI,EI,NI,QI,VI;$CLJS.zI=new $CLJS.N(null,"get-month","get-month",-369374731);AI=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.BI=new $CLJS.N(null,"convert-timezone","convert-timezone",-124803001);CI=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.DI=new $CLJS.N(null,"iso","iso",-1366207543);EI=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.FI=new $CLJS.N(null,"get-second","get-second",-2065946318);$CLJS.GI=new $CLJS.N(null,"get-year","get-year",-936011274);$CLJS.HI=new $CLJS.N(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.II=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.JI=new $CLJS.N(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.KI=new $CLJS.N(null,"us","us",746429226);$CLJS.LI=new $CLJS.N(null,"datetime","datetime",494675702);$CLJS.MI=new $CLJS.N(null,"now","now",-1650525531);
NI=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.OI=new $CLJS.N(null,"instance","instance",-2121349050);$CLJS.PI=new $CLJS.N(null,"get-day","get-day",127568857);QI=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.RI=new $CLJS.N(null,"get-minute","get-minute",1468086343);
$CLJS.SI=new $CLJS.N(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.TI=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.UI=new $CLJS.N(null,"get-hour","get-hour",622714059);VI=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.WI=new $CLJS.N(null,"get-quarter","get-quarter",1327778593);
$CLJS.XI=new $CLJS.N(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.YI=new $CLJS.N(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.ZI=new $CLJS.N(null,"target","target",253001721);$CLJS.AV($CLJS.zy,$CLJS.G([$CLJS.ft,$CLJS.vz,$CLJS.ab,$CLJS.JF]));$CLJS.EL.o(null,$CLJS.II,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.FL(a);return $CLJS.le(a)?(a=$CLJS.Gz.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ik,null,$CLJS.Uk,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.pV($CLJS.II,$CLJS.iD);
for(var $I=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Cy,$CLJS.Fy],null)),aJ=null,bJ=0,cJ=0;;)if(cJ<bJ){var dJ=aJ.$(null,cJ);$CLJS.AV(dJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null),$CLJS.ab,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.JF],null)]));$CLJS.pV(dJ,$CLJS.II);cJ+=1}else{var eJ=$CLJS.A($I);if(eJ){var fJ=eJ;if($CLJS.re(fJ)){var gJ=$CLJS.$c(fJ),Xga=$CLJS.ad(fJ),Yga=gJ,Zga=$CLJS.E(gJ);$I=Xga;aJ=Yga;bJ=Zga}else{var hJ=$CLJS.C(fJ);$CLJS.AV(hJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,
[$CLJS.W,$CLJS.NC],null),$CLJS.ab,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.JF],null)]));$CLJS.pV(hJ,$CLJS.II);$I=$CLJS.D(fJ);aJ=null;bJ=0}cJ=0}else break}
for(var iJ=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.GI,$CLJS.zI,$CLJS.PI,$CLJS.UI,$CLJS.RI,$CLJS.FI,$CLJS.WI],null)),jJ=null,kJ=0,lJ=0;;)if(lJ<kJ){var $ga=jJ.$(null,lJ);$CLJS.AV($ga,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)]));lJ+=1}else{var mJ=$CLJS.A(iJ);if(mJ){var nJ=mJ;if($CLJS.re(nJ)){var oJ=$CLJS.$c(nJ),aha=$CLJS.ad(nJ),bha=oJ,cha=$CLJS.E(oJ);iJ=aha;jJ=bha;kJ=cha}else{var dha=$CLJS.C(nJ);$CLJS.AV(dha,$CLJS.G([$CLJS.ft,
$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)]));iJ=$CLJS.D(nJ);jJ=null;kJ=0}lJ=0}else break}$CLJS.AV($CLJS.yy,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.FF],null)]));
for(var pJ=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.WI,null,$CLJS.RI,null,$CLJS.UI,null,$CLJS.SI,null,$CLJS.FI,null,$CLJS.zI,null,$CLJS.GI,null,$CLJS.PI,null],null),null)),qJ=null,rJ=0,sJ=0;;)if(sJ<rJ){var eha=qJ.$(null,sJ);$CLJS.AV(eha,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)]));sJ+=1}else{var tJ=$CLJS.A(pJ);if(tJ){var uJ=tJ;if($CLJS.re(uJ)){var vJ=$CLJS.$c(uJ),fha=$CLJS.ad(uJ),gha=vJ,hha=$CLJS.E(vJ);
pJ=fha;qJ=gha;rJ=hha}else{var iha=$CLJS.C(uJ);$CLJS.AV(iha,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)]));pJ=$CLJS.D(uJ);qJ=null;rJ=0}sJ=0}else break}$CLJS.lL(VI,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.DI,$CLJS.KI,$CLJS.OI],null));
$CLJS.CV($CLJS.By,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VI],null)],null)],null)],null)]));
$CLJS.lL($CLJS.TI,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,$CLJS.oC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,2,[$CLJS.tt,"valid timezone ID",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.XL.tz.names())),$CLJS.qI],null)],null));
$CLJS.CV($CLJS.BI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZI,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.TI],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fx,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.TI],null)],null)],null)],
null)]));$CLJS.pV($CLJS.BI,$CLJS.II);$CLJS.AV($CLJS.MI,$CLJS.G([$CLJS.ft,$CLJS.Lk]));$CLJS.lL(EI,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.CC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.QH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ik,$CLJS.Uk],null))}],null)],null));
$CLJS.lL(AI,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iy,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,EI],null)],null)],null)],null));
$CLJS.lF.h($CLJS.HI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.tt,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.HI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,AI],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ls,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.tt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,
4,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.uI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oI],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.oa],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.IF],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.tt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.ev],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yI],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.oa],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yF],null)],null)],null)],null)],null)],null));
$CLJS.EL.o(null,$CLJS.HI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.ev)?$CLJS.F.h(a,$CLJS.oa)?$CLJS.Uk:$CLJS.m($CLJS.rV($CLJS.IF,a))?$CLJS.Ik:$CLJS.Uk:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.lI,b))?$CLJS.Ik:$CLJS.m($CLJS.Di($CLJS.mI,b))?$CLJS.Ik:null:null;if($CLJS.m(a))return a;b=$CLJS.FL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.QH(c,$CLJS.Zj)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.lL(QI,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.ev],null),$CLJS.ab],null));
$CLJS.CV($CLJS.Ay,$CLJS.G([$CLJS.ft,$CLJS.Uk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,QI],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.JF],null)],null)],null)],null)]));
$CLJS.AV($CLJS.Aw,$CLJS.G([$CLJS.ft,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.mD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.HF],null)]));$CLJS.lL(CI,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.ok,$CLJS.CF,$CLJS.Jk,$CLJS.bl,$CLJS.JI,$CLJS.XI,$CLJS.YI,$CLJS.Vk,$CLJS.Kk,$CLJS.Gk,$CLJS.Tk,$CLJS.xF],null));$CLJS.lL(NI,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.DI,$CLJS.KI,$CLJS.OI],null));
$CLJS.CV($CLJS.Dy,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CI],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,NI],null)],null)],
null)],null)]));