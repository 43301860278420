var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var p0,q0,f_,g_,Ena,i_,r0,o0,s0,JW,Ooa,Poa,Qoa,hla,x0,y0,A0,B0,C0,D0,E0,F0,Soa,Toa,H0,Uoa,I0,Voa,K0,Woa,Xoa,Yoa,Zoa,$oa,SH;$CLJS.TH=function(a,b){if("string"===typeof b)return SH(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.HW=function(a,b,c){var d=$CLJS.Ke(a,b);if($CLJS.m(d)){var e=$CLJS.U.j;d=$CLJS.uc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.U,a,b,c)}return a};
p0=function(a){var b=o0;return $CLJS.aG(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.cta=function(a,b){return $CLJS.aG(function(c){return function(d,e,f){return $CLJS.m(a.g?a.g(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};q0=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.db,a],null));};
$CLJS.e_=function(a,b,c){return $CLJS.oe(c)?$CLJS.oh.h(c,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null),I=$CLJS.J(B,1,null);B=q;var Q=$CLJS.T,Y=H;H=$CLJS.kf.h(b,H);H=a.h?a.h(H,I):a.call(null,H,I);B.add(new $CLJS.S(null,2,5,Q,[Y,H],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,function(){var y=$CLJS.kf.h(b,v),B=x;return a.h?a.h(y,B):a.call(null,y,B)}()],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):$CLJS.ne(c)?$CLJS.Wm.h($CLJS.bg(a,$CLJS.C(c)instanceof $CLJS.N?$CLJS.kf.h(b,$CLJS.C(c)):b),c):c};f_=function(a){var b=(b=a instanceof $CLJS.N)?$CLJS.qf(a):b;return $CLJS.m(b)?[$CLJS.qf(a),"/",$CLJS.ui(a)].join(""):$CLJS.ui(a)};
g_=function(a,b){var c=$CLJS.ne(b)&&!$CLJS.Ig(b)&&$CLJS.C(b)instanceof $CLJS.N;return c?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c};
Ena=function(a,b){return $CLJS.sg(function(c){var d=$CLJS.ug;a:try{if($CLJS.qe(c)&&1<=$CLJS.E(c))try{var e=$CLJS.bn.j(c,0,1);if($CLJS.qe(e)&&1===$CLJS.E(e))try{var f=$CLJS.Td(e,0);if($CLJS.m($CLJS.bg($CLJS.F,a)(f))){var g=$CLJS.bn.h(c,1);var l=new $CLJS.S(null,1,5,$CLJS.T,[g],null)}else throw $CLJS.Z;}catch(q){if(q instanceof Error){var n=q;if(n===$CLJS.Z)throw $CLJS.Z;throw n;}throw q;}else throw $CLJS.Z;}catch(q){if(q instanceof Error){n=q;if(n===$CLJS.Z)throw $CLJS.Z;throw n;}throw q;}else throw $CLJS.Z;
}catch(q){if(q instanceof Error){n=q;if(n===$CLJS.Z){l=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[c],null)],null);break a}throw n;}throw q;}return $CLJS.C($CLJS.A(d($CLJS.Hb,l)))},$CLJS.G([b]))};
i_=function(a,b){for(;;)switch(b=$CLJS.pn.g($CLJS.ug($CLJS.Hb,b)),$CLJS.E(b)){case 0:return null;case 1:return a=$CLJS.C(b),h_.g?h_.g(a):h_.call(null,a);default:if($CLJS.m($CLJS.Xf($CLJS.bg(g_,a),b))){var c=a;b=Ena(a,b);a=c}else{c=$CLJS.rg.h(h_,b);if($CLJS.F.h(c,b))return $CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),b);b=c}}};r0=function(a){var b=$CLJS.ne(a);return b?(b=!$CLJS.Ig(a))?$CLJS.Tx($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b};
o0=function(a){return $CLJS.m($CLJS.Tx($CLJS.pf,$CLJS.Jb)(a))?$CLJS.Oi.g($CLJS.Pu(f_(a).toLowerCase(),/_/,"-")):a};s0=function(a,b){var c=r0(b);return $CLJS.m(c)?(b=o0($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c};
JW=function(a){a=t0.h?t0.h(a,IW):t0.call(null,a,IW);var b=$CLJS.m($CLJS.iy.g(a))?$CLJS.Xm.j(a,$CLJS.iy,$CLJS.Oi):a;b=$CLJS.m($CLJS.EE.g(a))?$CLJS.Xm.j(b,$CLJS.EE,$CLJS.Oi):b;return $CLJS.m($CLJS.mR.g(a))?$CLJS.Xm.j(b,$CLJS.mR,function(c){return $CLJS.m($CLJS.EC.g(c))?$CLJS.Xm.j(c,$CLJS.EC,$CLJS.Oi):c}):b};
Ooa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.oa,$CLJS.Ye,$CLJS.$k,o0,$CLJS.zN,o0],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=t0.h?t0.h(b,IW):t0.call(null,b,IW);return d.call(c,b,a)})};
Poa=function(a){a=$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=o0(c);var d=Ooa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.$k.g(a),$CLJS.HD)&&$CLJS.Gb($CLJS.zN.g(a))?$CLJS.U.j(a,$CLJS.zN,$CLJS.RE):a};Qoa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=f_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(Poa(b),$CLJS.V,c)],null)}),a)};
hla=function(a){a=t0.g?t0.g(a):t0.call(null,a);return w0.g?w0.g(a):w0.call(null,a)};x0=function(a){return $CLJS.HW($CLJS.HW($CLJS.Sb(function(b,c){return $CLJS.HW(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.xz,$CLJS.yea,$CLJS.vO,ila,$CLJS.Fx,$CLJS.Fk],null)),$CLJS.Gy,hla),$CLJS.BN,$CLJS.YZ)};y0=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,a,null],null):a};A0=function(a){return z0.g(y0(a))};
B0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return h_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(z0,b)))};C0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,A0(b)],null),$CLJS.rg.h(z0,c))};D0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,A0(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)};
E0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,A0(a)],null)};F0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,z0.g(a)],null)};Soa=function(a){return $CLJS.Mg($CLJS.eg(w0,G0(a)))};Toa=function(a){for(;;)if($CLJS.m(r0(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Wm.h(y0,a))};
H0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.HD);return $CLJS.m(b)?$CLJS.Xm.j(a,$CLJS.HD,z0):a};
Uoa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,H0(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[l,H0(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};I0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.xQ);return $CLJS.m(b)?$CLJS.Xm.j(a,$CLJS.xQ,Uoa):a};Voa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.aQ);b=$CLJS.Gb(a)?J0.g?J0.g(b):J0.call(null,b):b;return $CLJS.m(a)?I0(b):b};K0=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a};
Woa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.SD);var c=$CLJS.M.h(b,$CLJS.dN),d=$CLJS.M.h(b,$CLJS.JO),e=$CLJS.M.h(b,$CLJS.aQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.JO);var l=$CLJS.Ru($CLJS.Km.h(f,$CLJS.JO),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SD,$CLJS.JO],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Xm.j(l,$CLJS.SD,J0):l,q=$CLJS.m(c)?$CLJS.Xm.j(n,$CLJS.dN,$CLJS.bg($CLJS.Wm,w0)):n,u=$CLJS.m(e)?$CLJS.Xm.j(q,$CLJS.aQ,I0):q,v=$CLJS.Qf(u),x=$CLJS.M.h(v,$CLJS.aQ);return $CLJS.m(x)?
$CLJS.U.j(w0($CLJS.Km.h(v,$CLJS.aQ)),$CLJS.aQ,x):w0(v)}catch(y){if(y instanceof Error)throw n=y,$CLJS.yj($CLJS.WH("Error canonicalizing query: {0}",$CLJS.G([$CLJS.UZ(n)])),new $CLJS.k(null,1,[$CLJS.SD,a],null),n);throw y;}};
Xoa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.SD),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.mE);c=$CLJS.M.h(c,$CLJS.JC);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.oh.j($CLJS.oi,$CLJS.$F,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.yE)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,n,$CLJS.Km.h(q,$CLJS.EE)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.tZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b)))),$CLJS.L0.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SD,$CLJS.JC],null),$CLJS.Sm.h($CLJS.Mg,$CLJS.bg($CLJS.Vm,b)))):a};
Yoa=function(a){try{return Xoa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.XH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.SD,a],null),b);}throw c;}};
Zoa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=M0.h?M0.h(B,I):M0.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return M0.h?M0.h(y,B):M0.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null};$oa=function(a,b){a=$CLJS.Wm.h(function(c){var d=$CLJS.kf.h(b,KW);return M0.h?M0.h(c,d):M0.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null};
SH=function SH(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Mg(d);return $CLJS.nf(f,new $CLJS.yf(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),SH.h?SH.h(a,g):SH.call(null,a,g)):null},null,null))};
$CLJS.L0=function L0(a){switch(arguments.length){case 3:return L0.j(arguments[0],arguments[1],arguments[2]);case 4:return L0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return L0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return L0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return L0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.L0.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.C(d);if(d=$CLJS.D(d))a=$CLJS.U.j(a,b,$CLJS.L0.j($CLJS.M.h(a,b),d,c));else{d=$CLJS.U.j;var e=$CLJS.M.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.U,a,b,c)}return a};
$CLJS.L0.D=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.C(e);if(e=$CLJS.D(e))a=$CLJS.U.j(a,b,$CLJS.L0.D($CLJS.M.h(a,b),e,c,d));else{e=$CLJS.U.j;var f=$CLJS.M.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.L0.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.C(f);if(f=$CLJS.D(f))a=$CLJS.U.j(a,b,$CLJS.L0.R($CLJS.M.h(a,b),f,c,d,e));else{f=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.L0.aa=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.C(g);if(g=$CLJS.D(g))a=$CLJS.U.j(a,b,$CLJS.L0.aa($CLJS.M.h(a,b),g,c,d,e,f));else{g=$CLJS.U.j;var l=$CLJS.M.h(a,b);c=c.D?c.D(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.L0.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.C(l);return(l=$CLJS.D(l))?$CLJS.U.j(a,b,$CLJS.R.l($CLJS.L0,$CLJS.M.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.M.h(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.L0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.L0.v=6;
var h_=function h_(a){for(;;){if($CLJS.oe(a))return $CLJS.Mu(a,h_);if($CLJS.we(a))a=$CLJS.Mg(a);else if($CLJS.qe(a)){if($CLJS.Gb($CLJS.Xf($CLJS.Hb,a)))return null;var c=a,d=$CLJS.A(c),e=$CLJS.C(d),f=$CLJS.D(d),g=e,l=f,n=$CLJS.J(l,0,null),q=l,u=g,v=u instanceof $CLJS.N?u.S:null;switch(v){case "not":if($CLJS.xe(n)){var x=$CLJS.C(n),y=x instanceof $CLJS.N?x.S:null;switch(y){case "not":a=$CLJS.Zd(n);continue;case "and":return i_($CLJS.qs,$CLJS.rg.h(function(){return function(B){return new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.ss,B],null)}}(a,x,y,u,v,c,d,e,f,g,l,n,q),$CLJS.zd(n)));case "or":return i_($CLJS.As,$CLJS.rg.h(function(){return function(B){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ss,B],null)}}(a,x,y,u,v,c,d,e,f,g,l,n,q),$CLJS.zd(n)));default:return a}}else return a;case "and":return i_($CLJS.As,q);case "or":return i_($CLJS.qs,q);default:return $CLJS.Wm.h(h_,a)}}else return a}},jla=new $CLJS.N(null,"value_field","value_field",-980977878),LW=new $CLJS.N(null,"ascending","ascending",-988350486),
MW=new $CLJS.N(null,"named","named",-422393479),NW=new $CLJS.N(null,"values_source_config","values_source_config",-590570309),OW=new $CLJS.N(null,"descending","descending",-24766135),PW=new $CLJS.N(null,"datetime-field","datetime-field",21731696),kla=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),QW=new $CLJS.N(null,"field-literal","field-literal",-1295883554),RW=new $CLJS.N(null,"viz-settings","viz-settings",256055379),rla=new $CLJS.N(null,"rows","rows",850049680),sla=
new $CLJS.N(null,"special-fn","special-fn",1290649344),KW=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),IW=new $CLJS.N(null,"ignore-path","ignore-path",944069061),tla=new $CLJS.N(null,"label_field","label_field",-1573182765),ula=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),vla=new $CLJS.N(null,"joined-field","joined-field",-2048778268),ila=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var W0,wla=$CLJS.na($CLJS.P),xla=$CLJS.na($CLJS.P),yla=$CLJS.na($CLJS.P),zla=$CLJS.na($CLJS.P),Ala=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));W0=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Sm.h(o0,$CLJS.C),Ala,wla,xla,yla,zla);W0.o(null,$CLJS.nE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nE,b],null);return null!=a?$CLJS.kf.h(b,a):b});
W0.o(null,$CLJS.fy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fy,b instanceof $CLJS.N?f_(b):b],null);a=$CLJS.Rf($CLJS.cta(new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.iy,null,$CLJS.EE,null,$CLJS.mR,null],null),null),JW(a)));return $CLJS.m(a)?$CLJS.kf.h(b,a):b});
W0.o(null,$CLJS.lV,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(W0.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lV,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lV,t0.h?t0.h(b,IW):t0.call(null,b,IW),o0(c)],null)});W0.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,b,JW(a)],null)});
W0.o(null,QW,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[QW,b instanceof $CLJS.N?f_(b):b,$CLJS.Oi.g(a)],null)});W0.o(null,PW,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[PW,t0.h?t0.h(b,IW):t0.call(null,b,IW),$CLJS.dt,o0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[PW,t0.h?t0.h(b,IW):t0.call(null,b,IW),o0(c)],null)});
W0.o(null,$CLJS.Ey,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(W0.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ey,b,c,d],null)),t0.h?t0.h(a,IW):t0.call(null,a,IW)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ey,t0.h?t0.h(b,IW):t0.call(null,b,IW),$CLJS.Ae(c)?c:o0(c),o0(d)],null)});
W0.o(null,$CLJS.Ay,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ay,b,o0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ay,$CLJS.ev],null)});W0.o(null,$CLJS.zy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zy,b,o0(a)],null)});
W0.o(null,$CLJS.Cy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Cy,t0.h?t0.h(b,IW):t0.call(null,b,IW),c,o0(a)],null)});W0.o(null,$CLJS.Fy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Fy,t0.h?t0.h(b,IW):t0.call(null,b,IW),c,o0(a)],null)});
W0.o(null,$CLJS.By,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.By,t0.h?t0.h(b,IW):t0.call(null,b,IW),o0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.By,t0.h?t0.h(b,IW):t0.call(null,b,IW)],null)});
W0.o(null,$CLJS.Dy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Dy,t0.h?t0.h(b,IW):t0.call(null,b,IW),o0(c),o0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dy,t0.h?t0.h(b,IW):t0.call(null,b,IW),o0(c)],null)});
W0.o(null,$CLJS.yy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.yy,t0.h?t0.h(b,IW):t0.call(null,b,IW),t0.h?t0.h(c,IW):t0.call(null,c,IW),o0(a)],null)});W0.o(null,$CLJS.vl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,b,a],null)});
W0.o(null,$CLJS.oa,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[o0(a)],null),$CLJS.rg.g(function(c){return t0.h?t0.h(c,IW):t0.call(null,c,IW)}),b)});
var X0=function X0(a){if($CLJS.m($CLJS.Tx($CLJS.pf,$CLJS.Jb)(a))){var c=o0(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.fb,null,$CLJS.fs,null,$CLJS.NE,null,$CLJS.HE,null,$CLJS.ft,null,$CLJS.zE,null,$CLJS.SE,null,$CLJS.TE,null,$CLJS.aE,null,$CLJS.iE,null,$CLJS.es,null,$CLJS.bE,null,$CLJS.LE,null,$CLJS.FE,null,$CLJS.mk,null,$CLJS.gw,null,$CLJS.sE,null,$CLJS.KE,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(r0(a))?(a=$CLJS.C(a),X0.g?X0.g(a):X0.call(null,
a)):null},npa=new $CLJS.k(null,8,[$CLJS.$k,o0,$CLJS.aQ,function(a){a=p0(a);return $CLJS.A($CLJS.xQ.g(a))?$CLJS.Xm.j(a,$CLJS.xQ,Qoa):a},$CLJS.SD,new $CLJS.k(null,6,[$CLJS.nE,function Y0(a){if($CLJS.m($CLJS.Tx($CLJS.pf,$CLJS.Jb)(a)))return o0(a);if($CLJS.m(s0(MW,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[MW,Y0.g?Y0.g(a):Y0.call(null,a)],null),c)}return $CLJS.m($CLJS.m(r0(a))?X0($CLJS.Zd(a)):null)?$CLJS.Wm.h(Y0,a):t0.h?t0.h(a,
IW):t0.call(null,a,IW)},$CLJS.RP,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[f_(u),t0.h?t0.h(q,IW):t0.call(null,q,IW)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[f_(l),t0.h?t0.h(f,IW):t0.call(null,f,IW)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.GR,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(r0(q))?W0.g(q):$CLJS.lf(W0.g($CLJS.lf(q)));l.add(q);n+=
1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(r0(l))?W0.g(l):$CLJS.lf(W0.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.zQ,function(a){a=p0(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.aQ);if($CLJS.m(b))return a=$CLJS.TN(a,new $CLJS.k(null,1,[$CLJS.aQ,$CLJS.SD],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.aQ],null),a=t0.h?t0.h(a,b):t0.call(null,a,b),$CLJS.TN(a,new $CLJS.k(null,1,[$CLJS.SD,$CLJS.aQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.SD],null);return t0.h?t0.h(a,b):t0.call(null,a,b)},$CLJS.JO,new $CLJS.k(null,1,[KW,x0],null),$CLJS.mN,new $CLJS.k(null,1,[KW,function(a){a=t0.h?t0.h(a,$CLJS.SD):t0.call(null,a,$CLJS.SD);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.EC),d=$CLJS.M.h(b,$CLJS.JC);a=$CLJS.M.h(b,$CLJS.OC);b=$CLJS.m(c)?$CLJS.Xm.j(b,$CLJS.EC,o0):b;d=$CLJS.m($CLJS.Tx($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Xm.j(b,$CLJS.JC,o0):b;return $CLJS.m(a)?$CLJS.Xm.j(d,$CLJS.OC,f_):d}],null)],null),$CLJS.Bw,
new $CLJS.k(null,1,[$CLJS.ZN,$CLJS.Ye],null),$CLJS.dN,new $CLJS.k(null,1,[KW,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.$k),d=$CLJS.M.h(b,$CLJS.ZI),e=$CLJS.M.h(b,$CLJS.Zf);a=$CLJS.M.h(b,NW);b=$CLJS.m(e)?$CLJS.Xm.j(b,$CLJS.Zf,f_):b;c=$CLJS.m(c)?$CLJS.Xm.j(b,$CLJS.$k,o0):b;d=$CLJS.m(d)?$CLJS.Xm.j(c,$CLJS.ZI,function(f){return t0.h?t0.h(f,IW):t0.call(null,f,IW)}):c;d=$CLJS.m(a)?$CLJS.L0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[NW,tla],null),function(f){return t0.h?t0.h(f,IW):t0.call(null,f,IW)}):d;return $CLJS.m(a)?
$CLJS.L0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[NW,jla],null),function(f){return t0.h?t0.h(f,IW):t0.call(null,f,IW)}):d}],null),$CLJS.ZM,function(a){return null==a?null:o0(a)},$CLJS.JO,new $CLJS.k(null,1,[KW,x0],null),RW,o0],null),t0=function t0(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return t0.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
t0.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Lu(npa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=o0(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,t0.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=o0(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,t0.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(r0(a))?W0.g(a):$CLJS.ne(a)?$CLJS.Wm.h(function(e){return t0.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),KW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.WH("Error normalizing form: {0}",$CLJS.G([$CLJS.UZ(d)])),new $CLJS.k(null,3,[$CLJS.qb,a,$CLJS.fm,c,sla,b],null),d);}throw e;}};t0.v=1;t0.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var z0=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(r0(f))?$CLJS.C(f):null},e,a,b,c,d)}();z0.o(null,$CLJS.oa,function(a){return a});
z0.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(s0($CLJS.yE,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return z0.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,c,$CLJS.Rf($CLJS.nn.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,b,$CLJS.Rf(a)],null)});
z0.o(null,$CLJS.nE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nE,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nE,b,a],null)});z0.o(null,$CLJS.EK,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(r0(a))?z0.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,a,null],null)});
z0.o(null,QW,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,b,new $CLJS.k(null,1,[$CLJS.iy,a],null)],null)});z0.o(null,ula,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=A0(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=A0(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,a,$CLJS.U.j(c,$CLJS.nN,b)],null)});
z0.o(null,vla,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=A0(a);return $CLJS.R.D($CLJS.s_,a,$CLJS.U,$CLJS.G([$CLJS.lP,b]))});
z0.o(null,PW,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=A0(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.iy);$CLJS.Gb(d)||$CLJS.KS.h(d,b)?a=$CLJS.R.D($CLJS.s_,a,$CLJS.U,$CLJS.G([$CLJS.EE,b])):(c=$CLJS.ZF($CLJS.zw),$CLJS.m($CLJS.fG("metabase.mbql.util",c))&&(b=$CLJS.WH("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.aj.l($CLJS.G([a]))])),
b instanceof Error?$CLJS.gG("metabase.mbql.util",c,$CLJS.WB(),b):$CLJS.gG("metabase.mbql.util",c,$CLJS.WB.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),z0.g(new $CLJS.S(null,3,5,$CLJS.T,[PW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}});
z0.o(null,$CLJS.lV,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=A0(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,b,$CLJS.U.j(e,$CLJS.mR,$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.EC,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var SW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,$CLJS.qs,$CLJS.ss],null)),TW=null,UW=0,VW=0;;)if(VW<UW){var WW=TW.$(null,VW);z0.o(null,WW,function(){return function(a){return B0(a)}}(SW,TW,UW,VW,WW));VW+=1}else{var XW=$CLJS.A(SW);if(XW){var ZW=XW;if($CLJS.re(ZW)){var $W=$CLJS.$c(ZW),Bla=$CLJS.ad(ZW),Cla=$W,Dla=$CLJS.E($W);SW=Bla;TW=Cla;UW=Dla}else{var aX=$CLJS.C(ZW);z0.o(null,aX,function(){return function(a){return B0(a)}}(SW,TW,UW,VW,aX,ZW,XW));SW=$CLJS.D(ZW);TW=null;UW=0}VW=0}else break}
z0.o(null,$CLJS.dE,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dE,A0(a),A0(b)],null),c)});z0.o(null,$CLJS.Ey,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=A0(a);a=$CLJS.m(g_($CLJS.yE,a))?$CLJS.s_.l(c,$CLJS.Km,$CLJS.G([$CLJS.EE])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ey,a],null),b)});
for(var bX=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.pE,$CLJS.JE,$CLJS.xE,$CLJS.qE,$CLJS.$r,$CLJS.PE,$CLJS.Wr,$CLJS.Xr,$CLJS.Ur,$CLJS.Vr,$CLJS.ZD,$CLJS.gE,$CLJS.XD,$CLJS.jE,$CLJS.YD],null)),cX=null,dX=0,eX=0;;)if(eX<dX){var fX=cX.$(null,eX);z0.o(null,fX,function(){return function(a){return C0(a)}}(bX,cX,dX,eX,fX));eX+=1}else{var gX=$CLJS.A(bX);if(gX){var hX=gX;if($CLJS.re(hX)){var iX=$CLJS.$c(hX),Ela=$CLJS.ad(hX),Fla=iX,Gla=$CLJS.E(iX);bX=Ela;cX=Fla;dX=Gla}else{var jX=$CLJS.C(hX);z0.o(null,jX,
function(){return function(a){return C0(a)}}(bX,cX,dX,eX,jX,hX,gX));bX=$CLJS.D(hX);cX=null;dX=0}eX=0}else break}z0.o(null,rla,function(){return null});z0.o(null,$CLJS.dM,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dM,z0.g(b),a],null)});
z0.o(null,MW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=z0.g;var e=$CLJS.T;b=z0.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,kla)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.eC,a],null);return c.call(z0,new $CLJS.S(null,3,5,e,[$CLJS.dM,b,a],null))});
for(var kX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gw,$CLJS.zE],null)),lX=null,mX=0,nX=0;;)if(nX<mX){var pX=lX.$(null,nX);z0.o(null,pX,function(){return function(a){return D0(a)}}(kX,lX,mX,nX,pX));nX+=1}else{var qX=$CLJS.A(kX);if(qX){var rX=qX;if($CLJS.re(rX)){var sX=$CLJS.$c(rX),Hla=$CLJS.ad(rX),Ila=sX,Jla=$CLJS.E(sX);kX=Hla;lX=Ila;mX=Jla}else{var tX=$CLJS.C(rX);z0.o(null,tX,function(){return function(a){return D0(a)}}(kX,lX,mX,nX,tX,rX,qX));kX=$CLJS.D(rX);lX=null;mX=0}nX=0}else break}
for(var uX=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.sE,$CLJS.UE,$CLJS.aE,$CLJS.NE,$CLJS.KE,$CLJS.fb,$CLJS.mk,$CLJS.LE,$CLJS.bE],null)),vX=null,wX=0,xX=0;;)if(xX<wX){var yX=vX.$(null,xX);z0.o(null,yX,function(){return function(a){return E0(a)}}(uX,vX,wX,xX,yX));xX+=1}else{var zX=$CLJS.A(uX);if(zX){var AX=zX;if($CLJS.re(AX)){var BX=$CLJS.$c(AX),Kla=$CLJS.ad(AX),Lla=BX,Mla=$CLJS.E(BX);uX=Kla;vX=Lla;wX=Mla}else{var CX=$CLJS.C(AX);z0.o(null,CX,function(){return function(a){return E0(a)}}(uX,vX,wX,xX,
CX,AX,zX));uX=$CLJS.D(AX);vX=null;wX=0}xX=0}else break}z0.o(null,$CLJS.iE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iE,A0(b),a],null)});
for(var DX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FE,$CLJS.HE],null)),EX=null,FX=0,GX=0;;)if(GX<FX){var HX=EX.$(null,GX);z0.o(null,HX,function(){return function(a){return F0(a)}}(DX,EX,FX,GX,HX));GX+=1}else{var IX=$CLJS.A(DX);if(IX){var JX=IX;if($CLJS.re(JX)){var KX=$CLJS.$c(JX),Nla=$CLJS.ad(JX),Ola=KX,Pla=$CLJS.E(KX);DX=Nla;EX=Ola;FX=Pla}else{var LX=$CLJS.C(JX);z0.o(null,LX,function(){return function(a){return F0(a)}}(DX,EX,FX,GX,LX,JX,IX));DX=$CLJS.D(JX);EX=null;FX=0}GX=0}else break}
z0.o(null,$CLJS.SE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SE,z0.g(b),z0.g(a)],null)});
z0.o(null,$CLJS.eE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(z0.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eE,b],null)),t0.l(a,$CLJS.G([IW]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eE,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[z0.g(v),z0.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[z0.g(n),z0.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
z0.o(null,$CLJS.tD,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tD,z0.g(a),$CLJS.F.h(0,b)?1:z0.g(b)],null),$CLJS.rg.h(z0,c))});
var w0=function w0(a){if($CLJS.pe(a))return $CLJS.Xe(function(d,e,f){return $CLJS.U.j(d,e,w0.g?w0.g(f):w0.call(null,f))},a,a);if($CLJS.oe(a))return $CLJS.Mu(a,w0);if($CLJS.m(r0(a))){var c=function(){try{return z0.g(a)}catch(g){if(g instanceof Error){var d=g,e=$CLJS.ZF($CLJS.xw);if($CLJS.m($CLJS.fG("metabase.mbql.normalize",e))){var f=$CLJS.XH("Invalid clause:");f instanceof Error?$CLJS.gG("metabase.mbql.normalize",e,$CLJS.WB.l($CLJS.G([a])),f):$CLJS.gG("metabase.mbql.normalize",e,$CLJS.WB.l($CLJS.G([f,
a])),null)}throw $CLJS.yj($CLJS.WH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.UZ(d)])),new $CLJS.k(null,1,[$CLJS.RR,a],null),d);}throw g;}}();return $CLJS.A(c)?$CLJS.oh.j($CLJS.kf.h($CLJS.ae(c),$CLJS.C(c)),$CLJS.rg.g(w0),$CLJS.zd(c)):c}return $CLJS.we(a)?$CLJS.Wm.h(w0,a):$CLJS.ke(a)?$CLJS.oh.j($CLJS.ae(a),$CLJS.rg.g(w0),a):a},G0=function G0(a){return function f(d,e){try{if($CLJS.m(function(){var x=q0($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=q0($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.bn.j(e,0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.PB($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.fs,null,$CLJS.ft,null,$CLJS.TE,null,$CLJS.es,null,
MW,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(X0(n)))return $CLJS.oh.j($CLJS.Lg,$CLJS.XG(G0),a);throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&
1<=$CLJS.E(e))try{var u=$CLJS.bn.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;
else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},R1=function R1(a){return function f(d,e){try{var g=q0($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.vE)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);
if($CLJS.O(n,$CLJS.oE))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oE,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,LW))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,OW))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oE,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=
Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,LW))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,OW))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oE,q],null));throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.vE))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,A0(q)],null);throw $CLJS.Z;
}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.oE))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oE,A0(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.bn.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.bn.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.pn.g($CLJS.rg.h(R1,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.e_(f,d,e);
throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},J0=$CLJS.Sm.h(w0,function(a){var b=K0($CLJS.nE.g(a))?$CLJS.Xm.j(a,$CLJS.nE,Soa):a;b=K0($CLJS.mE.g(a))?$CLJS.Xm.j(b,$CLJS.mE,Toa):b;b=K0($CLJS.JC.g(a))?$CLJS.Xm.j(b,$CLJS.JC,$CLJS.bg($CLJS.Wm,y0)):b;b=K0($CLJS.GR.g(a))?$CLJS.Xm.j(b,$CLJS.GR,R1):b;return K0($CLJS.zQ.g(a))?$CLJS.Xm.j(b,$CLJS.zQ,Voa):b}),Dpa=new $CLJS.k(null,3,[$CLJS.aQ,$CLJS.Ye,$CLJS.SD,new $CLJS.k(null,2,[$CLJS.zQ,function(a){a=$CLJS.Qf(a);var b=
$CLJS.M.h(a,$CLJS.aQ);if($CLJS.m(b))return a=$CLJS.TN(a,new $CLJS.k(null,1,[$CLJS.aQ,$CLJS.SD],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.aQ],null),a=M0.h?M0.h(a,b):M0.call(null,a,b),$CLJS.TN(a,new $CLJS.k(null,1,[$CLJS.SD,$CLJS.aQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.SD],null);return M0.h?M0.h(a,b):M0.call(null,a,b)},$CLJS.mN,new $CLJS.k(null,1,[KW,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.SD],null);return M0.h?M0.h(a,b):M0.call(null,a,b)}],null)],null),RW,$CLJS.Ye],null),
M0=function M0(a){switch(arguments.length){case 1:return M0.g(arguments[0]);case 2:return M0.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};M0.g=function(a){return M0.h(a,$CLJS.Lg)};
M0.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Lu(Dpa,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?Zoa(a,b):$CLJS.ne(a)?$oa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.qb,a,$CLJS.fm,b],null),d);throw d;}};M0.v=2;
$CLJS.S1=function(){var a=$CLJS.Sm.l(M0,Yoa,Woa,$CLJS.G([t0]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.WH("Error normalizing query: {0}",$CLJS.G([$CLJS.UZ(c)])),new $CLJS.k(null,1,[$CLJS.SD,b],null),c);}throw d;}}}();$CLJS.Z$=function Z$(a,b){if($CLJS.Gb($CLJS.A(a)))a=(0,$CLJS.S1)(b);else{var d=$CLJS.M,e=d.h;var f=$CLJS.ti(a);b=$CLJS.Pf([$CLJS.$d(a),b]);f=Z$.h?Z$.h(f,b):Z$.call(null,f,b);a=e.call(d,f,$CLJS.$d(a))}return a};