var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.js");
'use strict';var rua=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.iy);return $CLJS.Xf(function(c){return $CLJS.QH(b,c)},$CLJS.WL)},l6=new $CLJS.N("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.pV($CLJS.vE,l6);$CLJS.pV($CLJS.oE,l6);
$CLJS.N3.o(null,$CLJS.GR,function(a,b){var c=$CLJS.Rf($CLJS.GR.g($CLJS.e3(a,b)));return $CLJS.m(c)?$CLJS.WH("Sorted by {0}",$CLJS.G([$CLJS.O4($CLJS.XH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.n3.D(a,b,v,$CLJS.d_);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);
return $CLJS.nf($CLJS.n3.D(a,b,q,$CLJS.d_),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});$CLJS.K3.o(null,l6,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.n3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "asc":return $CLJS.WH("{0} ascending",$CLJS.G([a]));case "desc":return $CLJS.WH("{0} descending",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.Q3.o(null,l6,function(a,b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.U.j($CLJS.q3.j(a,b,c),$CLJS.y_,d)});var U7,hta=$CLJS.na($CLJS.P),ita=$CLJS.na($CLJS.P),jta=$CLJS.na($CLJS.P),kta=$CLJS.na($CLJS.P),lta=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));U7=new $CLJS.vj($CLJS.Ui.h("metabase.lib.order-by","order-by-clause-method"),$CLJS.EH,lta,hta,ita,jta,kta);U7.o(null,l6,function(a){return $CLJS.hW(a)});
U7.o(null,$CLJS.oa,function(a){if(null==a)throw $CLJS.zj($CLJS.XH("Can''t order by nil"),$CLJS.P);return $CLJS.hW(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,$CLJS.iW(a)],null))});
$CLJS.h9=function(){function a(d,e){d=U7.g(d);e=$CLJS.m(e)?e:$CLJS.vE;return $CLJS.U.j($CLJS.Mg(d),0,e)}function b(d){return c.h?c.h(d,$CLJS.vE):c.call(null,d,$CLJS.vE)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.i9=function(){function a(e,f,g,l){f=$CLJS.m(f)?f:-1;var n=function(){var q=U7.g(g);return $CLJS.m(l)?$CLJS.U.j($CLJS.Mg(q),0,l):q}();return $CLJS.J7.l(e,f,$CLJS.Xm,$CLJS.G([$CLJS.GR,function(q){return $CLJS.kf.h($CLJS.Mg(q),n)}]))}function b(e,f,g){return d.D?d.D(e,-1,f,g):d.call(null,e,-1,f,g)}function c(e,f){return d.D?d.D(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,g,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,g);case 4:return a.call(this,
e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.j=b;d.D=a;return d}();$CLJS.j9=function(){function a(d,e){return $CLJS.Rf($CLJS.M.h($CLJS.e3(d,e),$CLJS.GR))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.k9=function(){function a(d,e){var f=$CLJS.Rf($CLJS.f8.h(d,e)),g=$CLJS.Rf($CLJS.g8.h(d,e));f=$CLJS.m($CLJS.m(f)?f:g)?$CLJS.qg.h(f,g):function(){var q=$CLJS.e3(d,e);return $CLJS.N_.D(d,e,q,new $CLJS.k(null,1,[$CLJS.H_,!1],null))}();var l=$CLJS.ug(rua,f);f=$CLJS.rg.h(function(q){$CLJS.J(q,0,null);$CLJS.J(q,1,null);return $CLJS.J(q,2,null)},$CLJS.j9.h(d,e));if($CLJS.je(l))return null;if($CLJS.je(f))return $CLJS.Mg(l);var n=$CLJS.oh.j($CLJS.P,$CLJS.Sm.h($CLJS.rg.g($CLJS.iW),$CLJS.p1(function(q,u){u=
$CLJS.y1.D(d,e,u,l);return $CLJS.m(u)?new $CLJS.S(null,2,5,$CLJS.T,[u,q],null):null})),f);return $CLJS.Wm.h(function(q){var u=n.g?n.g(q):n.call(null,q);return $CLJS.m(u)?$CLJS.U.j(q,$CLJS.v_,u):q},l)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.V7=new $CLJS.k(null,2,[$CLJS.vE,$CLJS.oE,$CLJS.oE,$CLJS.vE],null);$CLJS.mta=function(){function a(d,e){return $CLJS.J7.l(d,e,$CLJS.Km,$CLJS.G([$CLJS.GR]))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();