var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var T1,X1,U1,Hpa,Ipa,ML,Nha,OL,fO,hO,gO,Oha,iO,PL,nO,oO,Y1,QL,Pha,RL,Qha,SL,TL,UL,Rha,VL,YL,Sha,ZL,Tha,$L,aM,Uha,cM,Vha,Wha,Xha,eM,fM,gM,hM,iM,jM,kM,lM,Yha,mM,nM,oM,qM,rM,Zha,sM,tM,uM,vM,$ha,wM,xM,yM,zM,aia,AM,BM,CM,DM,bia,EM,cia,FM,GM,HM,dia,IM,JM,KM,LM,NM,OM,eia,PM,QM,fia,RM,SM,TM,UM,VM,gia,WM,hia,XM,YM,$M,iia,aN,bN,cN,jia,fN,gN,kia,lia,hN,jN,kN,lN,mia,oN,pN,nia,oia,pia,qN,rN,sN,tN,uN,vN,qia,wN,ria,xN,yN,AN,CN,DN,EN,FN,sia,tia,HN,uia,via,IN,JN,KN,LN,MN,wia,NN,xia,yia,ON,PN,QN,RN,SN,
zia,Aia,Bia,UN,Cia,VN,Dia,XN,YN,Eia,$N,aO,bO,cO,Fia,jO,Gia,Hia,lO,pO,qO,Iia,rO,sO,tO,uO,Jia,wO,xO,yO,zO,AO,Kia,BO,CO,Lia,DO,EO,FO,GO,HO,IO,KO,Mia,LO,Nia,MO,NO,OO,Oia,Pia,Qia,QO,RO,SO,Ria,TO,UO,VO,WO,Sia,Tia,YO,Uia,Via,ZO,$O,aP,bP,cP,Wia,dP,eP,fP,gP,Xia,hP,iP,jP,kP,mP,Yia,nP,oP,Zia,pP,qP,rP,$ia,sP,aja,uP,bja,vP,wP,xP,yP,zP,AP,BP,cja,dja,eja,CP,fja,DP,EP,gja,hja,FP,GP,HP,ija,IP,jja,JP,kja,lja,KP,mja,nja,oja,NP,OP,pja,PP,QP,SP,qja,TP,rja,UP,VP,WP,sja,XP,YP,ZP,$P,tja,bQ,cQ,uja,dQ,eQ,fQ,gQ,hQ,iQ,jQ,kQ,
vja,lQ,mQ,wja,nQ,xja,oQ,pQ,qQ,yja,rQ,sQ,zja,Aja,tQ,uQ,vQ,Bja,Cja,wQ,Dja,Eja,Fja,yQ,Gja,AQ,Hja,Ija,BQ,CQ,Jja,Kja,Lja,DQ,EQ,FQ,GQ,HQ,IQ,JQ,Mja,KQ,LQ,Nja,NQ,Oja,Pja,Qja,OQ,PQ,QQ,Rja,RQ,SQ,Sja,TQ,Tja,UQ,Uja,Vja,WQ,XQ,YQ,ZQ,$Q,Wja,aR,bR,Xja,cR,dR,eR,fR,gR,hR,iR,jR,Yja,kR,lR,nR,oR,pR,qR,rR,sR,tR,uR,vR,wR,Zja,xR,yR,zR,AR,BR,$ja,CR,DR,ER,FR,aka,HR,bka,cka,JR,dka,KR,eka,LR,fka,gka,MR,hka,NR,PR,QR,ika,jka,UR,VR,kka,lka,WR,YR,ZR,$R,aS,mka,bS,cS,eS,fS,nka;
$CLJS.Gpa=function(a){var b=$CLJS.Xp.h(a,null),c=$CLJS.qy(b,$CLJS.Ls,function(d){return $CLJS.Xo(d,$CLJS.Lg)});return function(){function d(g,l,n){return(l=$CLJS.A(c.j?c.j(g,l,n):c.call(null,g,l,n)))?new $CLJS.k(null,3,[$CLJS.jm,b,$CLJS.vl,g,$CLJS.Rs,l],null):null}function e(g){return f.j(g,$CLJS.Lg,$CLJS.Lg)}var f=null;f=function(g,l,n){switch(arguments.length){case 1:return e.call(this,g);case 3:return d.call(this,g,l,n)}throw Error("Invalid arity: "+arguments.length);};f.g=e;f.j=d;return f}()};
T1=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};X1=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.$o($CLJS.Xp.h($CLJS.jm.g(a),null));if($CLJS.m(b)){var e=T1($CLJS.Qt.g(b),c);$CLJS.m(e)?(e=$CLJS.hs(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:T1($CLJS.tt.g(b),c)}return null};U1=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};
Hpa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.jm),d=$CLJS.M.h(a,$CLJS.$k);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.Rs,$CLJS.mba),f=$CLJS.M.j(b,$CLJS.Rba,!0),g=$CLJS.M.h(b,$CLJS.Rt),l=$CLJS.M.j(b,$CLJS.Ot,$CLJS.Kt),n=X1(a,$CLJS.ts.g(c),g,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.uu.g(c),g,b);if($CLJS.m(n))return n;n=X1(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=X1(a,function(){var q=$CLJS.Fp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.ts.g(c),
l,b);if($CLJS.m(n))return n;n=X1(a,$CLJS.uu.g(c),l,b);if($CLJS.m(n))return n;d=X1(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=X1(a,function(){var q=$CLJS.Fp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?X1(a,e.g?e.g($CLJS.Ft):e.call(null,$CLJS.Ft),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?X1(a,e.g?e.g($CLJS.Ft):e.call(null,$CLJS.Ft),l,b):f};
Ipa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.tn.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.jm),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.Rt);l=$CLJS.M.j(l,$CLJS.Ot,$CLJS.Kt);g=$CLJS.ts.g(g);f=T1($CLJS.Nt.g(g),f);f=$CLJS.m(f)?f:T1($CLJS.Nt.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Hpa(b,c)],null)};
$CLJS.Jpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.vl);a=$CLJS.M.h(b,$CLJS.Rs);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.Sba,$CLJS.mb),f=$CLJS.M.j(d,$CLJS.Uba,Ipa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.mb,n);l=e.g?e.g(l):e.call(null,l);return Y1(g,c,q,l)},null,a):null};
ML=function(a,b,c){var d=$CLJS.Lu($CLJS.r($CLJS.OB),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null));if($CLJS.m(d))return d;c=c.A?c.A():c.call(null);$CLJS.dj.D($CLJS.OB,$CLJS.Ru,new $CLJS.S(null,2,5,$CLJS.T,[a,b],null),c);return c};$CLJS.NL=function(a){return ML($CLJS.xm,a,function(){return $CLJS.sy.g(a)})};Nha=function(){var a=gV;return ML($CLJS.Ls,a,function(){var b=$CLJS.sy.g(a),c=$CLJS.Gpa(a);return function(d){return $CLJS.m(b.g?b.g(d):b.call(null,d))?null:c.g?c.g(d):c.call(null,d)}})};
OL=function(a){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.xf(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,a],null):a],null)};fO=function(a){if($CLJS.qe(a)){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);switch(b instanceof $CLJS.N?b.S:null){case "optional":return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,OL(c)],null)],null);case "rest":return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fs,OL(c)],null);default:return OL(a)}}else return OL(a)};
hO=function(a,b){var c=$CLJS.qe(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
gO=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,["not a ",$CLJS.p.g(a)," clause"].join("")],null),$CLJS.bg(hO,a)],null),$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ms,new $CLJS.S(null,2,5,$CLJS.T,["tag",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,a],null)],null)],null),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);
a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,2,5,$CLJS.T,[v,fO(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[n,fO(g)],null),e($CLJS.zd(f)))}return null}},null,null)}($CLJS.xu(2,2,b))}())],null)};Oha=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N?$CLJS.C(a):null};
iO=function(a){return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.ib,Oha,$CLJS.tt,["valid instance of one of these MBQL clauses: ",$CLJS.it(", ",$CLJS.rg.h($CLJS.C,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,
$CLJS.xf(q)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,q],null):q],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,$CLJS.xf(f)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,f],null):f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};
PL=function(a){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.LL],null)],null)};$CLJS.mO={};nO={};oO={};$CLJS.Ru=function Ru(a,b,c){var e=$CLJS.A(b);b=$CLJS.C(e);var f=$CLJS.D(e);if(f){e=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=Ru.j?Ru.j(g,f,c):Ru.call(null,g,f,c);a=e.call($CLJS.U,a,b,c)}else a=$CLJS.U.j(a,b,c);return a};
Y1=function Y1(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=U1(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.Ht.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=U1(f,c),d=Y1.D?Y1.D(b,l,g,d):Y1.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Tba],null),Y1.D?Y1.D(f,b,c,d):Y1.call(null,f,b,c,d)):$CLJS.m($CLJS.Ht.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.Ht,!0],null))};QL=new $CLJS.N("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Pha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
RL=new $CLJS.N("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Qha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);SL=new $CLJS.N("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);TL=new $CLJS.N("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);UL=new $CLJS.N("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Rha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);VL=new $CLJS.N("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);YL=new $CLJS.N("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Sha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);ZL=new $CLJS.N("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Tha=new $CLJS.N(null,"from","from",1815293044);$L=new $CLJS.N("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);aM=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Uha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.bM=new $CLJS.N(null,"snippet-name","snippet-name",819240328);cM=new $CLJS.N("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Vha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Wha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.dM=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Xha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);eM=new $CLJS.N("location","zip_code","location/zip_code",1641155222);
fM=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);gM=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);hM=new $CLJS.N("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);iM=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);jM=new $CLJS.N("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
kM=new $CLJS.N("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);lM=new $CLJS.N("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Yha=new $CLJS.N(null,"lon-max","lon-max",1590224717);mM=new $CLJS.N("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);nM=new $CLJS.w(null,"stddev","stddev",775056588,null);oM=new $CLJS.N("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.pM=new $CLJS.N(null,"snippet","snippet",953581994);qM=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);rM=new $CLJS.N(null,"string-expression","string-expression",-1395337766);Zha=new $CLJS.N(null,"lat-field","lat-field",-830652957);sM=new $CLJS.N("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);tM=new $CLJS.N("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
uM=new $CLJS.N("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);vM=new $CLJS.N("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);$ha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);wM=new $CLJS.N("location","country","location/country",1666636202);xM=new $CLJS.N("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
yM=new $CLJS.N("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);zM=new $CLJS.N("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);aia=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);AM=new $CLJS.N(null,"unary","unary",-989314568);BM=new $CLJS.N("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
CM=new $CLJS.N("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);DM=new $CLJS.w(null,"ceil","ceil",-184398425,null);bia=new $CLJS.N(null,"lon-min","lon-min",-787291357);EM=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);cia=new $CLJS.N(null,"match","match",1220059550);FM=new $CLJS.w(null,"count-where","count-where",2025939247,null);GM=new $CLJS.N("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
HM=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);dia=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);IM=new $CLJS.N("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);JM=new $CLJS.w(null,"sum","sum",1777518341,null);KM=new $CLJS.N("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);LM=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.MM=new $CLJS.N("date","range","date/range",1647265776);NM=new $CLJS.w(null,"between","between",-1523336493,null);OM=new $CLJS.N(null,"clause-form","clause-form",1820463737);eia=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);PM=new $CLJS.N("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);QM=new $CLJS.w(null,"field","field",338095027,null);fia=new $CLJS.N(null,"segment-id","segment-id",1810133590);
RM=new $CLJS.N("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);SM=new $CLJS.w(null,"not-null","not-null",313812992,null);TM=new $CLJS.N("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);UM=new $CLJS.N("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);VM=new $CLJS.N("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);gia=new $CLJS.N(null,"template-tag","template-tag",310841038);
WM=new $CLJS.N(null,"invalid","invalid",412869516);hia=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);XM=new $CLJS.N("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);YM=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.ZM=new $CLJS.N(null,"context","context",-830191113);$M=new $CLJS.w(null,"get-year","get-year",704520253,null);
iia=new $CLJS.N(null,"format-rows?","format-rows?",992129486);aN=new $CLJS.N("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);bN=new $CLJS.N("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);cN=new $CLJS.N("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.dN=new $CLJS.N(null,"parameters","parameters",-1229919748);jia=new $CLJS.N(null,"json-download","json-download",-971130133);
$CLJS.eN=new $CLJS.N(null,"card","card",-1430355152);fN=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);gN=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);kia=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);lia=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);hN=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.iN=new $CLJS.N("date","month-year","date/month-year",1948031290);
jN=new $CLJS.N("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);kN=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);lN=new $CLJS.N("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.mN=new $CLJS.N(null,"joins","joins",1033962699);mia=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.nN=new $CLJS.N(null,"source-field","source-field",933829534);oN=new $CLJS.w(null,"Field","Field",430385967,null);
pN=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);nia=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);oia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);pia=new $CLJS.N(null,"items","items",1031954938);qN=new $CLJS.N(null,"datetime-expression","datetime-expression",391782195);rN=new $CLJS.N("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
sN=new $CLJS.N(null,"more","more",-2058821800);tN=new $CLJS.N(null,"first-clause","first-clause",-20953491);uN=new $CLJS.N("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);vN=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);qia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);wN=new $CLJS.w(null,"contains","contains",-1977535957,null);
ria=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);xN=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);yN=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.zN=new $CLJS.N(null,"widget-type","widget-type",1836256899);AN=new $CLJS.N("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.BN=new $CLJS.N(null,"fingerprint","fingerprint",598613022);
CN=new $CLJS.w(null,"is-null","is-null",-356519403,null);DN=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);EN=new $CLJS.N("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);FN=new $CLJS.N("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);sia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);$CLJS.GN=new $CLJS.N(null,"required","required",1807647006);tia=new $CLJS.N(null,"embedded-csv-download","embedded-csv-download",1858870014);
HN=new $CLJS.N(null,"unnamed-aggregation","unnamed-aggregation",-93854280);uia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);via=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);IN=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);JN=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);KN=new $CLJS.N("string","contains","string/contains",1602423827);LN=new $CLJS.N("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
MN=new $CLJS.N("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);wia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);NN=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);xia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);yia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
ON=new $CLJS.N("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);PN=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);QN=new $CLJS.N("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);RN=new $CLJS.N("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);SN=new $CLJS.w(null,"share","share",1051097594,null);
zia=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Aia=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Bia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);UN=new $CLJS.N("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Cia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
VN=new $CLJS.N("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Dia=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.WN=new $CLJS.N(null,"collection","collection",-683361892);XN=new $CLJS.N("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);YN=new $CLJS.N("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.ZN=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Eia=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);$N=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);aO=new $CLJS.w(null,"metric","metric",2049329604,null);bO=new $CLJS.w(null,"concat","concat",-467652465,null);cO=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.dO=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);
Fia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.eO=new $CLJS.N("date","relative","date/relative",25987732);jO=new $CLJS.N("location","city","location/city",-1746973325);Gia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);Hia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);lO=new $CLJS.N("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);pO=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
qO=new $CLJS.N("number","between","number/between",97700581);Iia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);rO=new $CLJS.N("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);sO=new $CLJS.w(null,"sqrt","sqrt",370479598,null);tO=new $CLJS.N("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);uO=new $CLJS.N("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.vO=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Jia=new $CLJS.N(null,"metric-id","metric-id",-686486942);wO=new $CLJS.w(null,"*","*",345799209,null);xO=new $CLJS.w(null,"+","+",-740910886,null);yO=new $CLJS.w(null,"-","-",-471816912,null);zO=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);AO=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Kia=new $CLJS.N(null,"question","question",-1411720117);BO=new $CLJS.w(null,"asc","asc",1997386096,null);
CO=new $CLJS.N("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Lia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);DO=new $CLJS.N("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);EO=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);FO=new $CLJS.w(null,"\x3c","\x3c",993667236,null);GO=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);HO=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
IO=new $CLJS.N("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.JO=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);KO=new $CLJS.N("string","ends-with","string/ends-with",302681156);Mia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);LO=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Nia=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
MO=new $CLJS.w(null,"and","and",668631710,null);NO=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);OO=new $CLJS.w(null,"round","round",-645002441,null);Oia=new $CLJS.N(null,"to","to",192099007);$CLJS.PO=new $CLJS.N("date","single","date/single",1554682003);Pia=new $CLJS.N(null,"action-id","action-id",-1727958578);Qia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);QO=new $CLJS.w(null,"exp","exp",1378825265,null);
RO=new $CLJS.w(null,"Filter","Filter",-424893332,null);SO=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Ria=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);TO=new $CLJS.N("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);UO=new $CLJS.N("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);VO=new $CLJS.N("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
WO=new $CLJS.N(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.XO=new $CLJS.N(null,"source-table","source-table",-225307692);Sia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);Tia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);YO=new $CLJS.w(null,"floor","floor",-772394748,null);Uia=new $CLJS.N(null,"middleware","middleware",1462115504);
Via=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);ZO=new $CLJS.N(null,"requires-features","requires-features",-101116256);$O=new $CLJS.N("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);aP=new $CLJS.N(null,"clause-name","clause-name",-996419059);bP=new $CLJS.N("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);cP=new $CLJS.w(null,"now","now",-9994004,null);
Wia=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);dP=new $CLJS.w(null,"not","not",1044554643,null);eP=new $CLJS.w(null,"avg","avg",1837937727,null);fP=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);gP=new $CLJS.N(null,"max-results","max-results",-32858165);Xia=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);hP=new $CLJS.w(null,"case","case",-1510733573,null);
iP=new $CLJS.w(null,"distinct","distinct",-148347594,null);jP=new $CLJS.w(null,"get-second","get-second",-425414791,null);kP=new $CLJS.N("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.lP=new $CLJS.N(null,"join-alias","join-alias",1454206794);mP=new $CLJS.w(null,"is-empty","is-empty",600228619,null);Yia=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
nP=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);oP=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);Zia=new $CLJS.N(null,"original","original",-445386197);pP=new $CLJS.N("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);qP=new $CLJS.w(null,"abs","abs",1394505050,null);rP=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);$ia=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
sP=new $CLJS.N("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);aja=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.tP=new $CLJS.N(null,"date","date",-1463434462);uP=new $CLJS.N(null,"second-clause","second-clause",-461435645);bja=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);vP=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
wP=new $CLJS.N("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);xP=new $CLJS.N("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);yP=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);zP=new $CLJS.N("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);AP=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);BP=new $CLJS.w(null,"or","or",1876275696,null);
cja=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);dja=new $CLJS.N(null,"constraints","constraints",422775616);eja=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);CP=new $CLJS.N("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);fja=new $CLJS.N(null,"csv-download","csv-download",2141432084);DP=new $CLJS.N("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
EP=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);gja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);hja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);FP=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);GP=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
HP=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);ija=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);IP=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);jja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);JP=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);kja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
lja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);KP=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);mja=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);nja=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);oja=new $CLJS.N(null,"display_name","display_name",-1494335013);$CLJS.LP=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);
$CLJS.MP=new $CLJS.N(null,"database","database",1849087575);NP=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);OP=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);pja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);PP=new $CLJS.N("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
QP=new $CLJS.N("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.RP=new $CLJS.N(null,"expressions","expressions",255689909);SP=new $CLJS.N("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);qja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);TP=new $CLJS.N("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
rja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);UP=new $CLJS.N("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);VP=new $CLJS.N("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);WP=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);sja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
XP=new $CLJS.w(null,"get-day","get-day",1768100384,null);YP=new $CLJS.N("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);ZP=new $CLJS.N("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);$P=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.aQ=new $CLJS.N(null,"native","native",-613060878);tja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);bQ=new $CLJS.N(null,"page","page",849072397);
cQ=new $CLJS.w(null,"length","length",-2065447907,null);uja=new $CLJS.N(null,"dashboard","dashboard",-631747508);dQ=new $CLJS.w(null,"get-week","get-week",752472178,null);eQ=new $CLJS.w(null,"get-month","get-month",1271156796,null);fQ=new $CLJS.w(null,"dimension","dimension",-2111181571,null);gQ=new $CLJS.N(null,"boolean-expression","boolean-expression",-394924008);hQ=new $CLJS.N("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
iQ=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);jQ=new $CLJS.N("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);kQ=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);vja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);lQ=new $CLJS.N("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);mQ=new $CLJS.w(null,"substring","substring",-1513569493,null);
wja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);nQ=new $CLJS.N(null,"internal","internal",-854870097);xja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);oQ=new $CLJS.N("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);pQ=new $CLJS.N("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
qQ=new $CLJS.N("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);yja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);rQ=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);sQ=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);zja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Aja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);tQ=new $CLJS.N("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);uQ=new $CLJS.N("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);vQ=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Bja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Cja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);wQ=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.xQ=new $CLJS.N(null,"template-tags","template-tags",1853115685);Dja=new $CLJS.N(null,"public-question","public-question",629369976);Eja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Fja=new $CLJS.N(null,"binary","binary",-1802232288);yQ=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Gja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);$CLJS.zQ=new $CLJS.N(null,"source-query","source-query",198004422);AQ=new $CLJS.N("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Hja=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
Ija=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);BQ=new $CLJS.N("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);CQ=new $CLJS.N("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Jja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Kja=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Lja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);DQ=new $CLJS.N(null,"amount","amount",364489504);EQ=new $CLJS.w(null,"percentile","percentile",1039342775,null);FQ=new $CLJS.N("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);GQ=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);HQ=new $CLJS.N("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
IQ=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);JQ=new $CLJS.w(null,"trim","trim",-1880116002,null);Mja=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);KQ=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);LQ=new $CLJS.N("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Nja=new $CLJS.N("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
NQ=new $CLJS.N("string","\x3d","string/\x3d",983744235);Oja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);Pja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Qja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);OQ=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);PQ=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);
QQ=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Rja=new $CLJS.N(null,"lat-min","lat-min",1630784161);RQ=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);SQ=new $CLJS.w(null,"inside","inside",-681932758,null);Sja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);TQ=new $CLJS.N("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Tja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);UQ=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Uja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Vja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.VQ=new $CLJS.N(null,"card-id","card-id",-1770060179);WQ=new $CLJS.N(null,"variadic","variadic",882626057);
XQ=new $CLJS.w(null,"upper","upper",1886775433,null);YQ=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);ZQ=new $CLJS.N("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);$Q=new $CLJS.w(null,"optional","optional",-600484260,null);Wja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);aR=new $CLJS.N("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
bR=new $CLJS.N(null,"sugar","sugar",-73788488);Xja=new $CLJS.N(null,"lat-max","lat-max",841568226);cR=new $CLJS.w(null,"power","power",702679448,null);dR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);eR=new $CLJS.N("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);fR=new $CLJS.N("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
gR=new $CLJS.N("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);hR=new $CLJS.w(null,"median","median",-2084869638,null);iR=new $CLJS.N("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);jR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Yja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);kR=new $CLJS.N(null,"y","y",-1757859776);lR=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.mR=new $CLJS.N(null,"binning","binning",1709835866);nR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);oR=new $CLJS.N("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);pR=new $CLJS.N("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);qR=new $CLJS.N("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);rR=new $CLJS.w(null,"Reference","Reference",2024574086,null);sR=new $CLJS.N(null,"b","b",1482224470);
tR=new $CLJS.N(null,"a","a",-2123407586);uR=new $CLJS.N("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);vR=new $CLJS.w(null,"replace","replace",853943757,null);wR=new $CLJS.N("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Zja=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);xR=new $CLJS.N("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
yR=new $CLJS.N("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);zR=new $CLJS.N("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);AR=new $CLJS.N("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);BR=new $CLJS.w(null,"segment","segment",675610331,null);$ja=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);CR=new $CLJS.N("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
DR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);ER=new $CLJS.N("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);FR=new $CLJS.N("string","starts-with","string/starts-with",1266861170);aka=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.GR=new $CLJS.N(null,"order-by","order-by",1527318070);HR=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
bka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);cka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.IR=new $CLJS.N(null,"condition","condition",1668437652);JR=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);dka=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);KR=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
eka=new $CLJS.N(null,"card-name","card-name",-2035606807);LR=new $CLJS.N("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);fka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);gka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);MR=new $CLJS.w(null,"log","log",45015523,null);hka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
NR=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.OR=new $CLJS.N(null,"database_type","database_type",-54700895);PR=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);QR=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.RR=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.SR=new $CLJS.N(null,"expression-name","expression-name",-1798450709);$CLJS.TR=new $CLJS.N("date","all-options","date/all-options",549325958);
ika=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);jka=new $CLJS.N(null,"embedded-json-download","embedded-json-download",-1980927033);UR=new $CLJS.N("location","state","location/state",-114378652);VR=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);kka=new $CLJS.N(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);lka=new $CLJS.N(null,"lon-field","lon-field",517872067);
WR=new $CLJS.N("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);YR=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);ZR=new $CLJS.N(null,"numeric","numeric",-1495594714);$R=new $CLJS.w(null,"variable","variable",1359185035,null);aS=new $CLJS.w(null,"lower","lower",-1534114948,null);mka=new $CLJS.N(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
bS=new $CLJS.N("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);cS=new $CLJS.N("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.dS=new $CLJS.N(null,"limit","limit",-1355822363);eS=new $CLJS.N("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);fS=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);nka=new $CLJS.N(null,"pulse","pulse",-244494476);var gS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oC],null),hS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.CC],null),iS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.fC],null),jS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.UB],null),kS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.tC],null),lS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.BC],null),oka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.FK],null),pka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yK],null),mS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,
$CLJS.uI],null),nS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.yI],null),oS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.pI],null),wS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Oa,null,$CLJS.fl,null,$CLJS.Fj,null,$CLJS.oa,null,$CLJS.Kk,null,$CLJS.Kj,null,$CLJS.bl,null,$CLJS.Vk,null,$CLJS.ra,null,$CLJS.Qj,null,$CLJS.Va,null,$CLJS.Jk,null],null),null),xS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.cl,null,$CLJS.wF,null,$CLJS.oa,null,$CLJS.Gk,null,$CLJS.BF,null,$CLJS.Tk,null,$CLJS.Ok,null],null),null),
yS=$CLJS.zz.h(wS,xS),rka=$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"date bucketing unit"],null)],null),wS),ska=$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"time bucketing unit"],null)],null),xS),zS=$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"datetime bucketing unit"],null)],null),yS),AS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.TI],null),qka=new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,
1,[$CLJS.tt,"temporal extract unit"],null),$CLJS.CF,$CLJS.Jk,$CLJS.bl,$CLJS.JI,$CLJS.XI,$CLJS.YI,$CLJS.Vk,$CLJS.Kk,$CLJS.Gk,$CLJS.Tk,$CLJS.xF],null),tka=new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"datetime-diff unit"],null),$CLJS.wF,$CLJS.Ok,$CLJS.cl,$CLJS.fl,$CLJS.Fj,$CLJS.Kj,$CLJS.Oa,$CLJS.ra],null),pS=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"temporal-extract week extraction mode"],null),$CLJS.DI,$CLJS.KI,$CLJS.OI],null),CS=new $CLJS.S(null,
10,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"relative-datetime unit"],null),$CLJS.oa,$CLJS.Ok,$CLJS.cl,$CLJS.fl,$CLJS.Fj,$CLJS.Kj,$CLJS.Oa,$CLJS.ra],null),JS,Cka,SS,TS,US,VS,WS,XS,bT,RU,SU,TU,Jka,Kka,UU,Tka,Lka,Mka;$CLJS.lL(CM,gO($CLJS.Ay,$CLJS.G(["n",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.ev],null),$CLJS.ab],null),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,CS],null)])));var DS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CM],null);
$CLJS.lL(pP,gO($CLJS.zy,$CLJS.G(["n",$CLJS.ab,"unit",CS])));var yka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,pP],null);
$CLJS.lL(XM,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"valid :absolute-datetime clause",$CLJS.ib,function(a){if($CLJS.Gb(hO($CLJS.HI,a)))a=WM;else{a=$CLJS.Zd(a);var b=$CLJS.NL(mS);a=b.g?b.g(a):b.call(null,a);a=$CLJS.m(a)?$CLJS.tP:$CLJS.LI}return a}],null),new $CLJS.S(null,2,5,$CLJS.T,[WM,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"not an :absolute-datetime clause"],null),$CLJS.ag(!1)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tP,gO($CLJS.HI,
$CLJS.G(["date",mS,"unit",rka]))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,gO($CLJS.HI,$CLJS.G(["datetime",nS,"unit",zS]))],null)],null));var ES=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,XM],null);$CLJS.lL(lN,gO($CLJS.Aw,$CLJS.G(["time",oS,"unit",ska])));var FS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,lN],null),GS=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"date or datetime literal"],null),ES,nS,mS],null);
$CLJS.lL(VL,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"time literal"],null),FS,oS],null));$CLJS.lL(WR,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"temporal literal"],null),GS,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VL],null)],null));var HS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,WR],null);
$CLJS.lL(Nja,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof ES?new $CLJS.ud(function(){return ES},$CLJS.K(nia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,nQ,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WP,"metabase/mbql/schema.cljc",69,$CLJS.HI,1,!0,168,168,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(ES)?ES.J:null])):null));return $CLJS.m(a)?a:WP}(),ES],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof DS?new $CLJS.ud(function(){return DS},$CLJS.K(ika,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,
$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",29,$CLJS.Ay,1,131,131,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Ay,$CLJS.el,$CLJS.X($CLJS.Kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.ev],null),$CLJS.ab],null)),$CLJS.Fk,$CLJS.X($CLJS.Kl,$CLJS.X($Q,LO))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",
$CLJS.m(DS)?DS.J:null])):null));return $CLJS.m(a)?a:nP}(),DS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof FS?new $CLJS.ud(function(){return FS},$CLJS.K(lja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,nQ,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NR,"metabase/mbql/schema.cljc",27,$CLJS.Aw,1,!0,175,175,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Aw,$CLJS.Aw,$CLJS.X($CLJS.Kl,Mja),$CLJS.Fk,$CLJS.X($CLJS.Kl,Fia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(FS)?FS.J:null])):null));return $CLJS.m(a)?a:NR}(),FS],null)])));
$CLJS.lL(LR,gO($CLJS.vl,$CLJS.G(["value",$CLJS.Is,"type-info",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OR,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,gS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xz,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,hS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.ps,iS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Fk,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,zS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,gS],null)],null)],null)],null)])));var IS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,LR],null);$CLJS.lL(uO,gO($CLJS.fy,$CLJS.G(["expression-name",gS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,$CLJS.ik],null)])));
JS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uO],null);
Cka=function(a){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,a,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.EC);c=$CLJS.M.h(c,$CLJS.VB);return $CLJS.F.h(b,$CLJS.VB)?c:!0}],null)],null)}(function(a){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,a,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.EC);c=$CLJS.M.h(c,$CLJS.DC);return $CLJS.F.h(b,$CLJS.DC)?c:!0}],null)],null)}(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"binning options"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EC,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"binning strategy"],null),$CLJS.VB,$CLJS.DC,$CLJS.oa],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VB,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),jS],null),new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.DC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,$CLJS.Eb,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"bin width must be \x3e\x3d 0."],null),$CLJS.$f($CLJS.ef)],null)],null)],null)],null)));$CLJS.KS=function KS(a){switch(arguments.length){case 1:return KS.g(arguments[0]);case 2:return KS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.KS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.iy);b=$CLJS.M.h(b,$CLJS.EE);return $CLJS.KS.h(a,b)};$CLJS.KS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.QH(a,$CLJS.Ik)?wS:$CLJS.QH(a,$CLJS.gl)?xS:$CLJS.QH(a,$CLJS.Uk)?yS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.KS.v=2;
$CLJS.lL(aR,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"field options"],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iy,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,hS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,lS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EE,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,zS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,gS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mR,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,Cka],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.KS],null)],
null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.$f($CLJS.EC)],null)],null));
$CLJS.lL(CR,function(a){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,a,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.iy);return"string"===typeof c?b:!0}],null)],null)}(gO($CLJS.yE,$CLJS.G(["id-or-name",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,lS,gS],null),"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,aR],null)],null)]))));var MS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CR],null);
$CLJS.lL(CP,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof JS?new $CLJS.ud(function(){return JS},$CLJS.K(xN,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hN,"metabase/mbql/schema.cljc",60,$CLJS.fy,1,249,249,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.fy,$CLJS.SR,$CLJS.X($CLJS.Kl,EM),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,$CLJS.ik))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(JS)?JS.J:null])):null));return $CLJS.m(a)?a:hN}(),JS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof MS?new $CLJS.ud(function(){return MS},
$CLJS.K(PN,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.vr,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],["0.39.0",$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QM,"metabase/mbql/schema.cljc",51,$CLJS.yE,1,382,382,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(MS)?MS.J:null])):null));return $CLJS.m(a)?a:QM}(),MS],null)])));
$CLJS.OS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CP],null);$CLJS.lL(QL,gO($CLJS.nE,$CLJS.G(["aggregation-clause-index",$CLJS.ab,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,$CLJS.ik],null)])));var Dka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,QL],null);
$CLJS.lL(yM,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof Dka?new $CLJS.ud(function(){return Dka},$CLJS.K(Kja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lR,"metabase/mbql/schema.cljc",23,$CLJS.nE,1,418,418,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nE,kka,$CLJS.X($CLJS.Kl,$CLJS.ab),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,$CLJS.ik))],null),$CLJS.yd,"Schema for a valid aggregation clause.",$CLJS.m(Dka)?Dka.J:null])):null));return $CLJS.m(a)?a:lR}(),Dka],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof JS?new $CLJS.ud(function(){return JS},
$CLJS.K(xN,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",60,$CLJS.fy,1,249,249,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.fy,$CLJS.SR,$CLJS.X($CLJS.Kl,EM),$CLJS.Bj,
$CLJS.X($CLJS.Kl,$CLJS.X($Q,$CLJS.ik))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(JS)?JS.J:null])):null));return $CLJS.m(a)?a:hN}(),JS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof MS?new $CLJS.ud(function(){return MS},$CLJS.K(PN,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.vr,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,
$CLJS.nk,$CLJS.qk],["0.39.0",$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QM,"metabase/mbql/schema.cljc",51,$CLJS.yE,1,382,382,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(MS)?MS.J:null])):null));return $CLJS.m(a)?a:QM}(),MS],null)])));
var qS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,yM],null),QS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.jt,null,$CLJS.QE,null,$CLJS.tD,null,$CLJS.WE,null,$CLJS.VD,null,$CLJS.BE,null,$CLJS.eE,null,$CLJS.pt,null,$CLJS.OE,null,$CLJS.AE,null,$CLJS.kF,null],null),null),RS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,vM],null);
$CLJS.lL(ZL,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,function(a){return"string"===typeof a?$CLJS.ak:$CLJS.m(hO(QS,a))?rM:$CLJS.m(hO($CLJS.vl,a))?$CLJS.vl:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,$CLJS.ak],null),new $CLJS.S(null,2,5,$CLJS.T,[rM,RS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,IS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,$CLJS.OS],null)],null));SS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ZL],null);
TS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.MD,null,$CLJS.WI,null,$CLJS.fs,null,$CLJS.Dy,null,$CLJS.RI,null,$CLJS.kE,null,$CLJS.ft,null,$CLJS.sD,null,$CLJS.UI,null,$CLJS.By,null,$CLJS.BE,null,$CLJS.TE,null,$CLJS.SI,null,$CLJS.DE,null,$CLJS.TD,null,$CLJS.FI,null,$CLJS.es,null,$CLJS.WD,null,$CLJS.eE,null,$CLJS.zI,null,$CLJS.ew,null,$CLJS.GI,null,$CLJS.PI,null,$CLJS.yy,null,$CLJS.VE,null,$CLJS.wE,null],null),null);
US=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Xr,null,$CLJS.Ur,null,$CLJS.qs,null,$CLJS.ss,null,$CLJS.Vr,null,$CLJS.PE,null,$CLJS.As,null,$CLJS.$r,null,$CLJS.Wr,null],null),null);VS=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.fb,null,$CLJS.NE,null,$CLJS.HE,null,$CLJS.zE,null,$CLJS.SE,null,$CLJS.UE,null,$CLJS.aE,null,$CLJS.iE,null,$CLJS.dM,null,$CLJS.bE,null,$CLJS.LE,null,$CLJS.FE,null,$CLJS.mk,null,$CLJS.gw,null,$CLJS.sE,null,$CLJS.tE,null,$CLJS.KE,null],null),null);
WS=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.MI,null,$CLJS.BI,null,$CLJS.Fy,null,$CLJS.es,null,$CLJS.Cy,null],null),null);XS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TQ],null);bT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,jN],null);$CLJS.$S=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tQ],null);$CLJS.YS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ZQ],null);
$CLJS.lL(fR,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"numeric expression argument",$CLJS.ib,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.m(hO(TS,a))?WO:$CLJS.m(hO(VS,a))?$CLJS.nE:$CLJS.m(hO($CLJS.vl,a))?$CLJS.vl:$CLJS.yE}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xt,$CLJS.Eb],null),new $CLJS.S(null,2,5,$CLJS.T,[WO,XS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nE,$CLJS.YS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,IS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yE,
$CLJS.OS],null)],null));var ZS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,fR],null);
$CLJS.lL(oQ,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"datetime expression argument",$CLJS.ib,function(a){return $CLJS.m(hO(VS,a))?$CLJS.nE:$CLJS.m(hO($CLJS.vl,a))?$CLJS.vl:$CLJS.m(hO(WS,a))?qN:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nE,$CLJS.YS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,IS],null),new $CLJS.S(null,2,5,$CLJS.T,[qN,$CLJS.$S],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,GS,$CLJS.OS],null)],null)],null));
var aT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,oQ],null);
$CLJS.lL(SP,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"expression argument",$CLJS.ib,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.ve(a)?$CLJS.Hs:$CLJS.m(hO(US,a))?gQ:$CLJS.m(hO(TS,a))?WO:$CLJS.m(hO(WS,a))?qN:"string"===typeof a?$CLJS.ak:$CLJS.m(hO(QS,a))?rM:$CLJS.m(hO($CLJS.vl,a))?$CLJS.vl:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xt,$CLJS.Eb],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Hs,$CLJS.Hs],null),new $CLJS.S(null,2,5,$CLJS.T,[gQ,bT],null),new $CLJS.S(null,
2,5,$CLJS.T,[WO,XS],null),new $CLJS.S(null,2,5,$CLJS.T,[qN,$CLJS.$S],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,$CLJS.ak],null),new $CLJS.S(null,2,5,$CLJS.T,[rM,RS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,IS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,$CLJS.OS],null)],null));var cT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,SP],null);$CLJS.lL(QP,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"numeric expression arg or interval"],null),yka,ZS],null));
var dT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,QP],null);$CLJS.lL(jM,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"int greater than zero or numeric expression",$CLJS.ib,function(a){return"number"===typeof a?$CLJS.Xt:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xt,jS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,XS],null)],null));var Eka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,jM],null);
$CLJS.lL(rN,gO($CLJS.BE,$CLJS.G(["a",cT,"b",cT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,cT],null)])));var eT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,rN],null);$CLJS.lL(UL,gO($CLJS.tD,$CLJS.G(["s",SS,"start",Eka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,ZS],null)])));var fT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,UL],null);$CLJS.lL(hQ,gO($CLJS.ew,$CLJS.G(["s",SS])));var gT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,hQ],null);$CLJS.lL(xM,gO($CLJS.OE,$CLJS.G(["s",SS])));
var hT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,xM],null);$CLJS.lL(BM,gO($CLJS.kF,$CLJS.G(["s",SS])));var iT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,BM],null);$CLJS.lL(UM,gO($CLJS.QE,$CLJS.G(["s",SS])));var jT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,UM],null);$CLJS.lL(FN,gO($CLJS.jt,$CLJS.G(["s",SS])));var kT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,FN],null);$CLJS.lL(oM,gO($CLJS.pt,$CLJS.G(["s",SS])));var lT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,oM],null);
$CLJS.lL(eR,gO($CLJS.WE,$CLJS.G(["s",SS,"match",$CLJS.ak,"replacement",$CLJS.ak])));var mT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,eR],null);$CLJS.lL(bS,gO($CLJS.VD,$CLJS.G(["a",SS,"b",SS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,SS],null)])));var nT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,bS],null);$CLJS.lL(DO,gO($CLJS.AE,$CLJS.G(["s",SS,"pattern",$CLJS.ak])));var oT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,DO],null);
$CLJS.lL(AR,gO($CLJS.es,$CLJS.G(["x",dT,"y",dT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,dT],null)])));var pT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,AR],null);$CLJS.lL(yR,gO($CLJS.ft,$CLJS.G(["x",ZS,"y",dT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,dT],null)])));var qT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,yR],null);$CLJS.lL(xR,gO($CLJS.TE,$CLJS.G(["x",ZS,"y",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,ZS],null)])));var rT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,xR],null);
$CLJS.lL(zR,gO($CLJS.fs,$CLJS.G(["x",ZS,"y",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,ZS],null)])));var sT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,zR],null);$CLJS.lL(PP,gO($CLJS.wE,$CLJS.G(["x",ZS])));var tT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,PP],null);$CLJS.lL(HQ,gO($CLJS.MD,$CLJS.G(["x",ZS])));var uT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,HQ],null);$CLJS.lL(eS,gO($CLJS.DE,$CLJS.G(["x",ZS])));var vT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,eS],null);$CLJS.lL(UO,gO($CLJS.WD,$CLJS.G(["x",ZS])));
var wT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,UO],null);$CLJS.lL($O,gO($CLJS.TD,$CLJS.G(["x",ZS,"y",ZS])));var xT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$O],null);$CLJS.lL(wP,gO($CLJS.kE,$CLJS.G(["x",ZS])));var yT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,wP],null);$CLJS.lL(kM,gO($CLJS.sD,$CLJS.G(["x",ZS])));var zT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,kM],null);$CLJS.lL(VP,gO($CLJS.VE,$CLJS.G(["x",ZS])));var AT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VP],null);
$CLJS.lL(KM,gO($CLJS.yy,$CLJS.G(["datetime-x",aT,"datetime-y",aT,"unit",tka])));var CT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,KM],null);$CLJS.lL(bN,gO($CLJS.Dy,$CLJS.G(["datetime",aT,"unit",qka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,pS],null)])));var DT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,bN],null);$CLJS.lL(bP,gO($CLJS.GI,$CLJS.G(["date",aT])));var ET=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,bP],null);$CLJS.lL(DP,gO($CLJS.WI,$CLJS.G(["date",aT])));
var FT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,DP],null);$CLJS.lL(YL,gO($CLJS.zI,$CLJS.G(["date",aT])));var GT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,YL],null);$CLJS.lL(cS,gO($CLJS.By,$CLJS.G(["date",aT,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,pS],null)])));var HT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,cS],null);$CLJS.lL(lO,gO($CLJS.PI,$CLJS.G(["date",aT])));var IT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,lO],null);$CLJS.lL(xP,gO($CLJS.SI,$CLJS.G(["date",aT])));
var JT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,xP],null);$CLJS.lL(GM,gO($CLJS.UI,$CLJS.G(["datetime",aT])));var KT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,GM],null);$CLJS.lL(tO,gO($CLJS.RI,$CLJS.G(["datetime",aT])));var LT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tO],null);$CLJS.lL(zM,gO($CLJS.FI,$CLJS.G(["datetime",aT])));var MT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,zM],null);$CLJS.lL(QN,gO($CLJS.BI,$CLJS.G(["datetime",aT,"to",AS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,AS],null)])));
var NT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,QN],null),OT=new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"datetime arithmetic unit"],null),$CLJS.BF,$CLJS.wF,$CLJS.Ok,$CLJS.cl,$CLJS.fl,$CLJS.Fj,$CLJS.Kj,$CLJS.Oa,$CLJS.ra],null);$CLJS.lL(TL,gO($CLJS.Cy,$CLJS.G(["datetime",aT,"amount",ZS,"unit",OT])));var PT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TL],null);
$CLJS.lL(sM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return gO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.MI));var QT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,sM],null);$CLJS.lL(SL,gO($CLJS.Fy,$CLJS.G(["datetime",aT,"amount",ZS,"unit",OT])));var RT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,SL],null);
$CLJS.lL(tQ,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(JP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xO,"metabase/mbql/schema.cljc",51,$CLJS.es,1,612,612,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.es,$CLJS.zC,$CLJS.X($CLJS.Kl,NP),kR,$CLJS.X($CLJS.Kl,NP),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,NP))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:xO}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof PT?new $CLJS.ud(function(){return PT},
$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[JN,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",67,$CLJS.Cy,1,703,703,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Cy,$CLJS.LI,$CLJS.X($CLJS.Kl,YR),DQ,$CLJS.X($CLJS.Kl,
IQ),$CLJS.Fk,$CLJS.X($CLJS.Kl,RQ)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(PT)?PT.J:null])):null));return $CLJS.m(a)?a:EP}(),PT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(Pha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,
$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[JN,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VR,"metabase/mbql/schema.cljc",72,$CLJS.Fy,1,710,710,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Fy,$CLJS.LI,$CLJS.X($CLJS.Kl,YR),DQ,$CLJS.X($CLJS.Kl,IQ),$CLJS.Fk,$CLJS.X($CLJS.Kl,RQ)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(RT)?RT.J:null])):null));
return $CLJS.m(a)?a:VR}(),RT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof NT?new $CLJS.ud(function(){return NT},$CLJS.K(Tja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.BI,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",71,$CLJS.BI,1,695,695,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.BI,$CLJS.LI,$CLJS.X($CLJS.Kl,YR),Oia,$CLJS.X($CLJS.Kl,nR),Tha,$CLJS.X($CLJS.Kl,$CLJS.X($Q,nR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(NT)?NT.J:null])):null));return $CLJS.m(a)?a:pN}(),NT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&
"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof QT?new $CLJS.ud(function(){return QT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.MI,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",
45,$CLJS.MI,1,708,708,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.MI],null),$CLJS.yd,"Schema for a valid now clause.",$CLJS.m(QT)?QT.J:null])):null));return $CLJS.m(a)?a:cP}(),QT],null)])));$CLJS.UT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,zP],null);$CLJS.lL($L,gO($CLJS.As,$CLJS.G(["first-clause",$CLJS.UT,"second-clause",$CLJS.UT,"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,$CLJS.UT],null)])));var VT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$L],null);
$CLJS.lL(MN,gO($CLJS.qs,$CLJS.G(["first-clause",$CLJS.UT,"second-clause",$CLJS.UT,"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,$CLJS.UT],null)])));var WT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,MN],null);$CLJS.lL(uM,gO($CLJS.ss,$CLJS.G(["clause",$CLJS.UT])));
var XT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uM],null),rS=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,3,[$CLJS.tt,":field or :expression reference or :relative-datetime",$CLJS.Qt,$CLJS.ag(":field or :expression reference or :relative-datetime"),$CLJS.ib,function(a){return $CLJS.m(hO($CLJS.Ay,a))?$CLJS.Ay:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ay,DS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,$CLJS.OS],null)],null);
$CLJS.lL(qQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.tt,"equality comparable"],null),new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.qs,$CLJS.Hs,$CLJS.Eb,$CLJS.ak,HS,rS,cT,IS],null)],null));var ZT=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,qQ],null);
$CLJS.lL(IO,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"order comparable",$CLJS.ib,function(a){return $CLJS.m(hO($CLJS.vl,a))?$CLJS.vl:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vl,IS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.qs,$CLJS.Eb,$CLJS.ak,HS,cT,rS],null)],null)],null));var $T=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,IO],null);
$CLJS.lL(qR,gO($CLJS.$r,$CLJS.G(["field",ZT,"value-or-field",ZT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,ZT],null)])));var aU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,qR],null);$CLJS.lL(uR,gO($CLJS.PE,$CLJS.G(["field",ZT,"value-or-field",ZT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jb,ZT],null)])));var bU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uR],null);$CLJS.lL(pR,gO($CLJS.Wr,$CLJS.G(["field",$T,"value-or-field",$T])));
var cU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,pR],null);$CLJS.lL(oR,gO($CLJS.Ur,$CLJS.G(["field",$T,"value-or-field",$T])));var dU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,oR],null);$CLJS.lL(VM,gO($CLJS.Xr,$CLJS.G(["field",$T,"value-or-field",$T])));var eU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VM],null);$CLJS.lL(aN,gO($CLJS.Vr,$CLJS.G(["field",$T,"value-or-field",$T])));var fU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,aN],null);$CLJS.lL(ZP,gO($CLJS.YD,$CLJS.G(["field",$T,"min",$T,"max",$T])));
var gU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ZP],null);$CLJS.lL(AQ,gO($CLJS.dE,$CLJS.G(["lat-field",$T,"lon-field",$T,"lat-max",$T,"lon-min",$T,"lat-min",$T,"lon-max",$T])));var hU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,AQ],null);$CLJS.lL(EN,gO($CLJS.XD,$CLJS.G(["field",$CLJS.OS])));var iU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,EN],null);$CLJS.lL(CO,gO($CLJS.jE,$CLJS.G(["field",$CLJS.OS])));var jU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CO],null);$CLJS.lL(XN,gO($CLJS.ZD,$CLJS.G(["field",$CLJS.OS])));
var kU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,XN],null);$CLJS.lL(cM,gO($CLJS.gE,$CLJS.G(["field",$CLJS.OS])));var lU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,cM],null),mU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null);$CLJS.lL(rO,gO($CLJS.pE,$CLJS.G(["field",SS,"string-or-field",SS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,mU],null)])));var nU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,rO],null);
$CLJS.lL(kP,gO($CLJS.JE,$CLJS.G(["field",SS,"string-or-field",SS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,mU],null)])));var oU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,kP],null);$CLJS.lL(VN,gO($CLJS.xE,$CLJS.G(["field",SS,"string-or-field",SS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,mU],null)])));var pU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VN],null);$CLJS.lL(TP,gO($CLJS.qE,$CLJS.G(["field",SS,"string-or-field",SS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,mU],null)])));
var qU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TP],null);$CLJS.lL(lM,gO($CLJS.Ey,$CLJS.G(["field",$CLJS.OS,"n",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.ab,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.ev,$CLJS.kw,$CLJS.cE],null)],null),"unit",CS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.al,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null)],null)])));var rU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,lM],null);
$CLJS.lL(jQ,gO($CLJS.UD,$CLJS.G(["segment-id",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wK],null),gS],null)])));var sU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,jQ],null);
$CLJS.lL(jN,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(dR,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MO,"metabase/mbql/schema.cljc",15,$CLJS.As,1,724,724,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.As,tN,$CLJS.X($CLJS.Kl,RO),uP,$CLJS.X($CLJS.Kl,RO),DN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,RO))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(VT)?VT.J:null])):null));return $CLJS.m(a)?a:MO}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},
$CLJS.K(yP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",14,$CLJS.qs,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.qs,tN,$CLJS.X($CLJS.Kl,RO),uP,$CLJS.X($CLJS.Kl,RO),DN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,RO))],null),$CLJS.yd,
"Schema for a valid or clause.",$CLJS.m(WT)?WT.J:null])):null));return $CLJS.m(a)?a:BP}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},$CLJS.K(kQ,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dP,"metabase/mbql/schema.cljc",15,$CLJS.ss,1,734,734,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ss,$CLJS.RR,$CLJS.X($CLJS.Kl,RO)],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:dP}(),XT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(HP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,
$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",13,$CLJS.Wr,1,798,798,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Wr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:FO}(),cU],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(KR,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EO,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,800,800,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Xr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:EO}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(FP,new $CLJS.k(null,
1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Ur,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(dU)?dU.J:null])):
null));return $CLJS.m(a)?a:GO}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(DR,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Vr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:NO}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(GP,new $CLJS.k(null,
1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.$r,$CLJS.yE,$CLJS.X($CLJS.Kl,PR),PQ,$CLJS.X($CLJS.Kl,PR),rQ,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,PR))],null),$CLJS.yd,"Schema for a valid \x3d clause.",
$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:HO}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(LM,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gN,"metabase/mbql/schema.cljc",14,$CLJS.PE,1,796,796,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PE,$CLJS.yE,$CLJS.X($CLJS.Kl,PR),PQ,$CLJS.X($CLJS.Kl,PR),rQ,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,PR))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:gN}(),bU],null)])));
$CLJS.lL(zP,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"valid filter expression",$CLJS.ib,function(a){return $CLJS.m(hO(WS,a))?$CLJS.LI:$CLJS.m(hO(TS,a))?ZR:$CLJS.m(hO(QS,a))?$CLJS.ak:$CLJS.m(hO(US,a))?$CLJS.Hs:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,$CLJS.$S],null),new $CLJS.S(null,2,5,$CLJS.T,[ZR,XS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,RS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Hs,bT],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,iO($CLJS.G([new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(dR,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",
15,$CLJS.As,1,724,724,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.As,tN,$CLJS.X($CLJS.Kl,RO),uP,$CLJS.X($CLJS.Kl,RO),DN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,RO))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(VT)?VT.J:null])):null));return $CLJS.m(a)?a:MO}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},$CLJS.K(yP,new $CLJS.k(null,1,[$CLJS.bk,
!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",14,$CLJS.qs,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.qs,tN,$CLJS.X($CLJS.Kl,RO),uP,$CLJS.X($CLJS.Kl,RO),DN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,RO))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(WT)?
WT.J:null])):null));return $CLJS.m(a)?a:BP}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},$CLJS.K(kQ,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dP,"metabase/mbql/schema.cljc",15,$CLJS.ss,1,734,734,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ss,$CLJS.RR,$CLJS.X($CLJS.Kl,RO)],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:dP}(),XT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(GP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,
$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",13,$CLJS.$r,1,795,795,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.$r,$CLJS.yE,$CLJS.X($CLJS.Kl,PR),PQ,$CLJS.X($CLJS.Kl,PR),rQ,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,PR))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(aU)?aU.J:null])):
null));return $CLJS.m(a)?a:HO}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(LM,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gN,"metabase/mbql/schema.cljc",14,$CLJS.PE,1,796,796,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PE,$CLJS.yE,$CLJS.X($CLJS.Kl,PR),PQ,$CLJS.X($CLJS.Kl,PR),rQ,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,PR))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:gN}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},
$CLJS.K(HP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",13,$CLJS.Wr,1,798,798,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Wr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3c clause.",
$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:FO}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(FP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,799,799,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Ur,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:GO}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(KR,new $CLJS.k(null,1,
[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",14,$CLJS.Xr,1,800,800,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Xr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(eU)?eU.J:null])):
null));return $CLJS.m(a)?a:EO}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(DR,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,801,801,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Vr,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),PQ,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:NO}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(nja,new $CLJS.k(null,
1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NM,"metabase/mbql/schema.cljc",19,$CLJS.YD,1,804,804,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.YD,$CLJS.yE,$CLJS.X($CLJS.Kl,vN),$CLJS.fb,$CLJS.X($CLJS.Kl,vN),$CLJS.mk,$CLJS.X($CLJS.Kl,vN)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:NM}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(pja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UQ,"metabase/mbql/schema.cljc",23,$CLJS.pE,1,829,829,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pE,$CLJS.yE,$CLJS.X($CLJS.Kl,OQ),fN,$CLJS.X($CLJS.Kl,OQ),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,iQ))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:UQ}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},
$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kN,"metabase/mbql/schema.cljc",21,$CLJS.JE,1,830,830,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.JE,$CLJS.yE,$CLJS.X($CLJS.Kl,OQ),fN,$CLJS.X($CLJS.Kl,OQ),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,iQ))],null),
$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(oU)?oU.J:null])):null));return $CLJS.m(a)?a:kN}(),oU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof pU?new $CLJS.ud(function(){return pU},$CLJS.K(Dia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wN,"metabase/mbql/schema.cljc",20,$CLJS.xE,1,831,831,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.xE,$CLJS.yE,$CLJS.X($CLJS.Kl,OQ),fN,$CLJS.X($CLJS.Kl,OQ),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,iQ))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(pU)?pU.J:null])):null));return $CLJS.m(a)?a:wN}(),pU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==
typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(gka,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aM,"metabase/mbql/schema.cljc",36,$CLJS.qE,1,834,834,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.qE,$CLJS.yE,$CLJS.X($CLJS.Kl,OQ),fN,$CLJS.X($CLJS.Kl,OQ),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,iQ))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:aM}(),qU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(Qja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),
$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",26,$CLJS.dE,1,807,807,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.dE,Zha,$CLJS.X($CLJS.Kl,vN),lka,$CLJS.X($CLJS.Kl,vN),Xja,$CLJS.X($CLJS.Kl,vN),bia,$CLJS.X($CLJS.Kl,vN),Rja,$CLJS.X($CLJS.Kl,vN),Yha,$CLJS.X($CLJS.Kl,
vN)],null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:SQ}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(Eia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",28,$CLJS.ZD,1,821,821,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZD,$CLJS.yE,$CLJS.X($CLJS.Kl,oN)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:mP}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&
"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(Bja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",29,$CLJS.gE,1,822,822,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gE,$CLJS.yE,$CLJS.X($CLJS.Kl,oN)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(lU)?lU.J:null])):null));return $CLJS.m(a)?a:$P}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(bka,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",27,$CLJS.XD,1,816,816,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XD,$CLJS.yE,$CLJS.X($CLJS.Kl,oN)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:CN}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&
"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SM,"metabase/mbql/schema.cljc",28,$CLJS.jE,1,817,817,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jE,$CLJS.yE,$CLJS.X($CLJS.Kl,oN)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:SM}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K(Qha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",33,$CLJS.Ey,1,856,856,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.Ey,$CLJS.yE,$CLJS.X($CLJS.Kl,oN),$CLJS.el,$CLJS.X($CLJS.Kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.ab,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.ev,$CLJS.kw,$CLJS.cE],null)],null)),$CLJS.Fk,$CLJS.X($CLJS.Kl,LO),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,sja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",
$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:yQ}(),rU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(Yia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BR,"metabase/mbql/schema.cljc",27,$CLJS.UD,1,872,872,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UD,fia,$CLJS.X($CLJS.Kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,Hja,EM],null))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:BR}(),sU],null)]))],null)],null));
$CLJS.lL(lQ,gO($CLJS.eE,$CLJS.G(["clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.k(null,1,[$CLJS.tt,":case subclause"],null),$CLJS.UT,cT],null)],null)],null),"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,":case options"],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),cT],null)],null)],null)])));
var vU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,lQ],null);
$CLJS.lL(TQ,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(JP,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xO,"metabase/mbql/schema.cljc",51,$CLJS.es,1,612,612,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.es,$CLJS.zC,$CLJS.X($CLJS.Kl,NP),kR,$CLJS.X($CLJS.Kl,NP),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,NP))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:xO}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},
$CLJS.K(jja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",51,$CLJS.ft,1,615,615,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ft,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ),
kR,$CLJS.X($CLJS.Kl,NP),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,NP))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:yO}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO?new $CLJS.ud(function(){return rT},$CLJS.K(ija,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,aP,$CLJS.ob,$CLJS.Ej,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Hx,"metabase/mbql/schema.cljc",$CLJS.TE,1,618,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.TE,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ),kR,$CLJS.X($CLJS.Kl,IQ),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,IQ))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Hx}(),rT],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(kja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wO,"metabase/mbql/schema.cljc",51,$CLJS.fs,1,620,620,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.fs,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ),kR,$CLJS.X($CLJS.Kl,IQ),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,IQ))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:wO}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},
$CLJS.K(vQ,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",58,$CLJS.BE,1,579,579,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.BE,tR,$CLJS.X($CLJS.Kl,fM),sR,$CLJS.X($CLJS.Kl,
fM),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,fM))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:KQ}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},$CLJS.K(Aja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,
$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",56,$CLJS.ew,1,585,585,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ew,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:cQ}(),gT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",55,$CLJS.wE,1,622,622,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wE,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:YO}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(lia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),
$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",54,$CLJS.MD,1,625,625,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MD,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(uT)?
uT.J:null])):null));return $CLJS.m(a)?a:DM}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Aia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",55,$CLJS.DE,1,628,628,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DE,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:OO}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&
"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(hka,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",53,$CLJS.WD,1,631,631,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.WD,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:qP}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},$CLJS.K(Vja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,
$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[HR,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",69,$CLJS.TD,1,634,634,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.TD,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ),kR,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(xT)?xT.J:null])):null));return $CLJS.m(a)?a:cR}(),xT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof yT?new $CLJS.ud(function(){return yT},$CLJS.K(Tia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[HR,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sO,"metabase/mbql/schema.cljc",68,$CLJS.kE,1,637,637,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kE,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(yT)?yT.J:null])):null));return $CLJS.m(a)?a:sO}(),yT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Wja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,
$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[HR,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",67,$CLJS.sD,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sD,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(zT)?zT.J:null])):
null));return $CLJS.m(a)?a:QO}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[HR,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",67,$CLJS.VE,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VE,$CLJS.zC,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:MR}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==
typeof vU?new $CLJS.ud(function(){return vU},$CLJS.K(qM,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",61,$CLJS.eE,1,909,909,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.eE,$CLJS.Fv,$CLJS.X($CLJS.Kl,IN),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,AP))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:hP}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(hia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,
aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.yy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",65,$CLJS.yy,1,656,656,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.yy,uia,$CLJS.X($CLJS.Kl,YR),via,$CLJS.X($CLJS.Kl,YR),$CLJS.Fk,$CLJS.X($CLJS.Kl,aja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:rP}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(Eja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,
new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",71,$CLJS.Dy,1,661,661,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Dy,$CLJS.LI,$CLJS.X($CLJS.Kl,YR),$CLJS.Fk,$CLJS.X($CLJS.Kl,aka),$CLJS.fk,$CLJS.X($CLJS.Kl,$CLJS.X($Q,fS))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:KP}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(Mia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$M,"metabase/mbql/schema.cljc",71,$CLJS.GI,1,667,667,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GI,$CLJS.tP,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:$M}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(Bia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),
$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",74,$CLJS.WI,1,670,670,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WI,$CLJS.tP,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:IP}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),
$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",72,$CLJS.zI,1,673,673,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zI,$CLJS.tP,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(GT)?GT.J:null])):null));return $CLJS.m(a)?a:eQ}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==
typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K($ja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",
71,$CLJS.By,1,676,676,!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.By,$CLJS.tP,$CLJS.X($CLJS.Kl,YR),$CLJS.fk,$CLJS.X($CLJS.Kl,$CLJS.X($Q,fS))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:dQ}(),HT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Wha,new $CLJS.k(null,1,[$CLJS.bk,!0],
null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",70,$CLJS.PI,1,680,680,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PI,$CLJS.tP,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-day clause.",
$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:XP}(),IT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(bja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),
$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",78,$CLJS.SI,1,683,683,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SI,$CLJS.tP,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:OP}(),JT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==
typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},$CLJS.K($ia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",
71,$CLJS.UI,1,686,686,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UI,$CLJS.LI,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:gM}(),KT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},$CLJS.K(Ija,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,
$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",73,$CLJS.RI,1,689,689,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RI,$CLJS.LI,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(LT)?LT.J:null])):null));
return $CLJS.m(a)?a:QR}(),LT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Dy,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",73,$CLJS.FI,1,692,692,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FI,$CLJS.LI,$CLJS.X($CLJS.Kl,YR)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?a:jP}(),MT],null)])));
$CLJS.lL(vM,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",59,$CLJS.tD,1,582,582,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tD,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ),$CLJS.Vu,$CLJS.X($CLJS.Kl,xia),$CLJS.ew,$CLJS.X($CLJS.Kl,$CLJS.X($Q,IQ))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:mQ}(),fT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},
$CLJS.K(Yja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",54,$CLJS.OE,1,588,588,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OE,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],
null),$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:JQ}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",55,$CLJS.QE,1,594,594,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QE,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(jT)?jT.J:null])):null));return $CLJS.m(a)?a:sQ}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&
"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",
55,$CLJS.kF,1,591,591,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kF,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(iT)?iT.J:null])):null));return $CLJS.m(a)?a:vP}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K(Rha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,
$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",57,$CLJS.WE,1,603,603,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.WE,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ),cia,$CLJS.X($CLJS.Kl,$CLJS.ak),$CLJS.Lx,$CLJS.X($CLJS.Kl,$CLJS.ak)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:vR}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(Cja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,
new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aS,"metabase/mbql/schema.cljc",55,$CLJS.pt,1,600,600,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pt,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:aS}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==
typeof oO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(Zja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",55,$CLJS.jt,1,597,597,
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jt,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:XQ}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(rja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,
$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",56,$CLJS.VD,1,606,606,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.VD,tR,$CLJS.X($CLJS.Kl,OQ),sR,$CLJS.X($CLJS.Kl,OQ),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,OQ))],null),$CLJS.yd,"Schema for a valid concat clause.",
$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:bO}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RP,"null",$CLJS.Xk,"null"],
null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YM,"metabase/mbql/schema.cljc",74,$CLJS.AE,1,609,609,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.AE,$CLJS.ow,$CLJS.X($CLJS.Kl,OQ),$CLJS.Kx,$CLJS.X($CLJS.Kl,$CLJS.ak)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:YM}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==
typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},$CLJS.K(vQ,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RP,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
KQ,"metabase/mbql/schema.cljc",58,$CLJS.BE,1,579,579,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.BE,tR,$CLJS.X($CLJS.Kl,fM),sR,$CLJS.X($CLJS.Kl,fM),sN,$CLJS.X($CLJS.Kl,$CLJS.X($CLJS.wk,fM))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:KQ}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},
$CLJS.K(qM,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",61,$CLJS.eE,1,909,909,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eE,$CLJS.Fv,$CLJS.X($CLJS.Kl,IN),$CLJS.Bj,
$CLJS.X($CLJS.Kl,$CLJS.X($Q,AP))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:hP}(),vU],null)])));
$CLJS.wU=new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,":field or :expression reference or expression",$CLJS.ib,function(a){return $CLJS.m(hO(TS,a))?ZR:$CLJS.m(hO(QS,a))?$CLJS.ak:$CLJS.m(hO(US,a))?$CLJS.Hs:$CLJS.m(hO(WS,a))?$CLJS.LI:$CLJS.m(hO($CLJS.eE,a))?$CLJS.eE:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[ZR,XS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,RS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Hs,bT],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,$CLJS.$S],null),
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eE,vU],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,$CLJS.OS],null)],null);$CLJS.lL(ER,gO($CLJS.gw,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,$CLJS.OS],null)])));var xU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ER],null);$CLJS.lL(sP,gO($CLJS.zE,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ks,$CLJS.OS],null)])));var yU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,sP],null);$CLJS.lL(tM,gO($CLJS.sE,$CLJS.G(["field-or-expression",$CLJS.wU])));
var zU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tM],null);$CLJS.lL(wR,gO($CLJS.UE,$CLJS.G(["field-or-expression",$CLJS.wU])));var AU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,wR],null);$CLJS.lL(UN,gO($CLJS.aE,$CLJS.G(["field-or-expression",$CLJS.wU])));var BU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,UN],null);$CLJS.lL(LQ,gO($CLJS.KE,$CLJS.G(["field-or-expression",$CLJS.wU])));var CU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,LQ],null);$CLJS.lL(ON,gO($CLJS.fb,$CLJS.G(["field-or-expression",$CLJS.wU])));
var DU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ON],null);$CLJS.lL(AN,gO($CLJS.mk,$CLJS.G(["field-or-expression",$CLJS.wU])));var EU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,AN],null);$CLJS.lL(uN,gO($CLJS.SE,$CLJS.G(["field-or-expression",$CLJS.wU,"pred",$CLJS.UT])));var FU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uN],null);$CLJS.lL(LN,gO($CLJS.HE,$CLJS.G(["pred",$CLJS.UT])));var GU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,LN],null);$CLJS.lL(CQ,gO($CLJS.FE,$CLJS.G(["pred",$CLJS.UT])));
var HU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CQ],null);$CLJS.lL(RM,gO($CLJS.NE,$CLJS.G(["field-or-expression",$CLJS.wU])));var IU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,RM],null);$CLJS.lL(RL,gO($CLJS.bE,$CLJS.G(["field-or-expression",$CLJS.wU])));var JU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,RL],null);$CLJS.lL(YN,gO($CLJS.LE,$CLJS.G(["field-or-expression",$CLJS.wU])));var KU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,YN],null);
$CLJS.lL(TO,gO($CLJS.iE,$CLJS.G(["field-or-expression",$CLJS.wU,"percentile",ZS])));var LU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TO],null);$CLJS.lL(FQ,gO($CLJS.tE,$CLJS.G(["metric-id",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.uK],null),gS],null)])));var MU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,FQ],null);
$CLJS.lL(BQ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"unnamed aggregation clause or numeric expression",$CLJS.ib,function(a){return $CLJS.m(hO(TS,a))?WO:$CLJS.lt}],null),new $CLJS.S(null,2,5,$CLJS.T,[WO,XS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lt,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(wja,
new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",60,$CLJS.sE,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid avg clause.",
$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:eP}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(Iia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,
new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",64,$CLJS.UE,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(AU)?AU.J:null])):null));return $CLJS.m(a)?a:jR}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==
typeof oO&&"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",65,$CLJS.aE,1,959,959,
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(BU)?BU.J:null])):null));return $CLJS.m(a)?a:iP}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,
$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.IE,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nM,"metabase/mbql/schema.cljc",76,$CLJS.NE,1,973,973,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(IU)?IU.J:null])):null));return $CLJS.m(a)?a:nM}(),IU],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(mja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JM,"metabase/mbql/schema.cljc",60,$CLJS.KE,1,960,960,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:JM}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K($ha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,
$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ws,"metabase/mbql/schema.cljc",60,$CLJS.fb,1,961,961,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fb,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(DU)?DU.J:
null])):null));return $CLJS.m(a)?a:$CLJS.Ws}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Vha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$s,"metabase/mbql/schema.cljc",60,$CLJS.mk,1,962,962,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mk,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:$CLJS.$s}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&
"undefined"!==typeof MU?new $CLJS.ud(function(){return MU},$CLJS.K(wia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",18,$CLJS.tE,1,994,994,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tE,Jia,$CLJS.X($CLJS.Kl,new $CLJS.S(null,3,5,
$CLJS.T,[$CLJS.qs,Nia,EM],null))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(MU)?MU.J:null])):null));return $CLJS.m(a)?a:aO}(),MU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,
$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",62,$CLJS.FE,1,970,970,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FE,$CLJS.Il,$CLJS.X($CLJS.Kl,RO)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:SN}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(aia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FM,"metabase/mbql/schema.cljc",68,$CLJS.HE,1,967,967,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HE,$CLJS.Il,$CLJS.X($CLJS.Kl,RO)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:FM}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(fka,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),
$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",66,$CLJS.SE,1,964,964,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.SE,YQ,$CLJS.X($CLJS.Kl,QQ),$CLJS.Il,$CLJS.X($CLJS.Kl,RO)],null),$CLJS.yd,"Schema for a valid sum-where clause.",
$CLJS.m(FU)?FU.J:null])):null));return $CLJS.m(a)?a:fP}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},$CLJS.K(qM,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,
new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",61,$CLJS.eE,1,909,909,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.eE,$CLJS.Fv,$CLJS.X($CLJS.Kl,IN),$CLJS.Bj,$CLJS.X($CLJS.Kl,$CLJS.X($Q,AP))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:hP}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&
"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof KU?new $CLJS.ud(function(){return KU},$CLJS.K(Lja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.uE,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",
68,$CLJS.LE,1,979,979,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(KU)?KU.J:null])):null));return $CLJS.m(a)?a:hR}(),KU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof LU?new $CLJS.ud(function(){return LU},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,
aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.uE,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",72,$CLJS.iE,1,982,982,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.iE,YQ,$CLJS.X($CLJS.Kl,QQ),$CLJS.iE,$CLJS.X($CLJS.Kl,IQ)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(LU)?LU.J:null])):
null));return $CLJS.m(a)?a:EQ}(),LU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof JU?new $CLJS.ud(function(){return JU},$CLJS.K(kia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iM,"metabase/mbql/schema.cljc",77,$CLJS.bE,1,976,976,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bE,YQ,$CLJS.X($CLJS.Kl,QQ)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(JU)?JU.J:null])):null));return $CLJS.m(a)?a:iM}(),JU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,
$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",74,$CLJS.zE,1,947,947,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zE,$CLJS.yE,$CLJS.X($CLJS.Kl,$CLJS.X($Q,oN))],null),$CLJS.yd,"Schema for a valid cum-count clause.",
$CLJS.m(yU)?yU.J:null])):null));return $CLJS.m(a)?a:SO}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(yia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([ZO,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,bR,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.JD,"null"],null),null),
$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ck,"metabase/mbql/schema.cljc",70,$CLJS.gw,1,946,946,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gw,$CLJS.yE,$CLJS.X($CLJS.Kl,$CLJS.X($Q,oN))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ck}(),xU],null)]))],null)],null));
$CLJS.lL(UP,gO($CLJS.dM,$CLJS.G(["aggregation",BQ,"options",new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,":aggregation-options options"],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null)],null)])));
$CLJS.lL(ZQ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.tt,"aggregation clause or numeric expression",$CLJS.ib,function(a){return $CLJS.m(hO($CLJS.dM,a))?$CLJS.dM:HN}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dM,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,UP],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[HN,BQ],null)],null));$CLJS.lL(mM,gO($CLJS.vE,$CLJS.G(["field",qS])));var QU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,mM],null);$CLJS.lL(VO,gO($CLJS.oE,$CLJS.G(["field",qS])));
RU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VO],null);
SU=iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof QU?new $CLJS.ud(function(){return QU},$CLJS.K(Pja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
BO,"metabase/mbql/schema.cljc",15,$CLJS.vE,1,1049,1049,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vE,$CLJS.yE,$CLJS.X($CLJS.Kl,rR)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(QU)?QU.J:null])):null));return $CLJS.m(a)?a:BO}(),QU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof RU?new $CLJS.ud(function(){return RU},$CLJS.K(Hia,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,
$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",16,$CLJS.oE,1,1050,1050,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oE,$CLJS.yE,$CLJS.X($CLJS.Kl,rR)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(RU)?RU.J:null])):null));return $CLJS.m(a)?a:wQ}(),RU],null)]));
TU=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ok,$CLJS.pM,$CLJS.eN,$CLJS.HD,$CLJS.Xt,$CLJS.sk,$CLJS.tP],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,gS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eC,gS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zf,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null)],null);
Jka=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,TU,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.pM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bM,gS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LP,jS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),jS],null)],null)],null);
Kka=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,TU,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.eN],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VQ,jS],null)],null)],null);
UU=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,TU,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Is],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null)],null);Tka=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,iR],null);
Lka=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,UU,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.HD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HD,MS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zN,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,IM],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bj,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,$CLJS.Sf,$CLJS.Is],
null)],null)],null)],null)],null);$CLJS.sS=new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.tP,null,$CLJS.Xt,null,$CLJS.Hs,null,$CLJS.sk,null],null),null);Mka=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ok],null),$CLJS.sS);
$CLJS.lL(pQ,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,$CLJS.$k],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HD,Lka],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pM,Jka],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eN,Kka],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,UU,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,Mka],null)],null)],null)],null)],null));
var tS=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,gS,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,pQ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,
$CLJS.V.g(b))},a)}],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,gS],null)],null)],null),WU=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,tS,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SD,$CLJS.Is],null)],null)],null),XU=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,YP],null),YU=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,function(a){return $CLJS.m($CLJS.PB($CLJS.oe,
$CLJS.aQ)(a))?$CLJS.aQ:$CLJS.dC}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,tS,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aQ,$CLJS.Is],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dC,XU],null)],null),ZU=new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,gS],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xz,hS],null),new $CLJS.S(null,2,5,$CLJS.T,[oja,gS],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.vO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,iS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null)],null),aV=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,pka,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rs,new $CLJS.k(null,1,[$CLJS.tt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Qka=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ok],null),
new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.nD,null,$CLJS.IC,null,$CLJS.$C,null,$CLJS.kD,null],null),null)),bV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,gR],null);
$CLJS.lL(RN,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),aV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),YU],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IR,$CLJS.UT],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),Qka],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,
4,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,$CLJS.rw,$CLJS.Ix],null),bV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,lS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JO,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,ZU],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.PB($CLJS.Tx($CLJS.XO,$CLJS.zQ),$CLJS.$f($CLJS.PB($CLJS.XO,$CLJS.zQ)))],null)],null));var uka=$CLJS.T,uS;var vS=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,RN],null)],null);
if($CLJS.ne(vS)&&$CLJS.F.h($CLJS.C(vS),$CLJS.Mj)){var BS=$CLJS.A(vS);$CLJS.C(BS);var LS=$CLJS.D(BS),vka=$CLJS.oe($CLJS.C(LS))?LS:$CLJS.nf(null,LS),NS=$CLJS.A(vka),wka=$CLJS.C(NS),xka=$CLJS.D(NS);uS=$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.U.j(wka,$CLJS.fb,1)],null),xka)}else uS=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,vS,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"non-empty"],null),$CLJS.A],null)],null);
$CLJS.lL(TM,new $CLJS.S(null,3,5,uka,[$CLJS.As,uS,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"All join aliases must be unique."],null),function(a){return $CLJS.kO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.OC,a)))}],null)],null));var Ska=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TM],null);
$CLJS.lL(gR,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jm,new $CLJS.k(null,1,[$CLJS.tt,"Distinct, non-empty sequence of Field clauses"],null),PL(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),$CLJS.OS],null))],null));
$CLJS.lL(YP,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),YU],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),aV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),$CLJS.YS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mE,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),$CLJS.OS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,gS,$CLJS.wU],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),bV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$D,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.UT],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dS,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),kS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GR,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),PL(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),SU],null))],null),new $CLJS.S(null,3,5,$CLJS.T,[bQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[bQ,jS],null),new $CLJS.S(null,2,5,$CLJS.T,[pia,jS],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),Ska],
null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JO,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,ZU],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zQ,$CLJS.XO],null))))}],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,
new $CLJS.k(null,1,[$CLJS.tt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.mE);b=$CLJS.M.h(b,$CLJS.JC);return $CLJS.je($CLJS.Gz.h($CLJS.si(a),$CLJS.si(b)))}],null)],null));
$CLJS.cV=$CLJS.Sh([$CLJS.RE,$CLJS.tP,NN,yN,KO,UR,qO,$CLJS.TR,$CLJS.Xt,pO,wM,NQ,JR,$CLJS.MM,FR,KN,$CLJS.PO,jO,$CLJS.Zf,$CLJS.eO,eM,$CLJS.iN,$CLJS.dO,oP,$CLJS.Hs,$CLJS.sk,$N],[new $CLJS.k(null,1,[AO,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.RE,null,$CLJS.tP,null,$CLJS.Xt,null,$CLJS.Hs,null,$CLJS.sk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.RE,null,$CLJS.tP,null,$CLJS.TR,null,$CLJS.PO,null,$CLJS.Zf,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,WQ,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[NN,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,ZR,$CLJS.sC,WQ,AO,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.RE,null,yN,null,$CLJS.Xt,null,$CLJS.Zf,null,eM,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[KO,null],null),null)],null),new $CLJS.k(null,1,[AO,new $CLJS.ni(null,new $CLJS.k(null,1,[UR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,
ZR,$CLJS.sC,Fja,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[qO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.TR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,ZR,AO,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.RE,null,yN,null,$CLJS.Xt,null,$CLJS.Zf,null,eM,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,ZR,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[pO,null],null),null)],null),new $CLJS.k(null,1,[AO,new $CLJS.ni(null,
new $CLJS.k(null,1,[wM,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,WQ,AO,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.RE,null,UR,null,wM,null,NQ,null,jO,null,$CLJS.Zf,null,eM,null,$CLJS.sk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[JR,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.TR,null,$CLJS.MM,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[FR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,$CLJS.ak,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[KN,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.RE,null,$CLJS.tP,null,$CLJS.TR,null,$CLJS.PO,null,$CLJS.Zf,null],null),null)],null),new $CLJS.k(null,1,[AO,new $CLJS.ni(null,new $CLJS.k(null,1,[jO,null],null),null)],null),new $CLJS.k(null,1,[AO,
new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Zf,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.TR,null,$CLJS.eO,null],null),null)],null),new $CLJS.k(null,1,[AO,new $CLJS.ni(null,new $CLJS.k(null,1,[eM,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.TR,null,$CLJS.iN,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.tP,AO,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.TR,
null,$CLJS.dO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,ZR,$CLJS.sC,WQ,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[oP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.Hs,AO,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.RE,null,$CLJS.Zf,null,$CLJS.Hs,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.$k,$CLJS.ak,AO,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.RE,null,UR,null,wM,null,NQ,null,jO,null,$CLJS.Zf,null,eM,null,$CLJS.sk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.$k,
ZR,$CLJS.sC,AM,AO,new $CLJS.ni(null,new $CLJS.k(null,1,[$N,null],null),null)],null)]);$CLJS.lL(iR,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"valid parameter type"],null)],null),$CLJS.gi($CLJS.cV)));$CLJS.lL(IM,$CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"valid template tag widget type"],null),$CLJS.Ix],null),$CLJS.gi($CLJS.cV)));
$CLJS.lL(uQ,gO(gia,$CLJS.G(["tag-name",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,gS,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,gS],null)],null)],null)])));var dV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uQ],null);$CLJS.lL(hM,gO($CLJS.HD,$CLJS.G(["target",new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.OS,dV],null)])));var eV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,hM],null);$CLJS.lL(cN,gO(cO,$CLJS.G(["target",dV])));
var fV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,cN],null),Uka=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.OS,iO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof eV?new $CLJS.ud(function(){return eV},$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",21,$CLJS.HD,1,1612,1612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HD,$CLJS.ZI,$CLJS.X($CLJS.Kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,oN,zO],null))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(eV)?eV.J:null])):null));return $CLJS.m(a)?a:fQ}(),eV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=aP.g($CLJS.fe("undefined"!==typeof $CLJS.mO&&
"undefined"!==typeof nO&&"undefined"!==typeof oO&&"undefined"!==typeof fV?new $CLJS.ud(function(){return fV},$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,aP,$CLJS.ob,$CLJS.Ej,$CLJS.zk,OM,$CLJS.Za,$CLJS.nk,$CLJS.qk],[$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.nk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$R,"metabase/mbql/schema.cljc",20,cO,1,1615,1615,new $CLJS.S(null,3,5,$CLJS.T,[cO,$CLJS.ZI,
$CLJS.X($CLJS.Kl,zO)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(fV)?fV.J:null])):null));return $CLJS.m(a)?a:$R}(),fV],null)]))],null),gV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,PM],null);
$CLJS.lL(PM,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.As,a,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.PB($CLJS.Tx($CLJS.aQ,$CLJS.SD),$CLJS.$f($CLJS.PB($CLJS.aQ,$CLJS.SD)))],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.aQ);var d=$CLJS.M.h(c,$CLJS.SD);c=$CLJS.M.h(c,$CLJS.$k);c=c instanceof $CLJS.N?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}}],null)],null)}(new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,new $CLJS.k(null,1,[$CLJS.tt,"valid Database ID"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NK],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.W,$CLJS.GK],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,$CLJS.SD,$CLJS.aQ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),WU],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SD,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),XU],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,new $CLJS.S(null,
9,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,Tka],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zf,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZI,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),Uka],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Is],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),gS],null),new $CLJS.S(null,3,5,$CLJS.T,[mia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),
gS],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Is],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Is],null)],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[dka,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),AS],null)],null)],null)],null),new $CLJS.S(null,
3,5,$CLJS.T,[dja,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[gP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),kS],null),new $CLJS.S(null,3,5,$CLJS.T,[HM,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),kS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.Qf(a);a=$CLJS.M.h(b,gP);b=$CLJS.M.h(b,HM);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Uia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[Oja,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[iia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,
3,5,$CLJS.T,[Sha,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[oia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[Gja,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Uha,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Uja,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bw,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZM,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,18,5,$CLJS.T,[$CLJS.ok,$CLJS.Pj,qja,$CLJS.WN,sia,nka,uja,Kia,fja,Cia,jia,Gia,Dja,Sia,dia,tia,mka,jka],null)],null)],null),new $CLJS.S(null,
3,5,$CLJS.T,[Jja,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,jS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Pia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,jS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VQ,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,oka],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[eka,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,gS],null)],null),
new $CLJS.S(null,3,5,$CLJS.T,[Ria,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,jS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[cka,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,$CLJS.Is,$CLJS.Is],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Lia,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,jS],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZN,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,$CLJS.Is,$CLJS.Is],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Is],null)],null)],null)],null)],null)],null)),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.$f($CLJS.JO)],null)],null));var PS=$CLJS.NL(gV);(function(){var a=Nha();return function(b){if($CLJS.m(PS.g?PS.g(b):PS.call(null,b)))return b;b=a.g?a.g(b):a.call(null,b);var c=$CLJS.Jpa(b);throw $CLJS.zj($CLJS.WH("Invalid query: {0}",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))])),new $CLJS.k(null,2,[$CLJS.xw,c,Zia,b],null));}})();