var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Uea,hC,pC,FC;
$CLJS.PB=function(a,b){return function(){function c(l,n,q){return $CLJS.ye(function(){var u=a.g?a.g(l):a.call(null,l);return $CLJS.m(u)?(u=a.g?a.g(n):a.call(null,n),$CLJS.m(u)?(u=a.g?a.g(q):a.call(null,q),$CLJS.m(u)?(u=b.g?b.g(l):b.call(null,l),$CLJS.m(u)?(u=b.g?b.g(n):b.call(null,n),$CLJS.m(u)?b.g?b.g(q):b.call(null,q):u):u):u):u):u}())}function d(l,n){return $CLJS.ye(function(){var q=a.g?a.g(l):a.call(null,l);return $CLJS.m(q)?(q=a.g?a.g(n):a.call(null,n),$CLJS.m(q)?(q=b.g?b.g(l):b.call(null,l),
$CLJS.m(q)?b.g?b.g(n):b.call(null,n):q):q):q}())}function e(l){var n=a.g?a.g(l):a.call(null,l);l=$CLJS.m(n)?b.g?b.g(l):b.call(null,l):n;return $CLJS.ye(l)}var f=null,g=function(){function l(q,u,v,x){var y=null;if(3<arguments.length){y=0;for(var B=Array(arguments.length-3);y<B.length;)B[y]=arguments[y+3],++y;y=new $CLJS.z(B,0,null)}return n.call(this,q,u,v,y)}function n(q,u,v,x){return $CLJS.ye(function(){var y=f.j(q,u,v);return $CLJS.m(y)?$CLJS.Wf(function(B){var H=a.g?a.g(B):a.call(null,B);return $CLJS.m(H)?
b.g?b.g(B):b.call(null,B):H},x):y}())}l.v=3;l.B=function(q){var u=$CLJS.C(q);q=$CLJS.D(q);var v=$CLJS.C(q);q=$CLJS.D(q);var x=$CLJS.C(q);q=$CLJS.zd(q);return n(u,v,x,q)};l.l=n;return l}();f=function(l,n,q,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,n);case 3:return c.call(this,l,n,q);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.z(x,0,null)}return g.l(l,n,q,
v)}throw Error("Invalid arity: "+arguments.length);};f.v=3;f.B=g.B;f.A=function(){return!0};f.g=e;f.h=d;f.j=c;f.l=g.l;return f}()};$CLJS.XG=function(a){return $CLJS.Sm.h($CLJS.rg.g(a),$CLJS.$F)};$CLJS.WH=function(a,b){a=$CLJS.lz($CLJS.Pu(a,/''/,"'"),/\{\d+\}/);return $CLJS.R.j($CLJS.jz.t,$CLJS.qn(a),$CLJS.qn(b))};$CLJS.QB=new $CLJS.N("lib","metadata","lib/metadata",1798917220);$CLJS.UB=new $CLJS.N("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.VB=new $CLJS.N(null,"num-bins","num-bins",847672055);$CLJS.dC=new $CLJS.N(null,"mbql","mbql",69346710);$CLJS.eC=new $CLJS.N(null,"display-name","display-name",694513143);Uea=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.fC=new $CLJS.N("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
hC=new $CLJS.N("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.iC=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.jC=new $CLJS.N("lib","expression-name","lib/expression-name",-1799326590);$CLJS.oC=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);pC=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.qC=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.sC=new $CLJS.N(null,"operator","operator",-1860875338);$CLJS.tC=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.wC=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.xC=new $CLJS.N("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.yC=new $CLJS.N("lib","uuid","lib/uuid",-2145250720);$CLJS.zC=new $CLJS.N(null,"x","x",2099068185);$CLJS.CC=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.DC=new $CLJS.N(null,"bin-width","bin-width",1377922579);$CLJS.EC=new $CLJS.N(null,"strategy","strategy",-1471631918);
FC=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.lL($CLJS.oC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,new $CLJS.k(null,1,[$CLJS.fb,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"non-blank string"],null),$CLJS.$f($CLJS.QF)],null)],null));$CLJS.lL($CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ab,new $CLJS.k(null,1,[$CLJS.fb,0],null)],null));$CLJS.lL($CLJS.UB,$CLJS.Ce);
$CLJS.lL($CLJS.xC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"positive number"],null),$CLJS.PB($CLJS.Eb,$CLJS.cf)],null));$CLJS.lL(FC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,new $CLJS.k(null,2,[$CLJS.fb,36,$CLJS.mk,36],null)],null));
$CLJS.lL(pC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"valid semantic type",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QH(a,$CLJS.Ij)}],null));
$CLJS.lL(hC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"valid relation type",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return["Not a valid relation type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QH(a,$CLJS.Ia)}],null));$CLJS.lL($CLJS.fC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,pC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,hC],null)],null));
$CLJS.lL($CLJS.CC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"valid base type",$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.QH(a,$CLJS.Nj)}],null));
$CLJS.lL($CLJS.wC,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yC,FC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iy,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.CC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cb,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.CC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Xj,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,
$CLJS.fC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null)],null));
$CLJS.lL(Uea,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.iC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.ak,$CLJS.Sf],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.Is],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bj,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.wC],null)],null));