var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var BT,ST,TT,tU,uU,NU,OU,PU,VU,$U;BT=new $CLJS.N("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);ST=new $CLJS.N("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);TT=new $CLJS.N("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.YT=new $CLJS.N("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);tU=new $CLJS.N("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);uU=new $CLJS.N("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);NU=new $CLJS.N("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
OU=new $CLJS.N("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);PU=new $CLJS.N("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);VU=new $CLJS.N("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);$U=new $CLJS.N("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.lL(BT,$CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid template tag :widget-type"],null),$CLJS.Ix],null),$CLJS.gi($CLJS.cV)));$CLJS.lL(tU,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ok,$CLJS.pM,$CLJS.eN,$CLJS.HD,$CLJS.Xt,$CLJS.sk,$CLJS.tP],null));
$CLJS.lL(OU,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eC,$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zf,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.oC,$CLJS.dk],null)],null)],null));
$CLJS.lL(PU,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,OU],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GN,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null)],null));
$CLJS.lL(uU,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,PU],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.HD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HD,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.UC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zN,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,BT],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bj,new $CLJS.k(null,1,[$CLJS.Ks,
!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null)],null)],null));
$CLJS.lL(NU,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,OU],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.pM],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bM,$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.RK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.GK],null)],null)],null));
$CLJS.lL($U,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,OU],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.eN],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VQ,$CLJS.FK],null)],null)],null));$CLJS.lL(VU,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ok],null),$CLJS.sS));
$CLJS.lL(ST,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,PU],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,VU],null)],null)],null)],null));
$CLJS.lL(TT,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tU],null)],null)],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,1,[$CLJS.ib,$CLJS.$k],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HD,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uU],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pM,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,NU],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eN,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$U],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,ST],null)],null)],null)],null));
$CLJS.lL($CLJS.YT,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ys,$CLJS.oC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,TT],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)}],null)],null));