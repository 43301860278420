var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.tools.reader.js");require("./cljs.tools.reader.reader_types.js");require("./clojure.string.js");require("./cljs.tools.reader.impl.inspect.js");
'use strict';var zF,AF,nfa;$CLJS.Iu=function(a){this.Yd=a;this.G=null;this.C=2153775104;this.K=2048};zF=function(a){a=parseInt(a,10);return $CLJS.Gb(isNaN(a))?a:null};AF=function(a,b,c,d){if(!(a<=b&&b<=c))throw Error([$CLJS.p.g(d)," Failed:  ",$CLJS.p.g(a),"\x3c\x3d",$CLJS.p.g(b),"\x3c\x3d",$CLJS.p.g(c)].join(""));return b};
nfa=function(a){var b=$CLJS.Di(lfa,a);$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null),d=$CLJS.J(b,2,null),e=$CLJS.J(b,3,null),f=$CLJS.J(b,4,null),g=$CLJS.J(b,5,null),l=$CLJS.J(b,6,null),n=$CLJS.J(b,7,null),q=$CLJS.J(b,8,null),u=$CLJS.J(b,9,null),v=$CLJS.J(b,10,null);if($CLJS.Gb(b))throw Error(["Unrecognized date/time syntax: ",$CLJS.p.g(a)].join(""));a=zF(c);b=function(){var I=zF(d);return $CLJS.m(I)?I:1}();c=function(){var I=zF(e);return $CLJS.m(I)?I:1}();var x=function(){var I=zF(f);return $CLJS.m(I)?
I:0}(),y=function(){var I=zF(g);return $CLJS.m(I)?I:0}(),B=function(){var I=zF(l);return $CLJS.m(I)?I:0}(),H=function(){a:if($CLJS.F.h(3,$CLJS.E(n)))var I=n;else if(3<$CLJS.E(n))I=n.substring(0,3);else for(I=new $CLJS.rb(n);;)if(3>I.getLength())I=I.append("0");else{I=I.toString();break a}I=zF(I);return $CLJS.m(I)?I:0}();q=($CLJS.F.h(q,"-")?-1:1)*(60*function(){var I=zF(u);return $CLJS.m(I)?I:0}()+function(){var I=zF(v);return $CLJS.m(I)?I:0}());return new $CLJS.S(null,8,5,$CLJS.T,[a,AF(1,b,12,"timestamp month field must be in range 1..12"),
AF(1,c,mfa(b,0===(a%4+4)%4&&(0!==(a%100+100)%100||0===(a%400+400)%400)),"timestamp day field must be in range 1..last day in month"),AF(0,x,23,"timestamp hour field must be in range 0..23"),AF(0,y,59,"timestamp minute field must be in range 0..59"),AF(0,B,$CLJS.F.h(y,59)?60:59,"timestamp second field must be in range 0..60"),AF(0,H,999,"timestamp millisecond field must be in range 0..999"),q],null)};$CLJS.h=$CLJS.Iu.prototype;$CLJS.h.Vg=$CLJS.t;$CLJS.h.toString=function(){return this.Yd};
$CLJS.h.equiv=function(a){return this.U(null,a)};$CLJS.h.U=function(a,b){return(a=null!=b?$CLJS.t===b.Vg?!0:!1:!1)?this.Yd===b.Yd:a};$CLJS.h.W=function(a,b){return $CLJS.Qc(b,['#uuid "',$CLJS.p.g(this.Yd),'"'].join(""))};$CLJS.h.ca=function(){null==this.G&&(this.G=$CLJS.pd(this.Yd));return this.G};$CLJS.h.Oc=function(a,b){if(b instanceof $CLJS.Iu)return $CLJS.Wa(this.Yd,b.Yd);throw Error(["Cannot compare ",$CLJS.p.g(this)," to ",$CLJS.p.g(b)].join(""));};
$CLJS.Kx=new $CLJS.N(null,"pattern","pattern",242135423);$CLJS.Lx=new $CLJS.N(null,"replacement","replacement",-1836238839);var mfa,lfa;mfa=function(){var a=new $CLJS.S(null,13,5,$CLJS.T,[null,31,28,31,30,31,30,31,31,30,31,30,31],null),b=new $CLJS.S(null,13,5,$CLJS.T,[null,31,29,31,30,31,30,31,31,30,31,30,31],null);return function(c,d){return $CLJS.M.h($CLJS.m(d)?b:a,c)}}();lfa=/(\d\d\d\d)(?:-(\d\d)(?:-(\d\d)(?:[T](\d\d)(?::(\d\d)(?::(\d\d)(?:[.](\d+))?)?)?)?)?)?(?:[Z]|([-+])(\d\d):(\d\d))?/;
$CLJS.DF=$CLJS.na($CLJS.nn.l($CLJS.G([new $CLJS.k(null,4,[new $CLJS.w(null,"inst","inst",-2008473268,null),function(a){if("string"===typeof a){var b=nfa(a);if($CLJS.m(b)){a=$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null),d=$CLJS.J(b,2,null),e=$CLJS.J(b,3,null),f=$CLJS.J(b,4,null),g=$CLJS.J(b,5,null),l=$CLJS.J(b,6,null);b=$CLJS.J(b,7,null);b=new Date(Date.UTC(a,c-1,d,e,f,g,l)-6E4*b)}else throw Error(["Unrecognized date/time syntax: ",$CLJS.p.g(a)].join(""));return b}throw Error("Instance literal expects a string for its timestamp.");
},$CLJS.Ul,function(a){if("string"===typeof a)return new $CLJS.Iu(a.toLowerCase());throw Error("UUID literal expects a string as its representation.");},new $CLJS.w(null,"queue","queue",-1198599890,null),function(a){if($CLJS.qe(a))return $CLJS.oh.h($CLJS.dn,a);throw Error("Queue literal expects a vector for its elements.");},new $CLJS.w(null,"js","js",-886355190,null),function(a){if($CLJS.qe(a)){var b=[];a=$CLJS.A(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.$(null,e);b.push(f);e+=1}else if(a=$CLJS.A(a))c=
a,$CLJS.re(c)?(a=$CLJS.$c(c),e=$CLJS.ad(c),c=a,d=$CLJS.E(a),a=e):(a=$CLJS.C(c),b.push(a),a=$CLJS.D(c),c=null,d=0),e=0;else break;return b}if($CLJS.oe(a)){b={};a=$CLJS.A(a);c=null;for(e=d=0;;)if(e<d){var g=c.$(null,e);f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);f=$CLJS.ui(f);b[f]=g;e+=1}else if(a=$CLJS.A(a))$CLJS.re(a)?(d=$CLJS.$c(a),a=$CLJS.ad(a),c=d,d=$CLJS.E(d)):(d=$CLJS.C(a),c=$CLJS.J(d,0,null),d=$CLJS.J(d,1,null),c=$CLJS.ui(c),b[c]=d,a=$CLJS.D(a),c=null,d=0),e=0;else break;return b}throw Error("JS literal expects a vector or map containing only string or unqualified keyword keys");
}],null),$CLJS.P])));