var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vL,xL,zL,CL,KL;$CLJS.tL=function(a){return $CLJS.mj($CLJS.r($CLJS.FH),a,$CLJS.CE)};$CLJS.J4=new $CLJS.N(null,"many-pks?","many-pks?",-459675021);$CLJS.uL=new $CLJS.N("lib","source-name","lib/source-name",1931622490);$CLJS.b5=new $CLJS.N(null,"object-id","object-id",-754527291);vL=new $CLJS.N("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.wL=new $CLJS.N(null,"operators","operators",-2064102509);
xL=new $CLJS.N("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.yL=new $CLJS.N(null,"stage-number","stage-number",-1752729638);zL=new $CLJS.N("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.AL=new $CLJS.N(null,"min-value","min-value",-1119123315);$CLJS.BL=new $CLJS.N(null,"dimensions","dimensions",-254818097);
CL=new $CLJS.N("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.GL=new $CLJS.N(null,"max-value","max-value",687805168);$CLJS.JL=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);KL=new $CLJS.N("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.S5=new $CLJS.N(null,"null","null",-180137709);
$CLJS.W5=new $CLJS.N("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.lL(xL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,$CLJS.wC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.MF],null)],null)],null));$CLJS.lL(KL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,xL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iy,$CLJS.CC],null)],null)],null));$CLJS.lL(zL,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.yE],null),KL,$CLJS.oC],null));
$CLJS.lL(CL,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.yE],null),xL,$CLJS.BC],null));
$CLJS.lF.h($CLJS.yE,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.yE],null),xL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.BC,$CLJS.oC],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.ib,function(a){return $CLJS.ne(a)?($CLJS.J(a,0,null),$CLJS.J(a,1,null),a=$CLJS.J(a,2,null),$CLJS.EH(a)):null},$CLJS.tt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.Yx,CL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xx,zL],null)],null)],null));$CLJS.pV($CLJS.yE,$CLJS.CE);$CLJS.EL.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.Tx($CLJS.cb,$CLJS.iy)(b);return $CLJS.m(a)?a:$CLJS.TC});$CLJS.AV($CLJS.fy,$CLJS.G([$CLJS.oC]));$CLJS.EL.o(null,$CLJS.fy,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.Tx($CLJS.cb,$CLJS.iy)(b);return $CLJS.m(a)?a:$CLJS.TC});
$CLJS.pV($CLJS.fy,$CLJS.CE);$CLJS.lL(vL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,$CLJS.wC,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uL,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.oC],null)],null)],null));
$CLJS.lF.h($CLJS.nE,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.nE],null),vL,$CLJS.ak],null));$CLJS.EL.o(null,$CLJS.nE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.Tx($CLJS.cb,$CLJS.iy)(b);return $CLJS.m(a)?a:$CLJS.TC});$CLJS.pV($CLJS.nE,$CLJS.CE);$CLJS.AV($CLJS.UD,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wK],null)],null)]));$CLJS.pV($CLJS.UD,$CLJS.CE);
$CLJS.AV($CLJS.tE,$CLJS.G([$CLJS.ft,$CLJS.TC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.uK],null)],null)]));$CLJS.pV($CLJS.tE,$CLJS.CE);
$CLJS.lL($CLJS.CE,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,$CLJS.GC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.Qt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.it(", ",$CLJS.Pe($CLJS.Le,$CLJS.rn.h($CLJS.r($CLJS.FH),$CLJS.CE)))].join("")}],null),function(a){a=$CLJS.J(a,0,null);return $CLJS.tL(a)}],null)],null));