var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var M7,R5,b3,d3,f3,m3,o3,Upa;M7=function(a,b){if(null!=a&&null!=a.nh)a=a.nh(a,b);else{var c=M7[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=M7._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.metric",a);}return a};$CLJS.N7=function(a,b){return M7($CLJS.i3(a),b)};
R5=function(a,b,c){var d=$CLJS.e3(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.nE);d=$CLJS.wW($CLJS.Sm.j($CLJS.pi([c]),$CLJS.yC,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.WH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.dk,c,$CLJS.SD,a,$CLJS.yL,b],null));return d};$CLJS.Tpa=function(a){return $CLJS.q1($CLJS.KE,new $CLJS.S(null,1,5,$CLJS.T,[a],null))};b3=new $CLJS.N("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
d3=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);f3=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);m3=new $CLJS.N("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);o3=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Upa=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.N3.o(null,$CLJS.nE,function(a,b){var c=$CLJS.Rf($CLJS.nE.g($CLJS.e3(a,b)));return $CLJS.m(c)?$CLJS.O4($CLJS.XH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.n3.D(a,b,v,$CLJS.d_);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.n3.D(a,
b,q,$CLJS.d_),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.l3.o(null,$CLJS.nE,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.iy);e=$CLJS.M.h(e,$CLJS.cb);c=$CLJS.J(c,2,null);c=R5(a,b,c);return $CLJS.nn.l($CLJS.G([$CLJS.b_.j(a,b,c),new $CLJS.k(null,2,[$CLJS.vK,$CLJS.UK,$CLJS.m0,$CLJS.yC.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.iy,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.cb,e],null):null]))});
$CLJS.K3.o(null,$CLJS.nE,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.n3.D(a,b,R5(a,b,c),d)});$CLJS.pV(f3,o3);
for(var p3=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gw,$CLJS.zE,$CLJS.HE],null)),r3=null,s3=0,t3=0;;)if(t3<s3){var Vpa=r3.$(null,t3);$CLJS.pV(Vpa,f3);t3+=1}else{var Wpa=$CLJS.A(p3);if(Wpa){var u3=Wpa;if($CLJS.re(u3)){var Xpa=$CLJS.$c(u3),Ypa=$CLJS.ad(u3),Zpa=Xpa,$pa=$CLJS.E(Xpa);p3=Ypa;r3=Zpa;s3=$pa}else{var aqa=$CLJS.C(u3);$CLJS.pV(aqa,f3);p3=$CLJS.D(u3);r3=null;s3=0}t3=0}else break}
$CLJS.K3.o(null,f3,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.n3.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.WH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.WH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.XH("Count");case "cum-count":return $CLJS.XH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.L3.o(null,f3,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.l3.o(null,m3,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.sj($CLJS.l3,o3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Xj,$CLJS.gz)});
$CLJS.pV(m3,o3);$CLJS.pV(f3,m3);$CLJS.pV($CLJS.aE,m3);$CLJS.K3.o(null,$CLJS.eE,function(){return $CLJS.XH("Case")});$CLJS.L3.o(null,$CLJS.eE,function(){return"case"});$CLJS.pV(d3,o3);
for(var v3=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.sE,$CLJS.UE,$CLJS.aE,$CLJS.mk,$CLJS.LE,$CLJS.fb,$CLJS.NE,$CLJS.KE,$CLJS.bE],null)),w3=null,x3=0,z3=0;;)if(z3<x3){var bqa=w3.$(null,z3);$CLJS.pV(bqa,d3);z3+=1}else{var cqa=$CLJS.A(v3);if(cqa){var A3=cqa;if($CLJS.re(A3)){var dqa=$CLJS.$c(A3),eqa=$CLJS.ad(A3),fqa=dqa,gqa=$CLJS.E(dqa);v3=eqa;w3=fqa;x3=gqa}else{var hqa=$CLJS.C(A3);$CLJS.pV(hqa,d3);v3=$CLJS.D(A3);w3=null;x3=0}z3=0}else break}
$CLJS.L3.o(null,d3,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.K3.o(null,d3,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.n3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.WH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.WH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.WH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.WH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.WH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.WH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.WH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.WH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.WH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.K3.o(null,$CLJS.iE,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.WH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.n3.D(a,b,e,d)]))});$CLJS.L3.o(null,$CLJS.iE,function(){return"percentile"});
$CLJS.pV(b3,o3);for(var B3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iE,$CLJS.bE],null)),C3=null,D3=0,E3=0;;)if(E3<D3){var iqa=C3.$(null,E3);$CLJS.pV(iqa,b3);E3+=1}else{var jqa=$CLJS.A(B3);if(jqa){var F3=jqa;if($CLJS.re(F3)){var kqa=$CLJS.$c(F3),lqa=$CLJS.ad(F3),mqa=kqa,nqa=$CLJS.E(kqa);B3=lqa;C3=mqa;D3=nqa}else{var oqa=$CLJS.C(F3);$CLJS.pV(oqa,b3);B3=$CLJS.D(F3);C3=null;D3=0}E3=0}else break}
$CLJS.l3.o(null,b3,function(a,b,c){var d=$CLJS.Km.h,e=$CLJS.sj($CLJS.l3,o3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Km,a,$CLJS.Xj)});$CLJS.K3.o(null,$CLJS.SE,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.WH("Sum of {0} matching condition",$CLJS.G([$CLJS.n3.D(a,b,e,d)]))});
$CLJS.L3.o(null,$CLJS.SE,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.M3.j(a,b,d))].join("")});$CLJS.pV($CLJS.SE,o3);$CLJS.K3.o(null,$CLJS.FE,function(){return $CLJS.XH("Share of rows matching condition")});$CLJS.L3.o(null,$CLJS.FE,function(){return"share"});$CLJS.l3.o(null,$CLJS.FE,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.sj($CLJS.l3,o3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Xj,$CLJS.nA)});
$CLJS.pV($CLJS.FE,o3);$CLJS.K3.o(null,$CLJS.HE,function(){return $CLJS.XH("Count of rows matching condition")});$CLJS.l3.o(null,o3,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.nn.l;d=$CLJS.m(d)?$CLJS.li($CLJS.b_.j(a,b,d),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LK,$CLJS.Xj],null)):null;var f=$CLJS.sj($CLJS.l3,$CLJS.oa);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.nn,$CLJS.G([d,a]))});
$CLJS.pqa=function(){function a(d){return $CLJS.q1($CLJS.gw,new $CLJS.S(null,1,5,$CLJS.T,[d],null))}function b(){return $CLJS.q1($CLJS.gw,$CLJS.Lg)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.A=b;c.g=a;return c}();$CLJS.dW.o(null,$CLJS.nE,function(a){return a});
$CLJS.V8=function(){function a(d,e,f){for(;;)if($CLJS.F.h($CLJS.EH(f),$CLJS.zK))f=$CLJS.iW(f);else return $CLJS.y8(d,e,$CLJS.nE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.W8=function(){function a(d,e){return $CLJS.Rf($CLJS.nE.g($CLJS.e3(d,e)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.g8=function(){function a(d,e){var f=$CLJS.Rf($CLJS.nE.g($CLJS.e3(d,e)));return null==f?null:$CLJS.oh.j($CLJS.Lg,$CLJS.rg.g(function(g){var l=$CLJS.b_.j(d,e,g),n=$CLJS.U.l,q=$CLJS.iy.g(l);return n.call($CLJS.U,$CLJS.X7(l,$CLJS.cb,$CLJS.m(q)?q:$CLJS.Nj),$CLJS.vK,$CLJS.UK,$CLJS.G([$CLJS.m0,$CLJS.yC.g($CLJS.Zd(g))]))}),f)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.h=a;return c}();$CLJS.K3.o(null,$CLJS.tF,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.pF);return $CLJS.eC.g(a.A?a.A():a.call(null))});$CLJS.Q3.o(null,$CLJS.tF,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.rE);b=$CLJS.M.h(d,$CLJS.pF);c=$CLJS.M.h(d,$CLJS.oF);d=$CLJS.M.h(d,$CLJS.EY);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.I_,$CLJS.kG(a),$CLJS.G([$CLJS.G_,c]));return null!=d?$CLJS.U.j(a,$CLJS.r_,d):a});
$CLJS.X8=function(){function a(d,e){var f=function(){var n=$CLJS.Jx.g($CLJS.b1(d));return $CLJS.m(n)?n:$CLJS.oi}(),g=$CLJS.e3(d,e),l=$CLJS.N_.j(d,e,g);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Sm.j($CLJS.tg(function(n){n=$CLJS.rF.g(n);var q=null==n;return q?q:f.g?f.g(n):f.call(null,n)}),$CLJS.Fn(function(n){n=$CLJS.Qf(n);var q=$CLJS.M.h(n,$CLJS.oF),u=$CLJS.M.h(n,$CLJS.mF);if($CLJS.Gb(q))return n;if($CLJS.F.h(u,$CLJS.Is))return $CLJS.U.j(n,$CLJS.uW,l);q=$CLJS.Rf($CLJS.t8(function(v){return $CLJS.Q4(u,
v)},l));return $CLJS.m(q)?$CLJS.U.j(n,$CLJS.uW,q):null}),$CLJS.rg.g(function(n){return $CLJS.U.j(n,$CLJS.cu,$CLJS.tF)})),$CLJS.vW))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.Y8=function(){function a(d,e){return $CLJS.hW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rE.g(d),$CLJS.P,$CLJS.lW.g(e)],null))}function b(d){if($CLJS.Gb($CLJS.oF.g(d)))return $CLJS.hW(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rE.g(d),$CLJS.P],null));var e=$CLJS.rE.g(d);e=$CLJS.xa.h?$CLJS.xa.h("aggregation operator %s requires an argument",e):$CLJS.xa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.zj(e,new $CLJS.k(null,1,[Upa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();