var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var DL,IL,Vea,efa,ffa,gfa,hfa,ifa,lD;
$CLJS.rL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Iu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join("").toLowerCase())};$CLJS.XH=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.WH(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)};DL=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N};
$CLJS.FL=function(a){var b=function(){var c=DL(a);return c?(c=$CLJS.oe($CLJS.Zd(a)))?(c=$CLJS.cb.g($CLJS.Zd(a)),$CLJS.m(c)?c:$CLJS.iy.g($CLJS.Zd(a))):c:c}();return $CLJS.m(b)?b:$CLJS.EL.g(a)};
IL=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,2,[$CLJS.tt,"valid MBQL clause",$CLJS.Qt,function(c){c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.vl);return["invalid MBQL clause: ",$CLJS.aj.l($CLJS.G([c]))].join("")}],null),$CLJS.$f(DL)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.GC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,b],null),function(c){var d=$CLJS.HC;
$CLJS.m(d)?c=d:(c=$CLJS.FL(c),c=HL(c,a));return c}],null)],null)};$CLJS.IC=new $CLJS.N(null,"right-join","right-join",-56349359);Vea=new $CLJS.N("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);efa=new $CLJS.N("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);ffa=new $CLJS.N("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);
$CLJS.JC=new $CLJS.N(null,"fields","fields",-1932066230);$CLJS.KC=new $CLJS.N(null,"expr","expr",745722291);$CLJS.LC=new $CLJS.N("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.NC=new $CLJS.N("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.OC=new $CLJS.N(null,"alias","alias",-2039751630);
$CLJS.QC=new $CLJS.N("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.RC=new $CLJS.N("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.TC=new $CLJS.N("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.UC=new $CLJS.N("mbql.clause","field","mbql.clause/field",1497292735);
gfa=new $CLJS.N("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.$C=new $CLJS.N(null,"left-join","left-join",-672831855);$CLJS.aD=new $CLJS.N("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.iD=new $CLJS.N("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);
$CLJS.jD=new $CLJS.N("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);hfa=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);ifa=new $CLJS.N("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.kD=new $CLJS.N(null,"inner-join","inner-join",659431740);
lD=new $CLJS.N("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.GC=new $CLJS.N("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.mD=new $CLJS.N("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.nD=new $CLJS.N(null,"full-join","full-join",1305476385);
$CLJS.oD=new $CLJS.N("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.pD=new $CLJS.N("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);var HL;$CLJS.EL=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.EH(f);return $CLJS.F.h(g,$CLJS.hy)?$CLJS.Ob(f):g},e,a,b,c,d)}();
$CLJS.lL(efa,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.TC],null),$CLJS.CC],null));$CLJS.EL.o(null,$CLJS.oa,function(a){throw $CLJS.zj($CLJS.WH("{0}: Don''t know how to determine the type of {1}",$CLJS.G([hfa,$CLJS.aj.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.KC,a],null));});$CLJS.EL.o(null,$CLJS.iD,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.FL(a)});
HL=function HL(a,b){return $CLJS.le(a)?$CLJS.Xf(function(d){return HL.h?HL.h(d,b):HL.call(null,d,b)},a):$CLJS.le(b)?$CLJS.Xf(function(d){return HL.h?HL.h(a,d):HL.call(null,a,d)},b):$CLJS.F.h(a,$CLJS.TC)?!0:$CLJS.QH(a,b)};$CLJS.HC=!1;$CLJS.lL($CLJS.QC,IL($CLJS.Sj,"expression returning a boolean"));$CLJS.lL($CLJS.mD,IL($CLJS.ek,"expression returning a string"));$CLJS.lL($CLJS.pD,IL($CLJS.Jj,"expression returning an integer"));$CLJS.lL(ffa,IL($CLJS.lB,"expression returning a non-integer real number"));
$CLJS.lL($CLJS.oD,IL($CLJS.Uj,"expression returning a number"));$CLJS.lL(gfa,IL($CLJS.Ik,"expression returning a date"));$CLJS.lL(ifa,IL($CLJS.gl,"expression returning a time"));$CLJS.lL(Vea,IL($CLJS.Uk,"expression returning a date time"));$CLJS.lL($CLJS.NC,IL($CLJS.Zj,"expression returning a date, time, or date time"));$CLJS.WL=new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Uj,null,$CLJS.ek,null,$CLJS.Zj,null,$CLJS.Sj,null],null),null);$CLJS.lL($CLJS.jD,IL($CLJS.WL,"an expression that can be compared with :\x3e or :\x3c"));
var jfa=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Qy,null,$CLJS.Uj,null,$CLJS.ek,null,$CLJS.cA,null,$CLJS.Zj,null,$CLJS.Nj,null,$CLJS.Sj,null,$CLJS.Py,null,$CLJS.jB,null],null),null);$CLJS.sG.h($CLJS.ek,lD);$CLJS.sG.h($CLJS.cA,lD);$CLJS.sG.h($CLJS.Py,lD);$CLJS.lL(lD,IL(lD,"expression returning something emptyable (e.g. a string or BSON ID)"));$CLJS.lL($CLJS.aD,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,IL(jfa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.lL($CLJS.RC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,IL($CLJS.Nj,"any type of expression")],null));
$CLJS.lL($CLJS.LC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.Is,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jC,$CLJS.ak],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fs,$CLJS.Is],null)],null)],null)],null));