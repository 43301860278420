var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var W2,X2,Y2;$CLJS.yra=function(a,b,c){var d=$CLJS.e3(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.mN);var e=$CLJS.wW(function(f){return $CLJS.F.h($CLJS.OC.g(f),c)},d);if($CLJS.m(e))return e;throw $CLJS.zj($CLJS.WH("No join named {0}, found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.aj.l($CLJS.G([$CLJS.Wm.h($CLJS.OC,d)]))])),new $CLJS.k(null,3,[$CLJS.lP,c,$CLJS.SD,a,$CLJS.yL,b],null));};
$CLJS.U2=new $CLJS.N("metabase.lib.column-group","group-type","metabase.lib.column-group/group-type",6276454);$CLJS.V2=new $CLJS.N("metadata","column-group","metadata/column-group",-902258024);$CLJS.zpa=new $CLJS.N("metabase.lib.column-group","columns","metabase.lib.column-group/columns",1229087729);W2=new $CLJS.N("group-type","main","group-type/main",-938346573);X2=new $CLJS.N("group-type","join.implicit","group-type/join.implicit",-684408229);
Y2=new $CLJS.N("group-type","join.explicit","group-type/join.explicit",-627022785);var Apa,Bpa,Cpa,Lpa,Qpa;$CLJS.Z2=new $CLJS.k(null,3,[W2,1,Y2,2,X2,3],null);$CLJS.l3.o(null,$CLJS.V2,function(a,b,c){return c});var $2=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.column-group","display-info-for-group-method"),function(f,g,l){return $CLJS.U2.g(l)},e,a,b,c,d)}();
$2.o(null,W2,function(a,b){return $CLJS.nn.l($CLJS.G([function(){var c=$CLJS.e3(a,b),d=function(){var e=$CLJS.XO.g(c);e=null==e?null:$CLJS.j3(a,e);return $CLJS.m(e)?$CLJS.q3.j(a,b,e):null}();if($CLJS.m(d))return d;d=function(){var e=$CLJS.xV.g(c);e=null==e?null:$CLJS.k3(a,e);return $CLJS.m(e)?$CLJS.q3.j(a,b,e):null}();if($CLJS.m(d))return d;d=$CLJS.D($CLJS.aL.g(a))?new $CLJS.k(null,1,[$CLJS.eC,""],null):null;return $CLJS.m(d)?d:new $CLJS.k(null,1,[$CLJS.eC,$CLJS.n3.j(a,b,c)],null)}(),new $CLJS.k(null,
2,[$CLJS.n_,!1,$CLJS.q_,!1],null)]))});
$2.o(null,Y2,function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.lP),e=$CLJS.M.h(c,$CLJS.KK),f=$CLJS.M.h(c,$CLJS.VQ);return $CLJS.nn.l($CLJS.G([function(){var g=$CLJS.m(d)?function(){var l=$CLJS.yra(a,b,d);return $CLJS.m(l)?$CLJS.q3.j(a,b,l):null}():null;if($CLJS.m(g))return g;g=$CLJS.m(e)?function(){var l=$CLJS.j3(a,e);return $CLJS.m(l)?$CLJS.q3.j(a,b,l):null}():null;return $CLJS.m(g)?g:$CLJS.m(f)?(g=$CLJS.k3(a,f),$CLJS.m(g)?$CLJS.q3.j(a,b,g):new $CLJS.k(null,1,[$CLJS.eC,$CLJS.m1(f)],null)):null}(),
new $CLJS.k(null,2,[$CLJS.n_,!0,$CLJS.q_,!1],null)]))});$2.o(null,X2,function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.JK);return $CLJS.nn.l($CLJS.G([function(){var e=$CLJS.wW(function(f){var g=$CLJS.F.h($CLJS.Zf.g(f),d);return g?$CLJS.HK.g(f):g},$CLJS.N_.j(a,b,$CLJS.e3(a,b)));return $CLJS.m(e)?(e=$CLJS.q3.j(a,b,e),$CLJS.Xm.j(e,$CLJS.eC,$CLJS.cpa)):null}(),new $CLJS.k(null,2,[$CLJS.n_,!1,$CLJS.q_,!0],null)]))});$CLJS.Q3.o(null,$CLJS.V2,function(a,b,c){return $2.j(a,b,c)});Apa=$CLJS.na($CLJS.P);
Bpa=$CLJS.na($CLJS.P);Cpa=$CLJS.na($CLJS.P);Lpa=$CLJS.na($CLJS.P);Qpa=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.a3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.column-group","column-group-info-method"),$CLJS.vK,Qpa,Apa,Bpa,Cpa,Lpa);$CLJS.a3.o(null,$CLJS.zY,function(a){return new $CLJS.k(null,3,[$CLJS.U2,X2,$CLJS.JK,$CLJS.JK.g(a),$CLJS.x_,$CLJS.x_.g(a)],null)});
$CLJS.a3.o(null,$CLJS.AY,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.KK),d=$CLJS.M.h(b,$CLJS.QY);a=$CLJS.nn.l;var e=new $CLJS.k(null,1,[$CLJS.U2,Y2],null);b=$CLJS.i1(b);b=$CLJS.m(b)?new $CLJS.k(null,1,[$CLJS.lP,b],null):null;$CLJS.m(b)?c=b:(d=$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.VQ,d],null):null,c=$CLJS.m(d)?d:$CLJS.m(c)?new $CLJS.k(null,1,[$CLJS.KK,c],null):null);return a.call($CLJS.nn,$CLJS.G([e,c]))});$CLJS.a3.o(null,$CLJS.oa,function(){return new $CLJS.k(null,1,[$CLJS.U2,W2],null)});
$CLJS.K3.o(null,$CLJS.V2,function(a,b,c){return $CLJS.eC.g($CLJS.q3.j(a,b,c))});