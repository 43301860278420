var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var h4,Tsa,i4,j4,u1,una,zna,Ana,Bna,Cna,Gna,Hna,Ina,Fna,w1,x1;h4=function(a,b){var c=$CLJS.He,d=g4;for(a=$CLJS.A(a);;)if(null!=a){d=$CLJS.M.j(d,$CLJS.C(a),c);if(c===d)return b;a=$CLJS.D(a)}else return d};
$CLJS.p1=function(a){return function(b){var c=$CLJS.ig(-1);return function(){function d(l,n){var q=c.Re(null,c.$b(null)+1);n=a.h?a.h(q,n):a.call(null,q,n);return null==n?l:b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);};g.A=f;g.g=e;g.h=d;return g}()}};
$CLJS.X7=function(a,b,c){return null==c||$CLJS.Ie(a,b)?a:$CLJS.U.j(a,b,c)};$CLJS.q1=function(a,b){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.k(null,1,[$CLJS.yC,$CLJS.p.g($CLJS.rL())],null)],null),$CLJS.rg.g($CLJS.lW),b)};$CLJS.r1=function(a,b){return $CLJS.Z_(a)&&$CLJS.F.h($CLJS.C(a),b)};$CLJS.o7=function(a,b){a=$CLJS.zW(a,b);return 0<a?a-1:null};
$CLJS.O4=function(a,b){if($CLJS.A(b)){if($CLJS.F.h($CLJS.E(b),1))return $CLJS.C(b);a=[" ",(0,$CLJS.Ca)(a)," "].join("");return $CLJS.F.h($CLJS.E(b),2)?[$CLJS.p.g($CLJS.C(b)),a,$CLJS.p.g($CLJS.Zd(b))].join(""):[$CLJS.it(", ",$CLJS.ti(b)),",",a,$CLJS.p.g($CLJS.$d(b))].join("")}return null};$CLJS.s1=function(a){return $CLJS.xV.g($CLJS.C($CLJS.aL.g(a)))};
$CLJS.y8=function(a,b,c,d){a=$CLJS.d0(a);b=$CLJS.m(b)?b:-1;var e=$CLJS.e3(a,b);e=!($CLJS.A($CLJS.nE.g(e))||$CLJS.A($CLJS.mE.g(e)));c=$CLJS.J7.l(a,b,$CLJS.Xm,$CLJS.G([c,function(f){return $CLJS.kf.h($CLJS.Mg(f),$CLJS.lW.g(d))}]));return e?$CLJS.Xm.R((0,$CLJS.J7)(c,b,function(f){return $CLJS.HW($CLJS.Km.l(f,$CLJS.GR,$CLJS.G([$CLJS.JC])),$CLJS.mN,function(g){return $CLJS.Wm.h(function(l){return $CLJS.Km.h(l,$CLJS.JC)},g)})}),$CLJS.aL,$CLJS.Sm.h(function(f){return $CLJS.oh.h($CLJS.Lg,f)},$CLJS.bn),0,
$CLJS.zW(a,b)+1):c};Tsa=function(a,b){b=$CLJS.k3(a,b);return $CLJS.m(b)?$CLJS.f4(a,b):null};i4=function(a){return $CLJS.oh.j($CLJS.oi,$CLJS.Vm.g(function(b){return $CLJS.xf(b)&&$CLJS.ki.h(b,$CLJS.cu)}),$CLJS.gi(a))};j4=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Oo(function(b,c){$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)}),$CLJS.nE.g(a))};
$CLJS.t1=function(a,b,c){var d=$CLJS.nn.l;b=$CLJS.Gb($CLJS.o7(a,b))?function(){var f=$CLJS.s1(a);return $CLJS.m(f)?(f=Tsa(a,f),$CLJS.m(f)?$CLJS.wW(function(g){return $CLJS.F.h($CLJS.Zf.g(g),c)},f):null):null}():null;try{var e=$CLJS.b4(a,c)}catch(f){e=null}return d.call($CLJS.nn,$CLJS.G([b,e]))};
u1=function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null),d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.lP);d=$CLJS.M.h(d,$CLJS.nN);$CLJS.J(a,2,null);a=$CLJS.m(d)?$CLJS.F.h(d,$CLJS.JK.g(b)):d;return $CLJS.m(a)?a:$CLJS.F.h($CLJS.Tx($CLJS.ZY,$CLJS.RY)(b),c)};
una=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null),d=$CLJS.Rf($CLJS.ug(function(e){var f=$CLJS.F.h($CLJS.DY.g(e),c);return f?u1(a,e):f},b));return $CLJS.m(d)?d:$CLJS.ug(function(e){var f=$CLJS.F.h($CLJS.V.g(e),c);return f?u1(a,e):f},b)};
zna=function(a,b,c){$CLJS.J(a,0,null);var d=$CLJS.J(a,1,null),e=$CLJS.J(a,2,null),f=$CLJS.Rf($CLJS.ug(function(g){var l=$CLJS.F.h($CLJS.Zf.g(g),e);if(l){if(l=$CLJS.Gb($CLJS.lP.g(d))){l=$CLJS.vK.g(g);var n=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.GY,null,$CLJS.LY,null],null),null);l=n.g?n.g(l):n.call(null,l)}return $CLJS.m(l)?l:u1(a,g)}return l},b));if($CLJS.m(f))return f;b=$CLJS.m(c)?$CLJS.Rf($CLJS.ug(function(g){return $CLJS.F.h($CLJS.Zf.g(g),e)},b)):null;return $CLJS.m(b)?b:$CLJS.Lg};
Ana=function(a){var b=$CLJS.n4.h($CLJS.vK.g(a),$CLJS.xY);return $CLJS.m(b)?b:$CLJS.jC.g(a)};
Bna=function(a,b){b=$CLJS.Rf($CLJS.Vm.h($CLJS.JK,b));if($CLJS.m(b))if($CLJS.Gb($CLJS.D(b)))a=$CLJS.C(b);else{if($CLJS.m($CLJS.n4.h($CLJS.C(a),$CLJS.yE))){var c=$CLJS.Rf($CLJS.Vm.h(Ana,b));c=$CLJS.m(c)?$CLJS.D(c)?null:$CLJS.C(c):null}else c=null;a=$CLJS.m(c)?c:console.warn($CLJS.zj("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.k(null,2,[$CLJS.W,a,$CLJS.uW,b],null)))}else a=null;return a};
Cna=function(a,b){b=$CLJS.Rf($CLJS.Vm.h(function(c){var d=$CLJS.Tx($CLJS.ZY,$CLJS.RY)(c);$CLJS.m(d)?(c=$CLJS.vK.g(c),d=new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.GY,null],null),null),c=d.g?d.g(c):d.call(null,c),c=$CLJS.Gb(c)):c=d;return c},b));return $CLJS.m(b)?$CLJS.Gb($CLJS.D(b))?$CLJS.C(b):Bna(a,b):null};
Gna=function(a,b){var c=$CLJS.GH(a);c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.lP);return $CLJS.m(d)?(b=$CLJS.Rf($CLJS.ug(function(e){return $CLJS.F.h($CLJS.Tx($CLJS.ZY,$CLJS.RY)(e),d)},b)),$CLJS.m(b)?$CLJS.Gb($CLJS.D(b))?$CLJS.C(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.k(null,2,[$CLJS.W,a,Fna,b],null)):null):Cna(a,b)};Hna=new $CLJS.N("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
Ina=new $CLJS.N("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.v1=new $CLJS.N(null,"generous?","generous?",1247836118);$CLJS.Jna=new $CLJS.N(null,"matching-refs","matching-refs",324693611);Fna=new $CLJS.N(null,"matches","matches",635497998);w1=new $CLJS.N(null,"left","left",-399115937);x1=new $CLJS.N("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.n4=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.EH(f);g=$CLJS.EH(g);return $CLJS.ki.h(f,g)?x1:f},e,a,b,c,d)}();$CLJS.n4.o(null,x1,function(){return!1});
$CLJS.n4.o(null,$CLJS.ey,function(a,b){var c=i4(a),d=i4(b);return $CLJS.F.h(c,d)&&$CLJS.Wf(function(e){return $CLJS.n4.h($CLJS.M.h(a,e),$CLJS.M.h(b,e))},c)});$CLJS.n4.o(null,$CLJS.$x,function(a,b){var c=$CLJS.F.h($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.A(a),$CLJS.C(c),$CLJS.D(c),c=$CLJS.A(b),$CLJS.C(c),$CLJS.D(c),c=a,a=b;;){c=$CLJS.A(c);b=$CLJS.C(c);var d=$CLJS.D(c);c=b;b=d;a=$CLJS.A(a);d=$CLJS.C(a);a=$CLJS.D(a);c=$CLJS.n4.h(c,d);if($CLJS.m(c)){if(c=$CLJS.je(b))return c;c=b}else return c}else return c});
var g4=null;$CLJS.n4.o(null,$CLJS.IV,function(a,b){var c=g4;g4=new $CLJS.k(null,2,[w1,j4(a),$CLJS.Vv,j4(b)],null);try{var d=$CLJS.sj($CLJS.n4,$CLJS.ey);return d.h?d.h(a,b):d.call(null,a,b)}finally{g4=c}});
$CLJS.n4.o(null,$CLJS.nE,function(a,b){var c=$CLJS.J(a,0,null),d=$CLJS.J(a,1,null),e=$CLJS.J(a,2,null),f=$CLJS.J(b,0,null),g=$CLJS.J(b,1,null),l=$CLJS.J(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.h(c,f))?(d=$CLJS.n4.h(d,g),$CLJS.m(d)?$CLJS.m(g4)?$CLJS.F.h(h4(new $CLJS.S(null,2,5,$CLJS.T,[w1,e],null),Ina),h4(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vv,l],null),Hna)):$CLJS.F.h(e,l):d):c:a});
$CLJS.n4.o(null,$CLJS.oa,function(a,b){if($CLJS.oe(a)){var c=$CLJS.sj($CLJS.n4,$CLJS.ey);return c.h?c.h(a,b):c.call(null,a,b)}return $CLJS.ne(a)?(c=$CLJS.sj($CLJS.n4,$CLJS.$x),c.h?c.h(a,b):c.call(null,a,b)):$CLJS.F.h(a,b)});
$CLJS.y1=function(){function a(f,g,l,n,q){var u=$CLJS.Z_(l)?l:$CLJS.iW(l),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null),y=$CLJS.J(u,2,null),B=e.j?e.j(u,n,q):e.call(null,u,n,q);if($CLJS.m(B))return B;B=$CLJS.m(function(){var I=$CLJS.n4.h(v,$CLJS.nE);return $CLJS.m(I)?$CLJS.uL.g(x):I}())?$CLJS.wW(function(I){var Q=$CLJS.n4.h($CLJS.vK.g(I),$CLJS.UK);return $CLJS.m(Q)?$CLJS.n4.h($CLJS.V.g(I),$CLJS.uL.g(x)):Q},n):null;if($CLJS.m(B))return B;if($CLJS.m($CLJS.m(f)?"number"===typeof y:f)&&(n=$CLJS.Rf($CLJS.Vm.h($CLJS.Zf,
n)),$CLJS.m(n))){var H=$CLJS.Z_(l)?$CLJS.t1(f,g,y):l;if($CLJS.m(H))return f=$CLJS.gW($CLJS.U.j(u,2,function(){var I=$CLJS.DY.g(H);return $CLJS.m(I)?I:$CLJS.V.g(H)}()),$CLJS.bg($CLJS.nn,new $CLJS.k(null,1,[$CLJS.iy,$CLJS.Nj],null))),e.j?e.j(f,n,q):e.call(null,f,n,q)}return null}function b(f,g,l,n){return e.R?e.R(f,g,l,n,$CLJS.P):e.call(null,f,g,l,n,$CLJS.P)}function c(f,g,l){var n=$CLJS.J(f,0,null);$CLJS.J(f,1,null);var q=$CLJS.J(f,2,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.v1);switch(n instanceof $CLJS.N?
n.S:null){case "aggregation":return $CLJS.wW(function(u){return $CLJS.F.h($CLJS.vK.g(u),$CLJS.UK)&&$CLJS.F.h($CLJS.m0.g(u),q)},g);case "expression":case "field":switch(g="string"===typeof q?una(f,g):zna(f,g,l),$CLJS.E(g)){case 0:return null;case 1:return $CLJS.C(g);default:return Gna(f,g)}default:throw $CLJS.zj("Unknown type of ref",new $CLJS.k(null,1,[$CLJS.W,f],null));}}function d(f,g){return e.j?e.j(f,g,$CLJS.P):e.call(null,f,g,$CLJS.P)}var e=null;e=function(f,g,l,n,q){switch(arguments.length){case 2:return d.call(this,
f,g);case 3:return c.call(this,f,g,l);case 4:return b.call(this,f,g,l,n);case 5:return a.call(this,f,g,l,n,q)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.j=c;e.D=b;e.R=a;return e}();
$CLJS.Kna=function(){function a(d,e,f,g){return $CLJS.y1.D(d,e,$CLJS.nY.j(d,e,f),g)}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();