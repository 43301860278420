var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var u8,Mta,Nta,Qta,x8,ira,F8,G8,tua,uua,J8,H8,I8,K8,z4,jra,kra,lra,mra,nra,ora,pra,rra,tra,ura,vra,wra,xra,zra,Ara,Bra,Cra,Dra,Era,Fra,Gra,Hra;u8=function(a,b){if(b=$CLJS.A(b)){var c=$CLJS.A(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.A(c)?(c=u8($CLJS.M.h(a,b),c),$CLJS.je(c)?$CLJS.Km.h(a,b):$CLJS.U.j(a,b,c)):$CLJS.Km.h(a,b)}return a};
Mta=function(a,b,c,d){return function q(f,g,l,n){var u=$CLJS.A(g);g=$CLJS.C(u);u=$CLJS.D(u);var v=$CLJS.Ke(f,g);return $CLJS.m(v)?u?$CLJS.U.j(f,g,q($CLJS.uc(v),u,l,n)):$CLJS.U.j(f,g,$CLJS.R.j(l,$CLJS.uc(v),n)):f}(a,b,c,d)};Nta=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Mta(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.z(b.slice(3),0,null):null)};$CLJS.w4=function(a){return $CLJS.yC.g($CLJS.GH(a))};
Qta=function(a,b,c,d){var e=$CLJS.F.h($CLJS.RP,$CLJS.C(b))?$CLJS.tW(d,function(){var f=$CLJS.GW.g(d);return $CLJS.m(f)?f:$CLJS.$_(c)}()):d;return Nta(a,b,function(f){return $CLJS.Mg(function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.F.h($CLJS.w4(B),$CLJS.w4(c))?e:B;x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):
$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.F.h($CLJS.w4(x),$CLJS.w4(c))?e:x,n($CLJS.zd(q)))}return null}},null,null)}(f)}())})};
x8=function(a,b,c,d){var e=$CLJS.Lu(a,b);if($CLJS.m(e)){var f=$CLJS.w4(c),g=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),$CLJS.$d(b)],null);c=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);e=$CLJS.oh.j($CLJS.Lg,$CLJS.Vm.g($CLJS.Sm.h($CLJS.pi([f]),$CLJS.w4)),e);e=$CLJS.F.h(b,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.JC],null))&&$CLJS.Wf(function(l){return $CLJS.r1(l,$CLJS.fy)},e)?null:e;if($CLJS.A(e))return $CLJS.Ru(a,b,e);if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mN,$CLJS.dL],null),new $CLJS.S(null,2,5,$CLJS.T,
[c,g],null)))throw $CLJS.zj($CLJS.XH("Cannot remove the final join condition"),new $CLJS.k(null,5,[$CLJS.xw,$CLJS.BW,$CLJS.dL,$CLJS.Lu(a,b),$CLJS.CW,$CLJS.Lu(a,$CLJS.ie(b)),$CLJS.yL,d,$CLJS.DW,a],null));return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mN,$CLJS.JC],null),new $CLJS.S(null,2,5,$CLJS.T,[c,g],null))?$CLJS.L0.D(a,$CLJS.ie(b),$CLJS.Km,g):u8(a,b)}return a};$CLJS.Rta=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.aL);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
$CLJS.hra=function(a,b){a=$CLJS.J7.l(a,b,$CLJS.Km,$CLJS.G([$CLJS.JC]));return $CLJS.oh.j($CLJS.Lg,$CLJS.Vm.g($CLJS.Sm.h(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.AY,null,$CLJS.zY,null],null),null),$CLJS.vK)),$CLJS.L_.j(a,b,$CLJS.e3(a,b)))};
ira=function(a,b,c,d){var e=$CLJS.n4.h($CLJS.E(c),$CLJS.E(d));return $CLJS.m(e)?(c=$CLJS.fj(function(f){return $CLJS.y1.D(a,b,f,d)},c),(e=!$CLJS.Ie(c,null))?(e=$CLJS.n4.h($CLJS.E(c),$CLJS.E(d)),$CLJS.m(e)?$CLJS.Wf(function(f){return $CLJS.n4.h($CLJS.E(f),1)},$CLJS.ii(c)):e):e):e};
F8=function(a,b){var c=$CLJS.E8.h(a,b),d=$CLJS.Ai(0,$CLJS.E(c));return $CLJS.qg.l(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.GR],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.mE],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.pW],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.JC],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.nE],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.RP],null)],null),function(){return function g(f){return new $CLJS.yf(null,function(){for(var l=f;;)if(l=$CLJS.A(l)){if($CLJS.re(l)){var n=
$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.Td(c,x);$CLJS.JC.g(y)instanceof $CLJS.N||u.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,x,$CLJS.JC],null));v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);n=$CLJS.Td(c,u);if($CLJS.JC.g(n)instanceof $CLJS.N)l=$CLJS.zd(l);else return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,u,$CLJS.JC],null),g($CLJS.zd(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function g(f){return new $CLJS.yf(null,
function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);u.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,x,$CLJS.dL],null));v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,u,$CLJS.dL],null),g($CLJS.zd(l)))}return null}},null,null)}(d)}()]))};
G8=function(a,b,c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,null);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.EE),g=$CLJS.M.h(e,$CLJS.mR),l=$CLJS.J(c,2,null);return $CLJS.C($CLJS.wW(function(n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);$CLJS.J(n,0,null);$CLJS.J(n,1,null);n=$CLJS.J(n,2,null);return $CLJS.F.h($CLJS.C(n),d)&&$CLJS.F.h($CLJS.EE.g($CLJS.Zd(n)),f)&&$CLJS.F.h($CLJS.mR.g($CLJS.Zd(n)),g)&&$CLJS.F.h($CLJS.$d(n),l)},$CLJS.dg($CLJS.an,$CLJS.GR.g($CLJS.e3(a,b)))))};
tua=function(a,b,c,d){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.J7.l(a,b,$CLJS.L0,$CLJS.G([new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.GR,c,2,1],null),$CLJS.Sm.h(function(e){return $CLJS.$0($CLJS.Cb,e)},$CLJS.nn),d])):a};uua=function(a,b,c){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.J7.l(a,b,x8,$CLJS.G([new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.GR],null),$CLJS.Lu($CLJS.e3(a,b),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GR,c],null)),b])):a};
J8=function(a,b,c,d,e,f){var g=$CLJS.J7.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.w4(e);return $CLJS.ki.h(a,g)?$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(n,q){try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var u=$CLJS.Td(q,0);if($CLJS.O(u,$CLJS.RP))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var I=$CLJS.$_(e);I=H8.aa?H8.aa(g,b,c,$CLJS.fy,$CLJS.P,I):H8.call(null,g,b,c,$CLJS.fy,$CLJS.P,I);return I8.D?I8.D(I,b,c,l):I8.call(null,I,b,c,l)}()],null);throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{u=
$CLJS.Td(q,0);if($CLJS.O(u,$CLJS.nE))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var Q=H8.aa?H8.aa(g,b,c,$CLJS.nE,$CLJS.P,l):H8.call(null,g,b,c,$CLJS.nE,$CLJS.P,l);return I8.D?I8.D(Q,b,c,l):I8.call(null,Q,b,c,l)}()],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error){var v=Q;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw Q;}else throw n;else throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.mE))return new $CLJS.S(null,
1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.JC))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw v;else throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{if($CLJS.qe(q)&&3===$CLJS.E(q))try{var B=$CLJS.Td(q,
0);if($CLJS.O(B,$CLJS.mN))try{var H=$CLJS.Td(q,2);if($CLJS.O(H,$CLJS.JC))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)return new $CLJS.S(null,1,5,$CLJS.T,[g],null);throw y;}throw Y;}else throw v;
else throw Q;}else throw n;else throw I;}}($CLJS.Lg,d)))):g};
H8=function(a,b,c,d,e,f){var g=$CLJS.e3(a,b),l=$CLJS.sg(function(q){var u=$CLJS.Lu(g,q);return $CLJS.m(u)?$CLJS.eg(function(v){return $CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function H(y,B){try{if($CLJS.qe(B)&&3===$CLJS.E(B))try{var I=$CLJS.Td(B,0);if($CLJS.F.h(I,d))try{var Q=$CLJS.Td(B,1);if($CLJS.je(e)||$CLJS.Z0($CLJS.si(e),$CLJS.si(Q)))try{var Y=$CLJS.Td(B,2);if($CLJS.F.h(Y,f))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[q,v],null)],null);throw $CLJS.Z;}catch(ha){if(ha instanceof
Error){var aa=ha;if(aa===$CLJS.Z)throw $CLJS.Z;throw aa;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){aa=ha;if(aa===$CLJS.Z)throw $CLJS.Z;throw aa;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){aa=ha;if(aa===$CLJS.Z)throw $CLJS.Z;throw aa;}throw ha;}else throw $CLJS.Z;}catch(ha){if(ha instanceof Error){aa=ha;if(aa===$CLJS.Z)return $CLJS.tZ(H,y,B);throw aa;}throw ha;}}($CLJS.Lg,v))))},u):null},$CLJS.G([F8(a,b)])),n=$CLJS.ig($CLJS.Tc($CLJS.Lg));a=$CLJS.Sb(function(q,
u){var v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);return J8(q,b,c,v,u,function(x,y,B){try{return x8(x,y,B,b)}catch(I){if(I instanceof Error){y=I;var H=$CLJS.Qf($CLJS.ol(y));B=$CLJS.M.h(H,$CLJS.xw);H=$CLJS.M.h(H,$CLJS.CW);if($CLJS.F.h(B,$CLJS.BW))return n.Re(null,$CLJS.If(n.$b(null),H)),x;throw y;}throw I;}})},a,l);return $CLJS.Sb(function(q,u){return $CLJS.x4.j?$CLJS.x4.j(q,b,u):$CLJS.x4.call(null,q,b,u)},a,$CLJS.Vc($CLJS.r(n)))};
I8=function(a,b,c,d){b=$CLJS.Rta(c,b);if($CLJS.m(b)){var e=$CLJS.e3(c,b);c=$CLJS.Xf(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.vK),l=$CLJS.M.h(f,$CLJS.m0);return $CLJS.F.h($CLJS.pY,g)&&$CLJS.F.h(d,l)?$CLJS.DY.g(f):null},$CLJS.N_.j(c,b,e));return $CLJS.m(c)?H8(a,b,a,$CLJS.yE,$CLJS.P,c):a}return a};
K8=function(a,b,c,d,e){var f=$CLJS.lW.g(c),g=$CLJS.e3(a,b);c=$CLJS.wW(function(q){var u=$CLJS.Lu(g,q);if($CLJS.m(u)){var v=$CLJS.w4(f);return $CLJS.m($CLJS.Xf($CLJS.Sm.j($CLJS.pi([v]),$CLJS.yC,$CLJS.Zd),u))?q:null}return null},F8(a,b));var l=(d=$CLJS.F.h($CLJS.WE,d))?$CLJS.lW.g(e):null;e=d?function(q,u,v){return Qta(q,u,v,l)}:function(q,u,v){return x8(q,u,v,b)};var n=$CLJS.F.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.mE],null),c);a=d&&n&&$CLJS.F.h($CLJS.C(f),$CLJS.C(l))&&$CLJS.F.h($CLJS.$d(f),$CLJS.$d(l))?
tua(a,b,f,$CLJS.li($CLJS.Zd(l),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mR,$CLJS.EE],null))):n?uua(a,b,f):a;return $CLJS.m(c)?(a=J8(a,b,a,c,f,e),$CLJS.y4.g?$CLJS.y4.g(a):$CLJS.y4.call(null,a)):a};z4=function(a){return $CLJS.fW(a,$CLJS.U,$CLJS.G([$CLJS.yC,$CLJS.p.g($CLJS.rL())]))};
jra=function(a,b,c){return function(d){return function l(f,g){try{if($CLJS.qe(g)&&3===$CLJS.E(g))try{var n=$CLJS.Td(g,0);if($CLJS.O(n,$CLJS.fy))try{var q=$CLJS.Td(g,2);if($CLJS.F.h(q,b))return z4(c);throw $CLJS.Z;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw v;}else throw $CLJS.Z;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Z)return $CLJS.e_(l,f,g);throw u;
}throw v;}}($CLJS.Lg,d)}(a)};kra=function(a,b,c){var d=function(){var l=$CLJS.$_(c);return $CLJS.m(l)?l:$CLJS.V.g($CLJS.GH(c))}(),e=z4($CLJS.tW(c,d));a=$CLJS.Xm.j(a,$CLJS.RP,function(l){return $CLJS.Wm.h(function(n){return $CLJS.F.h(n,b)?e:n},l)});var f=$CLJS.$_(b),g=$CLJS.cb.g($CLJS.GH(c));return jra(a,f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fy,new $CLJS.k(null,1,[$CLJS.cb,g],null),d],null))};
lra=function(a,b,c){return $CLJS.XZ(function(d){return $CLJS.F.h(d,$CLJS.w4(b))?$CLJS.w4(c):d},$CLJS.m($CLJS.$_(b))?kra(a,b,c):$CLJS.XZ(function(d){return $CLJS.F.h(d,b)?c:d},a))};mra=function(a,b,c){a=$CLJS.L_.j(a,b,$CLJS.e3(a,b));b=$CLJS.L_.j(c,b,$CLJS.e3(c,b));return $CLJS.ug(function(d){return $CLJS.ki.h($CLJS.C(d),$CLJS.Zd(d))},$CLJS.rg.j($CLJS.an,a,b))};
nra=function(a,b,c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);var e=$CLJS.DY.g(d),f=$CLJS.iW($CLJS.U.j(c,$CLJS.vK,$CLJS.pY));return $CLJS.rg.h(function(g){return new $CLJS.S(null,2,5,$CLJS.T,[g,z4(f)],null)},$CLJS.A($CLJS.ug($CLJS.Hb,function q(l,n){try{if($CLJS.qe(n)&&3===$CLJS.E(n))try{var u=$CLJS.Td(n,0);if($CLJS.O(u,$CLJS.yE))try{var v=$CLJS.Td(n,2);if($CLJS.F.h(v,e))return new $CLJS.S(null,1,5,$CLJS.T,[n],null);throw $CLJS.Z;}catch(y){if(y instanceof Error){var x=y;if(x===$CLJS.Z)throw $CLJS.Z;
throw x;}throw y;}else throw $CLJS.Z;}catch(y){if(y instanceof Error){x=y;if(x===$CLJS.Z)throw $CLJS.Z;throw x;}throw y;}else throw $CLJS.Z;}catch(y){if(y instanceof Error){x=y;if(x===$CLJS.Z)return $CLJS.tZ(q,l,n);throw x;}throw y;}}($CLJS.Lg,$CLJS.e3(a,b)))))};ora=function(a,b,c){var d=$CLJS.cb.g($CLJS.GH(c));$CLJS.m(d)||(d=$CLJS.T0.g?$CLJS.T0.g(c):$CLJS.T0.call(null,c),d=$CLJS.Gb(d)||!$CLJS.Z_(c));if($CLJS.m(d))return c;a=$CLJS.P3.j(a,b,c);return $CLJS.fW(c,$CLJS.U,$CLJS.G([$CLJS.cb,a]))};
pra=function(a,b){a=$CLJS.$_(a);return $CLJS.m($CLJS.m(a)?$CLJS.Gb($CLJS.$_(b))&&$CLJS.Gb($CLJS.V.g($CLJS.GH(b))):a)?$CLJS.tW(b,a):b};
rra=function(a,b,c,d){d=ora(a,b,pra(c,d));c=$CLJS.J7.l(a,b,lra,$CLJS.G([c,d]));for(var e=b;;){var f=$CLJS.Rta(c,e);if($CLJS.m(f)){b=f;var g=$CLJS.sg(function(l,n,q){return function(u){return nra(l,q,u)}}(c,e,b,f,a,d),$CLJS.G([mra(a,e,c)]));c=$CLJS.Sb(function(l,n,q,u){return function(v,x){var y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);return $CLJS.J7.l(v,u,lra,$CLJS.G([y,x]))}}(c,e,g,b,f,a,d),c,g);e=b}else return c}};
tra=function(a,b){b=$CLJS.tn.g(b);if($CLJS.F.h($CLJS.C(b),$CLJS.aL)){var c=$CLJS.Zd(b),d=$CLJS.D($CLJS.D(b)),e=function(){var f=$CLJS.D(d);return f?$CLJS.Xf(function(g){return $CLJS.Wf($CLJS.ue,$CLJS.rg.j($CLJS.F,g,d))?g:null},F8(a,c)):f}();return $CLJS.m(e)?$CLJS.bn.j(b,0,$CLJS.E(e)+2+1):null}return null};
ura=function(a,b,c,d){for(var e=rra(a,b,c,d);;){var f=$CLJS.MX($CLJS.JL,e),g=$CLJS.pn.g($CLJS.eg(function(l){return function(n){return tra(l,n)}}(e,f),$CLJS.Rs.g(f)));if($CLJS.A(g))e=$CLJS.Sb(function(){return function(l,n){try{return $CLJS.l9.j(l,$CLJS.Zd(n),$CLJS.Lu(l,n))}catch(x){if(x instanceof Error){var q=x,u=$CLJS.Qf($CLJS.ol(q)),v=$CLJS.M.h(u,$CLJS.xw);u=$CLJS.M.h(u,$CLJS.CW);if($CLJS.F.h(v,$CLJS.BW))return n=$CLJS.Zd(n),$CLJS.x4.j?$CLJS.x4.j(l,n,u):$CLJS.x4.call(null,l,n,u);throw q;}throw x;
}}}(e,f,g),e,g);else return $CLJS.m(f)?K8(a,b,c,$CLJS.WE,d):e}};vra=function(a,b){return $CLJS.r1(a,$CLJS.yE)&&$CLJS.F.h($CLJS.i1(a),b)};wra=function(a,b,c){return function g(e,f){try{if(vra(f,b))return $CLJS.G5(f,c);throw $CLJS.Z;}catch(n){if(n instanceof Error){var l=n;if(l===$CLJS.Z)return $CLJS.e_(g,e,f);throw l;}throw n;}}($CLJS.Lg,a)};
xra=function(a,b,c){var d=$CLJS.mN.g(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.Lu(d,new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.OC],null))],null):null;b=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);if($CLJS.m($CLJS.m(b)?$CLJS.ki.h(e,c):b)){var f=$CLJS.a2();$CLJS.Lta(f,$CLJS.rg.h($CLJS.OC,d));c=f(c);return wra($CLJS.Ru(a,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,b,$CLJS.OC],null),c),e,c)}return a};
zra=function(a,b,c){if($CLJS.Ae(c))return c;var d=function(){var e=$CLJS.pi([c]);return"string"===typeof c?$CLJS.Sm.h(e,$CLJS.OC):e}();return $CLJS.Xf(function(e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return $CLJS.m(d.g?d.g(e):d.call(null,e))?f:null},$CLJS.dg($CLJS.an,$CLJS.mN.g($CLJS.e3(a,b))))};
Ara=function(a,b,c,d){b=$CLJS.Fz.h($CLJS.si($CLJS.N_.j(b,c,$CLJS.e3(b,c))),$CLJS.si($CLJS.N_.j(a,c,$CLJS.e3(a,c))));return $CLJS.Sb(function(e,f){return $CLJS.R.R(H8,e,c,a,d.g?d.g(f):d.call(null,f))},a,b)};
Bra=function(a,b,c){a=Ara(a,b,c,function(d){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,new $CLJS.k(null,1,[$CLJS.lP,$CLJS.ZY.g(d)],null),$CLJS.Zf.g(d)],null)});c=$CLJS.Rta(a,c);return $CLJS.m(c)?Ara(a,b,c,function(d){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,$CLJS.P,$CLJS.DY.g(d)],null)}):a};Cra=function(a,b,c){return $CLJS.Ae(c)?$CLJS.Lu($CLJS.e3(a,b),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mN,c,$CLJS.OC],null)):$CLJS.oe(c)?$CLJS.OC.g(c):c};
Dra=function(a,b,c,d){var e=Cra(a,b,c);return $CLJS.m(e)?(c=function(){var f=(0,$CLJS.J7)(a,b,function(g){var l=$CLJS.mN.g(g);l=d.h?d.h(l,e):d.call(null,l,e);return $CLJS.eW(g,$CLJS.mN,l)});return(0,$CLJS.J7)(f,b,function(g){return $CLJS.HW(g,$CLJS.mN,function(l){return $CLJS.Wm.h(function(n){return $CLJS.cua(f,b,n)},l)})})}(),c=Bra(c,a,b),$CLJS.y4.g?$CLJS.y4.g(c):$CLJS.y4.call(null,c)):a};
Era=function(a,b){return null!=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function f(d,e){try{if(vra(e,b))return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var g=l;if(g===$CLJS.Z)return $CLJS.tZ(f,d,e);throw g;}throw l;}}($CLJS.Lg,a))))};Fra=function(a,b){var c=$CLJS.JC.g($CLJS.e3(a,b));if($CLJS.m(c)){var d=$CLJS.Gb($CLJS.Xf($CLJS.Sm.h($CLJS.nN,$CLJS.GH),c));return d?ira(a,b,c,$CLJS.hra(a,b)):d}return c};
Gra=function(a,b){var c=$CLJS.e3(a,b),d=$CLJS.m(Fra(a,b))?$CLJS.J7.l(a,b,$CLJS.Km,$CLJS.G([$CLJS.JC])):a;return $CLJS.m($CLJS.mN.g(c))?$CLJS.J7.l(d,b,$CLJS.Xm,$CLJS.G([$CLJS.mN,$CLJS.bg($CLJS.Wm,function(e){var f=$CLJS.JC.g(e);var g=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.rw,null,$CLJS.Ix,null],null),null);f=g.g?g.g(f):g.call(null,f);return $CLJS.m(f)?e:$CLJS.m(ira(a,b,$CLJS.JC.g(e),$CLJS.L_.j(a,b,$CLJS.U.j(e,$CLJS.JC,$CLJS.rw))))?$CLJS.U.j(e,$CLJS.JC,$CLJS.rw):e})])):d};
$CLJS.y4=function(a){return $CLJS.Sb(Gra,a,$CLJS.Ai(0,$CLJS.E($CLJS.aL.g(a))))};Hra=new $CLJS.N(null,"remove","remove",-131428414);$CLJS.Ira=new $CLJS.N("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var A4;$CLJS.l9=function(){function a(d,e,f){return $CLJS.oe(f)&&$CLJS.F.h($CLJS.cu.g(f),$CLJS.WK)?$CLJS.x4.j?$CLJS.x4.j(d,e,f):$CLJS.x4.call(null,d,e,f):K8(d,e,f,Hra,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();A4=$CLJS.sy.g($CLJS.RC);
$CLJS.m9=function(){function a(d,e,f,g){if($CLJS.oe(f)&&$CLJS.F.h($CLJS.cu.g(f),$CLJS.WK))d=$CLJS.B4.D?$CLJS.B4.D(d,e,f,g):$CLJS.B4.call(null,d,e,f,g);else{var l=A4.g?A4.g(f):A4.call(null,f);l=$CLJS.m(l)?A4.g?A4.g(g):A4.call(null,g):l;d=$CLJS.m(l)?ura(d,e,f,g):K8(d,e,f,$CLJS.WE,g)}return d}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.D=a;return c}();$CLJS.Jra=function(){function a(d,e,f,g){f=zra(d,e,f);return $CLJS.m(f)?$CLJS.J7.l(d,e,xra,$CLJS.G([f,g])):d}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();
$CLJS.x4=function(){function a(d,e,f){try{return Dra(d,e,f,function(u,v){return $CLJS.Rf($CLJS.t8(function(x){return!($CLJS.F.h($CLJS.OC.g(x),v)||Era(x,v))},u))})}catch(u){var g=u,l=$CLJS.Qf($CLJS.ol(g)),n=$CLJS.M.h(l,$CLJS.yL),q=$CLJS.M.h(l,$CLJS.xw);l=$CLJS.M.h(l,$CLJS.CW);if($CLJS.F.h(q,$CLJS.BW))return d=c.j?c.j(d,n,l):c.call(null,d,n,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw g;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.B4=function(){function a(d,e,f,g){return null==g?$CLJS.x4.j(d,e,f):Dra(d,e,f,function(l,n){return $CLJS.Wm.h(function(q){return $CLJS.F.h($CLJS.OC.g(q),n)?g:q},l)})}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();