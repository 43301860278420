var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var f6,h6,Dsa,Esa,Fsa,Gsa,Hsa;f6=function(a){if("string"===typeof a)return $CLJS.Di(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.Di(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.ko(a));};$CLJS.g6=function(a){return $CLJS.Ib(a)?$CLJS.iF(a,$CLJS.G([$CLJS.hk,!0])):a};
h6=function(a,b){if(null!=a&&null!=a.Se)a=a.Se(a,b);else{var c=h6[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=h6._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.setting",a);}return a};$CLJS.i6=function(a,b){return h6($CLJS.i3(a),b)};Dsa=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.zj("Unexpected Null passed to ceil",new $CLJS.k(null,1,[$CLJS.nb,"ceil"],null));a=$CLJS.$e(a);return 1<a?a:1};
Esa=function(a,b,c){var d=f6(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.Sx(d))/Math.log(10)));return $CLJS.Xf(function(f){f*=e;return f>=d?f:null},$CLJS.Iqa)};
$CLJS.j6=function(a,b,c){var d=$CLJS.s7(b);if($CLJS.m(d)){var e=$CLJS.EC.g(d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "num-bins":e=$CLJS.Lu(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BN,$CLJS.$k,$CLJS.Uj],null));if($CLJS.m(e)){a=$CLJS.Qf(e);e=$CLJS.M.h(a,$CLJS.fb);var f=$CLJS.M.h(a,$CLJS.mk);a=$CLJS.Qf(d);a=$CLJS.M.h(a,$CLJS.VB);a=Esa(e,f,a);return new $CLJS.k(null,3,[$CLJS.DC,a,$CLJS.AL,c,$CLJS.GL,c+a],null)}return null;case "bin-width":return a=$CLJS.Qf(d),a=$CLJS.M.h(a,$CLJS.DC),new $CLJS.k(null,
3,[$CLJS.DC,a,$CLJS.AL,c,$CLJS.GL,c+a],null);case "default":e=$CLJS.Lu(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BN,$CLJS.$k,$CLJS.Uj],null));if($CLJS.m(e)){d=$CLJS.Qf(e);e=$CLJS.M.h(d,$CLJS.fb);f=$CLJS.M.h(d,$CLJS.mk);a:switch(d=e,e=f,f=$CLJS.oa instanceof $CLJS.N?$CLJS.oa.S:null,f){case "num-bins":a=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VB,new $CLJS.k(null,2,[$CLJS.VB,null,$CLJS.DC,f6(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DC,new $CLJS.k(null,
2,[$CLJS.DC,null,$CLJS.VB,Dsa(d,e,null)],null)],null);break a;case "default":$CLJS.NZ(b)?(a=$CLJS.i6(a,$CLJS.Hqa),a=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DC,new $CLJS.k(null,2,[$CLJS.DC,a,$CLJS.VB,Dsa(d,e,a)],null)],null)):(a=$CLJS.i6(a,$CLJS.Gqa),a=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.VB,new $CLJS.k(null,2,[$CLJS.VB,a,$CLJS.DC,f6(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.g(f)].join(""));}if($CLJS.m(a))return $CLJS.J(a,0,null),a=$CLJS.J(a,1,null),
a=$CLJS.Qf(a),a=$CLJS.M.h(a,$CLJS.DC),new $CLJS.k(null,3,[$CLJS.DC,a,$CLJS.AL,c,$CLJS.GL,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}}else return null};Fsa=function(a){var b=$CLJS.Jx.g($CLJS.b1(a));b=b.g?b.g($CLJS.Asa):b.call(null,$CLJS.Asa);if($CLJS.m(b))a:for(b=a;;)if($CLJS.je($CLJS.W8.h(b,-1))&&$CLJS.je($CLJS.$8.h(b,-1))){if(b=$CLJS.Xm.j(b,$CLJS.aL,$CLJS.ie),!$CLJS.A($CLJS.aL.g(b))){b=null;break a}}else break a;else b=null;return $CLJS.m(b)?b:a};
Gsa=function(a,b){var c=Fsa(a);if($CLJS.F.h(a,c))return b;for(;;){if($CLJS.F.h(a,c))return $CLJS.TN(b,new $CLJS.k(null,2,[$CLJS.AW,$CLJS.Bsa,$CLJS.FW,$CLJS.Csa],null));var d=$CLJS.L_,e=d.j,f=a;var g=a;var l=$CLJS.o7(g,-1);g=$CLJS.m(l)?$CLJS.e3(g,l):null;d=e.call(d,f,-2,g);b=$CLJS.y1.D(a,-2,$CLJS.iW(b),d);if($CLJS.m(b))a=$CLJS.Xm.j(a,$CLJS.aL,$CLJS.ie);else return null}};
Hsa=function(a,b,c){var d=function(){var e=$CLJS.m($CLJS.s7(b))?function(){var f=$CLJS.b8(b,null);if(null!=c){var g=$CLJS.j6(a,b,c);if($CLJS.m(g)){var l=$CLJS.Qf(g);g=$CLJS.M.h(l,$CLJS.AL);l=$CLJS.M.h(l,$CLJS.GL);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.D4(f,g),$CLJS.C4(f,l)],null)}return null}return new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.q1($CLJS.XD,new $CLJS.S(null,1,5,$CLJS.T,[f],null))],null)}():null;if($CLJS.m(e))return e;e=function(){var f=$CLJS.Bsa.g(b);return $CLJS.m(f)?$CLJS.Z7(b,f):b}();
return new $CLJS.S(null,1,5,$CLJS.T,[(0,$CLJS.L2)(e,c)],null)}();return $CLJS.Sb(function(e,f){return $CLJS.L8.j(e,-1,f)},a,d)};
$CLJS.Isa=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.a5),d=$CLJS.M.h(b,$CLJS.BL);b=$CLJS.J7.l(a,-1,$CLJS.Km,$CLJS.G([$CLJS.nE,$CLJS.mE,$CLJS.GR,$CLJS.dS,$CLJS.JC]));b=$CLJS.Sb(function(f,g){var l=$CLJS.Qf(g);g=$CLJS.M.h(l,$CLJS.ob);l=$CLJS.M.h(l,$CLJS.vl);return Hsa(f,g,l)},b,function(){return function l(g){return new $CLJS.yf(null,function(){for(var n=g;;)if(n=$CLJS.A(n)){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);$CLJS.ki.h($CLJS.vK.g($CLJS.ob.g(y)),
$CLJS.UK)&&v.add(y);x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}v=$CLJS.C(n);if($CLJS.ki.h($CLJS.vK.g($CLJS.ob.g(v)),$CLJS.UK))return $CLJS.nf(v,l($CLJS.zd(n)));n=$CLJS.zd(n)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.$d(c);return $CLJS.m(f)?$CLJS.wW(function(g){return $CLJS.F.h($CLJS.w4(g),f)},$CLJS.W8.h(a,-1)):null}();return $CLJS.Sb(function(f,g){return $CLJS.L8.j(f,-1,g)},b,$CLJS.m(e)?function(){var f=$CLJS.C(e);switch(f instanceof
$CLJS.N?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.$d(e)],null);case "metric":return $CLJS.M8.h($CLJS.U.j($CLJS.r2.g((0,$CLJS.S1)($CLJS.X7($CLJS.g6(new $CLJS.k(null,2,[$CLJS.$k,$CLJS.SD,$CLJS.SD,$CLJS.g6($CLJS.yY.g($CLJS.N7(a,$CLJS.$d(e))))],null)),$CLJS.$k,$CLJS.SD))),$CLJS.MP,$CLJS.MP.g(a)),-1);default:return null}}():null)};$CLJS.I4.o(null,$CLJS.K5,function(a,b,c){b=$CLJS.Qf(c);a=$CLJS.M.h(b,$CLJS.F5);b=$CLJS.M.h(b,$CLJS.FY);return new $CLJS.k(null,3,[$CLJS.$k,$CLJS.K5,$CLJS.F5,a,$CLJS.FY,b],null)});
$CLJS.H4.o(null,$CLJS.K5,function(){function a(c,d,e,f){var g=null;if(3<arguments.length){g=0;for(var l=Array(arguments.length-3);g<l.length;)l[g]=arguments[g+3],++g;g=new $CLJS.z(l,0,null)}return b.call(this,c,d,e,g)}function b(c,d,e){return $CLJS.Isa(Fsa(c),$CLJS.Xm.j(e,$CLJS.BL,function(f){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){var u=q;if($CLJS.re(u)){var v=$CLJS.$c(u),x=$CLJS.E(v),y=$CLJS.Bf(x);return function(){for(var H=0;;)if(H<x){var I=$CLJS.be(v,
H);$CLJS.Ff(y,$CLJS.Xm.j(I,$CLJS.ob,function(){return function(Q){return Gsa(c,Q)}}(H,I,v,x,y,u,q)));H+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(y),n($CLJS.ad(u))):$CLJS.Ef($CLJS.Gf(y),null)}var B=$CLJS.C(u);return $CLJS.nf($CLJS.Xm.j(B,$CLJS.ob,function(){return function(H){return Gsa(c,H)}}(B,u,q)),n($CLJS.zd(u)))}return null}},null,null)}(f)}))}a.v=3;a.B=function(c){var d=$CLJS.C(c);c=$CLJS.D(c);var e=$CLJS.C(c);c=$CLJS.D(c);var f=$CLJS.C(c);c=$CLJS.zd(c);return b(d,e,f,c)};a.l=b;return a}());