var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var YE=function(){},bF=function(a){return $CLJS.M.h($CLJS.OD,a).value},Wea=function(a){var b=bF(a);return $CLJS.m(function(){var c=bF($CLJS.Ew);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.ix:$CLJS.m(function(){var c=bF($CLJS.Dw);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.sx:$CLJS.m(function(){var c=bF($CLJS.Bw);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.lx:$CLJS.m(function(){var c=bF($CLJS.vw);return $CLJS.Om.h?
$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.rx:$CLJS.m(function(){var c=bF($CLJS.Cw);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.tx:$CLJS.m(function(){var c=bF($CLJS.yw);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.ox:$CLJS.m(function(){var c=bF($CLJS.sw);return $CLJS.Om.h?$CLJS.Om.h(c,b):$CLJS.Om.call(null,c,b)}())?$CLJS.px:$CLJS.qx},ZE=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(b),$CLJS.p.g(" ")].join(""),
a],null)},aF=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.XE,c))].join(""),$CLJS.G(["color:black"]))],null)},$E=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.XE,$CLJS.ux)),";background-color:",$CLJS.p.g($CLJS.M.h($CLJS.XE,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},Xea=function(a){function b(c,d){return d>=c}a=bF(a);if(b(bF($CLJS.Ew),a))return"error";if(b(bF($CLJS.Dw),a))return"warn";if(b(bF($CLJS.Bw),a))return"info";b(bF($CLJS.vw),a);return"log"},cF=function(a){return function(b){var c=$CLJS.Qf(b),d=$CLJS.M.h(c,$CLJS.tw),e=$CLJS.M.h(c,$CLJS.ot);b=$CLJS.M.h(c,$CLJS.yk);e=Xea(e);e=$CLJS.Ua(console,e);e=$CLJS.m(e)?e:console.log;$CLJS.R.h(e,a.g?a.g(c):a.call(null,c));return $CLJS.m(b)?(c=
["[",$CLJS.p.g(d),"]"].join(""),d=$CLJS.p.g(b),b=b.stack,e.D?e.D(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},dF=function dF(a,b){for(;;){if($CLJS.F.h($CLJS.hx,b))return aF(a,", ",$CLJS.qx);if($CLJS.F.h($CLJS.jx,b))return ZE(a);if(b instanceof $CLJS.N)return aF(a,b,$CLJS.lx);if(b instanceof $CLJS.w)return aF(a,b,$CLJS.rx);if("string"===typeof b)return aF(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.kx);if($CLJS.Ig(b)){var d=ZE(function(){var f=a,g=$CLJS.tc(b);return dF.h?dF.h(f,g):dF.call(null,f,g)}()),e=$CLJS.uc(b);
return dF.h?dF.h(d,e):dF.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Rh)return d=a,d=aF(d,"{",$CLJS.vx),d=$CLJS.Sb(dF,d,$CLJS.pg($CLJS.hx,b)),aF(d,"}",$CLJS.vx);if($CLJS.oe(b))return d=a,d=aF(d,["#",$CLJS.p.g(function(){var f=$CLJS.Ob(b),g=f.name;return $CLJS.je(g)?$CLJS.aj.l($CLJS.G([f])):g}())," "].join(""),$CLJS.mx),d=aF(d,"{",$CLJS.vx),d=$CLJS.Sb(dF,d,$CLJS.pg($CLJS.hx,b)),aF(d,"}",$CLJS.vx);if($CLJS.le(b))return d=a,d=aF(d,"#{",$CLJS.vx),d=$CLJS.Sb(dF,d,$CLJS.pg($CLJS.jx,b)),aF(d,
"}",$CLJS.vx);if($CLJS.qe(b))return d=a,d=aF(d,"[",$CLJS.vx),d=$CLJS.Sb(dF,d,$CLJS.pg($CLJS.jx,b)),aF(d,"]",$CLJS.vx);if(b instanceof $CLJS.ah)d=aF(a,"#queue ",$CLJS.mx),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else{if($CLJS.we(b))return d=a,d=aF(d,"(",$CLJS.mx),d=$CLJS.Sb(dF,d,$CLJS.pg($CLJS.jx,b)),aF(d,")",$CLJS.mx);if(null!=b?b.K&16384||$CLJS.t===b.Rj||(b.K?0:$CLJS.Nb(YE,b)):$CLJS.Nb(YE,b))d=aF(a,"#atom ",$CLJS.mx),e=$CLJS.r(b),a=d,b=e;else if($CLJS.wj(b))d=aF(a,"#uuid ",$CLJS.mx),e=$CLJS.p.g(b),a=d,b=
e;else if($CLJS.Ib(b))d=aF(a,"#js ",$CLJS.mx),e=$CLJS.Sb(function(f,g){return function(l,n){return $CLJS.U.j(l,$CLJS.Oi.g(n),$CLJS.Ua(g,n))}}(a,b),$CLJS.P,Object.keys(b)),a=d,b=e;else if($CLJS.Db(b))d=aF(a,"#js ",$CLJS.mx),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else return aF(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.nx)}}};$CLJS.Yea=cF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.ot);var b=$CLJS.M.h(a,$CLJS.tw),c=$CLJS.M.h(a,$CLJS.mb);$CLJS.M.h(a,$CLJS.yk);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),c],null)});
$CLJS.Zea=cF(function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ot);a=$CLJS.M.h(b,$CLJS.tw);var d=$CLJS.M.h(b,$CLJS.mb);$CLJS.M.h(b,$CLJS.yk);b=Wea(c);d=dF(ZE($E($E($E(new $CLJS.S(null,2,5,$CLJS.T,["",$CLJS.Lg],null),"[",b),a,b),"]",b)),d);a=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.nf(a,d)});
$CLJS.$ea=cF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.ot);var b=$CLJS.M.h(a,$CLJS.tw),c=$CLJS.M.h(a,$CLJS.mb);$CLJS.M.h(a,$CLJS.yk);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),$CLJS.aj.l($CLJS.G([c]))],null)});