var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var yJ;$CLJS.wJ=function(a){return $CLJS.m($CLJS.Ky($CLJS.ne,$CLJS.le,$CLJS.Cb)(a))?a:new $CLJS.S(null,1,5,$CLJS.T,[a],null)};
$CLJS.xJ=function(a,b){return $CLJS.Xf($CLJS.ye,function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l);if(g=$CLJS.A(function(q,u,v,x){return function H(B){return new $CLJS.yf(null,function(I,Q,Y,aa){return function(){for(var ha=B;;){var qa=$CLJS.A(ha);if(qa){var Ea=qa,kb=$CLJS.C(Ea);if(qa=$CLJS.A(function(lb,Fb,Mb,ac,Tb,Ub,ge,aA){return function Np(Og){return new $CLJS.yf(null,function(IB,Es,Zt,Wv,XY,by){return function(){for(;;){var cy=
$CLJS.A(Og);if(cy){if($CLJS.re(cy)){var dy=$CLJS.$c(cy),NB=$CLJS.E(dy),Zr=$CLJS.Bf(NB);a:for(var Xv=0;;)if(Xv<NB){var Pw=$CLJS.be(dy,Xv);Pw=$CLJS.F.h(by,$CLJS.TC)||$CLJS.F.h(Zt,$CLJS.TC)||$CLJS.rD(by,Pw)&&$CLJS.rD(Zt,Pw);Zr.add(Pw);Xv+=1}else{dy=!0;break a}return dy?$CLJS.Ef($CLJS.Gf(Zr),Np($CLJS.ad(cy))):$CLJS.Ef($CLJS.Gf(Zr),null)}Zr=$CLJS.C(cy);return $CLJS.nf($CLJS.F.h(by,$CLJS.TC)||$CLJS.F.h(Zt,$CLJS.TC)||$CLJS.rD(by,Zr)&&$CLJS.rD(Zt,Zr),Np($CLJS.zd(cy)))}return null}}}(lb,Fb,Mb,ac,Tb,Ub,ge,
aA),null,null)}}(ha,I,kb,Ea,qa,Q,Y,aa)($CLJS.WL)))return $CLJS.qg.h(qa,H($CLJS.zd(ha)));ha=$CLJS.zd(ha)}else return null}}}(q,u,v,x),null,null)}}(f,n,l,g)($CLJS.wJ($CLJS.FL(b)))))return $CLJS.qg.h(g,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null}},null,null)}($CLJS.wJ($CLJS.FL(a)))}())};
yJ=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var g=Array(arguments.length-1);f<g.length;)g[f]=arguments[f+1],++f;f=new $CLJS.z(g,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,$CLJS.R.j($CLJS.zV,d,e),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"arguments should be comparable"],null),function(f){f=$CLJS.A(f);$CLJS.C(f);f=$CLJS.D(f);$CLJS.C(f);f=$CLJS.D(f);var g=$CLJS.Mg(f);f=$CLJS.HC;
return $CLJS.m(f)?f:$CLJS.Wf($CLJS.ue,$CLJS.rg.h(function(l){var n=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);return $CLJS.xJ($CLJS.M.h(g,n),$CLJS.M.h(g,l))},a))}],null)],null)}b.v=1;b.B=function(d){var e=$CLJS.C(d);d=$CLJS.zd(d);return c(e,d)};b.l=c;return b}()};$CLJS.zJ=new $CLJS.N("operator","filter","operator/filter",-1518842858);$CLJS.AJ=new $CLJS.N(null,"display-name-variant","display-name-variant",-1831788853);for(var BJ=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.As,$CLJS.qs],null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var jha=CJ.$(null,EJ);$CLJS.CV(jha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)],null)],null)],null)]));EJ+=1}else{var FJ=$CLJS.A(BJ);if(FJ){var GJ=FJ;if($CLJS.re(GJ)){var HJ=$CLJS.$c(GJ),kha=$CLJS.ad(GJ),
lha=HJ,mha=$CLJS.E(HJ);BJ=kha;CJ=lha;DJ=mha}else{var nha=$CLJS.C(GJ);$CLJS.CV(nha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)],null)],null)],null)]));BJ=$CLJS.D(GJ);CJ=null;DJ=0}EJ=0}else break}$CLJS.AV($CLJS.ss,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)]));
for(var IJ=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.PE],null)),JJ=null,KJ=0,LJ=0;;)if(LJ<KJ){var oha=JJ.$(null,LJ);$CLJS.CV(oha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.aD],null)],null)],null)],null)]));LJ+=1}else{var MJ=$CLJS.A(IJ);if(MJ){var NJ=MJ;if($CLJS.re(NJ)){var OJ=$CLJS.$c(NJ),pha=$CLJS.ad(NJ),
qha=OJ,rha=$CLJS.E(OJ);IJ=pha;JJ=qha;KJ=rha}else{var sha=$CLJS.C(NJ);$CLJS.CV(sha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.aD],null)],null)],null)],null)]));IJ=$CLJS.D(NJ);JJ=null;KJ=0}LJ=0}else break}
for(var PJ=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Wr,$CLJS.Xr,$CLJS.Ur,$CLJS.Vr],null)),QJ=null,RJ=0,SJ=0;;)if(SJ<RJ){var tha=QJ.$(null,SJ);$CLJS.yV.l(yJ($CLJS.pi([new $CLJS.S(null,2,5,$CLJS.T,[0,1],null)])),tha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)]));SJ+=1}else{var TJ=$CLJS.A(PJ);if(TJ){var UJ=TJ;if($CLJS.re(UJ)){var VJ=$CLJS.$c(UJ),uha=$CLJS.ad(UJ),vha=VJ,wha=$CLJS.E(VJ);PJ=uha;QJ=vha;RJ=wha}else{var xha=
$CLJS.C(UJ);$CLJS.yV.l(yJ($CLJS.pi([new $CLJS.S(null,2,5,$CLJS.T,[0,1],null)])),xha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)]));PJ=$CLJS.D(UJ);QJ=null;RJ=0}SJ=0}else break}
$CLJS.yV.l(yJ($CLJS.pi([new $CLJS.S(null,2,5,$CLJS.T,[0,2],null),new $CLJS.S(null,2,5,$CLJS.T,[0,1],null)])),$CLJS.YD,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)]));
$CLJS.yV.l(yJ($CLJS.pi([new $CLJS.S(null,2,5,$CLJS.T,[1,3],null),new $CLJS.S(null,2,5,$CLJS.T,[1,5],null),new $CLJS.S(null,2,5,$CLJS.T,[0,2],null),new $CLJS.S(null,2,5,$CLJS.T,[0,4],null)])),$CLJS.dE,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)]));
for(var WJ=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XD,$CLJS.jE],null)),XJ=null,YJ=0,ZJ=0;;)if(ZJ<YJ){var yha=XJ.$(null,ZJ);$CLJS.AV(yha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)]));ZJ+=1}else{var $J=$CLJS.A(WJ);if($J){var aK=$J;if($CLJS.re(aK)){var bK=$CLJS.$c(aK),zha=$CLJS.ad(aK),Aha=bK,Bha=$CLJS.E(bK);WJ=zha;XJ=Aha;YJ=Bha}else{var Cha=$CLJS.C(aK);$CLJS.AV(Cha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)]));WJ=$CLJS.D(aK);
XJ=null;YJ=0}ZJ=0}else break}
for(var cK=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZD,$CLJS.gE],null)),dK=null,eK=0,fK=0;;)if(fK<eK){var Dha=dK.$(null,fK);$CLJS.AV(Dha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)]));fK+=1}else{var gK=$CLJS.A(cK);if(gK){var hK=gK;if($CLJS.re(hK)){var iK=$CLJS.$c(hK),Eha=$CLJS.ad(hK),Fha=iK,Gha=$CLJS.E(iK);cK=Eha;dK=Fha;eK=Gha}else{var Hha=$CLJS.C(hK);$CLJS.AV(Hha,$CLJS.G([$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)]));cK=$CLJS.D(hK);
dK=null;eK=0}fK=0}else break}
for(var TY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null),jK=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.pE,$CLJS.JE,$CLJS.xE,$CLJS.qE],null)),kK=null,lK=0,mK=0;;)if(mK<lK){var nK=kK.$(null,mK);$CLJS.lF.D(nK,$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,nK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,$CLJS.wC,TY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,
$CLJS.mD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.mD],null)],null));mK+=1}else{var oK=$CLJS.A(jK);if(oK){var pK=oK;if($CLJS.re(pK)){var qK=$CLJS.$c(pK),Iha=$CLJS.ad(pK),Jha=qK,Kha=$CLJS.E(qK);jK=Iha;kK=Jha;lK=Kha}else{var rK=$CLJS.C(pK);$CLJS.lF.D(rK,$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,rK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,$CLJS.wC,TY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.mD],null),new $CLJS.S(null,2,5,$CLJS.T,
[$CLJS.W,$CLJS.mD],null)],null));jK=$CLJS.D(pK);kK=null;lK=0}mK=0}else break}
$CLJS.lF.D($CLJS.Ey,$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.Ey],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.st,$CLJS.wC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.al,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.ev,$CLJS.kw,$CLJS.cE],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.W,$CLJS.pD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.JF],null)],null));$CLJS.lF.D($CLJS.UD,$CLJS.ft,$CLJS.Sj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.UD],null),$CLJS.wC,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,$CLJS.UB,$CLJS.oC],null)],null));
$CLJS.lL($CLJS.ME,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.zJ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rE,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.ok,$CLJS.$r,$CLJS.PE,$CLJS.dE,$CLJS.YD,$CLJS.Wr,$CLJS.Ur,$CLJS.Xr,$CLJS.Vr,$CLJS.XD,$CLJS.jE,$CLJS.ZD,$CLJS.gE,$CLJS.xE,$CLJS.qE,$CLJS.pE,$CLJS.JE],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AJ,$CLJS.Sf],null)],null));