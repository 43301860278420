var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Roa,apa,bpa,dpa,Q2,epa,fpa,gpa,hpa,ipa,Wta,Xta,Yta,Zta,$ta,aua,bua,C8,mpa,opa,qra,ppa,lpa,kpa,qpa,S2;$CLJS.Lta=function(a,b){$CLJS.Sb(function(c,d){return a.g?a.g(d):a.call(null,d)},null,b)};
Roa=function(a){return function(b){var c=$CLJS.ig(!0);return function(){function d(l,n){var q=$CLJS.r(c);if($CLJS.m($CLJS.m(q)?a.g?a.g(n):a.call(null,n):q))return l;$CLJS.En(c,null);return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);};g.A=
f;g.g=e;g.h=d;return g}()}};apa=function(a,b){return function f(d,e){return new $CLJS.yf(null,function(){var g;a:{var l=d;for(g=e;;){var n=l;l=$CLJS.J(n,0,null);if(n=$CLJS.A(n)){var q=a.g?a.g(l):a.call(null,l);if($CLJS.Ie(g,q))l=$CLJS.zd(n);else{g=$CLJS.nf(l,f($CLJS.zd(n),$CLJS.kf.h(g,q)));break a}}else{g=null;break a}}}return g},null,null)}(b,$CLJS.oi)};
bpa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.z(b.slice(3),0,null):null;return $CLJS.Gb($CLJS.A($CLJS.Lu(c,d)))?c:$CLJS.R.R($CLJS.L0,c,d,e,b)};$CLJS.cpa=function(a){return(0,$CLJS.Ca)($CLJS.Pu(a,$CLJS.n0,""))};$CLJS.Z7=function(a,b){return $CLJS.r4.h(a,b instanceof $CLJS.N?b:$CLJS.Fk.g(b))};
$CLJS.M2=function(a,b,c,d){if($CLJS.A(c)){d=$CLJS.Wm.h($CLJS.iW,d);var e=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g(function(f){return $CLJS.y1.D(a,b,f,c)}),d);return $CLJS.Wm.h(function(f){return $CLJS.U.j(f,$CLJS.EY,$CLJS.Ie(e,f))},c)}return null};$CLJS.P2=function(a){return $CLJS.F.h($CLJS.EH(a),$CLJS.WK)};dpa=function(a,b){return $CLJS.m($CLJS.JC.g(a))?$CLJS.Xm.j(a,$CLJS.JC,function(c){return $CLJS.ne(c)?$CLJS.Wm.h(function(d){return $CLJS.G5.h?$CLJS.G5.h(d,b):$CLJS.G5.call(null,d,b)},c):c}):a};
Q2=function(a){if($CLJS.m(a)){var b=$CLJS.ug;a:try{if($CLJS.qe(a)&&4===$CLJS.E(a))try{if($CLJS.Td(a,0)instanceof $CLJS.N)try{var c=$CLJS.Td(a,2);if($CLJS.qe(c)&&3===$CLJS.E(c))try{var d=$CLJS.Td(c,0);if($CLJS.O(d,$CLJS.yE))try{var e=$CLJS.Td(a,3);if($CLJS.qe(e)&&3===$CLJS.E(e))try{var f=$CLJS.Td(e,0);if($CLJS.O(f,$CLJS.yE)){$CLJS.Td(e,1);$CLJS.Td(e,2);$CLJS.Td(c,1);$CLJS.Td(c,2);$CLJS.Td(a,0);$CLJS.Td(a,1);var g=new $CLJS.S(null,1,5,$CLJS.T,[!0],null)}else throw $CLJS.Z;}catch(n){if(n instanceof Error){var l=
n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof
Error){l=n;if(l===$CLJS.Z){g=new $CLJS.S(null,1,5,$CLJS.T,[!1],null);break a}throw l;}throw n;}a=$CLJS.C($CLJS.A(b($CLJS.Hb,g)))}else a=null;return a};epa=function(a){if($CLJS.m(Q2(a))){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);$CLJS.J(a,3,null);return b}return null};fpa=function(a){return $CLJS.m(Q2(a))?($CLJS.J(a,0,null),$CLJS.J(a,1,null),$CLJS.J(a,2,null),$CLJS.J(a,3,null)):null};
gpa=function(a,b){var c=$CLJS.G5;if($CLJS.Gb(Q2(a)))return a;var d=$CLJS.J(a,0,null),e=$CLJS.J(a,1,null),f=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[d,e,f,$CLJS.R.j(c,a,b)],null)};
hpa=function(a,b,c){return $CLJS.je($CLJS.dL.g(a))?a:$CLJS.m(b)?bpa(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.dL],null),function(d){return function l(f,g){try{if($CLJS.qe(g)&&3===$CLJS.E(g))try{var n=$CLJS.Td(g,0);if($CLJS.O(n,$CLJS.yE))try{var q=$CLJS.Td(g,1);if(null!=q?q.C&256||$CLJS.t===q.bg||(q.C?0:$CLJS.Nb($CLJS.lc,q)):$CLJS.Nb($CLJS.lc,q))try{var u=$CLJS.M.j(q,$CLJS.lP,$CLJS.mV);if($CLJS.F.h(u,b))return $CLJS.Td(g,2),$CLJS.G5.h?$CLJS.G5.h(g,c):$CLJS.G5.call(null,g,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.e_(l,f,g);throw v;}throw x;}}($CLJS.Lg,d)}):$CLJS.Xm.j(a,$CLJS.dL,function(d){return $CLJS.Wm.h(function(e){return gpa(e,$CLJS.G([c]))},d)})};
$CLJS.G5=function(a,b){var c=$CLJS.EH(a);switch(c instanceof $CLJS.N?c.S:null){case "field":return $CLJS.fW(a,$CLJS.eW,$CLJS.G([$CLJS.lP,b]));case "metadata/column":return $CLJS.eW(a,$CLJS.ZY,b);case "mbql/join":return c=$CLJS.i1(a),hpa(dpa($CLJS.eW(a,$CLJS.OC,b),b),c,b);default:return b=$CLJS.ZF($CLJS.xw),$CLJS.m($CLJS.fG("metabase.lib.join",b))&&$CLJS.gG("metabase.lib.join",b,$CLJS.WB.l($CLJS.G(["with-join-value should not be called with",$CLJS.aj.l($CLJS.G([a]))])),null),a}};
$CLJS.I5=function(a,b,c){var d=$CLJS.U.l,e=$CLJS.Tx($CLJS.oY,$CLJS.V)(c);a=$CLJS.j1($CLJS.OC.g(a),$CLJS.Tx($CLJS.oY,$CLJS.V)(c));b=b.g?b.g(a):b.call(null,a);return d.call($CLJS.U,c,$CLJS.oY,e,$CLJS.G([$CLJS.DY,b]))};ipa=function(a,b){return $CLJS.Gb(b)?a:$CLJS.Wm.h(function(c){var d=fpa(c);d=$CLJS.m(d)?$CLJS.m($CLJS.i1(d))?null:gpa(c,$CLJS.G([b])):null;return $CLJS.m(d)?d:c},a)};$CLJS.q8=function(a,b){b=ipa($CLJS.Wm.h($CLJS.lW,b),$CLJS.i1(a));return $CLJS.eW(a,$CLJS.dL,$CLJS.Rf(b))};
$CLJS.r8=function(a,b){b=b instanceof $CLJS.N?b:$CLJS.F.h(b,$CLJS.Lg)?$CLJS.Ix:$CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Sm.h($CLJS.rg.g($CLJS.iW),function(){var c=$CLJS.i1(a);return $CLJS.m(c)?$CLJS.rg.g(function(d){return $CLJS.G5(d,c)}):$CLJS.Ye}()),b));return $CLJS.eW(a,$CLJS.JC,b)};
Wta=function(a,b){b=$CLJS.eg(function(d){return $CLJS.y1.h(d,a)},b);var c=$CLJS.wW($CLJS.Tx($CLJS.T4,$CLJS.U4),b);if($CLJS.m(c))return c;b=$CLJS.C(b);if($CLJS.m(b))return b;b=$CLJS.wW($CLJS.T4,a);if($CLJS.m(b))return b;b=$CLJS.wW($CLJS.U4,a);return $CLJS.m(b)?b:$CLJS.C(a)};Xta=function(a){return"string"===typeof a?(0,$CLJS.Ca)($CLJS.Pu(a,/ id$/i,"")):null};
Yta=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Yi(function(){return $CLJS.v8(b)});var d=new $CLJS.Yi(function(){return $CLJS.v8(a)});return $CLJS.F.h(a,b)||$CLJS.F.h(a,$CLJS.r(c))||$CLJS.F.h($CLJS.r(d),b)||$CLJS.F.h($CLJS.r(d),$CLJS.r(c))}return c}return c};
Zta=function(a,b,c){var d=$CLJS.n3.h($CLJS.F.h($CLJS.cu.g(b),$CLJS.oW)?b:a,b),e=$CLJS.m(c)?Xta($CLJS.n3.h(a,c)):null,f=Yta(d,e);return function(){var g=function(){if($CLJS.m(d)){if($CLJS.m(e)){var l=$CLJS.Gb($CLJS.Di(/id/i,e));return l?(l=!f)?[$CLJS.p.g(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.m(g)?g:$CLJS.m(d)?d:$CLJS.m(e)?e:"source"}()};
$ta=function(a,b,c,d,e){return function n(g,l){try{if($CLJS.r1(l,$CLJS.yE)&&$CLJS.ye($CLJS.y1.D(a,b,l,e)))return $CLJS.G5(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var q=u;if(q===$CLJS.Z)return $CLJS.e_(n,g,l);throw q;}throw u;}}($CLJS.Lg,c)};
aua=function(a,b,c,d,e,f){c=$ta(a,b,c,d,f);return function q(l,n){try{if($CLJS.qe(n)&&4===$CLJS.E(n))try{var u=$CLJS.Td(n,2);if($CLJS.r1(u,$CLJS.yE))try{var v=$CLJS.Td(n,3);if($CLJS.r1(v,$CLJS.yE)){var x=$CLJS.Td(n,3),y=$CLJS.Td(n,2),B=$CLJS.Td(n,0),H=$CLJS.Td(n,1),I=$CLJS.i1(y),Q=$CLJS.i1(x);if($CLJS.Gb($CLJS.m(I)?I:Q))return $CLJS.m($CLJS.y1.D(a,b,x,e))?new $CLJS.S(null,4,5,$CLJS.T,[B,H,$CLJS.G5(y,d),x],null):new $CLJS.S(null,4,5,$CLJS.T,[B,H,y,$CLJS.G5(x,d)],null);if($CLJS.F.h(I,d)&&$CLJS.F.h(Q,
d)){var Y=$CLJS.fW(y,$CLJS.Km,$CLJS.G([$CLJS.lP])),aa=$CLJS.fW(x,$CLJS.Km,$CLJS.G([$CLJS.lP])),ha=null==$CLJS.y1.D(a,b,Y,e);var qa=ha?$CLJS.y1.D(a,b,aa,e):ha;return $CLJS.m(qa)?new $CLJS.S(null,4,5,$CLJS.T,[B,H,y,aa],null):new $CLJS.S(null,4,5,$CLJS.T,[B,H,Y,x],null)}return n}throw $CLJS.Z;}catch(kb){if(kb instanceof Error){var Ea=kb;if(Ea===$CLJS.Z)throw $CLJS.Z;throw Ea;}throw kb;}else throw $CLJS.Z;}catch(kb){if(kb instanceof Error){Ea=kb;if(Ea===$CLJS.Z)throw $CLJS.Z;throw Ea;}throw kb;}else throw $CLJS.Z;
}catch(kb){if(kb instanceof Error){Ea=kb;if(Ea===$CLJS.Z)return $CLJS.e_(q,l,n);throw Ea;}throw kb;}}($CLJS.Lg,c)};bua=function(a,b){var c=$CLJS.a2();$CLJS.Lta(c,b);return c(a)};
$CLJS.cua=function(a,b,c){if($CLJS.Ie(c,$CLJS.OC))return c;var d=$CLJS.e3(a,b),e=$CLJS.N_.j(a,b,d),f=$CLJS.A($CLJS.ug($CLJS.Hb,function v(q,u){try{if($CLJS.qe(u)&&1<=$CLJS.E(u))try{var x=$CLJS.bn.j(u,0,1);if($CLJS.qe(x)&&1===$CLJS.E(x))try{var y=$CLJS.Td(x,0);if($CLJS.O(y,$CLJS.yE))return new $CLJS.S(null,1,5,$CLJS.T,[u],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;
throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.tZ(v,q,u);throw B;}throw H;}}($CLJS.Lg,$CLJS.dL.g(c))));f=Wta(e,f);var g=bua(Zta(a,c,f),$CLJS.eg($CLJS.OC,$CLJS.mN.g(d))),l=$CLJS.L_.g($CLJS.f1.h(a,$CLJS.aL.g(c)));return $CLJS.G5($CLJS.Xm.j(c,$CLJS.dL,function(q){return $CLJS.Wm.h(function(u){return aua(a,b,u,g,e,l)},q)}),g)};$CLJS.dua=function(a){return $CLJS.dL.g(a)};
$CLJS.jpa=function(a){return $CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.cu,$CLJS.fL,$CLJS.EC,a],null),$CLJS.F.h(a,$CLJS.$C)?new $CLJS.k(null,1,[$CLJS.oa,!0],null):null]))};$CLJS.R2=function(a,b){b=$CLJS.C($CLJS.aL.g(b));return $CLJS.m($CLJS.xV.g(b))?$CLJS.k3(a,$CLJS.xV.g(b)):$CLJS.m($CLJS.XO.g(b))?$CLJS.j3(a,$CLJS.XO.g(b)):null};
C8=function(a){a=$CLJS.fj(function(d){return $CLJS.U4(d)?kpa:$CLJS.T4(d)?lpa:$CLJS.au},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,kpa);var c=$CLJS.M.h(b,lpa);b=$CLJS.M.h(b,$CLJS.au);return $CLJS.qg.l(a,c,$CLJS.G([b]))};mpa=function(a,b,c,d){return $CLJS.Gb(c)?d:$CLJS.Wm.h(function(e){return $CLJS.m($CLJS.EY.g(e))?$CLJS.Z7(e,$CLJS.$7(c)):e},$CLJS.M2(a,b,d,new $CLJS.S(null,1,5,$CLJS.T,[c],null)))};
opa=function(a,b,c,d){var e=new $CLJS.Yi(function(){return $CLJS.N_.D(a,b,d,new $CLJS.k(null,2,[$CLJS.z_,!1,$CLJS.H_,!1],null))});return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Fn(function(f){var g=$CLJS.Qf(f),l=$CLJS.M.h(g,$CLJS.HK);return $CLJS.m(function(){var n=$CLJS.T4(g);return n?l:n}())?(f=$CLJS.wW(function(n){return $CLJS.F.h(l,$CLJS.Zf.g(n))},$CLJS.r(e)),$CLJS.m(f)?$CLJS.U.j(g,S2,f):null):null}),$CLJS.N_.D(a,b,c,new $CLJS.k(null,2,[$CLJS.z_,!1,$CLJS.H_,!1],null))))};qra={};
ppa=new $CLJS.N("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);lpa=new $CLJS.N(null,"fk","fk",398442651);kpa=new $CLJS.N(null,"pk","pk",-771936732);qpa=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);S2=new $CLJS.N("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.K3.o(null,$CLJS.WK,function(a,b,c){b=$CLJS.Qf(c);b=$CLJS.M.h(b,$CLJS.aL);b=$CLJS.J(b,0,null);b=$CLJS.Qf(b);c=$CLJS.M.h(b,$CLJS.XO);b=$CLJS.M.h(b,$CLJS.xV);c=$CLJS.m(c)?$CLJS.eC.g($CLJS.j3(a,c)):null;if($CLJS.m(c))return c;$CLJS.m(b)?(c=$CLJS.k3(a,b),a=$CLJS.m(c)?$CLJS.n3.j(a,0,c):$CLJS.m1(b)):a=null;return $CLJS.m(a)?a:$CLJS.XH("Native Query")});$CLJS.Q3.o(null,$CLJS.WK,function(a,b,c){a=$CLJS.n3.j(a,b,c);c=$CLJS.OC.g(c);return new $CLJS.k(null,2,[$CLJS.V,$CLJS.m(c)?c:a,$CLJS.eC,a],null)});
$CLJS.l3.o(null,$CLJS.WK,function(){throw $CLJS.zj("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.P);});
$CLJS.K3.o(null,$CLJS.fL,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.EC);a=a instanceof $CLJS.N?a.S:null;switch(a){case "left-join":return $CLJS.XH("Left outer join");case "right-join":return $CLJS.XH("Right outer join");case "inner-join":return $CLJS.XH("Inner join");case "full-join":return $CLJS.XH("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});
$CLJS.Q3.o(null,$CLJS.fL,function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.EC);c=$CLJS.M.h(d,$CLJS.oa);a=new $CLJS.k(null,2,[$CLJS.I_,$CLJS.kG(e),$CLJS.eC,$CLJS.n3.j(a,b,d)],null);return $CLJS.m(c)?$CLJS.U.j(a,$CLJS.oa,!0):a});
$CLJS.K_.o(null,$CLJS.WK,function(a,b,c,d){var e=$CLJS.Qf(c),f=$CLJS.M.h(e,$CLJS.OC),g=$CLJS.M.j(e,$CLJS.JC,$CLJS.Ix),l=$CLJS.M.h(e,$CLJS.aL);c=$CLJS.Qf(d);var n=$CLJS.M.h(c,$CLJS.F_);if($CLJS.F.h(g,$CLJS.Ix))return null;var q="undefined"!==typeof $CLJS.mO&&"undefined"!==typeof qra&&"undefined"!==typeof $CLJS.sra&&"undefined"!==typeof $CLJS.M5?new $CLJS.ud(function(){return $CLJS.M5},$CLJS.K(qpa,new $CLJS.k(null,1,[$CLJS.bk,!0],null)),null):null,u=function(){var v=$CLJS.U.j(a,$CLJS.aL,l);return $CLJS.m(q)?
q.h?q.h(v,-1):q.call(null,v,-1):v}();c=$CLJS.F.h(g,$CLJS.rw)?$CLJS.L_.D(u,-1,$CLJS.he(l),c):function(){return function y(x){return new $CLJS.yf(null,function(){for(;;){var B=$CLJS.A(x);if(B){if($CLJS.re(B)){var H=$CLJS.$c(B),I=$CLJS.E(H),Q=$CLJS.Bf(I);a:for(var Y=0;;)if(Y<I){var aa=$CLJS.be(H,Y);aa=$CLJS.fW(aa,$CLJS.Km,$CLJS.G([$CLJS.lP]));aa=$CLJS.b_.j(u,-1,aa);Q.add(aa);Y+=1}else{H=!0;break a}return H?$CLJS.Ef($CLJS.Gf(Q),y($CLJS.ad(B))):$CLJS.Ef($CLJS.Gf(Q),null)}Q=$CLJS.C(B);Q=$CLJS.fW(Q,$CLJS.Km,
$CLJS.G([$CLJS.lP]));return $CLJS.nf($CLJS.b_.j(u,-1,Q),y($CLJS.zd(B)))}return null}},null,null)}(g)}();return $CLJS.Wm.h(function(v){v=$CLJS.U.j(v,$CLJS.RY,f);v=$CLJS.G5($CLJS.U.l(v,$CLJS.eC,$CLJS.n3.j(a,b,v),$CLJS.G([$CLJS.vK,$CLJS.AY])),f);return $CLJS.I5(e,n,v)},c)});$CLJS.R3.o(null,$CLJS.WK,function(a,b,c,d){return $CLJS.L_.D(a,b,$CLJS.U.j(c,$CLJS.JC,$CLJS.rw),d)});
var N5,rpa=$CLJS.na($CLJS.P),spa=$CLJS.na($CLJS.P),tpa=$CLJS.na($CLJS.P),upa=$CLJS.na($CLJS.P),vpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));N5=new $CLJS.vj($CLJS.Ui.h("metabase.lib.join","join-clause-method"),$CLJS.EH,vpa,rpa,spa,tpa,upa);N5.o(null,$CLJS.WK,function(a){return a});N5.o(null,$CLJS.oW,function(a){return $CLJS.hW(new $CLJS.k(null,2,[$CLJS.cu,$CLJS.WK,$CLJS.aL,$CLJS.aL.g($CLJS.d0(a))],null))});
N5.o(null,$CLJS.IV,function(a){return $CLJS.hW(new $CLJS.k(null,2,[$CLJS.cu,$CLJS.WK,$CLJS.aL,new $CLJS.S(null,1,5,$CLJS.T,[a],null)],null))});N5.o(null,$CLJS.SK,function(a){return $CLJS.hW(new $CLJS.k(null,2,[$CLJS.cu,$CLJS.WK,$CLJS.aL,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.xV,$CLJS.Zf.g(a),$CLJS.cu,$CLJS.IV],null)],null)],null))});
N5.o(null,$CLJS.BK,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ZY);a=$CLJS.M.h(b,ppa);b=N5.g(new $CLJS.k(null,3,[$CLJS.cu,$CLJS.IV,$CLJS.jy,new $CLJS.k(null,1,[$CLJS.yC,$CLJS.p.g($CLJS.rL())],null),$CLJS.XO,$CLJS.Zf.g(b)],null));c=$CLJS.m(c)?$CLJS.G5(b,c):b;return $CLJS.m(a)?$CLJS.r8.h?$CLJS.r8.h(c,a):$CLJS.r8.call(null,c,a):c});
$CLJS.s8=function(){function a(d,e){return $CLJS.q8(c.g?c.g(d):c.call(null,d),e)}function b(d){return $CLJS.X7(N5.g(d),$CLJS.JC,$CLJS.rw)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.P8=function(){function a(d,e,f){var g=$CLJS.s8.g(f);f=$CLJS.je($CLJS.dua.g?$CLJS.dua.g(g):$CLJS.dua.call(null,g))?function(){var n=$CLJS.R2.h?$CLJS.R2.h(d,g):$CLJS.R2.call(null,d,g);return $CLJS.T2.j?$CLJS.T2.j(d,e,n):$CLJS.T2.call(null,d,e,n)}():null;f=$CLJS.A(f)?$CLJS.q8(g,f):g;var l=$CLJS.cua(d,e,f);return $CLJS.J7.l(d,e,$CLJS.Xm,$CLJS.G([$CLJS.mN,function(n){return $CLJS.kf.h($CLJS.Mg(n),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.E8=function(){function a(d,e){return $CLJS.Rf($CLJS.M.h($CLJS.e3(d,e),$CLJS.mN))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.Q8=function(){function a(d){d=$CLJS.b1(d);d=$CLJS.Jx.g(d);return $CLJS.oh.j($CLJS.Lg,$CLJS.Sm.h($CLJS.tg($CLJS.bg($CLJS.Ie,d)),$CLJS.rg.g($CLJS.jpa)),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.$C,$CLJS.IC,$CLJS.kD,$CLJS.nD],null))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.R8=function(){function a(d,e,f,g){var l=$CLJS.P2(f)?$CLJS.i1(f):null,n=$CLJS.oh.j($CLJS.oi,$CLJS.Sm.h($CLJS.rg.g($CLJS.i1),Roa(function(q){return $CLJS.ki.h(q,l)})),$CLJS.E8.h(d,e));f=$CLJS.m(g)?g:$CLJS.P2(f)?epa($CLJS.C($CLJS.dua(f))):null;return C8(mpa(d,e,f,$CLJS.Vm.h(function(q){q=$CLJS.i1(q);return $CLJS.m(q)?$CLJS.Ie(n,q):null},$CLJS.N_.D(d,e,$CLJS.e3(d,e),new $CLJS.k(null,1,[$CLJS.z_,!1],null)))))}function b(d,e,f,g){return c.R?c.R(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,
e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.D=b;c.R=a;return c}();
$CLJS.S8=function(){function a(d,e,f,g,l){g=$CLJS.P2(f)?$CLJS.R2(d,f):f;var n=$CLJS.P2(f)?$CLJS.i1(f):null;f=$CLJS.m(l)?l:$CLJS.P2(f)?fpa($CLJS.C($CLJS.dua(f))):null;f=$CLJS.m(f)?$CLJS.Gb(n)?$CLJS.fW(f,$CLJS.Km,$CLJS.G([$CLJS.lP])):f:null;return C8(mpa(d,e,f,$CLJS.rg.h(function(q){q=$CLJS.U.j(q,$CLJS.vK,$CLJS.AY);return $CLJS.m(n)?$CLJS.G5(q,n):q},$CLJS.N_.D(d,e,g,new $CLJS.k(null,1,[$CLJS.z_,!1],null)))))}function b(d,e,f,g){return c.R?c.R(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,
e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.D=b;c.R=a;return c}();$CLJS.T8=function(){function a(c,d,e){return b.D?b.D(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.E1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.D=function(){return $CLJS.E1};return b}();
$CLJS.T2=function(){function a(d,e,f){function g(u,v){u=$CLJS.Km.h(u,$CLJS.n1);v=$CLJS.Km.h(v,$CLJS.n1);return $CLJS.O8.l($CLJS.B1.g($CLJS.$r),u,$CLJS.G([v]))}function l(u,v){return $CLJS.Rf(apa(function(x){return $CLJS.Zf.g(S2.g(x))},opa(d,e,u,v)))}var n=$CLJS.e3(d,e),q=function(){var u=l(n,f);return $CLJS.m(u)?$CLJS.Wm.h(function(v){return g(v,S2.g(v))},u):null}();if($CLJS.m(q))return q;q=l(f,n);return $CLJS.m(q)?$CLJS.Wm.h(function(u){return g(S2.g(u),u)},q):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.wpa=$CLJS.rg.g(function(a){return $CLJS.U.j(a,$CLJS.vK,$CLJS.AY)});
$CLJS.xpa=function(){function a(e,f,g,l){l=$CLJS.m(l)?l:$CLJS.P2(g)?epa($CLJS.C($CLJS.dua(g))):null;$CLJS.m(l)?(l=$CLJS.q3.j(e,f,l),l=$CLJS.Lu(l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tk,$CLJS.eC],null))):l=null;if($CLJS.m(l))return l;if(l=0===$CLJS.zW(e,f)){l=$CLJS.E8.h(e,f);var n=$CLJS.je(l);g=n?n:$CLJS.P2(g)?$CLJS.F.h($CLJS.OC.g(g),$CLJS.OC.g($CLJS.C(l))):null;g=$CLJS.m(g)?$CLJS.QZ(e):g}else g=l;$CLJS.m(g)?(g=$CLJS.QZ(e),g=$CLJS.j3(e,g),e=$CLJS.n3.j(e,f,g)):e=null;return $CLJS.m(e)?e:$CLJS.XH("Previous results")}
function b(e,f,g){return d.D?d.D(e,-1,f,g):d.call(null,e,-1,f,g)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,g,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,g);case 4:return a.call(this,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.j=b;d.D=a;return d}();
$CLJS.ypa=function(){function a(d,e,f,g){f=$CLJS.lW.g(f);$CLJS.J(f,0,null);$CLJS.J(f,1,null);var l=$CLJS.J(f,2,null),n=$CLJS.J(f,3,null);g=g instanceof $CLJS.N?g:$CLJS.Fk.g(g);e=$CLJS.zW(d,e);l=$CLJS.E7.j(d,e,l);d=$CLJS.E7.j(d,e,n);n=null==g||$CLJS.Ie($CLJS.si($CLJS.rg.h($CLJS.Fk,l)),g);d=null==g||$CLJS.Ie($CLJS.si($CLJS.rg.h($CLJS.Fk,d)),g);f=n?$CLJS.Xm.D(f,2,$CLJS.Z7,g):f;return d?$CLJS.Xm.D(f,3,$CLJS.Z7,g):f}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();$CLJS.N3.o(null,$CLJS.mN,function(a,b){var c=$CLJS.Rf($CLJS.E8.h(a,b));c=null==c?null:$CLJS.rg.h(function(d){return $CLJS.n3.j(a,b,d)},c);return null==c?null:$CLJS.it(" + ",c)});