var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ta,ua,va,wa,ya,za;ta=function(){var a=$CLJS.sa.navigator;return a&&(a=a.userAgent)?a:""};ua=function(a){return-1!=ta().indexOf(a)};va=function(){return ua("Firefox")||ua("FxiOS")};wa=function(){return(ua("Chrome")||ua("CriOS"))&&!ua("Edge")||ua("Silk")};
$CLJS.xa=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,g,l,n,q,u,v){if("%"==q)return"%";const x=c.shift();if("undefined"==typeof x)throw Error("[goog.string.format] Not enough arguments");arguments[0]=x;return $CLJS.xa.cd[q].apply(null,arguments)})};ya=function(){return ua("iPhone")&&!ua("iPod")&&!ua("iPad")};
za=function(){ya()||ua("iPad")||ua("iPod")};$CLJS.Aa=$CLJS.Aa||{};$CLJS.sa=global;$CLJS.Ba="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.sa=global;$CLJS.Ca=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var Da=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.ba=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.ca=new $CLJS.ba("OFF",Infinity);$CLJS.aaa=[$CLJS.ca,new $CLJS.ba("SHOUT",1200),new $CLJS.ba("SEVERE",1E3),new $CLJS.ba("WARNING",900),new $CLJS.ba("INFO",800),new $CLJS.ba("CONFIG",700),new $CLJS.ba("FINE",500),new $CLJS.ba("FINER",400),new $CLJS.ba("FINEST",300),new $CLJS.ba("ALL",0)];$CLJS.xa.cd={};$CLJS.xa.cd.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+Da(" ",Number(c)-a.length):Da(" ",Number(c)-a.length)+a};
$CLJS.xa.cd.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+Da(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+Da(b,a)+d);return d};
$CLJS.xa.cd.d=function(a,b,c,d,e,f,g,l){return $CLJS.xa.cd.f(parseInt(a,10),b,c,d,0,f,g,l)};$CLJS.xa.cd.i=$CLJS.xa.cd.d;$CLJS.xa.cd.u=$CLJS.xa.cd.d;ua("Opera");ua("Trident")||ua("MSIE");ua("Edge");!ua("Gecko")||-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")||ua("Trident")||ua("MSIE")||ua("Edge");-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")&&ua("Mobile");ua("Macintosh");ua("Windows");ua("Linux")||ua("CrOS");var da=$CLJS.sa.navigator||null;da&&(da.appVersion||"").indexOf("X11");ua("Android");ya();ua("iPad");ua("iPod");za();ta().toLowerCase().indexOf("kaios");Date.now();va();ya()||ua("iPod");ua("iPad");!ua("Android")||wa()||va()||ua("Opera")||ua("Silk");wa();!ua("Safari")||wa()||ua("Coast")||ua("Opera")||ua("Edge")||ua("Edg/")||ua("OPR")||va()||ua("Silk")||ua("Android")||za();