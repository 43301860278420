var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var Bz,Uca,Vca,Ez,Wca,Hz,Iz;
$CLJS.Az=function(a,b,c){return function(){function d(q,u,v){return a.R?a.R(b,c,q,u,v):a.call(null,b,c,q,u,v)}function e(q,u){return a.D?a.D(b,c,q,u):a.call(null,b,c,q,u)}function f(q){return a.j?a.j(b,c,q):a.call(null,b,c,q)}function g(){return a.h?a.h(b,c):a.call(null,b,c)}var l=null,n=function(){function q(v,x,y,B){var H=null;if(3<arguments.length){H=0;for(var I=Array(arguments.length-3);H<I.length;)I[H]=arguments[H+3],++H;H=new $CLJS.z(I,0,null)}return u.call(this,v,x,y,H)}function u(v,x,y,B){return $CLJS.R.l(a,
b,c,v,x,$CLJS.G([y,B]))}q.v=3;q.B=function(v){var x=$CLJS.C(v);v=$CLJS.D(v);var y=$CLJS.C(v);v=$CLJS.D(v);var B=$CLJS.C(v);v=$CLJS.zd(v);return u(x,y,B,v)};q.l=u;return q}();l=function(q,u,v,x){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,q);case 2:return e.call(this,q,u);case 3:return d.call(this,q,u,v);default:var y=null;if(3<arguments.length){y=0;for(var B=Array(arguments.length-3);y<B.length;)B[y]=arguments[y+3],++y;y=new $CLJS.z(B,0,null)}return n.l(q,u,v,y)}throw Error("Invalid arity: "+
arguments.length);};l.v=3;l.B=n.B;l.A=g;l.g=f;l.h=e;l.j=d;l.l=n.l;return l}()};Bz=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):new $CLJS.S(null,3,5,$CLJS.T,[a,b,null],null)};Uca=function(a){return $CLJS.A(a)?$CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return $CLJS.U.j(b,d,c)},$CLJS.Mg($CLJS.ng($CLJS.R.h($CLJS.Pm,$CLJS.gi(a)),null)),a):null};
Vca=function(a,b,c){var d=$CLJS.M.h(a,c),e=$CLJS.M.h(b,c),f=$CLJS.Cz(d,e),g=$CLJS.J(f,0,null),l=$CLJS.J(f,1,null);f=$CLJS.J(f,2,null);a=$CLJS.Ie(a,c);b=$CLJS.Ie(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.S(null,3,5,$CLJS.T,[!a||null==g&&d?null:$CLJS.Pf([c,g]),!b||null==l&&d?null:$CLJS.Pf([c,l]),d?$CLJS.Pf([c,f]):null],null)};
Ez=function(a,b){var c=$CLJS.rg.h,d=Dz.j,e=$CLJS.qe(a)?a:$CLJS.Mg(a),f=$CLJS.qe(b)?b:$CLJS.Mg(b);a=$CLJS.E(a);b=$CLJS.E(b);return $CLJS.Mg(c.call($CLJS.rg,Uca,d.call(Dz,e,f,$CLJS.Ai(0,a>b?a:b))))};Wca=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Rf($CLJS.Fz.h(a,b)),$CLJS.Rf($CLJS.Fz.h(b,a)),$CLJS.Rf($CLJS.Gz.h(a,b))],null)};
Hz=function(a){if(null!=a&&null!=a.Wh)a=a.Wh(a);else{var b=Hz[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Hz._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("EqualityPartition.equality-partition",a);}return a};Iz=function(a,b){if(null!=a&&null!=a.Vh)a=a.Vh(a,b);else{var c=Iz[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Iz._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("Diff.diff-similar",a);}return a};
$CLJS.Cz=function(a,b){return $CLJS.F.h(a,b)?new $CLJS.S(null,3,5,$CLJS.T,[null,null,a],null):$CLJS.F.h(Hz(a),Hz(b))?Iz(a,b):Bz(a,b)};$CLJS.Gz=function Gz(a){switch(arguments.length){case 1:return Gz.g(arguments[0]);case 2:return Gz.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gz.l(arguments[0],arguments[1],new $CLJS.z(c.slice(2),0,null))}};$CLJS.Gz.g=function(a){return a};
$CLJS.Gz.h=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.Sb(function(d,e){return function(f,g){return $CLJS.Ie(e,g)?f:$CLJS.Lm.h(f,g)}}(a,b),a,a)};$CLJS.Gz.l=function(a,b,c){a=$CLJS.sz(function(d){return-$CLJS.E(d)},$CLJS.kf.l(c,b,$CLJS.G([a])));return $CLJS.Sb($CLJS.Gz,$CLJS.C(a),$CLJS.zd(a))};$CLJS.Gz.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.Gz.v=2;
$CLJS.Fz=function Fz(a){switch(arguments.length){case 1:return Fz.g(arguments[0]);case 2:return Fz.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Fz.l(arguments[0],arguments[1],new $CLJS.z(c.slice(2),0,null))}};$CLJS.Fz.g=function(a){return a};$CLJS.Fz.h=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.Sb(function(c,d){return $CLJS.Ie(b,d)?$CLJS.Lm.h(c,d):c},a,a):$CLJS.Sb($CLJS.Lm,a,b)};
$CLJS.Fz.l=function(a,b,c){return $CLJS.Sb($CLJS.Fz,a,$CLJS.kf.h(c,b))};$CLJS.Fz.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};$CLJS.Fz.v=2;var du=new $CLJS.N(null,"atom","atom",-397043653);var Dz=function Dz(a){switch(arguments.length){case 2:return Dz.h(arguments[0],arguments[1]);case 3:return Dz.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Dz.h=function(a,b){return Dz.j(a,b,$CLJS.zz.h($CLJS.gi(a),$CLJS.gi(b)))};Dz.j=function(a,b,c){return $CLJS.Sb(function(d,e){return $CLJS.Ci($CLJS.rg.j($CLJS.nn,d,e))},new $CLJS.S(null,3,5,$CLJS.T,[null,null,null],null),$CLJS.rg.h($CLJS.Az(Vca,a,b),c))};Dz.v=3;
Hz["null"]=function(){return du};Hz.string=function(){return du};Hz.number=function(){return du};Hz.array=function(){return $CLJS.Mj};Hz["function"]=function(){return du};Hz["boolean"]=function(){return du};
Hz._=function(a){return(null!=a?a.C&1024||$CLJS.t===a.Kh||(a.C?0:$CLJS.Nb($CLJS.rc,a)):$CLJS.Nb($CLJS.rc,a))?$CLJS.ik:(null!=a?a.C&4096||$CLJS.t===a.Oh||(a.C?0:$CLJS.Nb($CLJS.vc,a)):$CLJS.Nb($CLJS.vc,a))?$CLJS.cj:(null!=a?a.C&16777216||$CLJS.t===a.eg||(a.C?0:$CLJS.Nb($CLJS.Lc,a)):$CLJS.Nb($CLJS.Lc,a))?$CLJS.Mj:du};Iz["null"]=function(a,b){return Bz(a,b)};Iz.string=function(a,b){return Bz(a,b)};Iz.number=function(a,b){return Bz(a,b)};Iz.array=function(a,b){return Ez(a,b)};
Iz["function"]=function(a,b){return Bz(a,b)};Iz["boolean"]=function(a,b){return Bz(a,b)};Iz._=function(a,b){var c=function(){var d=Hz(a);d=d instanceof $CLJS.N?d.S:null;switch(d){case "atom":return Bz;case "set":return Wca;case "sequential":return Ez;case "map":return Dz;default:throw Error(["No matching clause: ",$CLJS.p.g(d)].join(""));}}();return c.h?c.h(a,b):c.call(null,a,b)};