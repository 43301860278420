var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var W3,a4,d1,rna,qna,sna;$CLJS.Z0=function(a,b){return $CLJS.E(a)<=$CLJS.E(b)&&$CLJS.Wf(function(c){return $CLJS.Ie(b,c)},a)};$CLJS.$0=function(a,b){return $CLJS.bG($CLJS.$f(a),b)};$CLJS.a1=function(a){var b;a:for(b=a;;)if($CLJS.oe(b))b=$CLJS.Zf.g(b);else{b=$CLJS.Ae(b)?b:null;break a}if($CLJS.m(b))return b;throw Error($CLJS.WH("Not something with an ID: {0}",$CLJS.G([$CLJS.aj.l($CLJS.G([a]))])));};
W3=function(a){if(null!=a&&null!=a.kh)a=a.kh(a);else{var b=W3[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=W3._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("MetadataProvider.database",a);}return a};a4=function(a,b){if(null!=a&&null!=a.lh)a=a.lh(a,b);else{var c=a4[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=a4._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.field",a);}return a};$CLJS.b1=function(a){return W3($CLJS.i3(a))};
$CLJS.b4=function(a,b){return a4($CLJS.i3(a),b)};$CLJS.c1=function(a){var b=$CLJS.e3(a,0),c=$CLJS.Qf(b),d=$CLJS.M.h(c,$CLJS.XO),e=$CLJS.M.h(c,$CLJS.xV);return $CLJS.ye(function(){var f=$CLJS.b1(a);$CLJS.m(f)?(f=$CLJS.Qf(f),f=$CLJS.M.h(f,$CLJS.Zf),f=$CLJS.F.h($CLJS.MP.g(a),f)):f=null;if($CLJS.m(f)){f=$CLJS.m(d)?$CLJS.j3(a,d):d;if($CLJS.m(f))return f;f=$CLJS.m(e)?$CLJS.k3(a,e):e;return $CLJS.m(f)?f:$CLJS.F.h($CLJS.cu.g(c),$CLJS.bW)&&$CLJS.F.h($CLJS.UY,$CLJS.VY.g($CLJS.b1(a)))}return f}())};
d1=function(a,b){return function f(d,e){try{if($CLJS.qe(e)&&3===$CLJS.E(e))try{var g=$CLJS.Td(e,0);if($CLJS.O(g,$CLJS.yE))try{var l=$CLJS.Td(e,1);if($CLJS.m($CLJS.PB($CLJS.oe,$CLJS.$f($CLJS.PB($CLJS.iy,$CLJS.cb)))(l)))try{var n=$CLJS.Td(e,2);if($CLJS.m($CLJS.PB($CLJS.Ae,$CLJS.cf)(n))){var q=$CLJS.Td(e,2),u=$CLJS.Td(e,1);return $CLJS.m($CLJS.Xf(new $CLJS.ni(null,new $CLJS.k(null,1,[qna,null],null),null),d))?e:$CLJS.Xm.D(e,1,$CLJS.nn,null!=$CLJS.iy.g(u)&&$CLJS.ki.h($CLJS.iy.g(u),$CLJS.iy.g($CLJS.b4(b,
q)))?null:$CLJS.nn.l($CLJS.G([$CLJS.Ie(u,$CLJS.iy)?null:new $CLJS.k(null,1,[$CLJS.NX,!0],null),$CLJS.li($CLJS.b4(b,q),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iy,$CLJS.cb],null))])))}throw $CLJS.Z;}catch(x){if(x instanceof Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;
}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.e_(f,d,e);throw v;}throw x;}}($CLJS.Lg,a)};rna=new $CLJS.N(null,"is-native","is-native",-1797717934);qna=new $CLJS.N("mbql","stage-metadata","mbql/stage-metadata",1090845629);sna=new $CLJS.N(null,"is-editable","is-editable",269018020);$CLJS.V3.o(null,$CLJS.oW,function(a){return $CLJS.U3(a,new $CLJS.k(null,2,[$CLJS.$k,$CLJS.Oi,$CLJS.aL,$CLJS.bg($CLJS.Wm,$CLJS.V3)],null))});$CLJS.l3.o(null,$CLJS.oW,function(){throw $CLJS.zj("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.P);});$CLJS.K_.o(null,$CLJS.oW,function(a,b,c,d){return $CLJS.L_.D(a,b,$CLJS.e3(c,b),d)});$CLJS.K3.o(null,$CLJS.oW,function(a,b,c,d){return $CLJS.n3.D(a,b,$CLJS.e3(c,b),d)});
$CLJS.Q3.o(null,$CLJS.oW,function(a,b,c){a=$CLJS.e3(c,0);a=$CLJS.F.h($CLJS.cu.g(a),$CLJS.bW);return new $CLJS.k(null,2,[rna,a,sna,$CLJS.c1(c)],null)});$CLJS.e1=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.query","can-run-method"),function(f){return $CLJS.cu.g($CLJS.e3(f,0))},e,a,b,c,d)}();$CLJS.e1.o(null,$CLJS.oa,function(){return!0});
$CLJS.f1=function(){function a(d,e,f){return new $CLJS.k(null,4,[$CLJS.cu,$CLJS.oW,$CLJS.QB,$CLJS.i3(e),$CLJS.MP,d,$CLJS.aL,f],null)}function b(d,e){var f=$CLJS.Zf.g($CLJS.b1(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.g1=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.query","query-method"),function(f,g){return $CLJS.EH(g)},e,a,b,c,d)}();$CLJS.g1.o(null,$CLJS.ey,function(a,b){a:{var c=$CLJS.HC;$CLJS.HC=!0;try{var d=$CLJS.r2.g(b);break a}finally{$CLJS.HC=c}d=void 0}b=d1(d,a);return $CLJS.f1.h(a,$CLJS.aL.g(b))});
$CLJS.g1.o(null,$CLJS.oW,function(a,b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.RX);var d=$CLJS.i3(a),e=$CLJS.Km.h($CLJS.U.j(c,$CLJS.QB,d),$CLJS.RX);a=$CLJS.aL.g(e);return $CLJS.m(b)?$CLJS.U.j(e,$CLJS.aL,$CLJS.Wm.h(function(f){var g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return function u(n,q){try{if($CLJS.qe(q)&&3===$CLJS.E(q))try{var v=$CLJS.Td(q,0);if($CLJS.O(v,$CLJS.fy))try{var x=$CLJS.Td(q,1);if($CLJS.m($CLJS.PB($CLJS.oe,$CLJS.$f($CLJS.PB($CLJS.iy,$CLJS.cb)))(x))){var y=$CLJS.Td(q,1),B=$CLJS.Td(q,
2),H=function(){try{return $CLJS.$0(function(Q){return $CLJS.F.h($CLJS.Nj,Q)},$CLJS.li($CLJS.Zd($CLJS.jna.j(e,g,B)),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iy,$CLJS.cb],null)))}catch(Q){return null}}();return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fy,$CLJS.nn.l($CLJS.G([H,y])),B],null)}throw $CLJS.Z;}catch(Q){if(Q instanceof Error){var I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;
}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)return $CLJS.e_(u,n,q);throw I;}throw Q;}}($CLJS.Lg,d1(f,d))},$CLJS.dg($CLJS.an,a))):e});$CLJS.g1.o(null,$CLJS.BK,function(a,b){return $CLJS.f1.h(a,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.cu,$CLJS.IV,$CLJS.XO,$CLJS.a1(b)],null)],null))});$CLJS.g1.o(null,$CLJS.SK,function(a,b){return $CLJS.f1.h(a,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.cu,$CLJS.IV,$CLJS.xV,$CLJS.a1(b)],null)],null))});