var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var wqa,c3,xqa,g3,h3,p8,y3,Pma,Oma,Qma,Nma,C_,k_,E_,c_,Rma,o_;wqa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};c3=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ie($CLJS.zfa,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
xqa=function(a){var b=$CLJS.Qf(new $CLJS.k(null,1,[$CLJS.ky,!0],null)),c=$CLJS.M.h(b,$CLJS.hea),d=$CLJS.M.h(b,$CLJS.ky);return $CLJS.A(a)?(b=$CLJS.jV(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=c3(x,$CLJS.Gb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(c3(u,
$CLJS.Gb(d)),g($CLJS.zd(l)))}return null}},null,null)}(wqa($CLJS.lG(a)))}()),$CLJS.m(c)?$CLJS.jV($CLJS.jg(c,b)):b):null};$CLJS.QZ=function(a){return $CLJS.XO.g($CLJS.C($CLJS.aL.g(a)))};g3=function(a,b){if(null!=a&&null!=a.qh)a=a.qh(a,b);else{var c=g3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=g3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.table",a);}return a};
h3=function(a,b){if(null!=a&&null!=a.jh)a=a.jh(a,b);else{var c=h3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=h3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.card",a);}return a};p8=function(a,b){if(null!=a&&null!=a.mh)a=a.mh(a,b);else{var c=p8[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=p8._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.fields",a);}return a};
$CLJS.i3=function(a){return $CLJS.O2(a)?a:$CLJS.QB.g(a)};$CLJS.j3=function(a,b){return g3($CLJS.i3(a),b)};$CLJS.Bta=function(a,b){return p8($CLJS.i3(a),b)};$CLJS.k3=function(a,b){return h3($CLJS.i3(a),b)};
$CLJS.VZ=function(a,b,c,d,e){var f=$CLJS.Qf(e);e=$CLJS.M.j(f,$CLJS.YY,$CLJS.RZ);f=$CLJS.M.h(f,$CLJS.WY);if($CLJS.m($CLJS.m(f)?f:$CLJS.Ib(b)||$CLJS.oe(b))&&($CLJS.m(b.__mbcache)||(b.__mbcache=e.A?e.A():e.call(null)),b=b.__mbcache,$CLJS.m(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.m(f))return f;d=d.g?d.g(c):d.call(null,c);e.D?e.D(b,a,c,d):e.call(null,b,a,c,d);return d}return d.g?d.g(c):d.call(null,c)};
$CLJS.a_=function(a,b,c){return $CLJS.VZ(a,b,b,c,new $CLJS.k(null,2,[$CLJS.YY,$CLJS.RZ,$CLJS.WY,!1],null))};
y3=function(a,b,c){var d=$CLJS.b_.j(a,b,c);return $CLJS.nn.l($CLJS.G([$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.eC,$CLJS.Xj],null)),function(){var e=$CLJS.GW.g(c);return $CLJS.m(e)?new $CLJS.k(null,2,[$CLJS.eC,e,c_,!0],null):null}(),function(){var e=$CLJS.n3.D(a,b,c,$CLJS.d_);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.j_,e],null):null}(),function(){var e=$CLJS.Tx($CLJS.cb,$CLJS.iy)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.cb,e],null):null}(),function(){var e=$CLJS.KK.g(d);return $CLJS.m(e)?
(e=$CLJS.Ae(e)?$CLJS.j3(a,e):"string"===typeof e?$CLJS.k3(a,$CLJS.e0(e)):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.tk,$CLJS.q3.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.vK.g(d);return $CLJS.m(e)?new $CLJS.k(null,6,[k_,$CLJS.F.h(e,$CLJS.pY),$CLJS.n_,$CLJS.F.h(e,$CLJS.AY),o_,$CLJS.F.h(e,$CLJS.xY),$CLJS.q_,$CLJS.F.h(e,$CLJS.zY),Nma,$CLJS.F.h(e,$CLJS.UK),Oma,$CLJS.F.h(e,$CLJS.MK)],null):null}(),function(){var e=$CLJS.EY.g(d);return null==e?null:new $CLJS.k(null,1,[$CLJS.r_,e],null)}(),function(){var e=
$CLJS.Tx($CLJS.AW,$CLJS.EE)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.t_,$CLJS.Ie($CLJS.XV,e)&&!$CLJS.Ie($CLJS.pla,e)],null):null}(),$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.u_,$CLJS.v_,$CLJS.w_],null))]))};$CLJS.RZ=function RZ(a){switch(arguments.length){case 0:return RZ.A();case 3:return RZ.j(arguments[0],arguments[1],arguments[2]);case 4:return RZ.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.RZ.A=function(){return $CLJS.na($CLJS.P)};$CLJS.RZ.j=function(a,b){return $CLJS.M.h($CLJS.r(a),b)};$CLJS.RZ.D=function(a,b,c,d){return $CLJS.dj.D(a,$CLJS.U,b,d)};$CLJS.RZ.v=4;$CLJS.x_=new $CLJS.N(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.w_=new $CLJS.N(null,"filter-positions","filter-positions",378749375);$CLJS.y_=new $CLJS.N(null,"direction","direction",-633359395);$CLJS.q_=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.u_=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.z_=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.r_=new $CLJS.N(null,"selected","selected",574897764);Pma=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);Oma=new $CLJS.N(null,"is-breakout","is-breakout",-1878069983);
Qma=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.A_=new $CLJS.N("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.B_=new $CLJS.N("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Nma=new $CLJS.N(null,"is-aggregation","is-aggregation",872502629);
C_=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.D_=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);k_=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);E_=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);c_=new $CLJS.N(null,"named?","named?",-213401078);$CLJS.F_=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.n_=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.t_=new $CLJS.N(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.G_=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.v_=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);Rma=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.j_=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);
$CLJS.H_=new $CLJS.N(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.d_=new $CLJS.N(null,"long","long",-171452093);$CLJS.I_=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.J_=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);o_=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.K3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();
$CLJS.L3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();
$CLJS.n3=function(){function a(f,g,l,n){var q=$CLJS.Tx($CLJS.eC,$CLJS.jC)($CLJS.GH(l));if($CLJS.m(q))return q;try{return $CLJS.K3.D(f,g,l,n)}catch(u){if(u instanceof Error)throw g=u,$CLJS.yj($CLJS.WH("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([l])),$CLJS.UZ(g)])),new $CLJS.k(null,2,[$CLJS.SD,f,$CLJS.zC,l],null),g);throw u;}}function b(f,g,l){return e.D?e.D(f,g,l,$CLJS.oa):e.call(null,f,g,l,$CLJS.oa)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){return e.h?
e.h(f,f):e.call(null,f,f)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.D=a;return e}();
$CLJS.M3=function(){function a(d,e,f){var g=$CLJS.V.g($CLJS.GH(f));if($CLJS.m(g))return g;try{return $CLJS.L3.j(d,e,f)}catch(l){if(l instanceof Error)throw g=l,$CLJS.yj($CLJS.WH("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([f])),$CLJS.UZ(g)])),new $CLJS.k(null,3,[$CLJS.zC,f,$CLJS.SD,d,$CLJS.yL,e],null),g);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.K3.o(null,$CLJS.oa,function(a,b,c){a=$CLJS.ZF($CLJS.zw);$CLJS.m($CLJS.fG("metabase.lib.metadata.calculation",a))&&$CLJS.gG("metabase.lib.metadata.calculation",a,$CLJS.X_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),Qma,$CLJS.EH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.ui($CLJS.C(c)):$CLJS.aj.l($CLJS.G([c]))});
$CLJS.L3.o(null,$CLJS.oa,function(a,b,c){return xqa($CLJS.Pu($CLJS.n3.j(a,b,c),/[\(\)]/,""))});$CLJS.N3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},e,a,b,c,d)}();
$CLJS.n8=function(){function a(d,e,f){return $CLJS.N3.j(d,e,$CLJS.Oi.g(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.O3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();
$CLJS.P3=function(){function a(d,e,f){var g=$CLJS.GH(f);g=$CLJS.Qf(g);var l=$CLJS.M.h(g,$CLJS.EE),n=$CLJS.cb.g(g);if($CLJS.m(n))return n;l=$CLJS.m($CLJS.m(l)?$CLJS.Ie($CLJS.XV,l):l)?$CLJS.Jj:null;if($CLJS.m(l))return l;g=$CLJS.iy.g(g);if($CLJS.m(g))return g;d=$CLJS.O3.j(d,e,f);return $CLJS.QH(d,$CLJS.Nj)?d:$CLJS.Nj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.j=a;return c}();$CLJS.O3.o(null,$CLJS.oa,function(a,b,c){return $CLJS.FL(c)});$CLJS.O3.o(null,$CLJS.iD,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.P3.j(a,b,c)});$CLJS.O3.o(null,$CLJS.II,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.FL(c):$CLJS.P3.j(a,b,d)});
$CLJS.l3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();
$CLJS.l3.o(null,$CLJS.oa,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.cu,$CLJS.tK,$CLJS.iy,$CLJS.P3.j(a,b,c),$CLJS.V,$CLJS.M3.j(a,b,c),$CLJS.eC,$CLJS.n3.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.WH("Error calculating metadata for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([$CLJS.EH(c)])),$CLJS.UZ(d)])),new $CLJS.k(null,3,[$CLJS.SD,a,$CLJS.yL,b,$CLJS.zC,c],null),d);}throw e;}});
$CLJS.b_=function(){function a(e,f,g){return $CLJS.l3.j(e,f,g)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,g){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,g)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.h=b;d.j=a;return d}();
$CLJS.Q3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();
$CLJS.lL(C_,new $CLJS.S(null,20,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.j_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[c_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tk,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,C_],null)],null)],
null),new $CLJS.S(null,3,5,$CLJS.T,[k_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.n_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[o_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.q_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[E_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Rma,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Pma,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Hs],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hE,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.St,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.I_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ak],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.G_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.r_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],
null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.y_,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,$CLJS.vE,$CLJS.oE],null)],null)],null));
$CLJS.q3=function(){function a(d,e,f){return $CLJS.a_($CLJS.Oi.h("display-info",["stage-",$CLJS.p.g(e)].join("")),f,function(g){try{return $CLJS.Q3.j(d,e,g)}catch(n){if(n instanceof Error){var l=n;throw $CLJS.yj($CLJS.WH("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.EH(g),$CLJS.UZ(l)])),new $CLJS.k(null,3,[$CLJS.SD,d,$CLJS.yL,e,$CLJS.zC,g],null),l);}throw n;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.Q3.o(null,$CLJS.oa,function(a,b,c){return y3(a,b,c)});$CLJS.Q3.o(null,$CLJS.BK,function(a,b,c){return $CLJS.nn.l($CLJS.G([y3(a,b,c),new $CLJS.k(null,1,[E_,$CLJS.F.h($CLJS.QZ(a),$CLJS.Zf.g(c))],null)]))});
$CLJS.K_=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","returned-columns-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();$CLJS.K_.o(null,$CLJS.ly,function(){return $CLJS.Lg});
$CLJS.L_=function(){function a(f,g,l,n){n=$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.F_,$CLJS.a2()],null),n]));return $CLJS.K_.D(f,g,l,n)}function b(f,g,l){return e.D?e.D(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.e3(f,-1);return e.h?e.h(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,
f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.D=a;return e}();$CLJS.R3=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();$CLJS.R3.o(null,$CLJS.ly,function(){return $CLJS.Lg});
$CLJS.R3.o(null,$CLJS.oa,function(a,b,c,d){return $CLJS.K_.D(a,b,c,d)});
$CLJS.N_=function(){function a(f,g,l,n){n=$CLJS.nn.l($CLJS.G([$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.F_,$CLJS.a2()],null),new $CLJS.k(null,4,[$CLJS.D_,!0,$CLJS.J_,!0,$CLJS.z_,!0,$CLJS.H_,!0],null)])),n]));return $CLJS.R3.D(f,g,l,n)}function b(f,g,l){return $CLJS.m(function(){var n=$CLJS.oe(l);if(n){n=$CLJS.cu.g(l);var q=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.bW,null,$CLJS.IV,null],null),null);return q.g?q.g(n):q.call(null,n)}return n}())?$CLJS.a_($CLJS.Oi.g([$CLJS.p.g(g),"__visible-columns-no-opts"].join("")),
f,function(){return e.D?e.D(f,g,l,null):e.call(null,f,g,l,null)}):e.D?e.D(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.e3(f,-1);return e.h?e.h(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.D=a;return e}();
module.exports={display_name:$CLJS.n3};