var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.binning.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.malli.js");
'use strict';var Kra,Lra;
Kra=function(a,b){return function f(d,e){return new $CLJS.yf(null,function(){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){for(var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n),u=0;;)if(u<n){var v=function(){var x=d+u,y=$CLJS.be(l,u);return a.h?a.h(x,y):a.call(null,x,y)}();null!=v&&q.add(v);u+=1}else break;return $CLJS.Ef($CLJS.Gf(q),f(d+n,$CLJS.ad(g)))}n=function(){var x=$CLJS.C(g);return a.h?a.h(d,x):a.call(null,d,x)}();return null==n?f(d+1,$CLJS.zd(g)):$CLJS.nf(n,f(d+1,$CLJS.zd(g)))}return null},null,null)}(0,
b)};$CLJS.C4=function(a,b){return $CLJS.q1($CLJS.Wr,new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))};$CLJS.D4=function(a,b){return $CLJS.q1($CLJS.Vr,new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))};$CLJS.b8=function(a,b){return $CLJS.R6.h(a,$CLJS.Ie(b,$CLJS.dC)?$CLJS.dC.g(b):b)};Lra=new $CLJS.N(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.N3.o(null,$CLJS.mE,function(a,b){var c=$CLJS.Rf($CLJS.mE.g($CLJS.e3(a,b)));return $CLJS.m(c)?$CLJS.WH("Grouped by {0}",$CLJS.G([$CLJS.it([" ",$CLJS.p.g($CLJS.XH("and"))," "].join(""),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.n3.D(a,b,v,$CLJS.d_);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.n3.D(a,b,q,$CLJS.d_),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});$CLJS.Z8=function(){function a(d,e,f){f=$CLJS.iW($CLJS.de(f)?f.h?f.h(d,e):f.call(null,d,e):f);return $CLJS.y8(d,e,$CLJS.mE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.$8=function(){function a(d,e){return $CLJS.Rf($CLJS.mE.g($CLJS.e3(d,e)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.f8=function(){function a(d,e){var f=$CLJS.$8.h(d,e);return null==f?null:$CLJS.Wm.h(function(g){return $CLJS.U.j($CLJS.b_.j(d,e,g),$CLJS.vK,$CLJS.MK)},f)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.a9=function(){function a(d,e){var f=function(){var l=$CLJS.e3(d,e);return $CLJS.N_.D(d,e,l,new $CLJS.k(null,1,[$CLJS.H_,!1],null))}();if($CLJS.A(f)){var g=$CLJS.oh.h($CLJS.P,Kra(function(l,n){var q=$CLJS.y1.R(d,e,n,f,new $CLJS.k(null,1,[$CLJS.v1,!0],null));return $CLJS.m(q)?new $CLJS.S(null,2,5,$CLJS.T,[q,new $CLJS.S(null,2,5,$CLJS.T,[l,n],null)],null):null},function(){var l=$CLJS.$8.h(d,e);return $CLJS.m(l)?l:$CLJS.Lg}()));return $CLJS.Wm.h(function(l){var n=g.g?g.g(l):g.call(null,l),q=$CLJS.J(n,
0,null),u=$CLJS.J(n,1,null);n=$CLJS.s7(u);u=$CLJS.$7(u);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.Fk);l=$CLJS.U.j(l,$CLJS.Ira,!0);l=$CLJS.m(n)?$CLJS.b8(l,n):l;l=$CLJS.m(u)?$CLJS.Z7(l,u):l;return $CLJS.m(q)?$CLJS.U.j(l,$CLJS.u_,q):l},f)}return null}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.E4=function(){function a(d,e,f,g){g=$CLJS.Qf(g);var l=$CLJS.M.h(g,Lra);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.tg(function(n){var q=$CLJS.y1.R(d,e,n,new $CLJS.S(null,1,5,$CLJS.T,[f],null),new $CLJS.k(null,1,[$CLJS.v1,!0],null));return $CLJS.m(q)?$CLJS.m(l)?$CLJS.F.h($CLJS.$7(n),$CLJS.$7(f)):!0:q}),$CLJS.$8.h(d,e)))}function b(d,e,f){return c.D?c.D(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();$CLJS.Mra=function(){function a(d,e,f){return $CLJS.Sb(function(g,l){return $CLJS.l9.j(g,e,l)},d,$CLJS.E4.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();