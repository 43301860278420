var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var lg=function(a,b){return new $CLJS.yf(null,function(){a:for(var c=a,d=b;;){d=$CLJS.A(d);var e;if(e=d)e=$CLJS.C(d),e=c.g?c.g(e):c.call(null,e);if($CLJS.m(e))d=$CLJS.zd(d);else break a}return d},null,null)},Bi=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vi(a,b),lg(a,b)],null)},B6=function(a){var b=$CLJS.nn.l;var c=$CLJS.Qf(a);var d=$CLJS.M.h(c,$CLJS.e4);c=$CLJS.M.h(c,$CLJS.k4);if($CLJS.m($CLJS.m(d)?$CLJS.F.h(c,"currency"):d)){c=$CLJS.J3.g;d=$CLJS.Oi.g(d);var e=$CLJS.r($CLJS.m6);
d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.J3,d);c=new $CLJS.k(null,2,[$CLJS.c4,c,$CLJS.l4,c],null)}else c=new $CLJS.k(null,1,[$CLJS.l4,2],null);b=b.call($CLJS.nn,$CLJS.G([c,a]));return $CLJS.m($CLJS.m4.g(a))?$CLJS.U.l($CLJS.Km.h(b,$CLJS.m4),$CLJS.l4,$CLJS.m4.g(a),$CLJS.G([$CLJS.c4,$CLJS.m4.g(a)])):b},C6=function(a,b){if(null!=a&&null!=a.Ff)a=a.Ff(a,b);else{var c=C6[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=C6._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("NumberFormatter.format-number-basic",
a);}return a},asa=function(a,b){var c=$CLJS.iF(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.hk,!0],null)]));a=$CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.$k.g(d),"exponentMinusSign")},c))?c:function(){var d=Bi(function(f){return $CLJS.ki.h($CLJS.$k.g(f),"exponentInteger")},c),e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.qg.l(e,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.$k,"exponentPlusSign",$CLJS.vl,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.R.h($CLJS.p,
$CLJS.rg.h(function(d){var e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.$k);e=$CLJS.M.h(e,$CLJS.vl);switch(d){case "exponentSeparator":return"e";default:return e}},a))},bsa=function(a,b){switch($CLJS.k4.g(b)){case "scientific":return function(c){return asa(a,c)};default:return function(c){return a.format(c)}}},p4=function(a,b,c,d,e){this.options=a;this.rh=b;this.th=c;this.Ue=d;this.ki=e;this.C=393216;this.K=0},csa=function(a,b){return $CLJS.R.h($CLJS.p,function(){return function e(d){return new $CLJS.yf(null,
function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.be(g,v),y=$CLJS.Qf(x);x=$CLJS.M.h(y,$CLJS.$k);y=$CLJS.M.h(y,$CLJS.vl);var B=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);B=B.g?B.g(x):B.call(null,x);$CLJS.m(B)&&(x=$CLJS.F.h(x,"integer")?b:y,n.add(x));v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);q=$CLJS.Qf(q);
var u=$CLJS.M.h(q,$CLJS.$k);q=$CLJS.M.h(q,$CLJS.vl);if($CLJS.m(function(){var v=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.nf($CLJS.F.h(u,"integer")?b:q,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}($CLJS.iF(a.rh.formatToParts(1),$CLJS.G([$CLJS.hk,!0])))}())},E6=function(a){var b=$CLJS.F.h($CLJS.k4.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.qn($CLJS.bG($CLJS.Hb,$CLJS.Sh([$CLJS.Vqa,
$CLJS.Kqa,$CLJS.Nqa,$CLJS.Pqa,$CLJS.Yqa,$CLJS.e4,$CLJS.Oqa,$CLJS.Qqa,$CLJS.Lqa,$CLJS.Mqa],[$CLJS.l4.h(a,b),$CLJS.o4.g(a),$CLJS.Jqa.g(a),$CLJS.Uqa.g(a),$CLJS.F.h($CLJS.k4.g(a),"scientific")?null:$CLJS.k4.h(a,"decimal"),$CLJS.e4.g(a),!0,$CLJS.c4.h(a,b),$CLJS.F.h($CLJS.k4.g(a),"scientific")?"scientific":null,$CLJS.Xqa.g(a)]))));var c=$CLJS.o4.g(a);var d=$CLJS.e4.g(a);c=$CLJS.m(d)?null==c||$CLJS.F.h(c,"symbol"):d;d=bsa(b,a);return new p4(a,b,c,d,$CLJS.P)},F6=function(a,b){return E6(B6(b)).Ff(null,a)},
H6=function(a,b){var c=$CLJS.Km.l(b,$CLJS.ry,$CLJS.G([$CLJS.k4])),d=$CLJS.Sx(a);if(0===a)return"0";if(1E3>d)return $CLJS.G6.h?$CLJS.G6.h(a,c):$CLJS.G6.call(null,a,c);b=$CLJS.C($CLJS.ug(function(f){return d>=$CLJS.C(f)},dsa));var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return[$CLJS.p.g(function(){var f=a/e,g=$CLJS.nn.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.c4,1,$CLJS.l4,1],null)]));return $CLJS.G6.h?$CLJS.G6.h(f,g):$CLJS.G6.call(null,f,g)}()),$CLJS.p.g(b)].join("")},esa=function(a,b){var c=B6(b);if($CLJS.m(Zqa.g(c)))b=
Zqa.g(c);else if($CLJS.Gb($CLJS.m4.g(c))&&$CLJS.Gb($CLJS.c4.g(c))&&$CLJS.ki.h($CLJS.k4.g(c),"currency")&&$CLJS.Sx(a)<($CLJS.F.h($CLJS.k4.g(c),"percent")?.01:1)){b=$CLJS.U.j;var d=$CLJS.Km.h(c,$CLJS.l4);c=$CLJS.Xqa.h(c,0);b=E6(b.call($CLJS.U,d,$CLJS.Jqa,2>c?2:c))}else b=E6(c);return C6(b,a)};p4.prototype.O=function(a,b){return new p4(this.options,this.rh,this.th,this.Ue,b)};p4.prototype.N=function(){return this.ki};
p4.prototype.Ff=function(a,b){a=this.Ue.g?this.Ue.g(b):this.Ue.call(null,b);var c=$CLJS.Wqa.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.ki.h(c,".,"):c)&&(b=$CLJS.C(c),c=$CLJS.Zd(c),a=$CLJS.Pu(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.th)&&(b=$CLJS.e4.g(this.options),c=$CLJS.n6(b),a=$CLJS.Pu($CLJS.Pu(a,[$CLJS.ui(b)," "].join(""),c),$CLJS.ui(b),c));return a};
var Zqa=new $CLJS.N(null,"number-formatter","number-formatter",929788393),$qa=new $CLJS.N(null,"scale","scale",-230427353),ara=new $CLJS.N(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),bra=new $CLJS.N(null,"scientific","scientific",316285837);var L6=new $CLJS.k(null,1,[$CLJS.o4,"symbol"],null),gsa=$CLJS.qn(L6),dsa=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[1E12,"T"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E9,"B"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E6,"M"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E3,"k"],null)],null),M6=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Qf(g);return $CLJS.M.h(f,$CLJS.k4)},e,a,b,c,d)}();M6.o(null,$CLJS.oa,function(a,b){return H6(a,b)});M6.o(null,"percent",function(a,b){return[$CLJS.p.g(H6(100*a,b)),"%"].join("")});M6.o(null,"currency",function(a,b){b=$CLJS.nn.l($CLJS.G([b,L6]));var c=E6(b);return 1E3>$CLJS.Sx(a)?c.Ff(null,a):csa(c,H6(a,b))});M6.o(null,"scientific",function(a,b){return F6(a,$CLJS.nn.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.l4,1,$CLJS.c4,1],null)])))});
$CLJS.G6=function G6(a,b){b=$CLJS.qG(b);var d=$CLJS.Qf(b);b=$CLJS.M.h(d,$CLJS.ry);var e=$CLJS.M.h(d,ara),f=$CLJS.M.h(d,$CLJS.k4),g=$CLJS.M.h(d,$qa);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.Km.h(d,$qa),G6.h?G6.h(b,f):G6.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.U.j(d,ara,!1);return G6.h?G6.h(l,n):G6.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?M6.h(a,B6($CLJS.Km.h(d,$CLJS.ry))):$CLJS.F.h($CLJS.Oi.g(f),bra)?F6(a,d):esa(a,d)};
module.exports={compact_currency_options_js:gsa,format_number:$CLJS.G6};