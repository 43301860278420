var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var $ma,ana,bna,cna,dna,k0;
$CLJS.q4=function(){function a(e,f){if($CLJS.Gb(f))return"";e=$CLJS.Sx(e);var g=$CLJS.Oi.g(f);switch(g instanceof $CLJS.N?g.S:null){case "default":return $CLJS.$Z("Default period","Default periods",e);case "millisecond":return $CLJS.$Z("Millisecond","Milliseconds",e);case "second":return $CLJS.$Z("Second","Seconds",e);case "minute":return $CLJS.$Z("Minute","Minutes",e);case "hour":return $CLJS.$Z("Hour","Hours",e);case "day":return $CLJS.$Z("Day","Days",e);case "week":return $CLJS.$Z("Week","Weeks",
e);case "month":return $CLJS.$Z("Month","Months",e);case "quarter":return $CLJS.$Z("Quarter","Quarters",e);case "year":return $CLJS.$Z("Year","Years",e);case "minute-of-hour":return $CLJS.$Z("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.$Z("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.$Z("Day of week","Days of week",e);case "day-of-month":return $CLJS.$Z("Day of month","Days of month",e);case "day-of-year":return $CLJS.$Z("Day of year","Days of year",e);case "week-of-year":return $CLJS.$Z("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.$Z("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.$Z("Quarter of year","Quarters of year",e);default:return f=$CLJS.lz($CLJS.ui(f),/-/),e=$CLJS.A(f),f=$CLJS.C(e),e=$CLJS.D(e),$CLJS.it(" ",$CLJS.nf($CLJS.NF(f),e))}}function b(e){return d.h?d.h(1,e):d.call(null,1,e)}function c(){return d.h?d.h(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.A=c;d.g=b;d.h=a;return d}();$CLJS.r4=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.EH(f)},e,a,b,c,d)}();$ma=$CLJS.na($CLJS.P);ana=$CLJS.na($CLJS.P);
bna=$CLJS.na($CLJS.P);cna=$CLJS.na($CLJS.P);dna=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.s4=new $CLJS.vj($CLJS.Ui.h("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.EH,dna,$ma,ana,bna,cna);$CLJS.s4.o(null,$CLJS.oa,function(){return null});$CLJS.s4.o(null,$CLJS.LF,function(a){return $CLJS.Fk.g(a)});k0=new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.wF,null,$CLJS.BF,null,$CLJS.CF,null,$CLJS.xF,null],null),null);
$CLJS.Rqa=$CLJS.oh.j($CLJS.Lg,$CLJS.Sm.h($CLJS.Vm.g(k0),$CLJS.rg.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.cu,$CLJS.LF,$CLJS.Fk,a],null);return $CLJS.F.h(a,$CLJS.cl)?$CLJS.U.j(b,$CLJS.oa,!0):b})),$CLJS.mla);$CLJS.Sqa=$CLJS.Wm.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.cu,$CLJS.LF,$CLJS.Fk,a],null);return $CLJS.F.h(a,$CLJS.fl)?$CLJS.U.j(b,$CLJS.oa,!0):b},$CLJS.QV);
$CLJS.Tqa=$CLJS.oh.j($CLJS.Lg,$CLJS.Sm.h($CLJS.Vm.g(k0),$CLJS.rg.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.cu,$CLJS.LF,$CLJS.Fk,a],null);return $CLJS.F.h(a,$CLJS.fl)?$CLJS.U.j(b,$CLJS.oa,!0):b})),$CLJS.VV);$CLJS.K3.o(null,$CLJS.LF,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.Fk);return $CLJS.q4.g(a)});
$CLJS.Q3.o(null,$CLJS.LF,function(a,b,c){var d=$CLJS.nn.l;a=$CLJS.n3.j(a,b,c);b=$CLJS.kG($CLJS.s4.g(c));var e=$CLJS.s4.g(c);e=$CLJS.Ie($CLJS.XV,e)&&!$CLJS.Ie($CLJS.pla,e);return d.call($CLJS.nn,$CLJS.G([new $CLJS.k(null,3,[$CLJS.eC,a,$CLJS.I_,b,$CLJS.t_,e],null),$CLJS.li(c,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oa,$CLJS.r_],null))]))});
$CLJS.t4=function(){var a=$CLJS.na($CLJS.P),b=$CLJS.na($CLJS.P),c=$CLJS.na($CLJS.P),d=$CLJS.na($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,g,l){return $CLJS.EH(l)},e,a,b,c,d)}();$CLJS.t4.o(null,$CLJS.oa,function(){return $CLJS.oi});
$CLJS.E7=function(){function a(d,e,f){return $CLJS.t4.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();