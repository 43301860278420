var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var V1,Fpa,Qla,Kpa,b2,Mpa,Npa,Opa,Ppa,f2,g2,h2,j2,Rpa,k2,l2,Spa,Vla,Wla,OX,Rla,PX,QX,Xla,SX;V1=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};
$CLJS.Epa=function(a,b){return function(){function c(n,q,u){return new $CLJS.S(null,2,5,$CLJS.T,[a.j?a.j(n,q,u):a.call(null,n,q,u),b.j?b.j(n,q,u):b.call(null,n,q,u)],null)}function d(n,q){return new $CLJS.S(null,2,5,$CLJS.T,[a.h?a.h(n,q):a.call(null,n,q),b.h?b.h(n,q):b.call(null,n,q)],null)}function e(n){return new $CLJS.S(null,2,5,$CLJS.T,[a.g?a.g(n):a.call(null,n),b.g?b.g(n):b.call(null,n)],null)}function f(){return new $CLJS.S(null,2,5,$CLJS.T,[a.A?a.A():a.call(null),b.A?b.A():b.call(null)],null)}
var g=null,l=function(){function n(u,v,x,y){var B=null;if(3<arguments.length){B=0;for(var H=Array(arguments.length-3);B<H.length;)H[B]=arguments[B+3],++B;B=new $CLJS.z(H,0,null)}return q.call(this,u,v,x,B)}function q(u,v,x,y){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R(a,u,v,x,y),$CLJS.R.R(b,u,v,x,y)],null)}n.v=3;n.B=function(u){var v=$CLJS.C(u);u=$CLJS.D(u);var x=$CLJS.C(u);u=$CLJS.D(u);var y=$CLJS.C(u);u=$CLJS.zd(u);return q(v,x,y,u)};n.l=q;return n}();g=function(n,q,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,n);case 2:return d.call(this,n,q);case 3:return c.call(this,n,q,u);default:var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.l(n,q,u,x)}throw Error("Invalid arity: "+arguments.length);};g.v=3;g.B=l.B;g.A=f;g.g=e;g.h=d;g.j=c;g.l=l.l;return g}()};$CLJS.MX=function(a,b){return $CLJS.Gpa(a)(b,$CLJS.Lg,$CLJS.Lg)};
Fpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.nE,b),$CLJS.xu(2,2,c))};Qla=function(a,b){var c=$CLJS.Ke(a,$CLJS.xQ);if($CLJS.m(c)){var d=$CLJS.U.j;c=$CLJS.uc(c);b=$CLJS.Mu.h?$CLJS.Mu.h(c,b):$CLJS.Mu.call(null,c,b);a=d.call($CLJS.U,a,$CLJS.xQ,b)}return a};Kpa=function(a){return $CLJS.qg.h($CLJS.wZ(a),$CLJS.xZ(a))};
b2=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ns,null,$CLJS.xn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Nu(2,c):$CLJS.Nu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?V1(2,c):V1(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.L0.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Vm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.bG($CLJS.Hb,l):l}):$CLJS.Km.h(a,f)};
Mpa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return b2(b,Rla,d)},a,Kpa(a))};
Npa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.aL.g(a),b);a:{var d=c,e=$CLJS.HC;$CLJS.HC=!0;try{for(var f=$CLJS.Lg;;){var g=$CLJS.C($CLJS.rg.h($CLJS.Epa($CLJS.$k,$CLJS.tn),$CLJS.ug($CLJS.Sm.j(c2,$CLJS.C,$CLJS.tn),$CLJS.Rs.g($CLJS.MX($CLJS.sW,d)))));if($CLJS.m(g)){var l=g,n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null),u=b2(d,n,q);l=$CLJS.aj;var v=l.l,x=n;var y=$CLJS.m(x)?x:$CLJS.Jpa($CLJS.MX($CLJS.sW,d));var B=v.call(l,$CLJS.G([y]));console.warn("Clean: Removing bad clause due to error!",q,B,$CLJS.Y_($CLJS.C($CLJS.Cz(d,
u))));if($CLJS.F.h(u,d)){var H=d;break a}l=u;var I=$CLJS.kf.h(f,new $CLJS.S(null,2,5,$CLJS.T,[n,q],null));d=l;f=I}else{H=d;break a}}}finally{$CLJS.HC=e}H=void 0}e=Mpa(H);if($CLJS.F.h(c,e)){if($CLJS.F.h(b,$CLJS.E($CLJS.aL.g(a))-1))return a;b+=1}else a=$CLJS.Xm.R(a,$CLJS.aL,$CLJS.U,b,e)}};Opa=function(a){var b=$CLJS.a2();return $CLJS.Wm.h(function(c){return $CLJS.F.h($CLJS.OC.g(c),"__join")?$CLJS.Xm.j(c,$CLJS.OC,b):c},a)};
Ppa=function(a){return"string"===typeof $CLJS.XO.g(a)?$CLJS.Km.h($CLJS.U.j(a,$CLJS.xV,$CLJS.e0($CLJS.XO.g(a))),$CLJS.XO):a};f2=function(){return $CLJS.Vm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);(a=$CLJS.xf(b))?(b=$CLJS.qf(b),b=$CLJS.m(b)?$CLJS.F.h(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};g2=function(a){return $CLJS.oh.j($CLJS.P,f2(),a)};
h2=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Sm.h(f2(),$CLJS.Vm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.cb)})),$CLJS.Ie(a,$CLJS.NX)?$CLJS.Km.l(a,$CLJS.NX,$CLJS.G([$CLJS.iy])):a))};
j2=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.i2),c);a=$CLJS.F.h(a,$CLJS.eE)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.oa,$CLJS.he(d)],null)):d;b=$CLJS.Rf(h2(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dM,a,b],null):a};Rpa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Sm.h($CLJS.rg.g(function(b){return $CLJS.dG(b,$CLJS.VG)}),$CLJS.rg.g($CLJS.i2)),$CLJS.uW.g(a))};
k2=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.aL);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.i2.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.zQ,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.JO,Rpa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.hV.g(c)],null)},null,a));return $CLJS.m($CLJS.aQ.g(a))?$CLJS.TN(a,new $CLJS.k(null,1,[$CLJS.aQ,$CLJS.SD],null)):a};
l2=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.HW(a,b,$CLJS.Sm.h($CLJS.i2,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.HW(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.As],null),$CLJS.rg.g($CLJS.i2),e)}):d;return $CLJS.TN(a,$CLJS.Pf([b,c]))};Spa=function(a){var b=$CLJS.xV.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Km.h(a,$CLJS.xV),$CLJS.XO,["card__",$CLJS.p.g(b)].join("")):a};
$CLJS.NX=new $CLJS.N("metabase.lib.query","transformation-added-base-type","metabase.lib.query/transformation-added-base-type",121891381);Vla=new $CLJS.N(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Wla=new $CLJS.N(null,"legacy-ref","legacy-ref",1686404700);OX=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rla=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);
PX=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);QX=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.RX=new $CLJS.N("lib.convert","converted?","lib.convert/converted?",1937433683);Xla=new $CLJS.N(null,"m","m",1632677161);SX=new $CLJS.N("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var c2,Yla,Zla,$la,ama,bma,cma,dma,ema,fma,gma;$CLJS.p2=$CLJS.P;$CLJS.q2=$CLJS.P;c2=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.pW,null,$CLJS.JC,null,$CLJS.mN,null,$CLJS.RP,null,$CLJS.mE,null,$CLJS.GR,null,$CLJS.nE,null],null),null);Yla=$CLJS.na($CLJS.P);Zla=$CLJS.na($CLJS.P);$la=$CLJS.na($CLJS.P);ama=$CLJS.na($CLJS.P);bma=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.r2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.EH,bma,Yla,Zla,$la,ama);$CLJS.r2.o(null,$CLJS.oa,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.hW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.r2),d))}else b=a;return b});$CLJS.r2.o(null,$CLJS.oW,function(a){return a});
$CLJS.r2.o(null,$CLJS.IV,function(a){var b=$CLJS.r2.g($CLJS.nE.g(a)),c=$CLJS.Rf($CLJS.Wm.h(function(q){var u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);return $CLJS.tW($CLJS.r2.g(q),u)},$CLJS.RP.g(a))),d=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[q,u],null)}),b),e=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.yC);return new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null)}),b),f=$CLJS.q2,g=$CLJS.p2;$CLJS.q2=d;$CLJS.p2=e;try{var l=Fpa(Ppa(a),b,$CLJS.G([$CLJS.RP,c])),n=$CLJS.Sb(function(q,u){return $CLJS.Gb($CLJS.M.h(q,u))?q:$CLJS.Xm.j(q,u,$CLJS.r2)},l,$CLJS.Lm.l(c2,$CLJS.nE,$CLJS.G([$CLJS.RP])));return $CLJS.m($CLJS.mN.g(n))?$CLJS.Xm.j(n,$CLJS.mN,Opa):n}finally{$CLJS.p2=g,$CLJS.q2=f}});$CLJS.r2.o(null,$CLJS.bW,function(a){return Qla(a,function(b){return $CLJS.HW(b,$CLJS.HD,$CLJS.r2)})});
$CLJS.r2.o(null,$CLJS.WK,function(a){a=$CLJS.Xm.j($CLJS.Xm.j(a,$CLJS.dL,$CLJS.r2),$CLJS.aL,$CLJS.r2);var b=$CLJS.m($CLJS.JC.g(a))?$CLJS.Xm.j(a,$CLJS.JC,function(c){return $CLJS.xe(c)?$CLJS.Wm.h($CLJS.r2,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.OC.g(a))?$CLJS.U.j(b,$CLJS.OC,"__join"):b});$CLJS.r2.o(null,$CLJS.$x,function(a){return $CLJS.Wm.h($CLJS.r2,a)});
$CLJS.r2.o(null,$CLJS.ey,function(a){return $CLJS.m($CLJS.$k.g(a))?Npa($CLJS.U.j($CLJS.Xm.j($CLJS.d0(a),$CLJS.aL,function(b){return $CLJS.Wm.h($CLJS.r2,b)}),$CLJS.RX,!0)):$CLJS.Mu(a,$CLJS.r2)});$CLJS.r2.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.hW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,a,b],null))});
$CLJS.r2.o(null,$CLJS.vl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.TN(a,new $CLJS.k(null,3,[$CLJS.xz,$CLJS.iy,$CLJS.vO,$CLJS.Xj,$CLJS.OR,$CLJS.qC],null));var c=$CLJS.U.j;var d=$CLJS.cb.g(a);$CLJS.m(d)||(d=$CLJS.iy.g(a),d=$CLJS.m(d)?d:$CLJS.FL(b));a=c.call($CLJS.U,a,$CLJS.cb,d);return $CLJS.hW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,a,b],null))});
$CLJS.r2.o(null,$CLJS.eE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.oa.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eE,$CLJS.Km.h(c,$CLJS.oa),$CLJS.Wm.h($CLJS.r2,b)],null);b=$CLJS.hW(b);return null!=a?$CLJS.kf.h(b,$CLJS.r2.g(a)):b});$CLJS.r2.o(null,$CLJS.fy,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.hW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.r2.o(null,$CLJS.nE,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null),d=$CLJS.J(a,2,null),e=$CLJS.T;var f=$CLJS.M.h($CLJS.q2,c);if(!$CLJS.m(f))throw $CLJS.zj(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.g(c)].join(""),new $CLJS.k(null,1,[$CLJS.RR,a],null));return $CLJS.hW(new $CLJS.S(null,3,5,e,[b,d,f],null))});
$CLJS.r2.o(null,$CLJS.dM,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.r2.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.nn.l($CLJS.G([c,a]))],null),d)});cma=$CLJS.na($CLJS.P);dma=$CLJS.na($CLJS.P);ema=$CLJS.na($CLJS.P);fma=$CLJS.na($CLJS.P);gma=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.i2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.EH,gma,cma,dma,ema,fma);
$CLJS.i2.o(null,$CLJS.oa,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.i2,d);a=h2(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.i2,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Mu(g2(a),$CLJS.i2):
a;return a});for(var TX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[SX,QX],null)),UX=null,VX=0,WX=0;;)if(WX<VX){var hma=UX.$(null,WX);$CLJS.pV(hma,PX);WX+=1}else{var XX=$CLJS.A(TX);if(XX){var YX=XX;if($CLJS.re(YX)){var ZX=$CLJS.$c(YX),ima=$CLJS.ad(YX),jma=ZX,kma=$CLJS.E(ZX);TX=ima;UX=jma;VX=kma}else{var lma=$CLJS.C(YX);$CLJS.pV(lma,PX);TX=$CLJS.D(YX);UX=null;VX=0}WX=0}else break}
for(var $X=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.gw,$CLJS.sE,$CLJS.HE,$CLJS.aE,$CLJS.mk,$CLJS.LE,$CLJS.fb,$CLJS.iE,$CLJS.FE,$CLJS.NE,$CLJS.KE,$CLJS.SE],null)),aY=null,bY=0,cY=0;;)if(cY<bY){var mma=aY.$(null,cY);$CLJS.pV(mma,SX);cY+=1}else{var dY=$CLJS.A($X);if(dY){var eY=dY;if($CLJS.re(eY)){var fY=$CLJS.$c(eY),nma=$CLJS.ad(eY),oma=fY,pma=$CLJS.E(fY);$X=nma;aY=oma;bY=pma}else{var qma=$CLJS.C(eY);$CLJS.pV(qma,SX);$X=$CLJS.D(eY);aY=null;bY=0}cY=0}else break}
for(var gY=$CLJS.A($CLJS.Hg([$CLJS.es,$CLJS.ft,$CLJS.fs,$CLJS.TE,$CLJS.eE,$CLJS.BE,$CLJS.WD,$CLJS.VE,$CLJS.sD,$CLJS.kE,$CLJS.MD,$CLJS.wE,$CLJS.DE,$CLJS.TD,$CLJS.zy,$CLJS.Ay,$CLJS.Aw,$CLJS.HI,$CLJS.MI,$CLJS.BI,$CLJS.By,$CLJS.GI,$CLJS.zI,$CLJS.PI,$CLJS.UI,$CLJS.RI,$CLJS.FI,$CLJS.WI,$CLJS.Cy,$CLJS.Fy,$CLJS.VD,$CLJS.tD,$CLJS.WE,$CLJS.aH,$CLJS.AE,$CLJS.ew,$CLJS.OE,$CLJS.QE,$CLJS.kF,$CLJS.jt,$CLJS.pt],!0)),hY=null,iY=0,jY=0;;)if(jY<iY){var rma=hY.$(null,jY);$CLJS.pV(rma,QX);jY+=1}else{var kY=$CLJS.A(gY);
if(kY){var lY=kY;if($CLJS.re(lY)){var mY=$CLJS.$c(lY),sma=$CLJS.ad(lY),tma=mY,uma=$CLJS.E(mY);gY=sma;hY=tma;iY=uma}else{var vma=$CLJS.C(lY);$CLJS.pV(vma,QX);gY=$CLJS.D(lY);hY=null;iY=0}jY=0}else break}$CLJS.i2.o(null,PX,function(a){return j2(a)});$CLJS.i2.o(null,$CLJS.ey,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Sm.h(f2(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.i2.g(b)],null)})),a)});
$CLJS.i2.o(null,$CLJS.nE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);if($CLJS.oe(b))try{var d=h2(b);b=$CLJS.T;var e=$CLJS.p2,f=$CLJS.M.j(e,c,OX);if($CLJS.F.h(f,OX))throw $CLJS.zj(["Unable to find key ",$CLJS.aj.l($CLJS.G([c]))," in map."].join(""),new $CLJS.k(null,2,[Xla,e,$CLJS.Av,c],null));var g=new $CLJS.S(null,2,5,b,[$CLJS.nE,f],null);return $CLJS.A(d)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dM,g,d],null):g}catch(l){throw c=l,d=$CLJS.UZ(c),d=$CLJS.xa.h?$CLJS.xa.h("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.xa.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.yj(d,new $CLJS.k(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.i2.o(null,$CLJS.$x,function(a){return $CLJS.Wm.h($CLJS.i2,a)});
$CLJS.i2.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,$CLJS.i2.g(a),h2(b)],null)});
$CLJS.i2.o(null,$CLJS.vl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=h2($CLJS.TN(b,new $CLJS.k(null,3,[$CLJS.iy,$CLJS.xz,$CLJS.Xj,$CLJS.vO,$CLJS.qC,$CLJS.OR],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,a,b],null)});
$CLJS.i2.o(null,$CLJS.WK,function(a){var b=g2(a);a=0==$CLJS.OC.g(a).lastIndexOf("__join",0)?$CLJS.Km.h(b,$CLJS.OC):b;return $CLJS.nn.l($CLJS.G([$CLJS.Mu($CLJS.Km.l(a,$CLJS.aL,$CLJS.G([$CLJS.dL])),$CLJS.i2),l2($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.dL],null)),$CLJS.dL,$CLJS.IR),k2(a)]))});
$CLJS.i2.o(null,$CLJS.IV,function(a){var b=$CLJS.nE.g(a),c=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(f,g){$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);g=$CLJS.Qf(g);g=$CLJS.M.h(g,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[f,g],null)}),b);b=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(f,g){$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);g=$CLJS.Qf(g);g=$CLJS.M.h(g,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[g,f],null)}),b);var d=$CLJS.q2,e=$CLJS.p2;$CLJS.q2=c;$CLJS.p2=b;try{return $CLJS.Sb(function(f,g){return $CLJS.HW(f,
g,$CLJS.i2)},l2($CLJS.HW($CLJS.HW(Spa(g2(a)),$CLJS.nE,function(f){return $CLJS.Wm.h(j2,f)}),$CLJS.RP,function(f){return $CLJS.oh.h($CLJS.P,function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y),H=$CLJS.i2.g(B);B=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$_(B),$CLJS.F.h($CLJS.vl,$CLJS.C(H))?$CLJS.Zd(H):H],null);x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),
n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);u=$CLJS.i2.g(x);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$_(x),$CLJS.F.h($CLJS.vl,$CLJS.C(u))?$CLJS.Zd(u):u],null),n($CLJS.zd(q)))}return null}},null,null)}(f)}())}),$CLJS.pW,$CLJS.$D),$CLJS.Lm.l(c2,$CLJS.nE,$CLJS.G([$CLJS.pW,$CLJS.RP])))}finally{$CLJS.p2=e,$CLJS.q2=d}});$CLJS.i2.o(null,$CLJS.bW,function(a){return $CLJS.Mu(g2(a),$CLJS.i2)});
$CLJS.i2.o(null,$CLJS.oW,function(a){try{var b=g2(a),c=$CLJS.dN.g(b),d=k2(b),e=$CLJS.F.h($CLJS.cu.g($CLJS.$d($CLJS.aL.g(a))),$CLJS.bW)?$CLJS.aQ:$CLJS.SD;return $CLJS.nn.l($CLJS.G([$CLJS.Km.l(b,$CLJS.aL,$CLJS.G([$CLJS.dN,$CLJS.RX])),function(){var g=$CLJS.Pf([$CLJS.$k,e,e,d]);return $CLJS.A(c)?$CLJS.U.j(g,$CLJS.dN,c):g}()]))}catch(g){var f=g;throw $CLJS.yj(function(){var l=$CLJS.UZ(f);return $CLJS.xa.h?$CLJS.xa.h("Error converting MLv2 query to legacy query: %s",l):$CLJS.xa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.k(null,1,[$CLJS.SD,a],null),f);}});
$CLJS.nY=function(){function a(d,e,f){f=$CLJS.Z$(null,$CLJS.iF(f,$CLJS.G([$CLJS.hk,!0])));var g=$CLJS.e3(d,e);g=$CLJS.Qf(g);var l=$CLJS.M.h(g,$CLJS.nE);g=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(v,x){$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.Qf(x);x=$CLJS.M.h(x,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[v,x],null)}),l);l=$CLJS.oh.j($CLJS.P,$CLJS.Oo(function(v,x){$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);x=$CLJS.Qf(x);x=$CLJS.M.h(x,$CLJS.yC);return new $CLJS.S(null,2,5,$CLJS.T,[x,v],null)}),l);var n=
$CLJS.q2,q=$CLJS.p2;$CLJS.q2=g;$CLJS.p2=l;try{try{return $CLJS.r2.g(f)}catch(v){var u=v;throw $CLJS.yj(function(){var x=$CLJS.UZ(u);return $CLJS.xa.h?$CLJS.xa.h("Error converting legacy ref to pMBQL: %s",x):$CLJS.xa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.k(null,4,[$CLJS.SD,d,$CLJS.yL,e,Wla,f,Vla,$CLJS.q2],null),u);}}finally{$CLJS.p2=q,$CLJS.q2=n}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();