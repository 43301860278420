var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var t6,u6,v6,Fta,w6,x6,Hta,y6,Jta,Kta,z6,Uta,hua,A6,D6,nua,I6,pua,eva,J6,K6,Q6,U6,lva,X6,mva,nva,tva,Gva,Y6,Hva,Iva,Z6,Jva,Kva,Lva,Mva,Nva,$6,Ova,Pva,Qva,Rva,Sva,Tva,Uva,a7,Vva,Wva,Xva,Yva,Zva,$va,awa,bwa,cwa,dwa,ewa,fwa,gwa,lwa,zwa,Awa,Nwa,b7,c7,Owa,Pwa,Qwa,d7,Rwa,Swa,Twa,Uwa,p7,Vwa,Wwa,Xwa,q7,Ywa,Zwa,$wa,axa,bxa;$CLJS.hwa=function(a){return $CLJS.Vc($CLJS.Sb(function(b,c){return $CLJS.rh.j(b,c,$CLJS.M.j(b,c,0)+1)},$CLJS.Tc($CLJS.P),a))};
t6=function(a){var b=$CLJS.QZ(a);return $CLJS.m(b)?$CLJS.ug($CLJS.U4,$CLJS.Bta(a,b)):$CLJS.Lg};
u6=function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.ob),e=$CLJS.M.h(c,$CLJS.a5),f=$CLJS.M.h(c,$CLJS.BL),g=$CLJS.M.h(c,$CLJS.vl);return $CLJS.m(function(){var l=$CLJS.F4(a,b);return l?(l=$CLJS.Rf(f),$CLJS.m(l)?(l=$CLJS.m(d)?null!=g:d,l=$CLJS.m(l)?l:null==d&&null==g,$CLJS.m(l)?$CLJS.Gb($CLJS.m(d)?$CLJS.HZ(d):d):l):l):l}())?new $CLJS.k(null,6,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.K5,$CLJS.F5,"number"!==typeof g||0>g?2:g,$CLJS.FY,function(){var l=null==a?null:$CLJS.QZ(a);l=null==l?null:$CLJS.j3(a,l);
$CLJS.m(l)||(l=null==a?null:$CLJS.s1(a),l=null==l?null:$CLJS.k3(a,l));return $CLJS.m(l)?$CLJS.n3.j(a,b,l):null}(),$CLJS.BL,f,$CLJS.a5,e],null):null};
v6=function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.ob),e=$CLJS.M.h(c,$CLJS.a5),f=$CLJS.M.h(c,$CLJS.BL),g=$CLJS.M.h(c,$CLJS.vl);return $CLJS.m(function(){var l=$CLJS.F4(a,b);return l?(l=$CLJS.Gb(d)||null!=g)?(l=$CLJS.i6(a,$CLJS.Osa),$CLJS.m(l)?$CLJS.Rf(f):l):l:l}())?new $CLJS.k(null,4,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.t5,$CLJS.a5,e,$CLJS.BL,f],null):null};
Fta=function(a,b,c,d){var e=$CLJS.Rta(a,$CLJS.zW(a,b)),f=$CLJS.ki.h($CLJS.vK.g(c),$CLJS.UK)?new $CLJS.k(null,2,[$CLJS.SD,a,$CLJS.yL,b],null):$CLJS.m(e)?new $CLJS.k(null,2,[$CLJS.SD,a,$CLJS.yL,e],null):new $CLJS.k(null,2,[$CLJS.SD,$CLJS.vua(a),$CLJS.yL,-1],null),g=$CLJS.N8.h($CLJS.SD.g(f),$CLJS.yL.g(f));a=function(){var l=$CLJS.y1.D($CLJS.SD.g(f),$CLJS.yL.g(f),d,g);if($CLJS.m(l))return l;l=$CLJS.m0.g(c);return $CLJS.m(l)?$CLJS.wW(function(n){return $CLJS.F.h($CLJS.m0.g(n),$CLJS.m0.g(c))},g):l}();return $CLJS.U.j(f,
$CLJS.ob,a)};w6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);var e=$CLJS.M.h(d,$CLJS.a5);d=$CLJS.M.h(d,$CLJS.vl);var f=$CLJS.F4(a,b);d=f?$CLJS.m(c)?null==d&&!$CLJS.HZ(c):c:f;return $CLJS.m(d)?(d=$CLJS.CZ(c)?null:$CLJS.U.j($CLJS.C($CLJS.W1(c)),$CLJS.cu,$CLJS.zJ),$CLJS.nn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.o5,$CLJS.m5,d],null),Fta(a,b,c,e)]))):null};
x6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);d=$CLJS.M.h(d,$CLJS.vl);var e=$CLJS.F4(a,b);a=e?$CLJS.m(c)?null==d&&$CLJS.ki.h($CLJS.vK.g(c),$CLJS.UK)&&!$CLJS.U4(c)&&!$CLJS.HZ(c)&&!$CLJS.OZ(c)&&!$CLJS.S4(c)&&$CLJS.Gb($CLJS.A($CLJS.E4.j(a,b,c))):c:e;return $CLJS.m(a)?new $CLJS.k(null,3,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.J5,$CLJS.ob,c],null):null};
Hta=function(a,b,c){c=$CLJS.Qf(c);b=$CLJS.M.h(c,$CLJS.ob);c=$CLJS.M.h(c,$CLJS.vl);return $CLJS.T4(b)&&null!=c&&$CLJS.ki.h(c,$CLJS.S5)?new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.H5,$CLJS.ob,b,$CLJS.b5,c,$CLJS.J4,1<$CLJS.E(t6(a))],null):null};
y6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);var e=$CLJS.M.h(d,$CLJS.a5);d=$CLJS.M.h(d,$CLJS.vl);if($CLJS.m($CLJS.m(c)?null!=d&&$CLJS.ki.h(d,$CLJS.S5)&&$CLJS.F4(a,b)&&!$CLJS.U4(c)&&$CLJS.T4(c):c)){var f=null==a?null:$CLJS.QZ(a);f=null==f?null:$CLJS.j3(a,f);$CLJS.m(f)||(f=null==a?null:$CLJS.s1(a),f=null==f?null:$CLJS.k3(a,f));return new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.r5,$CLJS.$D,$CLJS.hW(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.$r,$CLJS.P,e,d],null)),$CLJS.hE,$CLJS.n3.D(a,
b,c,$CLJS.d_),$CLJS.FY,$CLJS.n3.j(a,0,f)],null)}return null};
Jta=function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.ob),f=$CLJS.M.h(d,$CLJS.vl),g=$CLJS.M.h(d,$CLJS.Ga);if(null!=f&&$CLJS.F4(a,b)&&1<$CLJS.E(t6(a))&&!$CLJS.T4(e)){if($CLJS.U4(e))return null!=f&&$CLJS.ki.h(f,$CLJS.S5)?new $CLJS.k(null,3,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.z5,$CLJS.BL,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.ob,e,$CLJS.vl,f],null)],null)],null):null;var l=t6(a);a=function(){return function u(q){return new $CLJS.yf(null,function(){for(var v=q;;){var x=$CLJS.A(v);if(x){var y=
x;if($CLJS.re(y)){var B=$CLJS.$c(y),H=$CLJS.E(B),I=$CLJS.Bf(H);return function(){for(var aa=0;;)if(aa<H){var ha=$CLJS.be(B,aa),qa=$CLJS.vl.g($CLJS.wW(function(Ea,kb,lb){return function(Fb){return $CLJS.F.h($CLJS.V.g($CLJS.ob.g(Fb)),$CLJS.V.g(lb))}}(aa,v,ha,B,H,I,y,x,l,c,d,d,e,f,g),g));$CLJS.m(qa)&&$CLJS.Ff(I,new $CLJS.k(null,2,[$CLJS.ob,ha,$CLJS.vl,qa],null));aa+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(I),u($CLJS.ad(y))):$CLJS.Ef($CLJS.Gf(I),null)}var Q=$CLJS.C(y),Y=$CLJS.vl.g($CLJS.wW(function(aa,ha){return function(qa){return $CLJS.F.h($CLJS.V.g($CLJS.ob.g(qa)),
$CLJS.V.g(ha))}}(v,Q,y,x,l,c,d,d,e,f,g),g));if($CLJS.m(Y))return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.ob,Q,$CLJS.vl,Y],null),u($CLJS.zd(y)));v=$CLJS.zd(y)}else return null}},null,null)}(l)}();return $CLJS.A(a)?new $CLJS.k(null,3,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.z5,$CLJS.BL,$CLJS.Mg($CLJS.Ata(function(n){return $CLJS.Lu(n,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ob,$CLJS.Zf],null))},a))],null):null}return null};
Kta=function(a,b,c){var d=$CLJS.Qf(c);b=$CLJS.M.h(d,$CLJS.ob);c=$CLJS.M.h(d,$CLJS.vl);d=$CLJS.M.h(d,$CLJS.Ga);if(null==c||1<$CLJS.E(t6(a)))return null;if($CLJS.U4(b))return $CLJS.F.h(c,$CLJS.S5)?null:new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.c6,$CLJS.ob,b,$CLJS.b5,c,$CLJS.J4,!1],null);a=t6(a);var e=$CLJS.J(a,0,null);a=$CLJS.vl.g($CLJS.wW(function(f){return $CLJS.F.h($CLJS.V.g($CLJS.ob.g(f)),$CLJS.V.g(e))},d));return $CLJS.m(a)?null==a?null:new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.$k,
$CLJS.c6,$CLJS.ob,e,$CLJS.b5,a,$CLJS.J4,!1],null):null};z6=function(a,b,c){return $CLJS.Xf(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.S(null,3,5,$CLJS.T,[Hta,Jta,Kta],null))};Uta=function(a,b,c,d){var e=$CLJS.Qf(c);c=$CLJS.M.h(e,$CLJS.ob);e=$CLJS.M.h(e,$CLJS.vl);var f=$CLJS.F4(a,b);c=f?$CLJS.m(c)?null!=e&&$CLJS.F.h($CLJS.vK.g(c),$CLJS.UK):c:f;return $CLJS.m(c)?$CLJS.ug(d,$CLJS.a9.h(a,b)):null};
hua=function(a,b){var c=$CLJS.hwa($CLJS.rg.h(function(d){d=$CLJS.b_.j(a,b,d);return $CLJS.CZ(d)?$CLJS.tP:$CLJS.JZ(d)?$CLJS.$sa:$CLJS.GZ(d)?$CLJS.RE:null},$CLJS.$8.h(a,b)));return $CLJS.F.h(new $CLJS.k(null,1,[$CLJS.tP,1],null),c)?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RE,null,$CLJS.j5,null],null),null):$CLJS.F.h(new $CLJS.k(null,2,[$CLJS.tP,1,$CLJS.RE,1],null),c)?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RE,null,$CLJS.j5,null],null),null):$CLJS.F.h(new $CLJS.k(null,1,[$CLJS.$sa,1],null),c)?
new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RE,null,$CLJS.Aw,null],null),null):$CLJS.F.h($CLJS.P,c)?new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.RE,null,$CLJS.Aw,null,$CLJS.j5,null],null),null):$CLJS.F.h(new $CLJS.k(null,1,[$CLJS.RE,1],null),c)?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RE,null,$CLJS.Aw,null],null),null):$CLJS.F.h(new $CLJS.k(null,1,[$CLJS.RE,2],null),c)?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RE,null,$CLJS.Aw,null],null),null):$CLJS.oi};
A6=function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.ob);c=$CLJS.M.h(d,$CLJS.BL);var f=$CLJS.M.h(d,$CLJS.vl);if($CLJS.m(function(){var n=$CLJS.F4(a,b);return n?$CLJS.m(e)?null!=f&&$CLJS.F.h($CLJS.vK.g(e),$CLJS.UK)&&0<$CLJS.E($CLJS.W8.h(a,b)):e:n}())){var g=hua(a,b),l=$CLJS.oh.h($CLJS.P,function(){return function u(q){return new $CLJS.yf(null,function(){for(var v=q;;)if(v=$CLJS.A(v)){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);a:for(var H=0;;)if(H<y){var I=$CLJS.be(x,H),Q=$CLJS.M.h($CLJS.ata,
I);Q=Uta(a,b,d,Q);$CLJS.m($CLJS.Rf(Q))&&B.add(new $CLJS.S(null,2,5,$CLJS.T,[I,Q],null));H+=1}else{x=!0;break a}return x?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}B=$CLJS.C(v);x=$CLJS.M.h($CLJS.ata,B);x=Uta(a,b,d,x);if($CLJS.m($CLJS.Rf(x)))return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[B,x],null),u($CLJS.zd(v)));v=$CLJS.zd(v)}else return null},null,null)}(g)}());return $CLJS.je(l)?null:new $CLJS.k(null,4,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.V5,$CLJS.BL,c,$CLJS.f5,l],null)}return null};
D6=function(a,b){return $CLJS.hW($CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,$CLJS.P],null),b))};
nua=function(a,b){var c=$CLJS.iW(a);if($CLJS.HZ(a))return $CLJS.Lg;if($CLJS.F.h(b,$CLJS.S5)){var d=function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=new $CLJS.k(null,2,[$CLJS.V,x,$CLJS.$D,D6(y,$CLJS.G([c]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);
u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.V,n,$CLJS.$D,D6(u,$CLJS.G([c]))],null),g($CLJS.zd(l)))}return null}},null,null)};return d($CLJS.EZ(a)||$CLJS.FZ(a)?new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZD,"\x3d"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gE,"≠"],null)],null):new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XD,"\x3d"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jE,"≠"],null)],null))}if($CLJS.DZ(a)||$CLJS.CZ(a))return d=
function g(f){return new $CLJS.yf(null,function(){for(var l=f;;)if(l=$CLJS.A(l)){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);return function(){for(var y=0;;)if(y<q){var B=$CLJS.be(n,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ur,null,$CLJS.Wr,null],null),null);I=I.g?I.g(H):I.call(null,H);I=(I=$CLJS.Gb(I))?I:$CLJS.xJ(c,b);$CLJS.m(I)&&(H=new $CLJS.k(null,2,[$CLJS.V,B,$CLJS.$D,D6(H,$CLJS.G([c,b]))],null),u.add(H));y+=1}else return!0}()?
$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}var v=$CLJS.C(l),x=$CLJS.J(v,0,null);v=$CLJS.J(v,1,null);if($CLJS.m(function(){var y=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Ur,null,$CLJS.Wr,null],null),null);y=y.g?y.g(x):y.call(null,x);return(y=$CLJS.Gb(y))?y:$CLJS.xJ(c,b)}()))return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.V,v,$CLJS.$D,D6(x,$CLJS.G([c,b]))],null),g($CLJS.zd(l)));l=$CLJS.zd(l)}else return null},null,null)},d(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,
[$CLJS.Wr,"\x3c"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ur,"\x3e"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,"\x3d"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PE,"≠"],null)],null));if($CLJS.EZ(a)&&($CLJS.OZ(a)||$CLJS.S4(a)))return d=function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=new $CLJS.k(null,2,[$CLJS.V,x,$CLJS.$D,
D6(y,$CLJS.G([c,b]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.V,n,$CLJS.$D,D6(u,$CLJS.G([c,b]))],null),g($CLJS.zd(l)))}return null}},null,null)},d(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xE,"contains"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qE,"does-not-contain"],null)],null));d=function g(f){return new $CLJS.yf(null,
function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=new $CLJS.k(null,2,[$CLJS.V,x,$CLJS.$D,D6(y,$CLJS.G([c,b]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.V,n,$CLJS.$D,D6(u,$CLJS.G([c,b]))],null),g($CLJS.zd(l)))}return null}},
null,null)};return d(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,"\x3d"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PE,"≠"],null)],null))};
I6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);var e=$CLJS.M.h(d,$CLJS.a5);d=$CLJS.M.h(d,$CLJS.vl);var f=(f=$CLJS.F4(a,b))?$CLJS.m(c)?null!=d&&!$CLJS.HZ(c)&&!$CLJS.U4(c)&&!$CLJS.T4(c):c:f;return $CLJS.m(f)?(a=Fta(a,b,c,e),$CLJS.nn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.$4,$CLJS.wL,nua($CLJS.ob.g(a),d),$CLJS.vl,d],null),a]))):null};
pua=function(a,b,c){return $CLJS.wW(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);d=$CLJS.J(d,2,null);return $CLJS.y1.D(a,b,d,new $CLJS.S(null,1,5,$CLJS.T,[c],null))},$CLJS.j9.h(a,b))};eva=function(a,b,c){a=pua(a,b,c);return $CLJS.m(a)?(b=$CLJS.J(a,0,null),$CLJS.J(a,1,null),$CLJS.J(a,2,null),b):null};
J6=function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.ob),e=$CLJS.M.h(c,$CLJS.a5),f=$CLJS.M.h(c,$CLJS.vl);if($CLJS.m(function(){var l=$CLJS.F4(a,b);return l?$CLJS.m(d)?null==f&&!$CLJS.HZ(d):d:l}())&&$CLJS.m($CLJS.y1.D(a,b,e,$CLJS.k9.h(a,b)))){var g=eva(a,b,d);return new $CLJS.k(null,4,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.v5,$CLJS.ob,d,$CLJS.y5,function(){switch(g instanceof $CLJS.N?g.S:null){case "asc":return new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.oE],null);case "desc":return new $CLJS.S(null,1,5,$CLJS.T,
[$CLJS.vE],null);default:return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vE,$CLJS.oE],null)}}()],null)}return null};
K6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);d=$CLJS.M.h(d,$CLJS.vl);var e=$CLJS.F4(a,b);a=e?$CLJS.m(c)?null==d&&!$CLJS.HZ(c)&&$CLJS.ki.h($CLJS.vK.g(c),$CLJS.UK)&&$CLJS.Gb($CLJS.A($CLJS.E4.j(a,b,c))):c:e;return $CLJS.m(a)?(a=$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.aE],null),$CLJS.R4(c)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KE,$CLJS.sE],null):null),new $CLJS.k(null,4,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.w5,$CLJS.ob,c,$CLJS.B5,a],null)):null};
Q6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);d=$CLJS.M.h(d,$CLJS.vl);var e=$CLJS.F4(a,b);d=e?$CLJS.m(c)?null==d&&!$CLJS.HZ(c)&&$CLJS.R4(c)&&$CLJS.ki.h($CLJS.vK.g(c),$CLJS.UK):c:e;if($CLJS.m(d)&&(d=$CLJS.wW($CLJS.CZ,$CLJS.a9.h(a,b)),$CLJS.m(d)&&(e=$CLJS.wW($CLJS.oa,$CLJS.E7.j(a,b,d)),$CLJS.m(e)))){var f=$CLJS.Z7(d,e);return $CLJS.m($CLJS.vV($CLJS.rg.h($CLJS.iW,$CLJS.nf(f,$CLJS.$8.h(a,b)))))?new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.Q5,$CLJS.ob,c,$CLJS.mE,d,$CLJS.Fk,$CLJS.s4.g(e)],
null):null}return null};
U6=function(a,b,c){var d=$CLJS.Qf(c);c=$CLJS.M.h(d,$CLJS.ob);d=$CLJS.M.h(d,$CLJS.vl);if($CLJS.m($CLJS.m(c)?d:c))if(b=$CLJS.C($CLJS.E4.j(a,b,c)),$CLJS.m(b))if(b=$CLJS.s7(b),$CLJS.m(b))if(a=$CLJS.j6(a,c,d),$CLJS.m(a)){var e=$CLJS.Qf(a);a=$CLJS.M.h(e,$CLJS.AL);var f=$CLJS.M.h(e,$CLJS.GL);e=$CLJS.M.h(e,$CLJS.DC);var g=$CLJS.EC.g(b);g=g instanceof $CLJS.N?g.S:null;switch(g){case "num-bins":case "default":return new $CLJS.k(null,6,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.A5,$CLJS.ob,c,$CLJS.AL,d,$CLJS.GL,d+e,
$CLJS.u5,new $CLJS.k(null,1,[$CLJS.EC,$CLJS.oa],null)],null);case "bin-width":return new $CLJS.k(null,6,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.A5,$CLJS.ob,c,$CLJS.AL,a,$CLJS.GL,f,$CLJS.u5,$CLJS.Xm.j(b,$CLJS.DC,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}else return null;else return null;else return null;else return null};
lva=function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.Ga),e=$CLJS.e3(a,b);b=$CLJS.Xf(function(f){var g=$CLJS.wW($CLJS.W4,f);return $CLJS.m(g)?(f=$CLJS.wW($CLJS.X4,f),$CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[g,f],null):null):null},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.L_.j(a,b,e),$CLJS.N_.j(a,b,e)],null));a=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.m($CLJS.m(a)?b:a)?(e=function(f){return $CLJS.Xf(function(g){var l=$CLJS.ob.g(g);l=$CLJS.m($CLJS.Zf.g(f))?$CLJS.F.h($CLJS.Zf.g(f),$CLJS.Zf.g(l)):
$CLJS.F.h($CLJS.DY.g(f),$CLJS.DY.g(l));return $CLJS.m(l)?$CLJS.vl.g(g):null},d)},$CLJS.U.l(c,$CLJS.o6,a,$CLJS.G([$CLJS.p6,b,$CLJS.Eta,e(a),$CLJS.Dta,e(b)]))):null};
X6=function(a,b){var c=$CLJS.Qf(a);a=$CLJS.M.h(c,$CLJS.ob);var d=$CLJS.M.h(c,$CLJS.vl),e=$CLJS.M.h(c,$CLJS.o6);c=$CLJS.M.h(c,$CLJS.p6);return $CLJS.m(d)?new $CLJS.k(null,7,[$CLJS.cu,$CLJS.W5,$CLJS.$k,$CLJS.$5,$CLJS.e5,$CLJS.p5,$CLJS.ob,a,$CLJS.vl,d,$CLJS.s5,new $CLJS.k(null,2,[$CLJS.ob,e,$CLJS.DC,b],null),$CLJS.q5,new $CLJS.k(null,2,[$CLJS.ob,c,$CLJS.DC,b],null)],null):null};mva=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.ob);return $CLJS.m(null==b?null:$CLJS.MZ(b))?X6(a,10):null};
nva=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.ob);return $CLJS.m(null==b?null:$CLJS.LZ(b))?X6(a,1):null};tva=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.ob);return $CLJS.m(null==b?null:$CLJS.KZ(b))?X6(a,.1):null};
Gva=function(a,b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.o6);var d=$CLJS.M.h(c,$CLJS.p6),e=$CLJS.M.h(c,$CLJS.Eta);c=$CLJS.M.h(c,$CLJS.Dta);if($CLJS.m($CLJS.m(e)?c:e)){var f=$CLJS.j6(a,b,e);if($CLJS.m(f)&&(f=$CLJS.Qf(f),f=$CLJS.M.h(f,$CLJS.DC),a=$CLJS.j6(a,d,c),$CLJS.m(a))){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.DC);var g=20<=f&&20<=a?new $CLJS.S(null,2,5,$CLJS.T,[10,10],null):new $CLJS.S(null,2,5,$CLJS.T,[f/10,a/10],null),l=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,
$CLJS.$k,$CLJS.$5,$CLJS.e5,$CLJS.L4,$CLJS.s5,new $CLJS.k(null,4,[$CLJS.ob,b,$CLJS.DC,l,$CLJS.fb,e,$CLJS.mk,e+f],null),$CLJS.q5,new $CLJS.k(null,4,[$CLJS.ob,d,$CLJS.DC,g,$CLJS.fb,c,$CLJS.mk,c+a],null)],null)}}return null};Y6=function(a,b,c){c=$CLJS.Qf(c);var d=$CLJS.M.h(c,$CLJS.vl);if($CLJS.m(d)){var e=lva(a,b,c);return $CLJS.m(e)?$CLJS.Xf(function(f){return f.g?f.g(e):f.call(null,e)},new $CLJS.S(null,4,5,$CLJS.T,[mva,nva,tva,$CLJS.bg(Gva,a)],null)):null}return null};
Hva=function(a,b,c){return $CLJS.C(function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;){var l=$CLJS.A(g);if(l){var n=l,q=$CLJS.C(n);if($CLJS.m(function(){var u=$CLJS.r1(q,$CLJS.yE);return u?$CLJS.$7(q):u}())&&(l=$CLJS.A(function(u,v,x,y){return function I(H){return new $CLJS.yf(null,function(Q,Y){return function(){for(var aa=H;;)if(aa=$CLJS.A(aa)){if($CLJS.re(aa)){var ha=$CLJS.$c(aa),qa=$CLJS.E(ha),Ea=$CLJS.Bf(qa);return function(){for(var Mb=0;;)if(Mb<qa){var ac=$CLJS.be(ha,
Mb),Tb=$CLJS.Qf(ac);ac=Tb;Tb=$CLJS.M.h(Tb,$CLJS.ob);var Ub=$CLJS.y1.h(Y,new $CLJS.S(null,1,5,$CLJS.T,[Tb],null));Tb=$CLJS.m(Ub)?$CLJS.F.h($CLJS.$7(Y),$CLJS.$7(Tb)):Ub;$CLJS.m(Tb)&&(ac=$CLJS.U.j(ac,$CLJS.a5,Y),Ea.add(ac));Mb+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(Ea),I($CLJS.ad(aa))):$CLJS.Ef($CLJS.Gf(Ea),null)}var kb=$CLJS.C(aa),lb=kb=$CLJS.Qf(kb),Fb=$CLJS.M.h(kb,$CLJS.ob);if($CLJS.m(function(){var Mb=$CLJS.y1.h(Y,new $CLJS.S(null,1,5,$CLJS.T,[Fb],null));return $CLJS.m(Mb)?$CLJS.F.h($CLJS.$7(Y),$CLJS.$7(Fb)):
Mb}()))return $CLJS.nf($CLJS.U.j(lb,$CLJS.a5,Y),I($CLJS.zd(aa)));aa=$CLJS.zd(aa)}else return null}}(u,v,x,y),null,null)}}(g,q,n,l)(c))))return $CLJS.qg.h(l,f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null}},null,null)}($CLJS.$8.h(a,b))}())};
Iva=function(a){a=a instanceof $CLJS.N?a.S:null;switch(a){case "quarter":return $CLJS.XH("See this year by quarter");case "month":return $CLJS.XH("See this quarter by month");case "week":return $CLJS.XH("See this month by week");case "day":return $CLJS.XH("See this week by day");case "hour":return $CLJS.XH("See this day by hour");case "minute":return $CLJS.XH("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}};
Z6=function(a,b,c){c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.BL);var d=(d=$CLJS.F4(a,b))?$CLJS.Rf(c):d;return $CLJS.m(d)&&(a=Hva(a,b,c),$CLJS.m(a)&&(a=$CLJS.Qf(a),b=$CLJS.M.h(a,$CLJS.vl),$CLJS.m(b)))?(b=$CLJS.ob.g(a),b=$CLJS.s4.g(b),b=$CLJS.m(b)?$CLJS.Ie($CLJS.si($CLJS.q6),b)?$CLJS.r6.g?$CLJS.r6.g(b):$CLJS.r6.call(null,b):null:null,$CLJS.m(b)?new $CLJS.k(null,5,[$CLJS.cu,$CLJS.W5,$CLJS.eC,Iva(b),$CLJS.$k,$CLJS.Y5,$CLJS.HD,a,$CLJS.P5,b],null):null):null};
Jva=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.BL);return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.nn.l($CLJS.G([b,v]));q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.nn.l($CLJS.G([b,q])),f($CLJS.zd(g)))}return null}},null,
null)}(c)}())};Kva=new $CLJS.w(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);Lva=new $CLJS.w(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);Mva=new $CLJS.w("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
Nva=new $CLJS.w(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);$6=new $CLJS.w(null,"stage-number","stage-number",-112198111,null);Ova=new $CLJS.w(null,"underlying-records-drill","underlying-records-drill",713740052,null);Pva=new $CLJS.w("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
Qva=new $CLJS.w(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);Rva=new $CLJS.w(null,"distribution-drill","distribution-drill",-898205041,null);Sva=new $CLJS.w(null,"sort-drill","sort-drill",600387798,null);Tva=new $CLJS.N(null,"drill","drill",-1704624793);Uva=new $CLJS.w("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);a7=new $CLJS.w(null,"_context","_context",-1238791322,null);
Vva=new $CLJS.w(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);Wva=new $CLJS.w(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);Xva=new $CLJS.w(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);Yva=new $CLJS.w(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
Zva=new $CLJS.w("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);$va=new $CLJS.w("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);awa=new $CLJS.w(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
bwa=new $CLJS.w(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);cwa=new $CLJS.w(null,"pivot-drill","pivot-drill",-1794856768,null);dwa=new $CLJS.w(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);ewa=new $CLJS.w(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
fwa=new $CLJS.w("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);gwa=new $CLJS.w(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);lwa=new $CLJS.w(null,"column-filter-drill","column-filter-drill",-1088669153,null);
zwa=new $CLJS.w("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Awa=new $CLJS.w(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Nwa=new $CLJS.w(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);b7=new $CLJS.w(null,"dimensions","dimensions",1385713430,null);
c7=new $CLJS.N(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Owa=new $CLJS.w("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Pwa=new $CLJS.w(null,"object-detail-drill","object-detail-drill",-613919053,null);Qwa=new $CLJS.w(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
d7=new $CLJS.w(null,"context","context",810340414,null);Rwa=new $CLJS.w(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Swa=new $CLJS.w("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Twa=new $CLJS.w(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Uwa=new $CLJS.w("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);p7=new $CLJS.w(null,"column-ref","column-ref",-636247393,null);Vwa=new $CLJS.w("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Wwa=new $CLJS.w(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Xwa=new $CLJS.w("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);q7=new $CLJS.w(null,"query","query",352022017,null);Ywa=new $CLJS.w(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Zwa=new $CLJS.w(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
$wa=new $CLJS.w("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);axa=new $CLJS.w("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);bxa=new $CLJS.w(null,"quick-filter-drill","quick-filter-drill",534264366,null);var cxa;$CLJS.Q3.o(null,$CLJS.W5,function(a,b,c){return $CLJS.I4.j(a,b,c)});
cxa=new $CLJS.S(null,14,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return v6},Swa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.isa],null)],null),Zwa,Yva,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,
new $CLJS.k(null,1,[$CLJS.lm,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ex,p7,b7,$CLJS.pb],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.m(v6)?v6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return w6},Owa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.k5],null)],null),Qwa,lwa,"metabase/lib/drill_thru/column_filter.cljc",29,1,82,82,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
1,[$CLJS.lm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ex,p7,$CLJS.pb],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.m(w6)?w6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return x6},$va,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.d5],null)],null),Vva,Rva,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
1,[$CLJS.lm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ex,$CLJS.pb],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.m(x6)?x6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return y6},$wa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.L5],null)],null),Wwa,awa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,37,37,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ex,p7,$CLJS.pb],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.m(y6)?y6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return z6},Uwa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,$CLJS.P4,$CLJS.O5,$CLJS.C5],null)],null)],null),Xva,Pwa,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,d7],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.m(z6)?z6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return A6},Vwa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.g5],null)],null),Kva,cwa,"metabase/lib/drill_thru/pivot.cljc",21,1,119,119,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ex,b7,$CLJS.pb],null),$CLJS.dt,d7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.m(A6)?A6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return I6},Uva,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.D5],null)],null),dwa,bxa,"metabase/lib/drill_thru/quick_filter.cljc",28,1,96,96,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ex,p7,$CLJS.pb],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.m(I6)?I6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return J6},axa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.a6],null)],null),Awa,Sva,"metabase/lib/drill_thru/sort.cljc",20,1,51,51,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ex,p7,$CLJS.pb],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.m(J6)?J6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return K6},Xwa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,
$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.n5],null)],null),bwa,gwa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,1,[$CLJS.lm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ex,$CLJS.pb],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.m(K6)?K6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return Q6},fwa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.l5],null)],null),Nva,Twa,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,
[q7,$6,new $CLJS.k(null,1,[$CLJS.lm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ex,$CLJS.pb],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.m(Q6)?
Q6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return u6},zwa,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Z5],null)],null),ewa,Ova,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ex,p7,b7,$CLJS.pb],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.m(u6)?u6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return Z6},Pva,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.T5],null)],null),Ywa,Qva,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,
new $CLJS.k(null,2,[$CLJS.lm,new $CLJS.S(null,1,5,$CLJS.T,[b7],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.m(Z6)?Z6.J:null])),c7,!1],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return Y6},Zva,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.U5],null)],null),Nwa,Rwa,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,
$6,new $CLJS.k(null,2,[$CLJS.lm,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.pb],null),$CLJS.dt,d7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.m(Y6)?Y6.J:null])),c7,!0],null),new $CLJS.k(null,2,[$CLJS.at,new $CLJS.ud(function(){return U6},Mva,$CLJS.Sh([$CLJS.jm,$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ks,$CLJS.JL,$CLJS.ab,$CLJS.i5],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.X5],null)],null),Lva,Wva,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.X(new $CLJS.S(null,3,5,$CLJS.T,[q7,$6,new $CLJS.k(null,
2,[$CLJS.lm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ex,$CLJS.pb],null),$CLJS.dt,a7],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.m(U6)?U6.J:null])),c7,!0],null)],null);
$CLJS.dxa=function(){function a(d,e,f){try{return $CLJS.oh.h($CLJS.Lg,$CLJS.c1(d)?function(){var l=Jva(f);return function u(q){return new $CLJS.yf(null,function(){for(var v=q;;){var x=$CLJS.A(v);if(x){var y=x,B=$CLJS.C(y),H=$CLJS.Qf(B),I=$CLJS.M.h(H,$CLJS.at),Q=$CLJS.M.h(H,c7);if(x=$CLJS.A(function(Y,aa,ha,qa,Ea,kb,lb,Fb){return function Tb(ac){return new $CLJS.yf(null,function(Ub,ge,aA,tj){return function(){for(var Og=ac;;)if(Og=$CLJS.A(Og)){if($CLJS.re(Og)){var Np=$CLJS.$c(Og),IB=$CLJS.E(Np),Es=
$CLJS.Bf(IB);a:for(var Zt=0;;)if(Zt<IB){var Wv=$CLJS.be(Np,Zt);Wv=tj.j?tj.j(d,e,Wv):tj.call(null,d,e,Wv);$CLJS.m(Wv)&&Es.add(Wv);Zt+=1}else{Np=!0;break a}return Np?$CLJS.Ef($CLJS.Gf(Es),Tb($CLJS.ad(Og))):$CLJS.Ef($CLJS.Gf(Es),null)}Es=$CLJS.C(Og);Es=tj.j?tj.j(d,e,Es):tj.call(null,d,e,Es);if($CLJS.m(Es))return $CLJS.nf(Es,Tb($CLJS.zd(Og)));Og=$CLJS.zd(Og)}else return null}}(Y,aa,ha,qa,Ea,kb,lb,Fb),null,null)}}(v,B,H,I,Q,y,x,l)($CLJS.m(function(){var Y=Q;return $CLJS.m(Y)?l:Y}())?l:new $CLJS.S(null,
1,5,$CLJS.T,[f],null))))return $CLJS.qg.h(x,u($CLJS.zd(v)));v=$CLJS.zd(v)}else return null}},null,null)}(cxa)}():null)}catch(l){var g=l;throw $CLJS.yj(["Error getting available drill thrus for query: ",$CLJS.p.g($CLJS.UZ(g))].join(""),new $CLJS.k(null,3,[$CLJS.SD,d,$CLJS.yL,e,$CLJS.ZM,f],null),g);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.exa=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,g,l,n){var q=null;if(3<arguments.length){q=0;for(var u=Array(arguments.length-3);q<u.length;)u[q]=arguments[q+3],++q;q=new $CLJS.z(u,0,null)}return e.call(this,f,g,l,q)}function e(f,g,l,n){var q=$CLJS.ZF($CLJS.ww);$CLJS.m($CLJS.fG("metabase.lib.drill-thru",q))&&$CLJS.gG("metabase.lib.drill-thru",q,$CLJS.X_("Applying drill thru: %s",$CLJS.G([$CLJS.Y_(new $CLJS.k(null,4,[$CLJS.SD,f,$CLJS.yL,
g,Tva,l,$CLJS.Us,n],null))])),null);return $CLJS.R.R($CLJS.H4,f,g,l,n)}d.v=3;d.B=function(f){var g=$CLJS.C(f);f=$CLJS.D(f);var l=$CLJS.C(f);f=$CLJS.D(f);var n=$CLJS.C(f);f=$CLJS.zd(f);return e(g,l,n,f)};d.l=e;return d}();b=function(d,e,f,g){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var n=Array(arguments.length-3);l<n.length;)n[l]=arguments[l+3],++l;l=new $CLJS.z(n,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.v=3;b.B=c.B;b.h=a;b.l=c.l;return b}();