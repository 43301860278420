var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var u7,O6,t7,Usa,Wsa,Xsa,z7,A7,C7,D7,ssa,tsa,usa,wsa,e6,xsa,ysa,zsa;u7=function(a){var b=new $CLJS.k(null,3,[$CLJS.yC,$CLJS.p.g($CLJS.rL()),$CLJS.iy,$CLJS.iy.g(a),$CLJS.cb,$CLJS.Tx($CLJS.cb,$CLJS.iy)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fy,b,$CLJS.Tx($CLJS.jC,$CLJS.V)(a)],null)};O6=function(a){return $CLJS.U.j(a,$CLJS.cu,$CLJS.G3)};t7=function(a,b){return $CLJS.F.h($CLJS.dC.g(a),$CLJS.li(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EC,$CLJS.VB,$CLJS.DC],null)))};
Usa=function(a){return $CLJS.U3(a,new $CLJS.k(null,1,[$CLJS.EC,$CLJS.Oi],null))};
Wsa=function(a,b){var c=$CLJS.Xf(function(e){return $CLJS.wW(function(f){return $CLJS.F.h($CLJS.M.h(f,e),a)},b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DY,$CLJS.V],null));if($CLJS.m(c))return c;c=$CLJS.ZF($CLJS.zw);if($CLJS.m($CLJS.fG("metabase.lib.field",c))){var d=$CLJS.WH("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([a])),$CLJS.aj.l($CLJS.G([$CLJS.Wm.h($CLJS.DY,b)]))]));d instanceof Error?$CLJS.gG("metabase.lib.field",c,$CLJS.WB(),d):$CLJS.gG("metabase.lib.field",
c,$CLJS.WB.l($CLJS.G([d])),null)}return null};
Xsa=function(a,b,c){if($CLJS.m(v7))return null;var d=v7;v7=!0;try{var e=$CLJS.o7(a,b),f=$CLJS.m(e)?$CLJS.e3(a,e):$CLJS.e3(a,b),g=function(){var n=$CLJS.d6.g(f);if($CLJS.m(n))return n;n=$CLJS.Lu(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hV,$CLJS.uW],null));if($CLJS.m(n))return n;n=$CLJS.m(function(){var u=$CLJS.xV.g(f);if($CLJS.m(u))return u;u=$CLJS.XO.g(f);if($CLJS.m(u))return u;u=$CLJS.RP.g(f);return $CLJS.m(u)?u:$CLJS.JC.g(f)}())?$CLJS.N_.j(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.ZF($CLJS.zw);if($CLJS.m($CLJS.fG("metabase.lib.field",
n))){var q=$CLJS.WH("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))]));return q instanceof Error?$CLJS.gG("metabase.lib.field",n,$CLJS.WB(),q):$CLJS.gG("metabase.lib.field",n,$CLJS.WB.l($CLJS.G([q])),null)}return null}(),l=function(){var n=$CLJS.A(g);return n?Wsa(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.U.j($CLJS.U.j($CLJS.G5($CLJS.Km.l(l,$CLJS.Zf,$CLJS.G([$CLJS.KK,$CLJS.FW,$CLJS.AW])),null),$CLJS.V,function(){var n=$CLJS.DY.g(l);return $CLJS.m(n)?n:$CLJS.V.g(l)}()),
$CLJS.vK,$CLJS.pY):l:null}finally{v7=d}};
z7=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.lP);var f=$CLJS.J(c,2,null);c=$CLJS.nn.l($CLJS.G([function(){var g=$CLJS.iy.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.iy,g],null):null}(),function(){var g=$CLJS.Tx($CLJS.cb,$CLJS.iy)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.cb,g],null):null}(),function(){var g=$CLJS.mR.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.FW,g],null):null}(),function(){var g=$CLJS.EE.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.AW,g],null):null}(),$CLJS.Ae(f)?function(){var g=$CLJS.t1(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.cu,$CLJS.tK,$CLJS.V,$CLJS.p.g(f)],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.cu,$CLJS.tK,$CLJS.V,$CLJS.p.g(f)],null):function(){var g=Xsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.cu,$CLJS.tK,$CLJS.V,$CLJS.p.g(f)],null)}()]));return $CLJS.m(d)?$CLJS.G5(c,d):c};
A7=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.AW);return $CLJS.m($CLJS.m(b)?$CLJS.Ie($CLJS.XV,b):b)?$CLJS.Jj:$CLJS.Tx($CLJS.cb,$CLJS.iy)(a)};
$CLJS.rsa=function(a,b,c,d){$CLJS.J(d,0,null);var e=$CLJS.J(d,1,null),f=$CLJS.Qf(e),g=$CLJS.M.h(f,$CLJS.yC),l=$CLJS.M.h(f,$CLJS.iy),n=$CLJS.M.h(f,$CLJS.mR),q=$CLJS.M.h(f,$CLJS.cb);e=$CLJS.M.h(f,$CLJS.lP);var u=$CLJS.M.h(f,$CLJS.nN),v=$CLJS.M.h(f,$CLJS.EE),x=$CLJS.nn.l;g=new $CLJS.k(null,2,[$CLJS.cu,$CLJS.tK,$CLJS.m0,g],null);f=$CLJS.eC.g(f);a=$CLJS.m(f)?f:$CLJS.n3.j(a,b,d);c=x.call($CLJS.nn,$CLJS.G([g,c,new $CLJS.k(null,1,[$CLJS.eC,a],null)]));c=$CLJS.m(q)?$CLJS.U.j(c,$CLJS.cb,q):c;l=$CLJS.m(l)?$CLJS.U.j(c,
$CLJS.iy,l):c;v=$CLJS.m(v)?$CLJS.U.j(l,$CLJS.AW,v):l;n=$CLJS.m(n)?$CLJS.U.j(v,$CLJS.FW,n):v;u=$CLJS.m(u)?$CLJS.U.j(n,$CLJS.JK,u):n;return $CLJS.m(e)?$CLJS.G5(u,e):u};C7=function(a,b,c){return $CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Fk.g(d),c)},a))?$CLJS.Wm.h(function(d){var e=$CLJS.Ie(d,b)?$CLJS.Km.l(d,d,$CLJS.G([b])):d;return $CLJS.F.h($CLJS.Fk.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
D7=function(a){var b=$CLJS.m($CLJS.n1.g(a))?null:function(){var e=$CLJS.vK.g(a),f=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.GY,null,$CLJS.NY,null,$CLJS.pY,null],null),null);return f.g?f.g(e):f.call(null,e)}(),c=$CLJS.nn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.yC,$CLJS.p.g($CLJS.rL()),$CLJS.iy,$CLJS.iy.g(a),$CLJS.cb,A7(a)],null),function(){var e=function(){var f=$CLJS.Gb(b);return f?(f=$CLJS.Gb($CLJS.JK.g(a)))?(f=$CLJS.ki.h($CLJS.zY,$CLJS.vK.g(a)))?$CLJS.RY.g(a):f:f:f}();return $CLJS.m(e)?new $CLJS.k(null,
1,[$CLJS.lP,e],null):null}(),function(){var e=$CLJS.i1(a);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lP,e],null):null}(),function(){var e=$CLJS.AW.g(a);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.EE,e],null):null}(),function(){var e=$CLJS.EW.g(a);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.EW,e],null):null}(),function(){var e=$CLJS.FW.g(a);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.mR,e],null):null}(),function(){var e=$CLJS.JK.g(a);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.nN,e],null):null}()])),
d=($CLJS.m(b)?$CLJS.Tx($CLJS.DY,$CLJS.V):$CLJS.Tx($CLJS.Zf,$CLJS.V))(a);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,c,d],null)};ssa=function(a,b){return $CLJS.ug(function(c){return $CLJS.F.h($CLJS.vK.g(c),$CLJS.xY)},$CLJS.N_.D(a,b,$CLJS.e3(a,b),new $CLJS.k(null,3,[$CLJS.D_,!1,$CLJS.J_,!0,$CLJS.z_,!1],null)))};tsa=new $CLJS.N("lib","simple-name","lib/simple-name",-734346338);usa=new $CLJS.N("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.vsa=new $CLJS.N(null,"search-field-id","search-field-id",-699326724);wsa=new $CLJS.N("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);e6=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.d6=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);xsa=new $CLJS.N("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
ysa=new $CLJS.N(null,"earliest","earliest",-1928154382);zsa=new $CLJS.N(null,"latest","latest",24323665);$CLJS.V3.o(null,$CLJS.yE,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Oi.g(b),$CLJS.U3(c,new $CLJS.k(null,2,[$CLJS.EE,$CLJS.Oi,$CLJS.mR,Usa],null)),a],null)});
var v7=!1,I7=function I7(a,b){var d=$CLJS.b4(a,e6.g(b));a=$CLJS.m(e6.g(d))?I7.h?I7.h(a,d):I7.call(null,a,d):d;a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.V),f=$CLJS.M.h(a,$CLJS.eC);return $CLJS.Xm.j($CLJS.U.j($CLJS.Xm.j($CLJS.U.j(b,tsa,$CLJS.V.g(b)),$CLJS.V,function(g){return[$CLJS.p.g(e),".",$CLJS.p.g(g)].join("")}),xsa,$CLJS.eC.g(b)),$CLJS.eC,function(g){return[$CLJS.p.g(f),": ",$CLJS.p.g(g)].join("")})};$CLJS.O3.o(null,$CLJS.tK,function(a,b,c){return A7(c)});
$CLJS.O3.o(null,$CLJS.yE,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.EE);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.AW,d):c;return $CLJS.P3.j(a,b,c)});$CLJS.l3.o(null,$CLJS.tK,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});$CLJS.l3.o(null,$CLJS.yE,function(a,b,c){var d=z7(a,b,c);b=$CLJS.rsa(a,b,d,c);return $CLJS.m(e6.g(b))?I7(a,b):b});
$CLJS.K3.o(null,$CLJS.tK,function(a,b,c,d){function e(aa){var ha=$CLJS.P6(l,g);return $CLJS.xa.j?$CLJS.xa.j("%s: %s",aa,ha):$CLJS.xa.call(null,"%s: %s",aa,ha)}function f(aa){var ha=$CLJS.nG($CLJS.Pu($CLJS.ui(u),"-"," "));return $CLJS.xa.j?$CLJS.xa.j("%s: %s",aa,ha):$CLJS.xa.call(null,"%s: %s",aa,ha)}var g=$CLJS.Qf(c),l=$CLJS.M.h(g,$CLJS.FW),n=$CLJS.M.h(g,$CLJS.JK),q=$CLJS.M.h(g,$CLJS.KK),u=$CLJS.M.h(g,$CLJS.Fk),v=$CLJS.M.h(g,$CLJS.RY),x=$CLJS.M.h(g,xsa),y=$CLJS.M.h(g,$CLJS.V),B=$CLJS.M.h(g,$CLJS.Ira),
H=$CLJS.M.h(g,$CLJS.eC),I=$CLJS.M.h(g,e6),Q=$CLJS.$3.h($CLJS.my,y);c=function(){if($CLJS.m(x))return x;if($CLJS.m($CLJS.m(I)?null==H||$CLJS.F.h(H,Q):I)){a:{var aa=$CLJS.Qf(g);var ha=$CLJS.M.h(aa,$CLJS.eC);aa=$CLJS.M.h(aa,e6);for(ha=new $CLJS.gf(null,ha,null,1,null);;)if($CLJS.m(aa)){aa=$CLJS.b4(a,aa);var qa=$CLJS.Qf(aa);aa=$CLJS.M.h(qa,$CLJS.eC);qa=$CLJS.M.h(qa,e6);ha=$CLJS.kf.h(ha,aa);aa=qa}else break a}ha=$CLJS.Wf($CLJS.Hb,ha)?$CLJS.it(": ",ha):null}else ha=null;return $CLJS.m(ha)?ha:$CLJS.m(H)?
H:"string"===typeof y?Q:$CLJS.p.g(y)}();var Y=$CLJS.F.h(d,$CLJS.d_)&&-1==c.indexOf(" → ")?function(){if($CLJS.m(n)){var aa=$CLJS.b4(a,n);$CLJS.m(aa)?aa=$CLJS.cpa($CLJS.eC.g($CLJS.q3.j(a,b,aa))):(aa=$CLJS.h1(a,q),aa=$CLJS.n3.D(a,b,aa,d))}else aa=null;return $CLJS.m(aa)?aa:$CLJS.m(v)?v:$CLJS.i1(g)}():null;c=$CLJS.m(Y)?[$CLJS.p.g(Y)," → ",$CLJS.p.g(c)].join(""):c;return $CLJS.m(function(){var aa=$CLJS.ki.h(d,$CLJS.d_);return aa?B:aa}())?c:$CLJS.m($CLJS.m(u)?$CLJS.ki.h(c,f(Q)):u)?f(c):$CLJS.m($CLJS.m(l)?
$CLJS.ki.h(c,e(Q)):l)?e(c):c});$CLJS.K3.o(null,$CLJS.yE,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Qf(e);e=$CLJS.M.h(f,$CLJS.mR);var g=$CLJS.M.h(f,$CLJS.lP),l=$CLJS.M.h(f,$CLJS.EE);f=$CLJS.M.h(f,$CLJS.nN);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.RY,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.Fk,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,$CLJS.FW,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.JK,f):e;return $CLJS.m(e)?$CLJS.n3.D(a,b,e,d):$CLJS.XH("[Unknown Field]")});
$CLJS.L3.o(null,$CLJS.tK,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.L3.o(null,$CLJS.yE,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=z7(a,b,c);return $CLJS.m(c)?$CLJS.M3.j(a,b,c):"unknown_field"});
$CLJS.Q3.o(null,$CLJS.tK,function(a,b,c){return $CLJS.nn.l($CLJS.G([function(){var d=$CLJS.sj($CLJS.Q3,$CLJS.oa);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.n3.j(a,b,c),$CLJS.j_,$CLJS.n3.D(a,b,c,$CLJS.d_)],null),$CLJS.F.h($CLJS.vK.g(c),$CLJS.GY)?function(){var d=$CLJS.QY.g(c);return $CLJS.m(d)?(d=$CLJS.k3(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.tk,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.eC,$CLJS.V.g(d)],null)],null):null):null}():null]))});
$CLJS.s4.o(null,$CLJS.yE,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.EE.g(b)});$CLJS.s4.o(null,$CLJS.tK,function(a){return $CLJS.AW.g(a)});
$CLJS.r4.o(null,$CLJS.yE,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ie($CLJS.XV,b),e=$CLJS.Ky($CLJS.EW,$CLJS.cb,$CLJS.iy)(c);c=$CLJS.U.l(c,$CLJS.EE,b,$CLJS.G([$CLJS.cb,d?$CLJS.Jj:e,$CLJS.EW,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,c,a],null)}b=$CLJS.EW.g(c);c=$CLJS.m(b)?$CLJS.Km.h($CLJS.U.j(c,$CLJS.cb,b),$CLJS.EW):c;c=$CLJS.Km.h(c,$CLJS.EE);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yE,c,a],null)});
$CLJS.r4.o(null,$CLJS.tK,function(a,b){return $CLJS.m(b)?$CLJS.U.l(a,$CLJS.AW,b,$CLJS.G([$CLJS.EW,$CLJS.Ky($CLJS.EW,$CLJS.cb,$CLJS.iy)(a)])):$CLJS.Km.l(a,$CLJS.AW,$CLJS.G([$CLJS.EW]))});$CLJS.t4.o(null,$CLJS.yE,function(a,b,c){return $CLJS.E7.j(a,b,z7(a,b,c))});
$CLJS.t4.o(null,$CLJS.tK,function(a,b,c){if($CLJS.ki.h($CLJS.vK.g(c),$CLJS.xY)){a=$CLJS.Tx($CLJS.cb,$CLJS.iy)(c);b=null==c?null:$CLJS.BN.g(c);if(null==b)var d=null;else try{var e=$CLJS.Uk.g($CLJS.$k.g(b));if($CLJS.m(e)){var f=$CLJS.Qf(e),g=$CLJS.M.h(f,ysa),l=$CLJS.M.h(f,zsa),n=$CLJS.Ssa.l($CLJS.G([$CLJS.n7.g(g),$CLJS.n7.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.vt.h?$CLJS.vt.h(1,n):$CLJS.vt.call(null,1,n))?$CLJS.Ok:$CLJS.m($CLJS.vt.h?$CLJS.vt.h(31,n):$CLJS.vt.call(null,31,n))?$CLJS.fl:$CLJS.m($CLJS.vt.h?
$CLJS.vt.h(365,n):$CLJS.vt.call(null,365,n))?$CLJS.Fj:$CLJS.Kj}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.QH(a,$CLJS.Uk)?$CLJS.Tqa:$CLJS.QH(a,$CLJS.Ik)?$CLJS.Sqa:$CLJS.QH(a,$CLJS.gl)?$CLJS.Rqa:$CLJS.Lg;d=$CLJS.m(d)?C7(e,$CLJS.oa,d):e;return $CLJS.m($CLJS.AW.g(c))?C7(d,$CLJS.r_,$CLJS.AW.g(c)):d}return $CLJS.Lg});
$CLJS.S6.o(null,$CLJS.yE,function(a){var b=null==a?null:$CLJS.GH(a);b=null==b?null:$CLJS.mR.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.cu,$CLJS.v4,$CLJS.G([$CLJS.u4,function(c,d){return z7(c,d,a)}]))});$CLJS.S6.o(null,$CLJS.tK,function(a){var b=null==a?null:$CLJS.FW.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.cu,$CLJS.v4,$CLJS.G([$CLJS.u4,$CLJS.ag(a)]))});$CLJS.R6.o(null,$CLJS.yE,function(a,b){return $CLJS.fW(a,$CLJS.eW,$CLJS.G([$CLJS.mR,b]))});
$CLJS.R6.o(null,$CLJS.tK,function(a,b){return $CLJS.eW(a,$CLJS.FW,b)});$CLJS.T6.o(null,$CLJS.yE,function(a,b,c){return $CLJS.F7.j(a,b,z7(a,b,c))});
$CLJS.T6.o(null,$CLJS.tK,function(a,b,c){b=$CLJS.Qf(c);c=$CLJS.M.h(b,$CLJS.cb);var d=$CLJS.M.h(b,$CLJS.BN),e=$CLJS.M.h(b,$CLJS.Xj);if($CLJS.ki.h($CLJS.vK.g(b),$CLJS.xY)){var f=function(){var n=null==a?null:$CLJS.b1(a);n=null==n?null:$CLJS.Jx.g(n);return null==n?null:$CLJS.Ie(n,$CLJS.mR)}(),g=$CLJS.Lu(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$k,$CLJS.Uj],null)),l=$CLJS.s7(b);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),
B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<y){var Q=$CLJS.be(x,I),Y=B,aa=Q;Q=t7(Q,l)?$CLJS.U.j(aa,$CLJS.r_,!0):aa;Y.add(Q);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf(function(){var I=H;return t7(H,l)?$CLJS.U.j(I,$CLJS.r_,!0):I}(),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Gb(function(){if($CLJS.m(f)){var q=$CLJS.fb.g(g);return $CLJS.m(q)?$CLJS.mk.g(g):q}return f}())?null:$CLJS.QH(e,$CLJS.Lj)?$CLJS.Wm.h(O6,new $CLJS.S(null,
5,5,$CLJS.T,[$CLJS.N6(),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("Bin every 0.1 degrees"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.DC,$CLJS.DC,.1],null)],null),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("Bin every 1 degree"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.DC,$CLJS.DC,1],null)],null),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("Bin every 10 degrees"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.DC,$CLJS.DC,10],null)],null),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("Bin every 20 degrees"),$CLJS.dC,new $CLJS.k(null,
2,[$CLJS.EC,$CLJS.DC,$CLJS.DC,20],null)],null)],null)):$CLJS.QH(c,$CLJS.Uj)&&!$CLJS.QH(e,$CLJS.Ia)?$CLJS.Wm.h(O6,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.N6(),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("10 bins"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.VB,$CLJS.VB,10],null)],null),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("50 bins"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.VB,$CLJS.VB,50],null)],null),new $CLJS.k(null,2,[$CLJS.eC,$CLJS.XH("100 bins"),$CLJS.dC,new $CLJS.k(null,2,[$CLJS.EC,$CLJS.VB,$CLJS.VB,
100],null)],null)],null)):null)}return $CLJS.Lg});$CLJS.dW.o(null,$CLJS.yE,function(a){return a});
$CLJS.dW.o(null,$CLJS.tK,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.vK);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,3,[$CLJS.yC,$CLJS.p.g($CLJS.rL()),$CLJS.cb,$CLJS.Tx($CLJS.cb,$CLJS.iy)(a),$CLJS.uL,$CLJS.V.g(a)],null),a=$CLJS.m0.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nE,b,a],null);case "source/expressions":return u7(a);case "source/fields":case "source/breakouts":return $CLJS.m($CLJS.jC.g(a))?u7(a):D7(a);default:return D7(a)}});
$CLJS.e9=function(){function a(e,f,g){g=$CLJS.Rf($CLJS.Wm.h($CLJS.iW,g));var l=ssa(e,f),n=$CLJS.oh.j($CLJS.oi,$CLJS.Fn(function(q){return $CLJS.y1.D(e,f,q,l)}),$CLJS.m(g)?g:$CLJS.Lg);n=$CLJS.Vm.h(n,l);g=$CLJS.m(g)?$CLJS.oh.j(g,$CLJS.rg.g($CLJS.iW),n):null;return $CLJS.J7.l(e,f,$CLJS.eW,$CLJS.G([$CLJS.JC,g]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,g){return d.j?d.j(f,g,e):d.call(null,f,g,e)}}var d=null;d=function(e,f,g){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,g)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.h=b;d.j=a;return d}();$CLJS.f9=function(){function a(d,e){return $CLJS.JC.g($CLJS.e3(d,e))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.g9=function(){function a(d,e){var f=$CLJS.N_.D(d,e,$CLJS.e3(d,e),new $CLJS.k(null,3,[$CLJS.D_,!1,$CLJS.J_,!1,$CLJS.z_,!1],null)),g=$CLJS.f9.h(d,e);return $CLJS.je(g)?$CLJS.Wm.h(function(l){return $CLJS.U.j(l,$CLJS.EY,!0)},f):$CLJS.M2(d,e,f,g)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.lL(wsa,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ok,$CLJS.Oj,$CLJS.sY,$CLJS.Ix],null));
$CLJS.lL(usa,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.BC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vsa,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.BC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MY,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,wsa],null)],null)],null));