var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.h1=function(a,b){var c=$CLJS.e0(b);return $CLJS.m(c)?$CLJS.k3(a,c):$CLJS.j3(a,b)};$CLJS.i1=function(a){var b=$CLJS.EH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.lP.g($CLJS.GH(a));case "metadata/column":return $CLJS.ZY.g(a);case "mbql/join":return $CLJS.OC.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};
$CLJS.j1=function(a,b){return $CLJS.xa.j?$CLJS.xa.j("%s__%s",a,b):$CLJS.xa.call(null,"%s__%s",a,b)};$CLJS.k1=function(a,b){var c=$CLJS.i1(b);if($CLJS.m(c))a=c;else a:{var d=$CLJS.Qf(b);c=$CLJS.M.h(d,$CLJS.JK);d=$CLJS.M.h(d,$CLJS.KK);if($CLJS.m($CLJS.m(c)?d:c)&&(d=$CLJS.h1(a,d),$CLJS.m(d))){d=$CLJS.V.g(d);a=$CLJS.V.g($CLJS.b4(a,c));a=$CLJS.xa.j?$CLJS.xa.j("%s__via__%s",d,a):$CLJS.xa.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.m(a)?$CLJS.j1(a,$CLJS.V.g(b)):$CLJS.V.g(b)};
$CLJS.l1=function(a,b,c,d){var e=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.KK),c);return $CLJS.oh.j($CLJS.Lg,$CLJS.Sm.l($CLJS.tg($CLJS.HK),$CLJS.tg($CLJS.Zf),$CLJS.tg($CLJS.Sm.h($CLJS.Eb,$CLJS.Zf)),$CLJS.G([$CLJS.rg.g(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.Zf),l=$CLJS.M.h(f,$CLJS.HK);return $CLJS.U.l($CLJS.b4(a,l),$CLJS.A_,g,$CLJS.G([$CLJS.B_,$CLJS.ZY.g(f)]))}),$CLJS.Vm.g(function(f){return $CLJS.Ie(e,$CLJS.KK.g(f))}),$CLJS.XG(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.KK),l=$CLJS.M.h(f,
$CLJS.A_),n=$CLJS.M.h(f,$CLJS.B_);f=$CLJS.j3(a,g);return function v(u){return new $CLJS.yf(null,function(){for(;;){var x=$CLJS.A(u);if(x){if($CLJS.re(x)){var y=$CLJS.$c(x),B=$CLJS.E(y),H=$CLJS.Bf(B);return function(){for(var Y=0;;)if(Y<B){var aa=$CLJS.be(y,Y),ha=$CLJS.U.l(aa,$CLJS.JK,l,$CLJS.G([$CLJS.x_,n,$CLJS.vK,$CLJS.zY,$CLJS.oY,$CLJS.V.g(aa)]));aa=H;var qa=$CLJS.U,Ea=qa.j,kb=ha;ha=$CLJS.k1(a,ha);ha=d.g?d.g(ha):d.call(null,ha);qa=Ea.call(qa,kb,$CLJS.DY,ha);aa.add(qa);Y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(H),
v($CLJS.ad(x))):$CLJS.Ef($CLJS.Gf(H),null)}var I=$CLJS.C(x),Q=$CLJS.U.l(I,$CLJS.JK,l,$CLJS.G([$CLJS.x_,n,$CLJS.vK,$CLJS.zY,$CLJS.oY,$CLJS.V.g(I)]));return $CLJS.nf($CLJS.U.j(Q,$CLJS.DY,function(){var Y=$CLJS.k1(a,Q);return d.g?d.g(Y):d.call(null,Y)}()),v($CLJS.zd(x)))}return null}},null,null)}($CLJS.R3.D(a,b,f,new $CLJS.k(null,2,[$CLJS.F_,d,$CLJS.z_,!1],null)))})])),c)};$CLJS.d4=function(a,b){return $CLJS.g1.h(a,b)};$CLJS.m1=function(a){return $CLJS.WH("Question {0}",$CLJS.G([$CLJS.aj.l($CLJS.G([a]))]))};
$CLJS.f4=function(a,b){var c=$CLJS.JC.g(b);$CLJS.m(c)||(c=$CLJS.JY.g(b),$CLJS.m(c)||(c=$CLJS.IY.g(b),c=null!=c?$CLJS.L_.g($CLJS.d4(a,$CLJS.r2.g(c))):null));return $CLJS.m(c)?(c=$CLJS.Rf($CLJS.oe(c)?$CLJS.uW.g(c):$CLJS.ne(c)?c:null),$CLJS.m(c)?$CLJS.Wm.h($CLJS.Az(tna,a,b),c):null):null};$CLJS.n1=new $CLJS.N("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.o1=new $CLJS.N(null,"field-ref","field-ref",-1699303428);$CLJS.K3.o(null,$CLJS.SK,function(a,b,c){return $CLJS.Tx($CLJS.eC,$CLJS.V)(c)});$CLJS.l3.o(null,$CLJS.SK,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);c=$CLJS.M.h(a,$CLJS.eC);return $CLJS.Gb(c)?$CLJS.U.j(a,$CLJS.eC,$CLJS.$3.h($CLJS.my,b)):a});
$CLJS.R3.o(null,$CLJS.SK,function(a,b,c,d){c=$CLJS.Qf(c);var e=$CLJS.M.h(c,$CLJS.JC),f=$CLJS.M.h(c,$CLJS.JY);d=$CLJS.Qf(d);var g=$CLJS.M.h(d,$CLJS.z_),l=$CLJS.M.h(d,$CLJS.F_);return $CLJS.qg.h($CLJS.L_.D(a,b,c,d),$CLJS.m(g)?$CLJS.l1(a,b,$CLJS.qg.h(e,f),l):null)});$CLJS.N3.o(null,$CLJS.xV,function(a,b){var c=$CLJS.e3(a,b);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.xV);if($CLJS.m(c)){var d=$CLJS.k3(a,c);a=$CLJS.m(d)?$CLJS.n3.D(a,b,d,$CLJS.d_):null;return $CLJS.m(a)?a:$CLJS.m1(c)}return null});
var tna=function(){function a(d,e,f){var g=$CLJS.Km.h($CLJS.dG(f,$CLJS.pG),$CLJS.o1);f=$CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.iy,$CLJS.Nj,$CLJS.cu,$CLJS.tK],null),function(){var l=$CLJS.Zf.g(g);if($CLJS.m(l))try{return $CLJS.b4(d,l)}catch(n){return null}else return null}(),g,new $CLJS.k(null,3,[$CLJS.cu,$CLJS.tK,$CLJS.vK,$CLJS.GY,$CLJS.oY,$CLJS.Tx($CLJS.oY,$CLJS.V)(g)],null)]));f=$CLJS.m(e)?$CLJS.U.j(f,$CLJS.QY,$CLJS.a1(e)):f;e=$CLJS.m($CLJS.m(!0)?$CLJS.Gb(e)||$CLJS.Gb($CLJS.vY.g($CLJS.k3(d,
$CLJS.a1(e)))):!0)?$CLJS.U.j(f,$CLJS.n1,!0):f;return $CLJS.ki.h($CLJS.Xj.g(g),$CLJS.Fa)?$CLJS.U.j(e,$CLJS.HK,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.K_.o(null,$CLJS.SK,function(a,b,c,d){b=$CLJS.Qf(d);var e=$CLJS.M.h(b,$CLJS.F_);return $CLJS.Wm.h(function(f){var g=$CLJS.Ky($CLJS.DY,$CLJS.oY,$CLJS.V)(f);return $CLJS.U.j(f,$CLJS.DY,e.g?e.g(g):e.call(null,g))},$CLJS.f4(a,c))});