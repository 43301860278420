var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var W6,oua,pta,l0;$CLJS.t8=function(a,b){return $CLJS.Vc($CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Uc(c,d):c},$CLJS.Tc($CLJS.Lg),b))};W6=function(a){return $CLJS.m(V6)?["(",$CLJS.p.g(a),")"].join(""):a};oua=function(a,b){var c=$CLJS.Xm.D(a,$CLJS.RP,$CLJS.cg($CLJS.kf,$CLJS.Lg),b);return $CLJS.qe($CLJS.JC.g(a))?$CLJS.Xm.D(c,$CLJS.JC,$CLJS.kf,$CLJS.hW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fy,$CLJS.P,$CLJS.$_(b)],null))):c};
pta=function(a,b,c){var d=$CLJS.$_(c);return $CLJS.U.l($CLJS.li($CLJS.b_.j(a,b,c),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.iy,$CLJS.cb,$CLJS.DY,$CLJS.oY,$CLJS.m0,$CLJS.cu],null)),$CLJS.vK,$CLJS.xY,$CLJS.G([$CLJS.V,d,$CLJS.eC,d]))};l0=new $CLJS.N("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.m0=new $CLJS.N("lib","source-uuid","lib/source-uuid",1828897581);var u0=function(){function a(d,e,f){var g=$CLJS.e3(d,e);g=$CLJS.wW($CLJS.Sm.h($CLJS.pi([f]),$CLJS.$_),$CLJS.RP.g(g));if($CLJS.m(g))return g;throw $CLJS.zj($CLJS.WH("No expression named {0}",$CLJS.G([$CLJS.aj.l($CLJS.G([f]))])),new $CLJS.k(null,3,[$CLJS.SR,f,$CLJS.SD,d,$CLJS.yL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.j=a;return c}();$CLJS.O3.o(null,$CLJS.fy,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);c=u0.j(a,b,c);return $CLJS.P3.j(a,b,c)});$CLJS.l3.o(null,$CLJS.fy,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.J(c,2,null);return new $CLJS.k(null,7,[$CLJS.cu,$CLJS.tK,$CLJS.m0,$CLJS.yC.g(d),$CLJS.V,e,$CLJS.jC,e,$CLJS.eC,$CLJS.n3.j(a,b,c),$CLJS.iy,$CLJS.P3.j(a,b,c),$CLJS.vK,$CLJS.xY],null)});$CLJS.K3.o(null,$CLJS.Yx,function(a,b,c){return $CLJS.p.g(c)});
$CLJS.K3.o(null,$CLJS.Wx,function(a,b,c){return $CLJS.p.g(c)});$CLJS.K3.o(null,$CLJS.Xx,function(a,b,c){return['"',$CLJS.p.g(c),'"'].join("")});$CLJS.K3.o(null,$CLJS.py,function(a,b,c){return $CLJS.p.g(c)});$CLJS.K3.o(null,$CLJS.fy,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);return $CLJS.J(c,2,null)});$CLJS.L3.o(null,$CLJS.fy,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);return $CLJS.J(c,2,null)});
for(var V6=!1,osa=new $CLJS.k(null,4,[$CLJS.es,"+",$CLJS.ft,"-",$CLJS.fs,"×",$CLJS.TE,"÷"],null),v0=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.es,$CLJS.ft,$CLJS.TE,$CLJS.fs],null)),N0=null,O0=0,P0=0;;)if(P0<O0){var ena=N0.$(null,P0);$CLJS.pV(ena,l0);P0+=1}else{var Q0=$CLJS.A(v0);if(Q0){var R0=Q0;if($CLJS.re(R0)){var S0=$CLJS.$c(R0),fna=$CLJS.ad(R0),gna=S0,hna=$CLJS.E(S0);v0=fna;N0=gna;O0=hna}else{var ina=$CLJS.C(R0);$CLJS.pV(ina,l0);v0=$CLJS.D(R0);N0=null;O0=0}P0=0}else break}
$CLJS.K3.o(null,l0,function(a,b,c){var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);$CLJS.C(d);d=$CLJS.D(d);c=$CLJS.M.h(osa,c);a:{var e=V6;V6=!0;try{var f=$CLJS.it([" ",$CLJS.ui(c)," "].join(""),$CLJS.rg.h($CLJS.Az($CLJS.n3,a,b),d));break a}finally{V6=e}f=void 0}return W6(f)});$CLJS.L3.o(null,l0,function(){return"expression"});
$CLJS.O3.o(null,$CLJS.xG,function(a,b,c){c=$CLJS.A(c);$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var d=$CLJS.D(c);return $CLJS.Ve($CLJS.oV,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=$CLJS.P3.j(a,b,x);u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf($CLJS.P3.j(a,
b,u),g($CLJS.zd(l)))}return null}},null,null)}(d)}())});$CLJS.K3.o(null,$CLJS.Cy,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);a=$CLJS.p.g($CLJS.n3.D(a,b,e,d));b=$CLJS.p;d=b.g;c=$CLJS.q4.h(f,c).toLowerCase();0<f?f=$CLJS.xa.j?$CLJS.xa.j("+ %d %s",f,c):$CLJS.xa.call(null,"+ %d %s",f,c):(f=$CLJS.Sx(f),f=$CLJS.xa.j?$CLJS.xa.j("- %d %s",f,c):$CLJS.xa.call(null,"- %d %s",f,c));f=W6(f);return[a," ",d.call(b,f)].join("")});
$CLJS.L3.o(null,$CLJS.Cy,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);a=$CLJS.p.g($CLJS.M3.j(a,b,d));b=$CLJS.p;d=b.g;c=$CLJS.q4.h(e,c).toLowerCase();0<e?e=$CLJS.xa.j?$CLJS.xa.j("plus_%s_%s",e,c):$CLJS.xa.call(null,"plus_%s_%s",e,c):(e=$CLJS.Sx(e),e=$CLJS.xa.j?$CLJS.xa.j("minus_%d_%s",e,c):$CLJS.xa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.K3.o(null,$CLJS.BE,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.n3.D(a,b,e,d)});$CLJS.L3.o(null,$CLJS.BE,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.M3.j(a,b,d)});
$CLJS.b9=function(){function a(d,e,f,g){return $CLJS.J7.l(d,$CLJS.m(e)?e:-1,oua,$CLJS.G([$CLJS.tW($CLJS.lW.g(g),f)]))}function b(d,e,f){return c.D?c.D(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.D=a;return c}();
$CLJS.h8=function(){function a(d,e){var f=$CLJS.Rf($CLJS.RP.g($CLJS.e3(d,e)));return null==f?null:$CLJS.Wm.h($CLJS.Az(pta,d,e),f)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.c9=function(){function a(d,e){return $CLJS.Rf($CLJS.RP.g($CLJS.e3(d,e)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();$CLJS.dW.o(null,$CLJS.fy,function(a){return a});
$CLJS.d9=function(){function a(d,e,f){var g=$CLJS.oh.h($CLJS.P,$CLJS.dg(function(n,q){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$_(q),n],null)},$CLJS.c9.h(d,e))),l=$CLJS.e3(d,e);d=$CLJS.N_.j(d,e,l);return $CLJS.Rf($CLJS.t8(function(n){return $CLJS.Gb(f)||$CLJS.ki.h($CLJS.vK.g(n),$CLJS.xY)||$CLJS.M.h(g,$CLJS.V.g(n))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.j=a;return c}();$CLJS.jna=function(){function a(d,e,f){return $CLJS.iW(pta(d,e,u0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.T0=$CLJS.sy.g($CLJS.RC);