var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Fu,Hoa,m_,a0,Ioa,Joa,Loa,p_,bla,ala;Fu=function(a){return a+1};$CLJS.sL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.ko(a));};
Hoa=function(a){var b=new $CLJS.rb,c=$CLJS.Tz;$CLJS.Tz=new $CLJS.gd(b);try{var d=$CLJS.Tz,e=$CLJS.Gb($CLJS.aB(d)),f=$CLJS.Tz;$CLJS.Tz=e?$CLJS.SA(d):d;try{d=$CLJS.cB;$CLJS.cB=!0;try{$CLJS.eB(a)}finally{$CLJS.cB=d}$CLJS.F.h(0,$CLJS.$z($CLJS.Tz,$CLJS.fu))||$CLJS.Qc($CLJS.Tz,"\n");$CLJS.Zz()}finally{$CLJS.Tz=f}$CLJS.Pz($CLJS.p.g(b))}finally{$CLJS.Tz=c}};$CLJS.X_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};
$CLJS.Y_=function(a){var b=$CLJS.PA;$CLJS.PA=120;try{var c=new $CLJS.rb,d=$CLJS.ea,e=$CLJS.Ab;$CLJS.ea=!0;$CLJS.Ab=function(f){return c.append(f)};try{Hoa(a)}finally{$CLJS.Ab=e,$CLJS.ea=d}return $CLJS.p.g(c)}finally{$CLJS.PA=b}};
m_=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.j(a,$CLJS.sV,$CLJS.Ye),c=$CLJS.M.j(a,$CLJS.kV,function(f,g){return[$CLJS.p.g(f),"_",$CLJS.p.g(g)].join("")}),d=$CLJS.na($CLJS.P),e=$CLJS.na($CLJS.P);return function(){function f(n,q){for(;;){var u=b.g?b.g(q):b.call(null,q),v=function(){var y=new $CLJS.S(null,2,5,$CLJS.T,[n,u],null),B=$CLJS.r(d);return B.g?B.g(y):B.call(null,y)}();if($CLJS.m(v))return v;var x=$CLJS.M.h($CLJS.dj.D(e,$CLJS.Xm,u,$CLJS.cg(Fu,0)),u);if($CLJS.F.h(x,1))return $CLJS.dj.D(d,$CLJS.U,
new $CLJS.S(null,2,5,$CLJS.T,[n,u],null),q),q;q=function(){var y=q,B=$CLJS.p.g(x);return c.h?c.h(y,B):c.call(null,y,B)}()}}function g(n){var q=l,u=q.h;null==p_&&(p_=$CLJS.na(0));var v=$CLJS.Ui.g([$CLJS.p.g("G__"),$CLJS.p.g($CLJS.dj.h(p_,Fu))].join(""));return u.call(q,v,n)}var l=null;l=function(n,q){switch(arguments.length){case 1:return g.call(this,n);case 2:return f.call(this,n,q)}throw Error("Invalid arity: "+arguments.length);};l.g=g;l.h=f;return l}()};
$CLJS.Z_=function(a){var b=$CLJS.qe(a);return b?(b=$CLJS.C(a)instanceof $CLJS.N)?(a=$CLJS.M.h(a,1),$CLJS.oe(a)&&$CLJS.Ie(a,$CLJS.yC)):b:b};$CLJS.$_=function(a){return $CLJS.Z_(a)?$CLJS.jC.g($CLJS.GH(a)):null};$CLJS.tW=function(a,b){return $CLJS.gW($CLJS.Z_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vl,new $CLJS.k(null,2,[$CLJS.yC,$CLJS.p.g($CLJS.rL()),$CLJS.cb,$CLJS.FL(a)],null),a],null),function(c){return $CLJS.Km.l($CLJS.U.j(c,$CLJS.jC,b),$CLJS.V,$CLJS.G([$CLJS.eC]))})};
a0=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Xm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.As)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.TN(d,$CLJS.Pf([b,c])):d};Ioa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XO,$CLJS.zQ],null));b=b0.g?b0.g(b):b0.call(null,b);return $CLJS.hW($CLJS.U.l(a0($CLJS.Km.l(a,$CLJS.XO,$CLJS.G([$CLJS.zQ])),$CLJS.IR,$CLJS.dL),$CLJS.cu,$CLJS.WK,$CLJS.G([$CLJS.aL,b])))};
Joa=function(a){return $CLJS.Wm.h(Ioa,a)};Loa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Xm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.uW,a],null):a,$CLJS.uW,function(b){return $CLJS.Wm.h(function(c){return $CLJS.U.j($CLJS.dG(c,$CLJS.pG),$CLJS.cu,$CLJS.tK)},b)}),$CLJS.cu,ala):null};
$CLJS.d0=function(a){if($CLJS.F.h($CLJS.cu.g(a),$CLJS.oW))return a;var b=$CLJS.$k.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.cu,$CLJS.oW,$CLJS.aL,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.cu,$CLJS.bW],null),$CLJS.TN($CLJS.aQ.g(a),new $CLJS.k(null,1,[$CLJS.SD,$CLJS.aQ],null))]))],null)],null),$CLJS.Km.l(a,$CLJS.$k,$CLJS.G([$CLJS.aQ]))]));case "query":return $CLJS.nn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.cu,$CLJS.oW,$CLJS.aL,b0($CLJS.SD.g(a))],null),$CLJS.Km.l(a,$CLJS.$k,$CLJS.G([$CLJS.SD]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.zW=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.aL);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.zj($CLJS.WH("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[bla,$CLJS.E(a)],null));return c};
$CLJS.e3=function(a,b){a=$CLJS.d0(a);a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.aL);return $CLJS.M.h($CLJS.Mg(c),$CLJS.zW(a,b))};$CLJS.e0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.sL(a)):null};$CLJS.a2=function(){return $CLJS.Sm.h($1,m_($CLJS.G([$CLJS.sV,$CLJS.lG,$CLJS.kV,function(a,b){return $1.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};p_=null;
$CLJS.AW=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.BW=new $CLJS.N("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.CW=new $CLJS.N(null,"join","join",-758861890);$CLJS.DW=new $CLJS.N(null,"stage","stage",1843544772);bla=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.uW=new $CLJS.N(null,"columns","columns",1998437288);
$CLJS.EW=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);ala=new $CLJS.N("metadata","results","metadata/results",330329298);$CLJS.FW=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var cla,dla,ela,fla,gla,b0,$1;cla=$CLJS.na($CLJS.P);dla=$CLJS.na($CLJS.P);ela=$CLJS.na($CLJS.P);fla=$CLJS.na($CLJS.P);gla=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.FH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.GW=new $CLJS.vj($CLJS.Ui.h("metabase.lib.util","custom-name-method"),$CLJS.EH,gla,cla,dla,ela,fla);$CLJS.GW.o(null,$CLJS.oa,function(a){return $CLJS.Z_(a)?$CLJS.Tx($CLJS.eC,$CLJS.jC)($CLJS.GH(a)):null});
b0=function b0(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.zQ),d=$CLJS.M.h(a,$CLJS.JO);c=$CLJS.m(c)?b0.g?b0.g(c):b0.call(null,c):$CLJS.Lg;d=Loa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Ru(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.hV],null),d):c;d=$CLJS.m($CLJS.aQ.g(a))?$CLJS.bW:$CLJS.IV;a=$CLJS.nn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.cu,d],null),$CLJS.Km.l(a,$CLJS.zQ,$CLJS.G([$CLJS.JO]))]));a=$CLJS.A($CLJS.mN.g(a))?$CLJS.Xm.j(a,$CLJS.mN,Joa):a;a=a0(a,$CLJS.$D,$CLJS.pW);return $CLJS.kf.h(c,
a)};
$CLJS.J7=function(){function a(c,d,e,f){var g=null;if(3<arguments.length){g=0;for(var l=Array(arguments.length-3);g<l.length;)l[g]=arguments[g+3],++g;g=new $CLJS.z(l,0,null)}return b.call(this,c,d,e,g)}function b(c,d,e,f){c=$CLJS.d0(c);c=$CLJS.Qf(c);var g=$CLJS.M.h(c,$CLJS.aL);d=$CLJS.zW(c,d);e=$CLJS.R.R($CLJS.Xm,$CLJS.Mg(g),d,e,f);return $CLJS.U.j(c,$CLJS.aL,e)}a.v=3;a.B=function(c){var d=$CLJS.C(c);c=$CLJS.D(c);var e=$CLJS.C(c);c=$CLJS.D(c);var f=$CLJS.C(c);c=$CLJS.zd(c);return b(d,e,f,c)};a.l=
b;return a}();
$1=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.TZ.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.g(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.g(f)].join("")}function b(d){return c.h?c.h(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.h=a;return c}();$CLJS.n0=RegExp(" id$","i");