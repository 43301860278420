var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Y7,ota,Vsa,qta,rta,sta,tta,uta,vta,wta,i8,k8,k6;
Y7=function(a){return function(b){var c=$CLJS.ig($CLJS.oi);return function(){function d(l,n){var q=a.g?a.g(n):a.call(null,n);if($CLJS.Ie($CLJS.r(c),q))return l;c.Re(null,$CLJS.kf.h(c.$b(null),q));return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);
};g.A=f;g.g=e;g.h=d;return g}()}};ota=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.XG(function(d){return $CLJS.N_.D(a,b,d,new $CLJS.k(null,2,[$CLJS.F_,c,$CLJS.z_,!1],null))}),$CLJS.mN.g($CLJS.e3(a,b)))};Vsa=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.XG(function(d){return $CLJS.L_.D(a,b,d,c)}),$CLJS.mN.g($CLJS.e3(a,b)))};$CLJS.M5=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.J7.l(c,d,$CLJS.U,$CLJS.G([$CLJS.d6,$CLJS.L_.j(c,d,$CLJS.e3(c,d))]))},a,$CLJS.eu(0,$CLJS.zW(a,b)))};
qta=function(a,b){a=$CLJS.e3(a,b);a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.cu),d=$CLJS.M.h(a,$CLJS.xV);b=$CLJS.d6.g(a);if($CLJS.m(b))return b;var e=$CLJS.hV.g(a);if($CLJS.m(e)&&$CLJS.m(function(){var g=$CLJS.F.h(c,$CLJS.bW);return g?g:d}())){var f=function(){var g=c instanceof $CLJS.N?c.S:null;switch(g){case "mbql.stage/native":return $CLJS.NY;case "mbql.stage/mbql":return $CLJS.GY;default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}();return $CLJS.Rf(function(){return function n(l){return new $CLJS.yf(null,
function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.oY,$CLJS.V.g(B),$CLJS.DY,$CLJS.V.g(B)],null),B,new $CLJS.k(null,1,[$CLJS.vK,f],null)]));x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.nn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.oY,$CLJS.V.g(x),$CLJS.DY,$CLJS.V.g(x)],
null),x,new $CLJS.k(null,1,[$CLJS.vK,f],null)])),n($CLJS.zd(q)))}return null}},null,null)}($CLJS.uW.g(e))}())}return null};
rta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.Tx($CLJS.oY,$CLJS.V)(x);x=$CLJS.k1(a,x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.vK,$CLJS.MK,$CLJS.G([$CLJS.oY,Q,$CLJS.DY,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.vK,$CLJS.MK,$CLJS.G([$CLJS.oY,$CLJS.Tx($CLJS.oY,$CLJS.V)(u),$CLJS.DY,function(){var v=$CLJS.k1(a,u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.f8.h(a,b))}())};
sta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.vK,$CLJS.UK,$CLJS.G([$CLJS.oY,Q,$CLJS.DY,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=
$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.vK,$CLJS.UK,$CLJS.G([$CLJS.oY,$CLJS.V.g(u),$CLJS.DY,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.g8.h(a,b))}())};
tta=function(a,b,c){var d=$CLJS.e3(a,b);if($CLJS.m(d)){d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.JC);return $CLJS.Rf(function(){return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var I=0;;)if(I<u){var Q=$CLJS.be(q,I),Y=$CLJS.J(Q,0,null),aa=function(){var qa=Y;qa=qa instanceof $CLJS.N?qa.S:null;switch(qa){case "field":return $CLJS.LY;case "expression":return $CLJS.xY;default:throw Error(["No matching clause: ",
$CLJS.p.g(qa)].join(""));}}(),ha=$CLJS.b_.j(a,b,Q);$CLJS.Ff(v,$CLJS.U.l(ha,$CLJS.vK,aa,$CLJS.G([$CLJS.oY,$CLJS.M3.j(a,b,ha),$CLJS.DY,function(){var qa=$CLJS.k1(a,ha);return c.g?c.g(qa):c.call(null,qa)}()])));I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n),y=$CLJS.J(x,0,null),B=function(){var I=y;I=I instanceof $CLJS.N?I.S:null;switch(I){case "field":return $CLJS.LY;case "expression":return $CLJS.xY;default:throw Error(["No matching clause: ",
$CLJS.p.g(I)].join(""));}}(),H=$CLJS.b_.j(a,b,x);return $CLJS.nf($CLJS.U.l(H,$CLJS.vK,B,$CLJS.G([$CLJS.oY,$CLJS.M3.j(a,b,H),$CLJS.DY,function(){var I=$CLJS.k1(a,H);return c.g?c.g(I):c.call(null,I)}()])),l($CLJS.zd(n)))}return null}},null,null)}(e)}())}return null};uta=function(a,b,c){return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.XG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.S(null,2,5,$CLJS.T,[rta,sta],null)))};
vta=function(a,b,c){var d=$CLJS.o7(a,b);return $CLJS.m(d)?$CLJS.Rf(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);return function(){for(var y=0;;)if(y<q){var B=$CLJS.be(n,y);var H=$CLJS.Tx($CLJS.DY,$CLJS.oY)(B);H=$CLJS.m(H)?H:$CLJS.M3.j(a,b,B);B=$CLJS.Km.l($CLJS.nn.l($CLJS.G([B,new $CLJS.k(null,3,[$CLJS.vK,$CLJS.pY,$CLJS.oY,H,$CLJS.DY,c.g?c.g(H):c.call(null,H)],null),$CLJS.m($CLJS.n1.g(B))?
$CLJS.li(B,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.n1],null)):null])),$CLJS.AW,$CLJS.G([$CLJS.jC]));u.add(B);y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}var v=$CLJS.C(l),x=function(){var y=$CLJS.Tx($CLJS.DY,$CLJS.oY)(v);return $CLJS.m(y)?y:$CLJS.M3.j(a,b,v)}();return $CLJS.nf($CLJS.Km.l($CLJS.nn.l($CLJS.G([v,new $CLJS.k(null,3,[$CLJS.vK,$CLJS.pY,$CLJS.oY,x,$CLJS.DY,c.g?c.g(x):c.call(null,x)],null),$CLJS.m($CLJS.n1.g(v))?$CLJS.li(v,new $CLJS.S(null,1,5,$CLJS.T,
[$CLJS.n1],null)):null])),$CLJS.AW,$CLJS.G([$CLJS.jC])),g($CLJS.zd(l)))}return null}},null,null)}($CLJS.L_.j(a,d,$CLJS.e3(a,d)))}()):null};wta=function(a,b,c,d){return $CLJS.m(c)?(c=$CLJS.k3(a,c),$CLJS.m(c)?$CLJS.Rf($CLJS.N_.D(a,b,c,d)):null):null};
i8=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q;var B=$CLJS.iy.g(x);var H=$CLJS.U.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=$CLJS.X7(H.call($CLJS.U,I,$CLJS.vK,$CLJS.xY,$CLJS.G([$CLJS.oY,Q,$CLJS.DY,x])),$CLJS.cb,$CLJS.m(B)?B:$CLJS.Nj);y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),
f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=$CLJS.C(g);return $CLJS.nf(function(){var v=$CLJS.iy.g(u),x=$CLJS.U.l,y=u,B=$CLJS.V.g(u);var H=$CLJS.V.g(u);H=c.g?c.g(H):c.call(null,H);return $CLJS.X7(x.call($CLJS.U,y,$CLJS.vK,$CLJS.xY,$CLJS.G([$CLJS.oY,B,$CLJS.DY,H])),$CLJS.cb,$CLJS.m(v)?v:$CLJS.Nj)}(),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.h8.h(a,b))}())};
k8=function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.F_);return $CLJS.Wm.h(function(f){return $CLJS.Km.l(f,$CLJS.ZY,$CLJS.G([$CLJS.AW,$CLJS.FW,$CLJS.JK]))},function(){var f=vta(a,b,e);if($CLJS.m(f))return f;f=$CLJS.e3(a,b);f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.XO),l=$CLJS.M.h(f,$CLJS.xV),n=$CLJS.m(g)?function(){var q=$CLJS.j3(a,g);return $CLJS.N_.D(a,b,q,d)}():null;if($CLJS.m(n))return n;l=$CLJS.m(l)?wta(a,b,l,$CLJS.U.j(d,$CLJS.z_,!1)):null;return $CLJS.m(l)?l:function v(u){return new $CLJS.yf(null,
function(){for(;;){var x=$CLJS.A(u);if(x){if($CLJS.re(x)){var y=$CLJS.$c(x),B=$CLJS.E(y),H=$CLJS.Bf(B);return function(){for(var Q=0;;)if(Q<B){var Y=$CLJS.be(y,Q),aa=H,ha=$CLJS.U,qa=ha.l,Ea=Y,kb=$CLJS.V.g(Y);Y=$CLJS.V.g(Y);Y=e.g?e.g(Y):e.call(null,Y);ha=qa.call(ha,Ea,$CLJS.vK,$CLJS.NY,$CLJS.G([$CLJS.oY,kb,$CLJS.DY,Y]));aa.add(ha);Q+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(H),v($CLJS.ad(x))):$CLJS.Ef($CLJS.Gf(H),null)}var I=$CLJS.C(x);return $CLJS.nf($CLJS.U.l(I,$CLJS.vK,$CLJS.NY,$CLJS.G([$CLJS.oY,$CLJS.V.g(I),
$CLJS.DY,function(){var Q=$CLJS.V.g(I);return e.g?e.g(Q):e.call(null,Q)}()])),v($CLJS.zd(x)))}return null}},null,null)}($CLJS.uW.g($CLJS.hV.g(f)))}())};k6=new $CLJS.N("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.sra={};$CLJS.pV($CLJS.IV,k6);$CLJS.pV($CLJS.bW,k6);$CLJS.V3.o(null,$CLJS.IV,function(a){return $CLJS.U3(a,new $CLJS.k(null,2,[$CLJS.nE,$CLJS.bg($CLJS.Wm,$CLJS.V3),$CLJS.pW,$CLJS.bg($CLJS.Wm,$CLJS.V3)],null))});$CLJS.l3.o(null,k6,function(){throw $CLJS.zj("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.P);});
$CLJS.R3.o(null,k6,function(a,b,c,d){var e=$CLJS.Qf(d);c=$CLJS.M.h(e,$CLJS.F_);d=$CLJS.M.h(e,$CLJS.z_);a=$CLJS.M5(a,b);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.F_),g=$CLJS.M.h(e,$CLJS.D_),l=$CLJS.M.h(e,$CLJS.J_);e=$CLJS.qg.l(k8(a,b,e),$CLJS.m(l)?i8(a,b,f):null,$CLJS.G([$CLJS.m(g)?ota(a,b,f):null]));return $CLJS.Mg($CLJS.qg.h(e,$CLJS.m(d)?$CLJS.l1(a,b,e,c):null))});
$CLJS.K_.o(null,k6,function(a,b,c,d){c=$CLJS.Qf(d);d=$CLJS.M.h(c,$CLJS.F_);var e=qta(a,b);if($CLJS.m(e))return e;a=$CLJS.M5(a,b);e=uta(a,b,d);var f=tta(a,b,d);return $CLJS.m(e)?$CLJS.oh.h(e,f):$CLJS.m(f)?($CLJS.Ci(f),$CLJS.oh.j($CLJS.Lg,Y7(function(g){return $CLJS.Km.l(g,$CLJS.RY,$CLJS.G([$CLJS.vK,$CLJS.m0,$CLJS.DY]))}),$CLJS.qg.h(f,Vsa(a,b,c)))):$CLJS.qg.l(k8(a,b,new $CLJS.k(null,2,[$CLJS.z_,!1,$CLJS.F_,d],null)),i8(a,b,d),$CLJS.G([Vsa(a,b,c)]))});$CLJS.K3.o(null,$CLJS.bW,function(){return $CLJS.XH("Native query")});
var Ysa=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,$CLJS.xV,$CLJS.mN],null),Zsa=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nE,$CLJS.mE,$CLJS.pW,$CLJS.GR,$CLJS.dS],null);
$CLJS.K3.o(null,$CLJS.IV,function(a,b,c,d){var e=$CLJS.M5(a,b);a=$CLJS.Rf(function(){var f=$CLJS.oh.j($CLJS.P,$CLJS.Sm.h($CLJS.$F,$CLJS.rg.g(function(l){return new $CLJS.S(null,2,5,$CLJS.T,[l,$CLJS.n8.j(e,b,l)],null)})),new $CLJS.S(null,2,5,$CLJS.T,[Ysa,Zsa],null)),g=$CLJS.it(" + ",$CLJS.Vm.h($CLJS.QF,$CLJS.rg.h(f,Ysa)));f=$CLJS.rg.h(f,Zsa);return $CLJS.it(", ",$CLJS.Vm.h($CLJS.QF,$CLJS.nf(g,f)))}());if($CLJS.m(a))return a;a=$CLJS.o7(e,b);return $CLJS.m(a)?$CLJS.n3.D(e,a,$CLJS.e3(e,a),d):null});