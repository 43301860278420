var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var xW,yW,uG,vG,Sla,Tla,Ula,wG,tG;$CLJS.fj=function(a,b){return $CLJS.Vc($CLJS.Sb(function(c,d){var e=a.g?a.g(d):a.call(null,d);return $CLJS.rh.j(c,e,$CLJS.kf.h($CLJS.M.j(c,e,$CLJS.Lg),d))},$CLJS.Tc($CLJS.P),b))};$CLJS.wW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};
xW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.QH(b,$CLJS.Ik)?$CLJS.XF:$CLJS.QH(b,$CLJS.gl)?$CLJS.HF:$CLJS.QH(b,$CLJS.Uk)?$CLJS.JF:null;return $CLJS.m(b)?$CLJS.rV(b,a):!0};
yW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.QH($CLJS.FL(d),$CLJS.vz)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.FL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(xW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
uG=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.As,new $CLJS.k(null,1,[$CLJS.tt,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ks,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.js,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tG],null)],
null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.js,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tG],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.Qt,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.vl);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(yW(b))].join("")}],null),$CLJS.$f(yW)],null)],null)};
vG=function(a){return new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.tt,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)],null)],null)};
Sla=function(a){return $CLJS.Ve($CLJS.oV,$CLJS.rg.h(function(b){var c=$CLJS.FL(b),d=$CLJS.QH(c,$CLJS.TC);b=d?$CLJS.rV($CLJS.CE,b):d;return $CLJS.m(b)?$CLJS.Uj:c},a))};Tla=function(a){a=$CLJS.wW(function(b){return!$CLJS.QH(b,$CLJS.vz)},$CLJS.rg.h($CLJS.FL,a));return $CLJS.QH(a,$CLJS.TC)?$CLJS.Zj:a};
Ula=function(a,b){return $CLJS.m($CLJS.Xf(function(c){return $CLJS.QH($CLJS.FL(c),$CLJS.vz)},b))?Tla(b):$CLJS.F.h(a,$CLJS.ft)&&$CLJS.F.h($CLJS.E(b),2)&&($CLJS.Wf(function(c){return $CLJS.QH($CLJS.FL(c),$CLJS.Ik)},b)||$CLJS.Wf(function(c){return $CLJS.QH($CLJS.FL(c),$CLJS.Uk)},b))?$CLJS.vz:Sla(b)};wG=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.xG=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);tG=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.lL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.As,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ms,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.es,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.W,tG],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.FL(a);return $CLJS.Wf(function(d){return xW(d,c)},b)}],null)],null));
$CLJS.lL(wG,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.js,new $CLJS.k(null,1,[$CLJS.fb,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)],null));
var Yfa=new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.tt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.ft],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.NC],null)],null)],
null);$CLJS.lF.h($CLJS.es,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,uG($CLJS.es),vG($CLJS.es)],null));$CLJS.lF.h($CLJS.ft,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.qs,uG($CLJS.ft),Yfa,vG($CLJS.ft)],null));$CLJS.CV($CLJS.fs,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,wG],null)]));$CLJS.CV($CLJS.TE,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Us,wG],null)]));
for(var yG=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.es,$CLJS.ft,$CLJS.fs],null)),zG=null,AG=0,BG=0;;)if(BG<AG){var Zfa=zG.$(null,BG);$CLJS.pV(Zfa,$CLJS.xG);BG+=1}else{var CG=$CLJS.A(yG);if(CG){var DG=CG;if($CLJS.re(DG)){var EG=$CLJS.$c(DG),$fa=$CLJS.ad(DG),aga=EG,bga=$CLJS.E(EG);yG=$fa;zG=aga;AG=bga}else{var cga=$CLJS.C(DG);$CLJS.pV(cga,$CLJS.xG);yG=$CLJS.D(DG);zG=null;AG=0}BG=0}else break}
$CLJS.EL.o(null,$CLJS.xG,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);$CLJS.C(b);b=$CLJS.D(b);return Ula(a,b)});$CLJS.AV($CLJS.WD,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.pV($CLJS.WD,$CLJS.iD);
for(var FG=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VE,$CLJS.sD,$CLJS.kE],null)),GG=null,HG=0,IG=0;;)if(IG<HG){var dga=GG.$(null,IG);$CLJS.AV(dga,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));IG+=1}else{var JG=$CLJS.A(FG);if(JG){var KG=JG;if($CLJS.re(KG)){var LG=$CLJS.$c(KG),ega=$CLJS.ad(KG),fga=LG,gga=$CLJS.E(LG);FG=ega;GG=fga;HG=gga}else{var hga=$CLJS.C(KG);$CLJS.AV(hga,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));FG=$CLJS.D(KG);GG=null;HG=0}IG=0}else break}
for(var MG=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MD,$CLJS.wE,$CLJS.DE],null)),NG=null,OG=0,PG=0;;)if(PG<OG){var iga=NG.$(null,PG);$CLJS.AV(iga,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));PG+=1}else{var QG=$CLJS.A(MG);if(QG){var YG=QG;if($CLJS.re(YG)){var ZG=$CLJS.$c(YG),jga=$CLJS.ad(YG),kga=ZG,lga=$CLJS.E(ZG);MG=jga;NG=kga;OG=lga}else{var mga=$CLJS.C(YG);$CLJS.AV(mga,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));MG=$CLJS.D(YG);NG=null;OG=0}PG=0}else break}$CLJS.AV($CLJS.TD,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));
$CLJS.EL.o(null,$CLJS.TD,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.QH($CLJS.FL(b),$CLJS.Jj)&&$CLJS.QH($CLJS.FL(a),$CLJS.Jj)?$CLJS.Jj:$CLJS.lB});