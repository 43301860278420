var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var ny,qD,nV,kfa;ny=function(a){if(null!=a&&null!=a.qa)a=a.cache;else{var b=ny[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=ny._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("Cached.-cache",a);}return a};
$CLJS.qy=function(a,b,c){if(null!=a&&$CLJS.t===a.eb){var d=ny(a);var e=$CLJS.r(d);e=e.g?e.g(b):e.call(null,b);if($CLJS.m(e))return e;a=$CLJS.dj.D(d,$CLJS.U,b,c.g?c.g(a):c.call(null,a));return a.g?a.g(b):a.call(null,b)}return c.g?c.g(a):c.call(null,a)};$CLJS.qV=function(a,b,c){a=$CLJS.sy.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.rV=function(a,b){return $CLJS.qV(a,b,null)};$CLJS.pV=function(a,b){$CLJS.dj.D($CLJS.FH,$CLJS.sG,a,b)};$CLJS.oV=function(a,b){a=nV(a,b);return $CLJS.m(a)?a:$CLJS.Nj};
qD=function(){return $CLJS.oh.j(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zs,new $CLJS.k(null,2,[$CLJS.ib,$CLJS.C,$CLJS.Qt,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.vl);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.am,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"not a known MBQL clause"],null),$CLJS.ag(!1)],null)],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,
2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(tV))};$CLJS.AV=function(a,b){$CLJS.R.D($CLJS.yV,$CLJS.zV,a,b)};$CLJS.CV=function(a,b){$CLJS.R.D($CLJS.yV,BV,a,b)};$CLJS.nm.prototype.qa=$CLJS.Ma(26,function(){return this.cache});$CLJS.qm.prototype.qa=$CLJS.Ma(25,function(){return this.cache});$CLJS.tm.prototype.qa=$CLJS.Ma(24,function(){return this.cache});$CLJS.wm.prototype.qa=$CLJS.Ma(23,function(){return this.cache});
$CLJS.zm.prototype.qa=$CLJS.Ma(22,function(){return this.cache});$CLJS.Bm.prototype.qa=$CLJS.Ma(21,function(){return this.cache});$CLJS.Fm.prototype.qa=$CLJS.Ma(20,function(){return this.cache});$CLJS.Tm.prototype.qa=$CLJS.Ma(19,function(){return this.cache});$CLJS.zn.prototype.qa=$CLJS.Ma(18,function(){return this.cache});$CLJS.Cn.prototype.qa=$CLJS.Ma(17,function(){return this.cache});$CLJS.Yn.prototype.qa=$CLJS.Ma(16,function(){return this.cache});$CLJS.Lo.prototype.qa=$CLJS.Ma(15,function(){return this.cache});
$CLJS.mp.prototype.qa=$CLJS.Ma(14,function(){return this.cache});$CLJS.wp.prototype.qa=$CLJS.Ma(13,function(){return this.cache});$CLJS.Ap.prototype.qa=$CLJS.Ma(12,function(){return this.cache});$CLJS.Hp.prototype.qa=$CLJS.Ma(11,function(){return this.cache});$CLJS.Lp.prototype.qa=$CLJS.Ma(10,function(){return this.cache});$CLJS.$p.prototype.qa=$CLJS.Ma(9,function(){return this.cache});$CLJS.jq.prototype.qa=$CLJS.Ma(8,function(){return this.cache});$CLJS.nq.prototype.qa=$CLJS.Ma(7,function(){return this.cache});
$CLJS.Jq.prototype.qa=$CLJS.Ma(6,function(){return this.cache});$CLJS.GB.prototype.qa=$CLJS.Ma(5,function(){return this.cache});$CLJS.sy=function sy(a){switch(arguments.length){case 1:return sy.g(arguments[0]);case 2:return sy.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.sy.g=function(a){return $CLJS.sy.h(a,null)};$CLJS.sy.h=function(a,b){return $CLJS.qy($CLJS.Xp.h(a,b),$CLJS.xm,$CLJS.Wo)};$CLJS.sy.v=2;
$CLJS.rD=function rD(a,b){return $CLJS.mj($CLJS.pB,a,b)||$CLJS.ye($CLJS.Xf(function(d){return rD.h?rD.h(a,d):rD.call(null,a,d)},$CLJS.rn.g(b)))||$CLJS.ye($CLJS.Xf(function(d){return rD.h?rD.h(d,b):rD.call(null,d,b)},$CLJS.oj(a)))};
nV=function nV(a,b){return $CLJS.F.h(a,$CLJS.Nj)?null:$CLJS.F.h(b,$CLJS.Nj)?null:$CLJS.rD(a,b)?b:$CLJS.rD(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:nV.h?nV.h(d,e):nV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};$CLJS.sD=new $CLJS.N(null,"exp","exp",-261706262);$CLJS.tD=new $CLJS.N(null,"substring","substring",1140866276);
$CLJS.HD=new $CLJS.N(null,"dimension","dimension",543254198);$CLJS.JD=new $CLJS.N(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.MD=new $CLJS.N(null,"ceil","ceil",-1824929952);$CLJS.SD=new $CLJS.N(null,"query","query",-1288509510);$CLJS.TD=new $CLJS.N(null,"power","power",-937852079);$CLJS.UD=new $CLJS.N(null,"segment","segment",-964921196);$CLJS.VD=new $CLJS.N(null,"concat","concat",-2108183992);$CLJS.WD=new $CLJS.N(null,"abs","abs",-246026477);
$CLJS.XD=new $CLJS.N(null,"is-null","is-null",-1997050930);$CLJS.YD=new $CLJS.N(null,"between","between",1131099276);$CLJS.ZD=new $CLJS.N(null,"is-empty","is-empty",-1040302908);$CLJS.$D=new $CLJS.N(null,"filter","filter",-948537934);$CLJS.aE=new $CLJS.N(null,"distinct","distinct",-1788879121);$CLJS.bE=new $CLJS.N(null,"var","var",-769682797);$CLJS.cE=new $CLJS.N(null,"next","next",-117701485);$CLJS.dE=new $CLJS.N(null,"inside","inside",1972503011);$CLJS.eE=new $CLJS.N(null,"case","case",1143702196);
$CLJS.gE=new $CLJS.N(null,"not-empty","not-empty",388922063);$CLJS.hE=new $CLJS.N(null,"column-name","column-name",551523580);$CLJS.iE=new $CLJS.N(null,"percentile","percentile",-601188752);$CLJS.jE=new $CLJS.N(null,"not-null","not-null",-1326718535);$CLJS.kE=new $CLJS.N(null,"sqrt","sqrt",-1270051929);$CLJS.mE=new $CLJS.N(null,"breakout","breakout",-732419050);$CLJS.nE=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.oE=new $CLJS.N(null,"desc","desc",2093485764);
$CLJS.pE=new $CLJS.N(null,"starts-with","starts-with",366503009);$CLJS.qE=new $CLJS.N(null,"does-not-contain","does-not-contain",-274946853);$CLJS.rE=new $CLJS.N(null,"short","short",1928760516);$CLJS.sE=new $CLJS.N(null,"avg","avg",197406200);$CLJS.tE=new $CLJS.N(null,"metric","metric",408798077);$CLJS.uE=new $CLJS.N(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.vE=new $CLJS.N(null,"asc","asc",356854569);$CLJS.wE=new $CLJS.N(null,"floor","floor",1882041021);
$CLJS.xE=new $CLJS.N(null,"contains","contains",676899812);$CLJS.yE=new $CLJS.N(null,"field","field",-1302436500);$CLJS.zE=new $CLJS.N(null,"cum-count","cum-count",-1964431543);$CLJS.AE=new $CLJS.N(null,"regex-match-first","regex-match-first",-657299718);$CLJS.BE=new $CLJS.N(null,"coalesce","coalesce",654622029);$CLJS.CE=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.DE=new $CLJS.N(null,"round","round",2009433328);
$CLJS.EE=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.FE=new $CLJS.N(null,"share","share",-589433933);$CLJS.GE=new $CLJS.N(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.HE=new $CLJS.N(null,"count-where","count-where",385407720);$CLJS.IE=new $CLJS.N(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.JE=new $CLJS.N(null,"ends-with","ends-with",-456791011);$CLJS.KE=new $CLJS.N(null,"sum","sum",136986814);
$CLJS.LE=new $CLJS.N(null,"median","median",569566131);$CLJS.ME=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);kfa=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.NE=new $CLJS.N(null,"stddev","stddev",-865474939);$CLJS.OE=new $CLJS.N(null,"trim","trim",774319767);$CLJS.PE=new $CLJS.N(null,"!\x3d","!\x3d",-1841737356);$CLJS.QE=new $CLJS.N(null,"ltrim","ltrim",1654269283);
$CLJS.RE=new $CLJS.N(null,"category","category",-593092832);$CLJS.SE=new $CLJS.N(null,"sum-where","sum-where",2135348428);$CLJS.TE=new $CLJS.N(null,"/","/",1282502798);$CLJS.UE=new $CLJS.N(null,"cum-sum","cum-sum",2064902349);$CLJS.VE=new $CLJS.N(null,"log","log",-1595516004);$CLJS.WE=new $CLJS.N(null,"replace","replace",-786587770);$CLJS.kF=new $CLJS.N(null,"rtrim","rtrim",-661336449);var tV=$CLJS.na($CLJS.oi),BV;$CLJS.lL($CLJS.GC,qD());$CLJS.HH(tV,kfa,function(){return $CLJS.lL($CLJS.GC,qD())});
$CLJS.lF=function(){function a(d,e,f,g){c.h?c.h(d,g):c.call(null,d,g);$CLJS.EL.o(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.Oi.h("mbql.clause",$CLJS.ui(d));$CLJS.lL(f,e);$CLJS.Ie($CLJS.r(tV),d)||$CLJS.dj.j(tV,$CLJS.kf,d);return null}var c=null;c=function(d,e,f,g){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.D=a;return c}();
BV=function BV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return BV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
BV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ms,new $CLJS.k(null,1,[$CLJS.tt,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Al,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null)],null)],null)],null),b)],null)};BV.v=1;
BV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};$CLJS.zV=function zV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return zV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
$CLJS.zV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vs,new $CLJS.k(null,1,[$CLJS.tt,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.wC],null)],null),b)};$CLJS.zV.v=1;$CLJS.zV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
$CLJS.yV=function yV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};$CLJS.yV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.ft)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.lF.D(b,$CLJS.ft,c,a):$CLJS.lF.h(b,a)};$CLJS.yV.v=2;
$CLJS.yV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};