var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var N2,wma,xma,yma,zma,tY,Ama,Bma,uY,Cma,wY,Dma,Ema,Fma,BY,CY,Gma,Hma,KY,Ima,Jma,Kma,PY,Lma;N2=function(){};$CLJS.O2=function(a){return null!=a?$CLJS.t===a.ej?!0:a.rd?!1:$CLJS.Nb(N2,a):$CLJS.Nb(N2,a)};wma=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.oY=new $CLJS.N("lib","source-column-alias","lib/source-column-alias",1494821386);
xma=new $CLJS.N("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);yma=new $CLJS.N("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.pY=new $CLJS.N("source","previous-stage","source/previous-stage",1962610878);$CLJS.qY=new $CLJS.N(null,"database-id","database-id",1883826326);zma=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.rY=new $CLJS.N("source","table-defaults","source/table-defaults",909975194);$CLJS.sY=new $CLJS.N(null,"search","search",1564939822);tY=new $CLJS.N("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Ama=new $CLJS.N("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Bma=new $CLJS.N(null,"dbms-version","dbms-version",-69238036);
uY=new $CLJS.N("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Cma=new $CLJS.N(null,"field-name","field-name",1300687948);$CLJS.vY=new $CLJS.N(null,"dataset","dataset",1159262238);wY=new $CLJS.N("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Dma=new $CLJS.N(null,"field-definitions","field-definitions",1650155116);
$CLJS.xY=new $CLJS.N("source","expressions","source/expressions",-458367840);$CLJS.yY=new $CLJS.N(null,"definition","definition",-1198729982);Ema=new $CLJS.N(null,"state","state",-1988618099);$CLJS.zY=new $CLJS.N("source","implicitly-joinable","source/implicitly-joinable",-729101197);Fma=new $CLJS.N(null,"is-audit","is-audit",327058420);$CLJS.AY=new $CLJS.N("source","joins","source/joins",1225821486);
BY=new $CLJS.N("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);CY=new $CLJS.N("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.DY=new $CLJS.N("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.EY=new $CLJS.N(null,"selected?","selected?",-742502788);Gma=new $CLJS.N("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Hma=new $CLJS.N("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.FY=new $CLJS.N(null,"table-name","table-name",-2117866341);$CLJS.GY=new $CLJS.N("source","card","source/card",-139977973);$CLJS.HY=new $CLJS.N(null,"active","active",1895962068);$CLJS.IY=new $CLJS.N(null,"dataset-query","dataset-query",1851066427);$CLJS.JY=new $CLJS.N(null,"result-metadata","result-metadata",2017934672);
KY=new $CLJS.N("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Ima=new $CLJS.N(null,"details","details",1956795411);$CLJS.LY=new $CLJS.N("source","fields","source/fields",-649667981);$CLJS.MY=new $CLJS.N(null,"has-field-values","has-field-values",626648377);Jma=new $CLJS.N("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.NY=new $CLJS.N("source","native","source/native",-1444604147);
Kma=new $CLJS.N("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.OY=new $CLJS.N(null,"auto-list","auto-list",314197731);PY=new $CLJS.N("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.QY=new $CLJS.N("lib","card-id","lib/card-id",-1770167062);Lma=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.RY=new $CLJS.N(null,"source-alias","source-alias",1652088724);$CLJS.SY=new $CLJS.N(null,"engine","engine",1459054265);$CLJS.lL(BY,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.ok,$CLJS.GY,$CLJS.NY,$CLJS.pY,$CLJS.rY,$CLJS.LY,$CLJS.UK,$CLJS.MK,$CLJS.AY,$CLJS.xY,$CLJS.zY],null));$CLJS.lL(uY,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ok],null),$CLJS.Pe($CLJS.Le,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.OY,null,$CLJS.sY,null,$CLJS.Oj,null,$CLJS.Ix,null],null),null))));
$CLJS.lL(KY,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.TK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.DK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EK,$CLJS.BC],null)],null));
$CLJS.lL(tY,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.OK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.DK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.Is],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.Is],null)],null)],null));
$CLJS.lL($CLJS.CK,new $CLJS.S(null,20,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid column metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.tK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.ak],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iy,$CLJS.CC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Zf,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.BC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.ps,$CLJS.ak],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cb,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.CC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.BC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.BC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RY,
new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,BY],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.FK],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,new $CLJS.k(null,2,[$CLJS.fb,1,$CLJS.mk,60],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.MY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,uY],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.IK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,KY],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,tY],null)],null)],
null)],null));$CLJS.lL(PY,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FY,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[Dma,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[Cma,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iy,$CLJS.CC],null)],null)],null)],null)],null)],null));
$CLJS.lL(wY,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HY,$CLJS.Hs],null),new $CLJS.S(null,2,5,$CLJS.T,[Ema,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FY,$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,PY],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,
$CLJS.oC],null)],null)],null));
$CLJS.lL(Jma,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid Card metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.SK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.FK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qY,$CLJS.GK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.IY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ik],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JY,
new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mj,$CLJS.ik],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.KK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.yK],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[yma,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.W,wY],null)],null)],null)],null));
$CLJS.lL(Gma,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid Segment metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.xK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.wK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KK,$CLJS.yK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yY,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.St,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null)],null));
$CLJS.lL(Hma,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid Metric metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.zK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.uK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KK,$CLJS.yK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yY,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.St,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null)],null));
$CLJS.lL(Kma,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid Table metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.BK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.yK],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.V,$CLJS.oC],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.eC,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jm,new $CLJS.k(null,
1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.oC],null)],null)],null));
$CLJS.lL(xma,new $CLJS.S(null,10,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"Valid Database metadata"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.QK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Zf,$CLJS.GK],null),new $CLJS.S(null,3,5,$CLJS.T,[Bma,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Ima,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.ik],null),new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.SY,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Sf],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jx,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cj,$CLJS.Sf],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Fma,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LK,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.ik],null)],null)],null));
$CLJS.lL(CY,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"Valid MetadataProvider"],null),new $CLJS.ud(function(){return $CLJS.O2},zma,$CLJS.Sh([$CLJS.Hk,$CLJS.V,$CLJS.rk,$CLJS.$a,$CLJS.ob,$CLJS.Ej,$CLJS.zk,$CLJS.Za,$CLJS.nk,$CLJS.qk],[wma,Lma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.X(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Xq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.m($CLJS.O2)?$CLJS.O2.J:null]))],null));
$CLJS.lL(Ama,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ik,new $CLJS.k(null,1,[$CLJS.tt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,CY],null)],null)],null)],null));