var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Rfa,Sfa,Tfa,Ufa,Vfa,Wfa,Xfa;$CLJS.wF=new $CLJS.N(null,"second","second",-444702010);$CLJS.xF=new $CLJS.N(null,"second-of-minute","second-of-minute",222734326);$CLJS.yF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.BF=new $CLJS.N(null,"millisecond","millisecond",-540123566);$CLJS.CF=new $CLJS.N(null,"year-of-era","year-of-era",682445876);
Rfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);Sfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.FF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.HF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.IF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Tfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
Ufa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Vfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Wfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.JF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.LF=new $CLJS.N("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.MF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.XF=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);Xfa=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var MV=new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Kk,$CLJS.Vk,$CLJS.Qj,$CLJS.Va,$CLJS.bl,$CLJS.Jk,$CLJS.ra,$CLJS.CF],null),NV=$CLJS.si(MV),lla,nla,WV;$CLJS.lL(Vfa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid date extraction unit"],null)],null),NV));var OV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.fl,$CLJS.Fj,$CLJS.Kj,$CLJS.Oa,$CLJS.ra],null),PV=$CLJS.si(OV);
$CLJS.lL(Ufa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid date truncation unit"],null)],null),PV));$CLJS.QV=$CLJS.oh.j($CLJS.Lg,$CLJS.pn.A(),$CLJS.qg.h(OV,MV));lla=$CLJS.si($CLJS.QV);$CLJS.lL($CLJS.IF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid date bucketing unit"],null)],null),lla));var RV=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xF,$CLJS.Tk,$CLJS.Gk],null),SV=$CLJS.si(RV);
$CLJS.lL(Tfa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid time extraction unit"],null)],null),SV));var TV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.BF,$CLJS.wF,$CLJS.Ok,$CLJS.cl],null),UV=$CLJS.si(TV);$CLJS.lL(Xfa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid time truncation unit"],null)],null),UV));$CLJS.mla=$CLJS.oh.j($CLJS.Lg,$CLJS.pn.A(),$CLJS.qg.h(TV,RV));nla=$CLJS.si($CLJS.mla);
$CLJS.lL(Sfa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid time bucketing unit"],null)],null),nla));$CLJS.VV=$CLJS.oh.j($CLJS.Lg,$CLJS.pn.A(),$CLJS.qg.l(TV,OV,$CLJS.G([RV,MV])));WV=$CLJS.si($CLJS.VV);$CLJS.lL($CLJS.yF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid datetime bucketing unit"],null)],null),$CLJS.VV));var ola=$CLJS.kf.h(WV,$CLJS.oa);
$CLJS.lL($CLJS.MF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid temporal bucketing unit"],null)],null),ola));$CLJS.pla=$CLJS.zz.h(PV,UV);$CLJS.lL($CLJS.FF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid datetime truncation unit"],null)],null),$CLJS.pla));$CLJS.XV=$CLJS.zz.h(NV,SV);$CLJS.lL(Rfa,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid datetime extraction unit"],null)],null),$CLJS.XV));
var YV=$CLJS.kf.h(PV,$CLJS.ra);$CLJS.lL($CLJS.XF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid date interval unit"],null)],null),YV));$CLJS.lL($CLJS.HF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid time interval unit"],null)],null),UV));var qla=$CLJS.zz.h(YV,UV);$CLJS.lL($CLJS.JF,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ok,new $CLJS.k(null,1,[$CLJS.tt,"Valid datetime interval unit"],null)],null),qla));
$CLJS.lL(Wfa,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.LF],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Fk,$CLJS.MF],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oa,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),$CLJS.Hs],null)],null));