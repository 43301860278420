var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var ofa,pfa,qfa,ufa,wfa,yfa,Afa,qF,Bfa,Cfa,Dfa,Efa,Hfa,Lfa,Ofa,Pfa,sF,Qfa;$CLJS.mF=new $CLJS.N(null,"supported-field","supported-field",-2061545519);ofa=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.nF=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);pfa=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
qfa=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.oF=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);ufa=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.pF=new $CLJS.N(null,"display-info","display-info",-816930907);wfa=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);yfa=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Afa=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);qF=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Bfa=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Cfa=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);Dfa=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Efa=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);
Hfa=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Lfa=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Ofa=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.rF=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Pfa=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);sF=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.tF=new $CLJS.N("operator","aggregation","operator/aggregation",-1961510924);Qfa=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.CV($CLJS.gw,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fy,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)],null)],null)],null)]));$CLJS.CV($CLJS.zE,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fy,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gs,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)],null)],null)],null)]));
$CLJS.AV($CLJS.sE,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.AV($CLJS.aE,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.RC],null)],null)]));$CLJS.AV($CLJS.HE,$CLJS.G([$CLJS.ft,$CLJS.Jj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)],null)]));
$CLJS.AV($CLJS.mk,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)],null)]));$CLJS.pV($CLJS.mk,$CLJS.iD);$CLJS.AV($CLJS.LE,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.pV($CLJS.LE,$CLJS.iD);$CLJS.AV($CLJS.fb,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.jD],null)],null)]));$CLJS.pV($CLJS.fb,$CLJS.iD);
$CLJS.lL(qF,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.As,new $CLJS.k(null,1,[$CLJS.tt,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nb,new $CLJS.k(null,1,[$CLJS.tt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.AV($CLJS.iE,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,qF],null)]));$CLJS.pV($CLJS.iE,$CLJS.iD);
$CLJS.AV($CLJS.FE,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)],null)]));$CLJS.AV($CLJS.NE,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.AV($CLJS.KE,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));
$CLJS.AV($CLJS.UE,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.pV($CLJS.KE,$CLJS.iD);$CLJS.pV($CLJS.UE,$CLJS.iD);$CLJS.AV($CLJS.SE,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.QC],null)],null)]));$CLJS.pV($CLJS.SE,$CLJS.iD);
$CLJS.AV($CLJS.bE,$CLJS.G([$CLJS.ft,$CLJS.lB,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,$CLJS.oD],null)],null)]));$CLJS.lL(sF,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.qs,Pfa,Lfa,yfa,pfa,ofa,wfa,Afa,Bfa,Cfa,Hfa,Dfa,Qfa,Ofa,qfa,Efa,$CLJS.Lb],null));$CLJS.lL($CLJS.nF,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mj,new $CLJS.k(null,1,[$CLJS.fb,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.W,sF],null)],null));
$CLJS.vW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.rE,$CLJS.gw,$CLJS.oF,!1,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Count of rows"),$CLJS.hE,$CLJS.XH("Count"),$CLJS.St,$CLJS.XH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.KE,$CLJS.mF,$CLJS.gk,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Sum of ..."),$CLJS.hE,$CLJS.XH("Sum"),$CLJS.St,$CLJS.XH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.sE,$CLJS.mF,$CLJS.gk,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Average of ..."),$CLJS.hE,$CLJS.XH("Average"),$CLJS.St,$CLJS.XH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.LE,$CLJS.mF,$CLJS.gk,$CLJS.oF,!0,$CLJS.rF,$CLJS.uE,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Median of ..."),$CLJS.hE,$CLJS.XH("Median"),$CLJS.St,$CLJS.XH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.aE,$CLJS.mF,$CLJS.Is,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Number of distinct values of ..."),$CLJS.hE,$CLJS.XH("Distinct values"),$CLJS.St,$CLJS.XH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.UE,$CLJS.mF,$CLJS.gk,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Cumulative sum of ..."),
$CLJS.hE,$CLJS.XH("Sum"),$CLJS.St,$CLJS.XH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.rE,$CLJS.zE,$CLJS.oF,!1,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Cumulative count of rows"),$CLJS.hE,$CLJS.XH("Count"),$CLJS.St,$CLJS.XH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.NE,$CLJS.mF,$CLJS.gk,$CLJS.oF,
!0,$CLJS.rF,$CLJS.IE,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Standard deviation of ..."),$CLJS.hE,$CLJS.XH("SD"),$CLJS.St,$CLJS.XH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.fb,$CLJS.mF,$CLJS.Yf,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Minimum of ..."),$CLJS.hE,$CLJS.XH("Min"),$CLJS.St,$CLJS.XH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.rE,$CLJS.mk,$CLJS.mF,$CLJS.Yf,$CLJS.oF,!0,$CLJS.rF,$CLJS.JD,$CLJS.pF,function(){return new $CLJS.k(null,3,[$CLJS.eC,$CLJS.XH("Maximum of ..."),$CLJS.hE,$CLJS.XH("Max"),$CLJS.St,$CLJS.XH("Maximum value of a column")],null)}],null)],null);
$CLJS.lL(ufa,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.ik,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$r,$CLJS.tF],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rE,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ok],null),$CLJS.rg.g($CLJS.rE),$CLJS.vW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mF,new $CLJS.k(null,1,[$CLJS.Ks,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ps,$CLJS.Sf],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oF,$CLJS.Hs],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.rF,$CLJS.Sf],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pF,$CLJS.de],null)],null));